import React from "react";
import ImageContainer from "../../../image-container";
import { IMAGE_URL, SUCCESS_MESSAGE } from "./consts";
import "./index.scss";

export default function SuccessModal({ reference }) {
    return <dialog ref={reference} className="mort-contact-success-modal">
        <form method="dialog">
            <button value="default" formMethod="dialog"><div className="close-container">
                <div />
                <div />
            </div></button>
            <ImageContainer src={IMAGE_URL} />
            <h2 >{SUCCESS_MESSAGE}</h2>
        </form>
    </dialog>
}