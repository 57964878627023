import React from "react";
import "./index.scss";
import {
  DATE_CTA,
  NO_CONTENT_TEXT,
  NO_CONTENT_TITLE,
  TOTAL_LOANS,
} from "./texts";
import { HEADER_ANIMATION, NO_DATA } from "./consts";

export default function PersonalAreaHeader({ totalLoans, updatedAt }) {
  if (!totalLoans)
    return (
      <div className="personal-area-header-container">
        <div className="no-content-container">
          <h1>{NO_CONTENT_TITLE}</h1>
          <h4>{NO_CONTENT_TEXT}</h4>
        </div>
        <div className="animation-container">
          <object type="image/svg+xml" rel="prefetch" data={NO_DATA} />
        </div>
      </div>
    );
  return (
    <div className="personal-area-header-container">
      <h1>
        <span className="total-loans">
          {totalLoans}

          <span className="shekel-and-star-container">
            <span className="shekel-container">*</span>
            <span className="shekel-container">₪</span>
          </span>
        </span>
        <span>{TOTAL_LOANS}</span>
      </h1>
      <h4>
        {DATE_CTA}
        {updatedAt}
      </h4>
      <div className="animation-container">
        <object type="image/svg+xml" rel="prefetch" data={HEADER_ANIMATION} />
      </div>
    </div>
  );
}
