import React, { useRef } from "react";

import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";
import CTAAnimationClick from "../../../../../components/product-pages/CTAAnimationClick";
import Container from "../../../../../components/product-pages/container";

import { ANALYTICS } from "../../consts";
import { URLS } from "../../../../../shared/urls";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { CTA_TITLE, PARAGRAPHS, SUBTITLE, TITLE } from "./texts";

import "./index.scss";
import ImageContainer from "../../../../../components/product-pages/image-container";
import { THIRD_SECTION_IMAGE } from "./consts";

export default function ThirdSection() {
  const CTARef = useRef();

  return (
    <GridSection className="reduced-mort-third-section">
      <TextSection className="reduced-mort-third-section-title" title={TITLE} text={SUBTITLE} />
      {PARAGRAPHS.map(({ ...props }, index) => {
        return <TextSection key={index} {...props} />;
      })}
      <Container className="animation-cta-container">
        <CTAAnimationClick
          analytics={ANALYTICS.THIRD_SECTION.CTA}
          reference={CTARef}
          title={CTA_TITLE}
          text={CTA_BUTTON_TEXT}
          CTALink={URLS.MortgageQue}
        />
      </Container>
      <ImageContainer className="reduced-mort-third-section-spread" src={THIRD_SECTION_IMAGE}/>
    </GridSection>
  );
}
