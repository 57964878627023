export const CROSS_COUNTRY = {
  name: "כלל ארצי",
  map: "other/israel",
  locked: false,
};

export const CARD_CITIES = {
  TEL_AVIV: {
    CODE: 1212,
    LOCKED: false,
  },
  BEER_SHEVA: {
    CODE: 1259,
    LOCKED: true,
  },
  JERUSALEM: {
    CODE: 1046,
    LOCKED: true,
  },
  HAIFA: {
    CODE: 2779,
    LOCKED: true,
  },
};
