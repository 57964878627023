import axios              from "axios";
import React, {Component} from "react";

import config          from "../../../../config/config";
import {MortEnums}     from "../../enums/MortEnums";

import "./index.scss";
import {CalcSettings}  from "./section/calc-settings";
import {ChooseSection} from "./section/choose";

class MortCalculator extends Component {
	constructor(props) {
		super(props);

		this.state = {
			propertyType: MortEnums.PropertyType.New,
			purchaseType: MortEnums.PurchaseType.FirstProperty,
			calcType    : MortEnums.CalcType.MyMoney,
			showIntro   : true,
			ready       : false
		};
	}

	async componentDidMount() {
		const response = await axios.get(
			config.mortServerAddr + "/mortgage/config"
		);

		if (response.data) {
			this.mortgageLimits = {...response.data};
			this.setState({ready: true});
		}
	}

	forward = (purchaseType, calcType) => {
		if (calcType !== undefined) {
			this.setState({
				showIntro   : false,
				calcType,
				purchaseType,
				propertyType: MortEnums.PropertyType.New
			});
		}
		else {
			this.setState({
				showIntro   : false,
				calcType    : MortEnums.CalcType.PropertyValue,
				purchaseType:
					purchaseType === 0
					? MortEnums.PurchaseType.FirstProperty
					: MortEnums.PurchaseType.Investment,
				propertyType: MortEnums.PropertyType.Existing
			});
		}
	};

	render = () =>
		this.state.ready ? (
			<div className = "MortCalc" >
				{this.state.showIntro ? (
					<ChooseSection forward = {this.forward} />
				) : (
					 <div className = "calculator" >
						 <CalcSettings
							 calcType = {this.state.calcType}
							 mortgageLimits = {this.mortgageLimits}
							 propertyType = {this.state.propertyType}
							 purchaseType = {this.state.purchaseType}
						 />
					 </div >
				 )}
			</div >
		) : (
			""
		);
}

export {MortCalculator};
