import Utils   from "../../../../../../../../shared/utils";
import {Slide} from "../../slide";

import "./index.scss";

const ErrorSlide = ({confirmSend, dontSend, address, withShadow}) => <div className = "ErrorSlide" >
	<Slide withShadow = {withShadow} >
		<img src = {Utils.buildTenantPageImgLink("people.svg")} alt = "" />
		<div className = "dataInput" >
			<h4 >שים לב - קיים דאבל צ׳ק על שמך על הנכס ב{address}.
				<br />
			     האם תרצה לשלוח שוב?</h4 >
			<br />
		</div >

		<div className = "buttons" >
			<div className = "buttonDiv" >
				<a onClick = {dontSend}
				   className = "btn--gray" >
					לא
				</a >
			</div >
			<div className = "buttonDiv" >
				<a onClick = {confirmSend}
				   className = "btn--azure" >
					כן
				</a >
			</div >
		</div >

	</Slide >
</div >;

export {ErrorSlide};