import React, { useRef } from "react";

import CTAAnimationClick from "../../../../../components/product-pages/CTAAnimationClick";
import GridSection from "../../../../../components/product-pages/grid-section";
import ItemList from "../../../../../components/product-pages/item-list";
import TextSection from "../../../../../components/product-pages/text-section";

import { URLS } from "../../../../../shared/urls";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { CTA_TITLE, PARAGRAPH, TITLE } from "./texts";
import { ITEM_LIST } from "./consts";

import "./index.scss";
import { ANALYTICS } from "../../consts";
import Container from "../../../../../components/product-pages/container";

export default function ThirdSection() {
        const CTARef = useRef(null)

        return <GridSection className="complete-mort-third-section">
                <TextSection title={TITLE} text={PARAGRAPH} />
                <ItemList list={ITEM_LIST} />
                <Container className="animation-cta-container">
                        <CTAAnimationClick analytics={ANALYTICS.THIRD_SECTION.CTA} reference={CTARef} title={CTA_TITLE} text={CTA_BUTTON_TEXT} CTALink={URLS.MortgageQue} />
                </Container>
        </GridSection>
}