import React from "react";
import "./index.scss";
import { Logo } from "../logo";
import LogoutButton from "./components/log-out";
import OpenDetails from "./components/open-details";
import { Link } from "react-router-dom";
import { URLS } from "../../shared/urls";
import useIsMobile from "../../hooks/useIsMobile";
import { FIRST_BREAKPOINT } from "../../newsletter/consts";

export default function PersonalAreaHeader() {
  const isMobile = useIsMobile(FIRST_BREAKPOINT);
  return (
    <header className="layout-personal-area-header-container">
      <OpenDetails />
      <div className="logo-and-logout-container">
        {!isMobile && <LogoutButton />}
        <Link to={URLS.MortInfo} className="logo-container">
          <Logo mode="" isPromotion={false} color={"blue"} />
        </Link>
      </div>
    </header>
  );
}
