import React, { useRef, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";

export default function YearDisplay({ availableYears, currentYearIndex }) {
  const containerRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  const isFilterOpen = useSelector((state) => state.newsletter.filter.isOpen);

  useEffect(() => {
    if (containerRef.current && isFilterOpen) {
        const selectedElement = containerRef.current.children[currentYearIndex];
        if (selectedElement) {
            const containerWidth = containerRef.current.offsetWidth;
            const elementWidth = selectedElement.offsetWidth;
            const elementLeft = selectedElement.offsetLeft;
            const paddingAndArrowWidth = (2 * 15) + (2 * 25);

            const scrollLeft = elementLeft - (containerWidth / 2) - (paddingAndArrowWidth / 2) + (elementWidth / 2);

            containerRef.current.scrollTo({
                top: 0,
                left: scrollLeft,
                behavior: isMounted ? "smooth" : "auto"
            });
        }

        if (!isMounted) {
            setIsMounted(true);
        }
    }
}, [currentYearIndex, isFilterOpen, isMounted]);



  return (
    <div ref={containerRef} className="year-container-wrapper">
      {availableYears.map((year, index) => {
        return (
          <div
            key={index}
            className={`single-year-container ${
              index === currentYearIndex ? "selected" : ""
            }`}
          >
            <div className="year">{year}</div>
          </div>
        );
      })}
    </div>
  );
}
