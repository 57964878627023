import axios from "axios";
import { useState } from "react";
import Config from "../../../../../../config/config";

import { ButtonsHeader } from "./components/buttonsHeader";
import { AssetDetailsSlide } from "./components/slides/assetDetails";
import { ErrorSlide } from "./components/slides/error";
import { OwnerDetailsSlide } from "./components/slides/ownerDetails";
import { SuccessSlide } from "./components/slides/success";
import { TenantDetailsSlide } from "./components/slides/tanentDetails";

import "./index.scss";
import { sendQueryParamsToServer } from "../../../../../hooks/useCampaignParams";

const WizardStates = {
  assetDetails: 0,
  ownerDetails: 1,
  tenantDetails: 2,
  error: 3,
  success: 4,
};

const doubleCheckInfo = {
  asset: {},
  owner: {},
  tenant: {},
};

const makeAddress = ({ city, streetName }) =>
  `${streetName ? streetName : ""}  ${city ? city : ""}`;

const SendDcWizard = ({ showButtonsHeader, withShadow, isYad2 }) => {
  const [wizardState, setWizardState] = useState(WizardStates.assetDetails);
  const [busyState, setBusyState] = useState(false);

  const confirmSend = () => {
    sendDC(true);
  };

  const sendDC = (forceNewDoubleCheck) => {
    const DC_ALREADY_EXISTS = 1025;

    doubleCheckInfo.forceNewDoubleCheck = forceNewDoubleCheck;
    setBusyState(true);
    axios
      .put(
        `${Config.serverAddr}/tenant-initiated-double-check`,
        doubleCheckInfo
      )
      .then((res) => {
        setBusyState(false);
        setWizardState(WizardStates.success);
        sendQueryParamsToServer(res.data);
      })
      .catch((error) => {
        setBusyState(false);
        const response = error.response;
        if (
          response &&
          response.status === 400 &&
          response.data === DC_ALREADY_EXISTS
        ) {
          setWizardState(WizardStates.error);
        }
      });
  };

  const assetDetailsComplete = (city, streetName, rentCost) => {
    doubleCheckInfo.asset = { city, streetName, rentCost };
    setWizardState(WizardStates.ownerDetails);
  };

  const ownerDetailsComplete = (firstName, phone, isForward) => {
    doubleCheckInfo.owner = { firstName, phone };
    setWizardState(
      isForward ? WizardStates.tenantDetails : WizardStates.assetDetails
    );
  };

  const tenantDetailsComplete = (firstName, phone, gender, isForward) => {
    doubleCheckInfo.tenant = { firstName, phone, gender };
    if (isForward) {
      sendDC(false);
    } else {
      setWizardState(WizardStates.ownerDetails);
    }
  };

  const getSlide = () => {
    let retSlide;

    switch (wizardState) {
      case WizardStates.assetDetails:
        retSlide = (
          <AssetDetailsSlide
            withShadow={withShadow}
            currCity={doubleCheckInfo.asset.city}
            currStreet={doubleCheckInfo.asset.streetName}
            currRentCost={doubleCheckInfo.asset.rentCost}
            done={assetDetailsComplete}
          />
        );
        break;

      case WizardStates.ownerDetails:
        retSlide = (
          <OwnerDetailsSlide
            withShadow={withShadow}
            currName={doubleCheckInfo.owner.firstName}
            currPhone={doubleCheckInfo.owner.phone}
            done={ownerDetailsComplete}
          />
        );
        break;

      case WizardStates.tenantDetails:
        retSlide = (
          <TenantDetailsSlide
            withShadow={withShadow}
            currName={doubleCheckInfo.tenant.firstName}
            currPhone={doubleCheckInfo.tenant.phone}
            existingPhone={doubleCheckInfo.owner.phone}
            currGender={doubleCheckInfo.tenant.gender}
            done={tenantDetailsComplete}
          />
        );
        break;

      case WizardStates.error:
        retSlide = (
          <ErrorSlide
            withShadow={withShadow}
            address={makeAddress(doubleCheckInfo.asset)}
            confirmSend={confirmSend}
            dontSend={() => setWizardState(WizardStates.assetDetails)}
          />
        );
        break;

      case WizardStates.success:
        retSlide = (
          <SuccessSlide
            withShadow={withShadow}
            buttonPressed={() => setWizardState(WizardStates.assetDetails)}
          />
        );
        break;

      default:
    }

    return retSlide;
  };

  return (
    <div class={`"SendDCWizard" ${busyState ? "SendDCWizard--disabled" : ""}`}>
      <div className="wizardContainer">
        {showButtonsHeader && <ButtonsHeader isYad2={isYad2} />}
      </div>
      {getSlide()}
    </div>
  );
};

export { SendDcWizard };
