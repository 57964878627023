import React, { useEffect, useRef } from "react";

export default function IDNumInput({ onChange, value, placeholder = "" }) {
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);
  return (
    <label>
      <span className="sr-only">ID Number:</span>
      <input
        ref={inputRef}
        className="phone-input"
        placeholder={placeholder}
        type="number"
        value={value}
        onChange={onChange}
        aria-label="ID Number"
        pattern="[0-9]*"
        inputMode="numeric"
      />
    </label>
  );
}
