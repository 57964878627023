import { createSlice } from "@reduxjs/toolkit";
import { STEPS } from "../../web/new-login/consts";
import { loginApi } from "../services/login";
import { LOGIN_TYPE } from "../consts";

const initialState = {
  shouldApproveTerms: true,
  adsApproved: true,
  policyConfirmed: false,
  phone: "",
  code: "",
  idNum: "",
  currentStep: STEPS.PHONE,
  isLoggedIn: null,
  otpSent: false,
  isAdmin: false,
  type: LOGIN_TYPE.NEWSLETTER,
  userType: null,
  existingUserData: {
    adsApproved: false,
    policyConfirmed: false,
  },
};

export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setIdNum: (state, action) => {
      state.idNum = action.payload;
    },
    setCode: (state, action) => {
      state.code = action.payload;
    },
    setAdsApproved: (state, action) => {
      state.adsApproved = action.payload;
    },
    setPolicyConfirmed: (state, action) => {
      state.policyConfirmed = action.payload;
    },
    setContactAgreement: (state, action) => {
      state.contactAgreement = action.payload;
    },
    changeValue: (state, action) => {
      state[action.payload.name] = action.payload.value;
    },
    changeMultipleValues: (state, action) => {
      action.payload.forEach((item) => {
        state[item.name] = item.value;
      });
    },
    setIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    setOtpResponse: (state, action) => {
      const { shouldApproveTerms, approvedAds } = action.payload;
      state.shouldApproveTerms = shouldApproveTerms;
      state.existingUserData.adsApproved = approvedAds;
      state.otpSent = true;
    },
    init: (state) => {
      Object.assign(state, { ...initialState, type: state.type });
    },
    setExistingUserData: (state, action) => {
      state.existingUserData = {
        ...state.existingUserData,
        policyConfirmed: action.payload.policyConfirmed,
      };
      state.isAdmin = action.payload.isAdmin;
    },
    setIsAdmin: (state, action) => {
      state.isAdmin = action.payload;
    },
    setUserType: (state, action) => {
      state.userType = action.payload;
    },
    setLoginType: (state, action) => {
      state.type = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      loginApi.endpoints.refreshToken.matchRejected,
      (state) => {
        state.isLoggedIn = false;
      }
    );
  },
});

export const {
  changeValue,
  setCode,
  setPhone,
  setAdsApproved,
  setPolicyConfirmed,
  setContactAgreement,
  setStep,
  changeMultipleValues,
  setIsLoggedIn,
  init,
  setOtpResponse,
  setExistingUserData,
  setIsAdmin,
  setIdNum,
  setUserType,
  setLoginType,
} = loginSlice.actions;

export default loginSlice.reducer;
