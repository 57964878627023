import React from "react";
import Container from "../container";

import "./index.scss";

export default function AnimationContainer({
  img,
  placeholder,
  className = "",
  style = {},
}) {
  return (
    <Container className={`animation-container ${className}`}>
      <object {...style} rel="prefetch" type="image/svg+xml" data={img}>
        {placeholder}
      </object>
    </Container>
  );
}
