import React from "react";
import "./index.scss";
import { FILTER_BUTTON } from "../../texts";
import { useDispatch } from "react-redux";
import { handleFilter } from "../../../../../../redux/features/newsletterSlice";

export default function SubmitButton() {
  const dispatch = useDispatch();
  const closeFilter = () => dispatch(handleFilter(false));
  return (
    <button onClick={closeFilter} className="search-button filter">
      {FILTER_BUTTON}
    </button>
  );
}
