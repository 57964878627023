import PropTypes          from "prop-types";
import React, {Component} from "react";

import Utils from "../../shared/utils";

import "./index.scss";

class CSDrop extends Component {
	static propTypes = {
		default: PropTypes.any
	};

	state = {
		hidden        : true,
		firstTimeClick: false,
		selected      : {
			value   : null,
			children: null
		}
	};

	setData = () => {
		React.Children.map(this.props.children, (child, index) => {
			if (
				(index === 0 || this.props.default === child.props.value) &&
				!this.props.placeholder
			) {
				this.setState({
					selected: {
						value   : child.props.value,
						children: child.props.children
					}
				});
				this.props.onChange(child.props.value);
			}
		});
	};

	componentDidMount = () => this.setData();

	componentWillMount = () =>
		document.addEventListener("mousedown", this.handleBlur, false);

	componentWillUnmount = () =>
		document.removeEventListener("mousedown", this.handleBlur, false);

	handleBlur = (e) => {
		if (!this.node.contains(e.target)) {
			this.setState({hidden: true});

			if (this.props.onBlur) {
				this.state.firstTimeClick &&
				this.props.onBlur(this.state.selected.value);
			}
		}
	};

	toggle = () =>
		this.setState({firstTimeClick: true, hidden: !this.state.hidden});

	render() {
		const {hidden, selected} = this.state;

		let items = React.Children.map(this.props.children, (child) =>
			React.cloneElement(child, {
				onSelect: (value, children) => {
					this.setState({
						selected: {
							value,
							children
						},
						hidden  : true
					});
					this.props.onChange(value);
				}
			})
		);

		return (
			<div
				tabIndex = "0"
				className = {`CSDrop customDrop ${hidden ? "" : "CSDrop--focus"}`}
				ref = {(node) => (this.node = node)}
				onKeyDown = {(evt) => Utils.openOnEnter(evt, this.toggle)}
			>
                <span onClick = {this.toggle} >
                    <p >
                        {selected.children
                         ? selected.children
                         : this.props.placeholder}
                    </p >
                    <div className = "arrow" />
                </span >
				<ul
					tabIndex = {0}
					id = {this.props.id}
					className = {
						hidden ? "CSDrop__list--hidden" : "CSDrop__list--show"
					}
				>
					{items}
				</ul >
			</div >
		);
	}
}

const CSItem = ({children, value, onSelect}) => (
	<li
		tabIndex = {0}
		onKeyDown = {(evt) => {
			if (evt.keyCode === Utils.KEYCODES.ENTER) onSelect(value, children);
		}}
		onClick = {() => onSelect(value, children)}
	>
		{children}
	</li >
);

export {CSDrop, CSItem};
