import React from "react";
import "./index.scss";
import useModal from "../../../../hooks/useModal";
import { NOOP } from "../../../../../../../utils";

export default function ModalCancelButton({ cancel: text, onClick = NOOP }) {
  const { close: closeModal } = useModal();
  const handleCancel = () => {
    onClick();
    closeModal();
  };
  return (
    <button className="modal-cancel-button" onClick={handleCancel}>
      {text}
    </button>
  );
}
