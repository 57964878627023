import React, { forwardRef, useCallback, useEffect, useRef } from "react";
import "./index.scss";
import { CLOSE_ICON } from "../../newsletter/components/data-view/components/close-button/consts";
import throttle from "lodash/throttle";

const VideoPlayer = forwardRef(({ url }, ref) => {
  const playerRef = useRef(null);

  const handleClickOutside = useCallback(
    throttle((event) => {
      if (playerRef.current && !playerRef.current.contains(event.target)) {
        handleClose();
      }
    }, 100),
    []
  );

  const handleClose = () => {
    if (playerRef.current) {
      playerRef.current.src = "";
      playerRef.current.src = `https://www.youtube.com/embed/${url}`;
    }
    ref.current.close();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <dialog ref={ref} className="video-player">
      <iframe
        ref={playerRef}
        src={`https://www.youtube.com/embed/${url}`}
        title="Embedded YouTube video"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
      <button className="close-button" onClick={handleClose}>
        <img src={CLOSE_ICON} alt="Close" />
      </button>
    </dialog>
  );
});

export default VideoPlayer;
