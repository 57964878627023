import React from "react";
import "./index.scss";
import usePersonalArea from "./hooks/usePersonalArea";
import Spinner from "../../new-login/components/spinner";
import PersonalAreaLayout from "./components/layout";

export default function PersonalArea() {
  const { loans, isLoading } = usePersonalArea();

  if (isLoading) return <Spinner />;

  return <PersonalAreaLayout loans={loans} />;
}
