import axios                 from "axios";
import {useEffect, useState} from "react";
import config                from "../../config/config";

export default function useVideos() {
	const [videoURLS, setVideoURLS] = useState({
		youtubeURL  : null,
		youtubeTVURL: null
	});

	async function getVideoID(name) {
		const response = await axios
			.get(config.serverAddr + "/translatefixedaddress/" + name)
			.then((res) => res.data.fullURL);
		return response;
	}

	useEffect(() => {
		async function getVideos() {
			const youtubeURL      = await getVideoID("clip");
			const youtubeTVURL    = await getVideoID("tv");
			const mortURL         = await getVideoID("mort");
			const newMortVideoURL = await getVideoID("yoosuf");

			const amosVideo       = await getVideoID("amos");
			const arieVideo       = await getVideoID("arie");
			const chanohVideo     = await getVideoID("chanoh");
			const mosheVideo      = await getVideoID("moshe");
			const videoBaaleVideo = await getVideoID("videoBaale");
			const videoDaiarVideo = await getVideoID("videoDaiar");
			const videoNadlaVideo = await getVideoID("videoNadla");
			const yevgeniVideo    = await getVideoID("yevgeni");
			const maxVideo        = await getVideoID("max");
			const kobiVideo       = await getVideoID("kobi");
			const hadarVideo      = await getVideoID("hadar");
			const danielVideo     = await getVideoID("daniel");
			const eliranVideo     = await getVideoID("eliran");
			const lizaVideo       = await getVideoID("liza");
			const meiraVideo      = await getVideoID("meira");
			const miriamVideo     = await getVideoID("miriam");
			const olgaVideo       = await getVideoID("olga");
			const nirVideo        = await getVideoID("nir");
			const molyVideo       = await getVideoID("moly");
			const mortVideo       = await getVideoID("mort");

			const newMortMai = await getVideoID("newMortMai");
			const newMortSah = await getVideoID("newMortSah");
			const newMortShi = await getVideoID("newMortShi");
			const newMortSta = await getVideoID("newMortSta");
			const newMortYon = await getVideoID("newMortYon");

			const newMortZvi = await getVideoID("newMortZvi");

			const mainTestimonilalsVideo = await getVideoID("testimonia");

			setVideoURLS({
				mortURL,
				youtubeURL,
				youtubeTVURL,
				newMortVideoURL,
				amosVideo,
				arieVideo,
				chanohVideo,
				mosheVideo,
				mainTestimonilalsVideo,
				videoBaaleVideo,
				videoDaiarVideo,
				videoNadlaVideo,
				yevgeniVideo,
				maxVideo,
				kobiVideo,
				hadarVideo,
				danielVideo,
				eliranVideo,
				lizaVideo,
				meiraVideo,
				miriamVideo,
				olgaVideo,
				nirVideo,
				molyVideo,
				mortVideo,
				newMortMai,
				newMortSah,
				newMortShi,
				newMortSta,
				newMortYon,
				newMortZvi
			});
		}

		getVideos();
	}, []);

	return videoURLS;
}
