import React from "react";
import Container from "../../../container";
import ImageContainer from "../../../image-container";
import Paragraph from "../../../paragraph";
import TextSection from "../../../text-section";
import "./index.scss";

export default function SingleItem({ icon, text, animationDelay, isTitled, title = "" }) {
    return <Container style={{ animationDelay: `${animationDelay}s` }} className={`single-item-container ${isTitled ? "is-titled" : ""}`}>
        <ImageContainer src={icon} className="icon-container" />
        {isTitled ? <TextSection title={title} text={text} />
            : <Paragraph text={text} />}
    </Container>
}