import React from "react";
import "./index.scss";
import ModalImage from "../modal-image";
import TextSection from "../text-section";

export default function ModalContent({ title, text, spread }) {
  return (
    <div className="modal-content-container">
      <ModalImage src={spread} />
      <TextSection text={text} title={title} />
    </div>
  );
}
