import React from "react";

import {Gender} from "@wecheck/uisharedutils/uiUtils";

import Utils from "../../../../../../../shared/utils";

import "./index.scss";

const GenderSelect = ({gender, genderChanged,  fireAnalytics}) => <div className = "GenderSelect" >
	<div className = "AGender" >
		<img className = {gender === Gender.male ? "selctedImg" : ""}
		     src = {Utils.buildDashboardImgLink("mortgage/genderM.png")}
		     onClick = {() => {
			     fireAnalytics("gender");
				  genderChanged(Gender.male);
		     }}
		     alt = "זכר" />
		<p >בזכר</p >
	</div >
	<div className = "AGender" >
		<img className = {gender === Gender.female ? "selctedImg" : ""}
		     src = {Utils.buildTenantPageImgLink("genderF.svg", true)}
		     onClick = {() => {
			     fireAnalytics("gender");
				 genderChanged(Gender.female)
		     }}
		     alt = "נקבה" />
		<p >בנקבה</p >
	</div >
</div >;

const GenderBox = ({gender, onChange,  fireAnalytics}) => <div className = "GenderBox" >
	<p>איך לפנות אליך?</p>
	<GenderSelect gender = {gender} genderChanged = {onChange} fireAnalytics={fireAnalytics}/>
</div >;

export {GenderSelect, GenderBox};