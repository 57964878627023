import React from "react";
import Container from "../../../../../../../components/product-pages/container";
import SingleCard from "../single-card";
import "./index.scss";

export default function CardList({ list }) {
  return (
    <Container className="card-list-container">
      {list.map(({ ...props }, index) => {
        return <SingleCard key={index} {...props} />;
      })}
    </Container>
  );
}
