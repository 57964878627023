import { useMemo, useState, useCallback } from "react";
import { MONTH_ENUM_ABBREVIATED } from "../consts";
import { useDispatch, useSelector } from "react-redux";
import {
  selectDateRange,
  setEndMonth,
  setStartMonth,
} from "../../../../../../../redux/features/newsletterSlice";
import { getCorrectDate } from "../../../../../utils";

export default function useDateRangePicker(itemsPerRow = 4) {
  const dispatch = useDispatch();

  const isAdmin = useSelector((state) => state.login.isAdmin);

  const startMonth = useSelector((state) => state.newsletter.filter.date.from);
  const endMonth = useSelector((state) => state.newsletter.filter.date.to);
  const availableYears = useSelector(
    (state) => state.newsletter.filter.date.availableYears
  );
  const currentYearIndex = useSelector(
    (state) => state.newsletter.filter.date.currentYearIndex
  );

  const [hoveredIndex, setHoveredIndex] = useState(null);

  const getPastTwoYearsWithMonths = useCallback(() => {
    const currentDate = getCorrectDate(isAdmin);
    const currentYear = currentDate.getFullYear();
    const years = Array.from({ length: 2 }, (_, i) => currentYear - i);

    return years.map((year) => {
      const months = Array.from({ length: 12 }, (_, i) => i);
      return { year, months };
    });
  }, [isAdmin]);

  const getMonthName = useCallback(
    (month) => MONTH_ENUM_ABBREVIATED[month],
    []
  );

  const generateDateRange = useMemo(() => {
    const dateRange = getPastTwoYearsWithMonths();
    const dateRangeFlat = dateRange.flatMap((year) =>
      year.months.map((month) => ({
        year: year.year,
        month: month,
        name: getMonthName(month),
      }))
    );
    return dateRangeFlat;
  }, [getPastTwoYearsWithMonths, getMonthName]);

  const handleDateRangeSelect = (from, to) =>
    dispatch(selectDateRange({ from, to }));

  const handleMonthSelect = (selectedMonth) => {
    if (!endMonth) {
      const action = selectedMonth > startMonth ? setEndMonth : setStartMonth;
      dispatch(action(selectedMonth));
      return; 
    }

    if (selectedMonth === endMonth || selectedMonth === startMonth) {
      dispatch(selectDateRange({ from: selectedMonth, to: null }));
      return; 
    }

    if (selectedMonth > endMonth && selectedMonth !== startMonth) {
      dispatch(setEndMonth(selectedMonth));
      return; 
    }

    if (selectedMonth <= startMonth) {
      dispatch(selectDateRange({ from: selectedMonth, to: null }));
      return; 
    }

    dispatch(setStartMonth(selectedMonth));
  };

  const handleHover = useCallback((index) => () => setHoveredIndex(index), []);
  const handleMouseLeave = useCallback(() => setHoveredIndex(null), []);

  const currentYear = useMemo(
    () => availableYears[currentYearIndex],
    [availableYears, currentYearIndex]
  );

  const isMonthEnabled = useCallback((item) => {
    const currentDate = getCorrectDate(isAdmin);
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() - 1;

    const oneYearAgo = new Date(currentYear - 1, currentMonth);

    const isEnabled =
      (item.year > oneYearAgo.getFullYear() ||
        (item.year === oneYearAgo.getFullYear() &&
          item.month > oneYearAgo.getMonth())) &&
      (item.year < currentYear ||
        (item.year === currentYear && item.month <= currentMonth));

    return isEnabled;
  }, []);

  const generateDisplayItems = useCallback(() => {
    const yearItems = generateDateRange.filter(
      (item) => item.year === currentYear
    );

    return yearItems.map((item, index) => {
      const isEnabled = isMonthEnabled(item);
      const isStartOfRow = index % itemsPerRow === 0;
      const isEndOfRow = index % itemsPerRow === itemsPerRow - 1;
      const isBeforeHovered = hoveredIndex !== null && index < hoveredIndex;

      const prevItemSelected =
        yearItems[index - 1]?.month >= startMonth &&
        yearItems[index - 1]?.month <= endMonth;
      const nextItemSelected =
        yearItems[index + 1]?.month >= startMonth &&
        yearItems[index + 1]?.month <= endMonth;

      return {
        item,
        isEnabled,
        isStartOfRow,
        isEndOfRow,
        isBeforeHovered,
        prevItemSelected,
        nextItemSelected,
      };
    });
  }, [
    generateDateRange,
    currentYear,
    itemsPerRow,
    isMonthEnabled,
    hoveredIndex,
    startMonth,
    endMonth,
  ]);

  return {
    generateDateRange,
    handleMonthSelect,
    handleDateRangeSelect,
    handleHover,
    handleMouseLeave,
    generateDisplayItems,
  };
}
