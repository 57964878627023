import React from "react";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import "./index.scss";
import Utils from "../../../../../../shared/utils";
import AnchorTagWithQueryParams from "../../../../../anchor-tag-with-params";

export default function DesktopLinksList({
  link,
  isNewSiteActive,
  isMortPersonalAreaActive,
}) {
  const firstColumnLinks = link.links.slice(0, link.text === "בלוג" ? 5 : 4);
  const secondColumnLinks = link.links.slice(link.text === "בלוג" ? 5 : 4);

  return (
    <ReactTooltip
      id={link.label}
      place="bottom"
      type="light"
      effect="solid"
      className="tooltip-container"
      delayHide={100}
      arrowColor="transparent" // Hides the default arrow
    >
      <div className="links-tooltip-container">
        <div className="links-column">
          {firstColumnLinks.map((innerLink, index) => {
            return innerLink.isPremium &&
              !isNewSiteActive ? null : innerLink.personalArea &&
              !isMortPersonalAreaActive ? null : innerLink.external ? (
              <AnchorTagWithQueryParams
                key={index}
                className={`header-link ${link.bold ? "text-bold" : ""}`}
                href={innerLink.to}
                text={innerLink.label}
              />
            ) : (
              <Link
                onClick={() =>
                  Utils.gaEvent(
                    innerLink.category,
                    innerLink.action,
                    innerLink.label
                  )
                }
                to={innerLink.to}
                key={index}
              >
                {innerLink.label}
              </Link>
            );
          })}
        </div>

        {secondColumnLinks.length > 0 && (
          <div className="links-column">
            {secondColumnLinks.map((innerLink, index) => {
              return innerLink.isPremium &&
                !isNewSiteActive ? null : innerLink.personalArea &&
                !isMortPersonalAreaActive ? null : innerLink.external ? (
                <AnchorTagWithQueryParams
                  key={index + 4} // Ensure the keys are unique across both columns
                  className={`header-link ${link.bold ? "text-bold" : ""}`}
                  href={innerLink.to}
                  text={innerLink.label}
                />
              ) : (
                <Link
                  onClick={() =>
                    Utils.gaEvent(
                      innerLink.category,
                      innerLink.action,
                      innerLink.label
                    )
                  }
                  to={innerLink.to}
                  key={index + 4} // Ensure the keys are unique across both columns
                >
                  {innerLink.label}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </ReactTooltip>
  );
}
