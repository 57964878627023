export const GOOGLE_MAPS_POSITION = {
  TOP_CENTER: 2,
  TOP_LEFT: 1,
  TOP_RIGHT: 3,
  LEFT_TOP: 5,
  RIGHT_TOP: 7,
  LEFT_CENTER: 4,
  RIGHT_CENTER: 8,
  LEFT_BOTTOM: 6,
  RIGHT_BOTTOM: 9,
  BOTTOM_CENTER: 11,
  BOTTOM_LEFT: 10,
  BOTTOM_RIGHT: 12,
};

export const MAP_OPTIONS = {
  center: {
    lat: 32.061317,
    lng: 34.806256,
  },
  zoom: 12,
  restriction: {
    latLngBounds: {
      north: 33.3325,
      south: 29.3535,
      west: 34.2676,
      east: 35.8974,
    },
    strictBounds: true,
  },
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ],
  mapTypeControl: false,
  fullscreenControl: false,
  streetViewControlOptions: {
    position: GOOGLE_MAPS_POSITION.RIGHT_TOP,
  },
  zoomControl: true,
  zoomControlOptions: {
    position: GOOGLE_MAPS_POSITION.RIGHT_TOP,
  },
};

export const MAP_OPTIONS_MOBILE = {
  center: {
    lat: 32.061317,
    lng: 34.806256,
  },
  zoom: 12,
  restriction: {
    latLngBounds: {
      north: 33.3325,
      south: 29.3535,
      west: 34.2676,
      east: 35.8974,
    },
    strictBounds: true,
  },
  styles: [
    {
      featureType: "poi",
      elementType: "labels",
      stylers: [{ visibility: "off" }],
    },
    {
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
    },
  ],
  mapTypeControl: false,
  fullscreenControl: false,
  zoomControl: false,
  streetViewControl: false,
};
