export const MAIN_TITLE = `משכנתא פרימיום`;

export const MAIN_SUBTITLE = `עד 85% מימון משווי הנכס`;

export const SECONDARY_TITLE = `ברוכים הבאים לעולם המשכנתאות החדש - "משכנתא פרימיום" של WeCheck`;

export const PARAGRAPH = `בעידן שבו החלום לרכוש בית נראה לעיתים רחוק ובלתי אפשרי, אנחנו ב-WeCheck מציעים לכם פתרון חדשני, גמיש וייחודי שישנה את האופן שבו אתם חושבים על המשכנתא שלכם. היום, הבנקים בישראל הינם הגורם המרכזי אשר מעניק משכנתאות לציבור, אך על הבנקים חלות מגבלות רבות בהתאם להנחיות ולפיקוח בנק ישראל - רגולציות בנקאיות, בירוקרטיה מעכבת ויכולת מימון אשר מוגבלת ל 50%-75% בלבד ובשל כך, הלקוחות מתקשים להגיע להון העצמי הנדרש ונאלצים להתפשר על סכום ההלוואה ועל הבית שלהם. כאן נכנסת "משכנתא פרימיום" של WeCheck לתמונה.`;
export const SECONDARY_PARAGRAPH_TITLE = `מהי "משכנתא פרימיום"?`;
export const SECONDARY_PARAGRAPH = `משכנתא פרימיום היא הלוואת משכנתא חוץ בנקאית, שמאפשרת לכם לקבל עד 85% מימון משווי הנכס, עם אופציה לפריסה של עד 30 שנה. היא מספקת פתרון גמיש ומותאם אישית, ללא המגבלות והאילוצים החלים על הבנקים.`;
export const CTA_BUTTON_TEXT = `לבדיקת זכאות וקבלת הצעה`;
