import { useState } from "react";
import useScrollToElement from "../../hooks/useScrollToElement";

import Utils from "../../shared/utils";
import useHeader from "../header/hooks/useHeader";

import "./index.scss";

const PhoneBanner = ({ isFooterVisible, isMortProductPage, mortContactRef }) => {
	const { isMortSite } = useHeader();

	const [isExpanded, setIsExpanded] = useState(false);

	const dynamicText = isExpanded ? "Whatsapp" : "ליצירת קשר";
	const dynamicPhoneNumber = isMortSite || isMortProductPage ? "0547109227" : "03-3030211";
	const dynamicWhatsappLink = isMortSite || isMortProductPage ? "https://bit.ly/3FkiQdy" : "https://bit.ly/2ZDyCk3"
    
	const scrollToElement = useScrollToElement();


	return <div onMouseEnter={() => {
		setTimeout(() => {
			// In mobile, expanding right away, also tries to open the link
			setIsExpanded(true);
		}, 100
		);
	}}
		onMouseLeave={() => setIsExpanded(false)} className={`PhoneBanner ${isFooterVisible ? "absolute-position" : "fixed-position"}`} >
		<div className={`content-container ${isExpanded ? "expanded" : ""}`} >
			<a href={dynamicWhatsappLink} target="_blank" rel="noopener noreferrer" >
				<p >{dynamicText}</p>
				<img src={Utils.buildImgLink("bluewhatsapp.svg", true)} alt="צור קשר" />
			</a >
			{isMortProductPage && <a onClick={() => scrollToElement(mortContactRef)}>
				<p>השארת פרטים</p>
				<img src={Utils.buildProductPageImageLink("mort/contact.svg")}/>
				</a>}
			<a href={`tel:${dynamicPhoneNumber}`} >
				<p >{dynamicPhoneNumber}</p >
				<img src={Utils.buildImgLink("bluephone.svg", true)} alt="צור קשר" />
			</a >
		</div >
	</div >;
};

export { PhoneBanner };