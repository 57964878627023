import React, { useEffect, useState } from "react";
import "./index.scss";

export default function Checkbox({
  onClick,
  value,
  text,
  action,
  splitString,
}) {
  const handleKeyPress = (event) => {
    if (event.key === "Enter" || event.key === " ") {
      onClick();
    }
  };

  const handleSpanClick = (event) => {
    event.stopPropagation();
    action?.();
  };
  const [textArray, setTextArray] = useState([]);

  useEffect(() => {
    if (action && splitString) {
      setTextArray(text.split(splitString));
    }
  }, [action, splitString, text]);

  return (
    <div
      onClick={onClick}
      onKeyDown={handleKeyPress}
      role="checkbox"
      aria-checked={value}
      tabIndex={0}
      className="checkbox-container"
    >
      <div className={`checkbox ${value ? "checked" : ""}`} />
      {action && splitString ? (
        <p>
          {textArray[0]}
          <span onClick={handleSpanClick}>{splitString}</span>
          {textArray[1]}
        </p>
      ) : (
        <p>{text}</p>
      )}
    </div>
  );
}
