import React from "react";
import "./index.scss";
import { BACK_CHEVRON, HEADER_IMAGE } from "./consts";
import { LAST_LOGIN_TEXT, LOG_OUT_CTA } from "./texts";
import { useDispatch, useSelector } from "react-redux";
import { handleDrawer } from "../../../../../../../redux/features/userSlice";
import { useLogoutMutation } from "../../../../../../../redux/services/login";
import { setIsLoggedIn } from "../../../../../../../redux/features/loginSlice";
import { format } from "date-fns";
import { Gender } from "@wecheck/uisharedutils/uiUtils";

export default function PersonalDetailsHeader() {
  const dispatch = useDispatch();
  const closeDrawer = () => dispatch(handleDrawer(false));

  const lastLogin = useSelector(
    (state) => state.user?.lastMortPersonalAreaLogin
  );
  const gender = useSelector((state) => state.user?.gender);
  const isMale = gender === Gender.male;

  const lastLoginString =
    lastLogin && format(new Date(lastLogin), "dd.MM.yyyy HH:mm");

  const [logout, { isLoading }] = useLogoutMutation();

  const logoutHandler = async () => {
    await logout();
    dispatch(setIsLoggedIn(false));
  };

  return (
    <div className="personal-details-header-container">
      <div className="details-close-button-container">
        <button onClick={closeDrawer} className="personal-details-close-button">
          <img src={BACK_CHEVRON} alt="back chevron" />
        </button>
      </div>
      <div className="header-image-and-last-login-container">
        <div className="image-container">
          <img src={HEADER_IMAGE} alt="" />
        </div>
        <p className="last-login">{`${LAST_LOGIN_TEXT} ${lastLoginString}`}</p>
      </div>
      <button
        disabled={isLoading}
        onClick={logoutHandler}
        className="log-out-button"
      >
        {isMale ? LOG_OUT_CTA.MALE : LOG_OUT_CTA.FEMALE}
      </button>
    </div>
  );
}
