import Utils from "../../../../shared/utils";

const MortTestimonials = {
	videoSection: {
		imgLink        : Utils.buildMortTestimonialsImgLink("TestimonialsMain.png"),
		videoName      : "newMortSah",
		eventName      : "עמוד טסטמוניאלס - סרטון ראשי",
		flexDirection  : "flexDirectionRow",
		ribbon         : "יועצי משכנתאות מספרים",
		ratingText     :
			"“הפתרון של וויצ’ק הוא יפיפה, זה חוסך לי המון זמן ומשאבים. הם יודעים לתת מענה ייחודי לכל בן אדם. אנחנו עוזרים לאנשים לקנות בית.״",
		buttonEventName: "לסיפורים נוספים - דיירים"
	},
	stripSection: [
		{
			img      : "TestimonialsSlide1.png",
			videoName: "newMortSah",
			eventName: "עמוד טסטמוניאלס - שחר כרמון"
		},
		{
			img      : "TestimonialsSlide2.png",
			videoName: "newMortYon",
			eventName: "עמוד טסטמוניאלס - יוני ברלינר"
		},
		{
			img      : "TestimonialsSlide3.png",
			videoName: "newMortShi",
			eventName: "עמוד טסטמוניאלס - שיר רוזוב"
		},
		{
			img      : "TestimonialsSlide4.png",
			videoName: "newMortSta",
			eventName: "עמוד טסטמוניאלס - סתיו קרול"
		},
		{
			img      : "TestimonialsSlide5.png",
			videoName: "newMortZvi",
			eventName: "עמוד טסטמוניאלס - צבי גליק"
		}
	]
};

export {MortTestimonials};
