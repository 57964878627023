import Utils from "../../../../shared/utils";

export function sumRemainingLoanAmounts(arr) {
  const sum = arr.reduce((acc, obj) => {
    return (
      acc +
      (obj.dealStatus === 18
        ? 0
        : parseFloat(obj.total_remaining_loan_amount) || 0)
    );
  }, 0);

  return Utils.numberWithComma(sum.toFixed(2));
}
