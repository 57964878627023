import { gtag } from 'ga-gtag';

export function reportCoversion({url, id}) {
	var callback = function () {
		if (typeof (url) != 'undefined') {
			window.location = url;
		}
	};
	gtag('event', 'conversion', {
		'send_to': `AW-801590444/${id}`, 'event_callback': callback
	});
	return false;
};