import React from "react";
import "./index.scss";
import ModalSubmitButton from "../submit-button";
import ModalCancelButton from "../cancel-button";
import { APPROVE_SECTION_TYPES } from "./consts";

export default function ApproveSection({ type, isExternalLink, href }) {
  return (
    <div className="approve-section-container">
      <ModalSubmitButton {...APPROVE_SECTION_TYPES[type]} />
      <ModalCancelButton {...APPROVE_SECTION_TYPES[type]} />
    </div>
  );
}
