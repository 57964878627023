import React from "react";
import Utils from "../../../../shared/utils";
import "./index.scss";

export const SocialIcons = () => {
  return (
    <div className="social-icons-container">
      <a
        href="https://www.linkedin.com/company/72698846/admin/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={Utils.buildImgLink("header/social/linkedin-light-blue.svg")}
          alt="linkedin icon"
        />
      </a>
      <a
        href="https://www.tiktok.com/@wecheck_1?_t=8gB7JKbzJu3&_r=1"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={Utils.buildImgLink("header/social/tiktok-light-blue.svg")}
          alt="tiktok icon"
        />
      </a>
      <a
        href="https://www.instagram.com/wecheck_ltd/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={Utils.buildImgLink("header/social/instagram-light-blue.svg")}
          alt="instagram icon"
        />
      </a>
      <a
        href="https://www.facebook.com/WeCheck.4U/"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img
          src={Utils.buildImgLink("header/social/facebook-light-blue.svg")}
          alt="facebook icon"
        />
      </a>
    </div>
  );
};
