import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";

export default function SingleMonth({
  item,
  handleMonthSelect,
  positionClass,
  handleHover,
  handleMouseLeave,
  isBeforeHovered,
  index,
  prevItemSelected,
  nextItemSelected,
  isEnabled,
}) {
  const startMonth = useSelector((state) => state.newsletter.filter.date.from);
  const endMonth = useSelector((state) => state.newsletter.filter.date.to);

  const zeroIndexedStartMonth = startMonth - 1;
  const zeroIndexedEndMonth = endMonth - 1;

  const selected = endMonth
    ? item.month <= zeroIndexedEndMonth && item.month >= zeroIndexedStartMonth
    : item.month === zeroIndexedStartMonth;

  const onlyStartMonth =
    startMonth && !endMonth && item.month === zeroIndexedStartMonth;

  const isStartOfRow = index % 4 === 0;
  const isEndOfRow = index % 4 === 3;
  const isSingleItemInRow =
    selected &&
    ((isStartOfRow && !nextItemSelected) || (isEndOfRow && !prevItemSelected));

  return (
    <div
      onMouseEnter={handleHover}
      onMouseLeave={handleMouseLeave}
      onClick={() => (isEnabled ? handleMonthSelect(item.month + 1) : null)}
      className={`single-month-container ${selected ? "selected" : ""} ${
        item.month === zeroIndexedStartMonth
          ? "start-month"
          : item.month === zeroIndexedEndMonth
          ? "end-month"
          : ""
      } ${positionClass} ${onlyStartMonth ? "only-start-month" : ""} ${
        isSingleItemInRow ? "only-item-in-row" : ""
      } ${
        isBeforeHovered && !selected && item.month > zeroIndexedStartMonth
          ? "before-hovered"
          : ""
      }
      ${!isEnabled ? "disabled" : ""}
        `}
    >
      <div className="month-name-container">{item.name}</div>
      <div className="year-container">{item.year}</div>
    </div>
  );
}
