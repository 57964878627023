import React, { forwardRef, useCallback, useEffect, useRef } from "react";
import "./index.scss";
import throttle from "lodash/throttle";
import { CLOSE_ICON } from "../../../newsletter/components/data-view/components/close-button/consts";
import { TITLE, UPDATED_AT } from "./texts";
import { format } from 'date-fns'

const PolicyModal = forwardRef(({ url }, ref) => {
  const modalRef = useRef(null);
  const today = format(new Date(), 'dd.MM.yyyy');

  const handleClickOutside = useCallback(
    throttle((event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleClose();
      }
    }, 100),
    []
  );

  const handleClose = () => {
    ref.current.close();
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);
  

  return (
    <dialog ref={ref} className="policy-modal">
      <h2>{TITLE}</h2>
      <h3>{UPDATED_AT} {today}</h3>
      <iframe
        ref={modalRef}
        src={
          "https://images-wecheck-production.s3-eu-west-1.amazonaws.com/static/legalTerms.html"
        }
        title="Policy Document"
        name="Policy Document"
        allowFullScreen
      ></iframe>
      <button className="close-button" onClick={handleClose}>
        <img src={CLOSE_ICON} alt="Close" />
      </button>
    </dialog>
  );
});

export default PolicyModal;
