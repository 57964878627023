import Utils          from "../../../../../shared/utils";

import "./index.scss";
import {SendDcWizard} from "../wizard";

const MOBILE_KIT_URL  =
	      "https://images-wecheck-production.s3.eu-west-1.amazonaws.com/static/tenantmobile.pdf";
const DESKTOP_KIT_URL =
	      "https://images-wecheck-production.s3.eu-west-1.amazonaws.com/static/tenantdesk.pdf";

const HelpKit = () => <div className = "HelpSection" >
	<SendDcWizard withShadow />
	<div className = "HelpKit" >

		<img src = {Utils.buildImgLink("tenannt.svg")} alt = "" />
		<p >
			מבולבלים לקראת המעבר? כבר שכחתם מה חשוב לבדוק?
			<br />
			<strong > הכנו לכם מדריך דיגיטלי עם כל השלבים לחיפוש דירה</strong >
		</p >
		<br />
		<a
			className = "desktopOnly btn--azure"
			onClick = {() => {
				Utils.gaEvent("עמוד אני דייר", "כפתור קיט דיירים", "");
			}}
			href = {DESKTOP_KIT_URL}
			target = "_blank"
		>
			לצפייה לחצו כאן
		</a >
		<a
			className = "mobileOnly btn--azure"
			onClick = {() => {
				Utils.gaEvent("עמוד אני דייר", "כפתור קיט דיירים", "");
			}}
			href = {MOBILE_KIT_URL}
			target = "_blank"
		>
			לצפייה לחצו כאן
		</a >
	</div >
</div >;

export {HelpKit};
