import React              from "react";
import {useOutletContext} from "react-router-dom";
import {Divider}          from "../../components/divider";

import {MortContactUs}   from "../../mortgage-new/pages/main/components/contact";
import PartnersSection   from "../../rent/pages/main/components/partners";
import {CalcSection}     from "./components/calculator";

import "./index.scss";
import {ExplainSection}  from "./sections/explain";
import {TermsAndVideo}   from "./sections/terms";
import {TopSection}      from "./sections/top";
import {WhoAreWeSection} from "./sections/who-we-are";

function MortInfo() {
	const [refs, videoURLS, mortRefs] = useOutletContext();

	return (
		<div className = "mortInfo" >
			<TopSection />
			<Divider height = "50px" />
			<ExplainSection />
			<TermsAndVideo />
			<CalcSection />
			<WhoAreWeSection reference = {mortRefs.mortWhoWeAreOld} />
			<PartnersSection reference = {mortRefs.mortPartnersOld} />
			<MortContactUs reference = {mortRefs.mortContact} />
		</div >
	);
}

export {MortInfo};
