import { useState, useEffect } from "react";
import useIsInViewport from "../../../../hooks/useIsInViewport";

export default function useCTAAnimationClick ({ref}) {
    const [showAnimation, setShowAnimation] = useState(false);
    const isInView = useIsInViewport(ref)

    useEffect(() => {
        setShowAnimation(false)
        if (isInView) {
            const timeout = setTimeout(() => {
                setShowAnimation(true)
            }, 1000)
            return () => clearTimeout(timeout)
        }
    }, [isInView])

    return {showAnimation, isInView}
}