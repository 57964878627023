import React, { useRef } from "react";
import useIsInViewport from "../../../../../hooks/useIsInViewport";

import CTAAnimationClick from "../../../../../components/product-pages/CTAAnimationClick";
import ImageContainer from "../../../../../components/product-pages/image-container";
import Section from "../../../../../components/product-pages/section";
import TextSection from "../../../../../components/product-pages/text-section";
import Container from "../../../../../components/product-pages/container";

import { IMAGE_URL } from "./consts";
import { CTA_TITLE, PARAGRAPH, TITLE } from "./texts";
import { CTA_BUTTON_TEXT } from "../../texts";
import { ANALYTICS } from "../../consts";

import "./index.scss";

export default function ThirdSection() {
    const sectionRef = useRef(null)
    const CTARef = useRef(null)
    const isInView = useIsInViewport(sectionRef)

    return <Section reference={sectionRef} className="safecheck-third-section">
        <Container reference={CTARef} className={`text-and-cta ${isInView ? "in-view" : ""}`}>
            <TextSection title={TITLE} text={PARAGRAPH} />
            <CTAAnimationClick  text={CTA_BUTTON_TEXT} analytics = {ANALYTICS.THIRD_SECTION.CTA} reference={CTARef} title={CTA_TITLE}/>
        </Container>
        <ImageContainer className="safecheck-image" src={IMAGE_URL} />
    </Section>
}