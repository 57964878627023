import React, {memo}            from "react";
import {Divider}                from "../../../components/divider";
import {TestimonialsStrip}      from "../../../rent/pages/testimonials/components/testimonials-strip";
import TestimonialsVideoSection from "../../../rent/pages/testimonials/components/video-section";
import {WeCheckSection}         from "../../../UIWck/Section";
import {MortTestimonials}       from "./consts/MortTestimonialsData";
import "./index.scss";

const TestimonialsSection = () => (
	<>
		<TestimonialsVideoSection
			video = {MortTestimonials.videoSection}
			eventCategory = "אתר שיווקי -￼ משכנתאות"
			grayBackground = "grayBackground"
		/>
		<TestimonialsStrip
			testimonials = {MortTestimonials.stripSection}
			eventCategory = "אתר שיווקי -￼ משכנתאות"
			isMortSite
			grayBackground = "grayBackground"
		/>
	</>
);

const TestimonialsTop = memo(() => (
	<>
		<div className = "section TestimonialsHeader" >
			<div className = "row flexDirectionColumn center" >
				<h1 className = "textColorPrimary bold text-center" >
					רוצים לדעת מה מספרים עלינו?
				</h1 >
				<h2 className = "textColorPrimary text-center" >
					כאן תוכלו לשמוע
				</h2 >
			</div >
		</div >
	</>
));

const MortTestimonialsPage = () => {
	return (
		<WeCheckSection className = "MortTestimonialsPage" >
			<TestimonialsTop />
			<Divider height = "calc(2vw + 10px)" />
			<TestimonialsSection />
		</WeCheckSection >
	);
};

export {MortTestimonialsPage};
