import React from "react";
import "./index.scss";
import { NOOP } from "../../../../utils";

export default function Container({
    children,
    className = "",
    reference = null,
    style = {},
    onClick = NOOP,
    onFocus = NOOP,
    onBlur = NOOP,
    dataTestId = null,
}) {
    return (
        <div
            data-testid={dataTestId}
            onClick={onClick}
            onFocus={onFocus}
            onBlur={onBlur}
            style={style}
            ref={reference}
            className={`flex-container ${className}`}
        >
            {children}
        </div>
    );
}
