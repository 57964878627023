import React from "react";
import "./index.scss";
import Spinner from "../spinner";

export default function SubmitButton({ onSubmit, text, disabled, isLoading }) {
  const handleClick = (event) => {
    if (!disabled && !isLoading) {
      onSubmit(event);
    }
  };

  return (
    <button
      type="submit"
      className="login-submit-button"
      disabled={disabled || isLoading}
      onClick={handleClick}
      aria-busy={isLoading}
      aria-label={isLoading ? "Submitting" : text}
    >
      {isLoading ? <Spinner /> : text}
    </button>
  );
}
