import React from "react";
import "./index.scss";
import { CLOSE_ICON } from "../../../data-view/components/close-button/consts";
import { useDispatch } from "react-redux";
import { setIsIntroVisible } from "../../../../../../redux/features/newsletterSlice";

export default function CloseButton() {
    const dispatch = useDispatch();
    const close = () => dispatch(setIsIntroVisible(false))
  return (
    <button onClick={close} className="close-button">
      <img src={CLOSE_ICON} />
    </button>
  );
}
