import React from "react";
import Container from "../container";
import "./index.scss";

export default function Heading({ text, icon, className = "" }) {
    return icon
        ? <Container className={`heading-container ${className}`}>
            <div className="icon-container">
                <img rel="prefetch" src={icon} />
            </div>
            <h1>{text}</h1>
        </Container>
        : <h1>{text}</h1>
}