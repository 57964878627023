import React from "react";
import { LOAN_PAID } from "./texts";
import "./index.scss";

export default function LoanPaid() {
  return (
    <div className="loan-paid-container">
      <p>{LOAN_PAID}</p>
    </div>
  );
}
