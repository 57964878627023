import React from "react";
import "./index.scss";
import useDateRangePicker from "../../hooks/useDateRangePick";
import SingleMonth from "./single-month";
import SaveButton from "./save-button";
import YearSelect from "./year-select";
import { FILTER_DATE_CTA } from "../../../../texts";

export default function OpenDisplay() {
  const {
    handleMonthSelect,
    handleDateRangeSelect,
    handleHover,
    handleMouseLeave,
    generateDisplayItems,
  } = useDateRangePicker();

  const items = generateDisplayItems();

  return (
    <div className="open-display-container">
      <YearSelect />
      <h4>{FILTER_DATE_CTA}</h4>
      <div className="date-range">
        {items.map((item, index) => {
          const positionClass = item.isStartOfRow
            ? "start-of-row"
            : item.isEndOfRow
            ? "end-of-row"
            : "";
          return (
            <SingleMonth
              {...item}
              handleMonthSelect={handleMonthSelect}
              key={index}
              positionClass={positionClass}
              handleHover={handleHover(index)}
              handleMouseLeave={handleMouseLeave}
              index={index}
            />
          );
        })}
      </div>
      <SaveButton handleSave={handleDateRangeSelect} />
    </div>
  );
}
