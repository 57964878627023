import React from "react";
import "./index.scss";
import SingleItem from "../single-item";
import { DETAILS_OBJECT } from "./consts";
import { useSelector } from "react-redux";
import ContactSection from "../contact";

export default function Details() {
  const user = useSelector((state) => state.user);
  const fullName = `${user?.firstName} ${user?.lastName}`;
  return (
    <div className="details-container">
      <div className="details-item-list-container">
        <SingleItem {...DETAILS_OBJECT.NAME} data={fullName} />
        <SingleItem {...DETAILS_OBJECT.PHONE} data={user?.phone} />
        <SingleItem {...DETAILS_OBJECT.ID} data={user?.idNum} />
        <SingleItem {...DETAILS_OBJECT.EMAIL} data={user?.email} />
      </div>
      <ContactSection/>
    </div>
  );
}
