import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { CHEVRON_BOX } from "../../../../../../search/components/month-select/consts";

import "./index.scss";
import YearDisplay from "./components/year-display";
import { setCurrentYearIndex } from "../../../../../../../../../redux/features/newsletterSlice";
import Container from "../../../../../../../../components/product-pages/container";
import { YEARS_INDEX } from "./consts";

export default function YearSelect() {
  const dispatch = useDispatch();

  const availableYears = useSelector(
    (state) => state.newsletter.filter.date.availableYears
  );
  const currentYearIndex = useSelector(
    (state) => state.newsletter.filter.date.currentYearIndex
  );

  const handleClick = (direction) => {
    if (direction === "left") {
      dispatch(setCurrentYearIndex(currentYearIndex - 1));
    } else {
      dispatch(setCurrentYearIndex(currentYearIndex + 1));
    }
  };

  return (
    <Container className={`year-select-container`}>
      <button
        disabled={currentYearIndex === YEARS_INDEX.PREVIOUS}
        onClick={() => handleClick("right")}
        className="arrow-right"
      >
        <img src={CHEVRON_BOX} alt="Right Arrow" />
      </button>
      <YearDisplay
        availableYears={availableYears}
        currentYearIndex={currentYearIndex}
      />
      <button
        disabled={currentYearIndex === YEARS_INDEX.CURRENT}
        onClick={() => handleClick("left")}
        className="arrow-left"
      >
        <img src={CHEVRON_BOX} alt="Left Arrow" />
      </button>
    </Container>
  );
}
