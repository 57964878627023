import React from "react";
import "./index.scss";
import PersonalAreaHeader from "../header";
import LoansInfo from "../loans";
import { sumRemainingLoanAmounts } from "./utils";
import { format } from "date-fns";
import PersonalDetailsDrawer from "../personal-details-drawer";

export default function PersonalAreaLayout({ loans }) {
  const totalLoansAmount = loans.length > 0 && sumRemainingLoanAmounts(loans);
  const updatedAt =
    loans.length > 0 &&
    format(new Date(loans[0]?.prime_last_update), "dd.MM.yyyy");
  return (
    <div className="personal-area-layout-container">
      <PersonalAreaHeader totalLoans={totalLoansAmount} updatedAt={updatedAt} />
      <LoansInfo loans={loans} />
      <PersonalDetailsDrawer />
    </div>
  );
}
