const requiredValidator = (value) => {
	return value !== null && value !== "" && value !== undefined;
};

const emailValidator = (email) => {
	let valid = false;
	if (email) {
		const regex = email.match(
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
		);
		valid       = regex !== null;
	}
	return valid;
};

const phoneValidator = (phone) => {
	let valid = false;
	if (phone) {
		const regex = phone.match(/^[0-9]*$/i);
		valid       = regex !== null && phone.length === 10;
	}
	return valid;
};

const hebrewOnlyValidator = (value) => {
	let valid = false;
	if (value) {
		const regex = value.match(/^[\u0590-\u05fe ]+$/i);
		valid       = regex !== null;
	}
	return valid;
};

const maxAmountValidator = (value) => {
	let valid = false;
	if (!value || (parseInt(value) <= 1500000 && parseInt(value) >= 100000)) {
		valid = true;
	}
	return valid;
};

export {
	requiredValidator,
	emailValidator,
	phoneValidator,
	hebrewOnlyValidator,
	maxAmountValidator
};
