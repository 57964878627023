import React from "react";
import { useDispatch, useSelector } from "react-redux";

import Autocomplete from "../autocomplete";
import Container from "../../../../../components/product-pages/container";

import {
  resetSearchValue,
  searchArea,
  searchCity,
  selectArea,
  selectCity,
  selectNeighborhood,
  setDataView,
} from "../../../../../../redux/features/newsletterSlice";
import {
  AREA_AUTOCOMPLETE_PLACEHOLDER,
  CITY_AUTOCOMPLETE_PLACEHOLDER,
  ROOM_SELECT_TITLE,
  SEARCH_BUTTON_TEXT,
  TITLE,
} from "./texts";

import "./index.scss";
import MonthSelect from "../month-select";
import RoomSelect from "../../../room-select";
import Accordion from "../../../filter/components/accordion";
import useIsMobile from "../../../../../hooks/useIsMobile";

export default function FormContainer({ isDataViewVisible }) {
  const dispatch = useDispatch();

  const isMobile = useIsMobile(1000);

  const cities = useSelector((state) => state.newsletter.cities);
  const areas = useSelector((state) => state.newsletter.areas);
  const neighborhoods = useSelector((state) => state.newsletter.neighborhoods);

  const citySearch = useSelector((state) => state.newsletter.citySearch);
  const areaSearch = useSelector((state) => state.newsletter.areaSearch);

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const selectedCity = useSelector((state) => state.newsletter.selectedCity);

  const selectedCityAreas = areas.filter(
    (area) => area.city_id === selectedCity?.id
  );

  const selectedCityNeighborhoods = neighborhoods.filter(
    (neighborhood) => neighborhood.city_id === selectedCity?.id
  );

  const selectedCityAreasAndNeighborhoods = [
    ...selectedCityAreas,
    ...selectedCityNeighborhoods,
  ];

  const selectedNeighborhood = useSelector(
    (state) => state.newsletter.selectedNeighborhood
  );

  const selectedArea = useSelector((state) => state.newsletter.selectedArea);

  const handleCitySearch = (e) => {
    dispatch(searchCity(e.target.value));
  };

  const handleCitySelect = (city) => {
    dispatch(selectCity(city?.id));
  };

  const handleAreaSearch = (e) => {
    dispatch(searchArea(e.target.value));
  };

  const handleAreaSelect = (area) => {
    return area?.name
      ? dispatch(selectArea(area?.id))
      : dispatch(selectNeighborhood(area?.id));
  };

  const handleSearchReset = (input) => {
    if (input === "citySearch") {
      dispatch(selectArea(null));
      dispatch(selectNeighborhood(null));
      dispatch(searchArea(""));
    }
    dispatch(resetSearchValue(input));
  };

  const openDataView = (e) => {
    e.preventDefault();
    dispatch(
      setDataView(
        selectedArea
          ? {
              ...selectedArea,
              name: `${selectedCity.name} - ${selectedArea.name}`,
            }
          : selectedNeighborhood
          ? {
              ...selectedNeighborhood,
              name: `${selectedCity.name} - ${selectedNeighborhood.display_name}`,
            }
          : selectedCity
      )
    );
  };

  return (
    <Container className={`form-container-newsletter-search`}>
      <MonthSelect />
      <Accordion
        className="form-content-accordion"
        isOpen={isMobile || !isDataViewVisible}
      >
        {!isMobile && <h2>{TITLE}</h2>}
        <Autocomplete
          dataTestId="data-view-city-autocomplete"
          placeholder={CITY_AUTOCOMPLETE_PLACEHOLDER}
          list={cities}
          selected={selectedCity}
          searchValue={citySearch}
          handleSearch={handleCitySearch}
          handleSelect={handleCitySelect}
          resetSearchValue={() => handleSearchReset("citySearch")}
          openOnType
        />
        <Autocomplete
          dataTestId="data-view-neighborhood-autocomplete"
          placeholder={AREA_AUTOCOMPLETE_PLACEHOLDER}
          list={selectedCityAreasAndNeighborhoods}
          selected={selectedArea || selectedNeighborhood}
          searchValue={areaSearch}
          handleSearch={handleAreaSearch}
          handleSelect={handleAreaSelect}
          resetSearchValue={() => handleSearchReset("areaSearch")}
          isDropdown
        />
        <RoomSelect title={ROOM_SELECT_TITLE} dataTestId="data-view-search-rooms" />
      </Accordion>
      {isLoggedIn && (
        <button
          data-testid="data-view-search-button"
          disabled={!selectedCity}
          onClick={openDataView}
          className={`search-button ${isDataViewVisible ? "no-margin" : ""}`}
        >
          {SEARCH_BUTTON_TEXT}
        </button>
      )}
    </Container>
  );
}
