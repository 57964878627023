import React from "react";
import "./index.scss";
import { TITLE } from "./texts";
import { HeaderButtons } from "../../../../../components/header";

export default function ViewOptions() {
  return (
    <div className="view-options-container">
      <h4>{TITLE}</h4>
      <div className="buttons-wrapper">
        <HeaderButtons />
      </div>
    </div>
  );
}
