import React, {useEffect, useState} from "react";

import {Validators} from "@wecheck/uisharedutils/validators";
import Utils        from "../../../../../../../../shared/utils";

import {CityField} from "../../../../../../../../UIWck/CityField";
import {Slide}     from "../../slide";

import "./index.scss";

const AssetDetailsSlide = ({currRentCost, currCity, currStreet, done, withShadow}) => {

	const [isValid, setIsValid]           = useState(false);
	const [rentCost, setRentCost]         = useState(currRentCost);
	const [city, setCity]                 = useState(currCity);
	const [streetName, setStreetName]     = useState(currStreet);
	const [validAddress, setValidAddress] = useState(false);

	useEffect(() => {
		setIsValid(Validators.numbersOnly(rentCost));
	}, []);

	useEffect(() => {
		setIsValid(isFormValid());
	}, [rentCost, validAddress]);

	const onAddressChange = ({value}) => {
		const isValidAddress = CityField.isValidAddress(value);
		setValidAddress(isValidAddress);

		setCity(value.city ? value.city.cityName : null);
		setStreetName(value.streetName);
	};

	const fireAnalytics = name => {
		Utils.gaEvent("עמוד אני דייר", "טופס מילוי דיירים", name === "city" ? "פרטי נכס - עיר" : "פרטי נכס - רחוב");
	};

	const isFormValid = () => Validators.numbersOnly(rentCost) && validAddress;

	return <div className = "AssetDetailsSlide" >
		<Slide withShadow = {withShadow}
		       caption1 = "אז למה אתם מחכים?" caption2 = "200,000 דיירים כבר משתמשים"
		       subCaption = "הזינו את פרטי הנכס בו אתם מעוניינים" >
			<div className = "dataInput" >
				<CityField handleBlur = {onAddressChange}
				           customPlaceHolder = "עיר"
				           fireAnalytics = {fireAnalytics}
				           fields = {[CityField.FIELDS.CITY, CityField.FIELDS.STREET_NAME]}
				           defaultAddress = {{city, streetName}}
				           handleChange = {onAddressChange}
				/>
				<div className = "InputContainer" >
					<input type = "number" name = "rent" placeholder = "שכר הדירה הצפוי" defaultValue = {rentCost}
					       autoComplete = "off"
					       onFocus = {() => {
						       Utils.gaEvent("עמוד אני דייר", "טופס מילוי דיירים", "פרטי נכס - שכר דירה צפוי");
					       }
					       }
					       onChange = {(e) => {
						       setRentCost(e.target.value);
					       }}
					/>
					<img src = {Utils.buildTenantPageImgLink("rent.svg")} alt = "" />
				</div >
			</div >
			<div className = "buttonDiv" >
				<a onClick = {() => {
					Utils.gaEvent("עמוד אני דייר", "טופס מילוי דיירים", "פרטי נכס - מעבר לשלב הבא");
					done(city, streetName, rentCost);
				}}
				   className = {isValid ? "btn--azure" : "btn--gray"} >
					הבא
				</a >
			</div >
		</Slide >
	</div >;
};

export {AssetDetailsSlide};