import React from "react";
import "./index.scss";
import { FILTER_DATE_CTA } from "../../texts";
import Display from "./components/display";

export default function DateRangePicker() {
  return (
    <div className="date-range-picker-container">
      <h4>
        {FILTER_DATE_CTA}
      </h4>
      <Display />
    </div>
  );
}
