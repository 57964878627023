import React from "react";
import Utils from "../../../shared/utils";
import Container from "../container";
import CTAButton from "../CTAButton";
import HeadingMD from "../heading-md";

import "./index.scss";

export default function CTA ({title, text, buttonClassName = "", className = "", reference = null, CTALink = Utils.getLoginLink(), analytics = {}}) {
    return <Container reference={reference} className={`cta-container ${className}`}>
        <HeadingMD text={title}/>
        <CTAButton analytics={analytics} href={CTALink} className={buttonClassName} text={text}/>
    </Container>
}