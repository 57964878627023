import React, { useState } from "react";

import { Formik, Form } from "formik";

import Container from "../../../container";
import Header from "../header";
import Input from "../input";
import Select from "../select";
import Submit from "../submit";
import Checkbox from "../checkbox";
import {
  legalDisclaimerTextFirstLine,
  legalDisclaimerTextSecondLine,
} from "./texts";
import { FORM } from "../../consts";

import "./index.scss";
import config from "../../../../../../config/config";

export default function FormContainer({ initialValues, validate, onSubmit }) {
  const [isChecked, setIsChecked] = useState(false);

  return (
    <Container className="form-container-mort-contact">
      <Header />
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ isSubmitting }) => (
          <Form>
            {FORM.map(({ ...props }, index) => {
              return props.type === "select" ? (
                <Select key={index} {...props} />
              ) : (
                <Input key={index} {...props} />
              );
            })}
            <Checkbox
              label={"אנו מתכננים לרכוש/קיים בבעלותינו נכס"}
              value={isChecked}
              setValue={setIsChecked}
            />
            <Submit
              loading={isSubmitting}
              text={"שליחה"}
              disabled={isSubmitting || !isChecked}
            />
          </Form>
        )}
      </Formik>
      <p className="legalDisclaimer">{legalDisclaimerTextFirstLine}</p>
      <p className="legalDisclaimer">{legalDisclaimerTextSecondLine}</p>
      <a
        href={`${config.s3ImagesLink}/marketing/mortgage-price-list.pdf`}
        target="_blank"
        rel="noreferrer"
      >
        תעריפון משכנתא
      </a>
    </Container>
  );
}
