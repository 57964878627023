import React, {Component}   from "react";
import Utils                from "../../../../../shared/utils";
import {ButtonWindowChoose} from "../../../../../UIWck/ButtonWindowChoose";

import "./index.scss";

const subOptions = ["ההון העצמי שלי", "ההחזר החודשי שלי", "שווי הנכס"];

const options        = ["נכס יחיד", "משפר דיור", "נכס להשקעה"];
const exitingOptions = ["בבעלותי נכס יחיד", "בבעלותי מספר נכסים"];

const subText = "אני רוצה לחשב לפי";

class ChooseSection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			openButton      : undefined,
			showFirstButton : true,
			showSecondButton: true,
			showThirdButton : true
		};
	}

	buttonOpened = (index) => {
		if (index === undefined) {
			this.setState({
				showFirstButton : true,
				showSecondButton: true,
				showThirdButton : true
			});
		}
		else {
			this.setState({
				showFirstButton : index === 0,
				showSecondButton: index === 1,
				showThirdButton : index === 2
			});
		}
	};

	componentDidMount = () =>
		Utils.gaVirtual("משכנתא משלימה/מחשבון משכנתא/בחירת סוג נכס");

	newPropertyChosen = (index, subIndex) => {
		Utils.gaEvent("מחשבון משכנתא", options[index], "");
		this.props.forward(index, subIndex);
	};

	exitingPropertyChosen = (index) => {
		Utils.gaEvent("מחשבון משכנתא", exitingOptions[index], "");
		this.props.forward(index);
	};

	render = () => (
		<div className = "ChooseSection" >
			<div className = "content" >
				<h3 >ספרו לנו איזה נכס אתם רוכשים</h3 >
				{this.state.showFirstButton && (
					<ButtonWindowChoose
						text = {options[0]}
						subText = {subText}
						subOptions = {subOptions}
						onChoose = {this.newPropertyChosen}
						buttonOpened = {this.buttonOpened}
						index = {0}
					/>
				)}
				{this.state.showSecondButton && (
					<ButtonWindowChoose
						text = {options[1]}
						subText = {subText}
						subOptions = {subOptions}
						onChoose = {this.newPropertyChosen}
						buttonOpened = {this.buttonOpened}
						index = {1}
					/>
				)}
				{this.state.showThirdButton && (
					<ButtonWindowChoose
						text = {options[2]}
						subText = {subText}
						subOptions = {subOptions}
						onChoose = {this.newPropertyChosen}
						buttonOpened = {this.buttonOpened}
						index = {2}
					/>
				)}
				<img
					className = "bricksImage"
					src = {Utils.buildDashboardImgLink("mortgage/briksline.svg")}
					alt = ""
				/>
				<h3 className = "lowerDesc" >
					{" "}
					כמו שאמרנו,ניתן לקבל משכנתא משלימה גם כנגד נכס קיים
				</h3 >
				<ButtonWindowChoose
					text = {exitingOptions[0]}
					onChoose = {this.exitingPropertyChosen}
					index = {0}
				/>
				<ButtonWindowChoose
					text = {exitingOptions[1]}
					onChoose = {this.exitingPropertyChosen}
					index = {1}
				/>
			</div >
		</div >
	);
}

export {ChooseSection};
