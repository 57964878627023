import Utils from "../../../../../shared/utils";

import {FeaturesList} from "../../../../../UIWck/FeaturesList";

import "./index.scss";

const features = [
	"עם הדאבל צ'ק שלנו, הנתונים של כל דייר ודיירת שממלאים שאלון דיגיטלי עוברים דרך אלגוריתם לניהול סיכונים שמבוסס על טכנולוגיות מתקדמות של בינה מלאכותית.",
	"על בסיס אלגוריתם זה, בעל הדירה מקבל את דוח הדאבל צ'ק עם אומדן הסיכון בעסקת השכירות (אגב, שלא תטעו, בכל עסקת שכירות קיים סיכון).",
	"אתם בתור דיירים תקבלו את הדוח גם אליכם לנייד, בדיוק כפי שבעל הדירה מקבל אותו."
];

const features1 = [
	"תוכלו לזהות גם מקרים שאולי שכר הדירה שתכננתם למתוח כלפיי מעלה קצת יותר מידי גדול עליכם, ואולי שווה שתחפשו משהו בתקציב יותר מתאים.",
	"בנוסף, נאפשר לכם או לבעלי הדירות לקבל הצעה לערבות וויצ'ק. ערבות שתתן הגנה לתשלומי שכר הדירה ותחסוך לכם העברת ערבויות מכבידות אחרות.",
	"תמיד נסייע גם לדיירים וגם לבעלי הדירות, ככה שאם חלילה יקרה משהו ותשלום שכר הדירה לא יתקבל, ננסה לפעול יחד איתכם על מנת למצוא פתרונות על בסיס כלכלי בכדי שתוכלו להמשיך להתגורר בדירה."
];

const WhatWeSay = () => {

	const button = <a onClick = {e => {
		Utils.gaEvent("עמוד אני דייר", "ליצירת פרופיל דיגיטלי - אז מה בעצם אנחנו אומרים", "");
		e.preventDefault();
		window.scrollTo(0, 0);
	}}
	                  className = "btn--azure" >
		ליצירת פרופיל דיגיטלי
	</a >;

	return <div className = "WhatWeSaySection" >
		<div className = "container" >
			<div className = "textContent" >

				<h2 >אז מה בעצם אנחנו אומרים?</h2 >
				<br />
				<div className = "shortList" >
					<FeaturesList features = {features} />
				</div >
				<div className = "longList" >
					<FeaturesList features = {features1} />
				</div >
				<div className = "buttonDiv desktopOnly" >
					{button}
				</div >
			</div >
			<div className = "buttonDiv mobileOnly" >
				{button}
			</div >
			<div className = "image" >
				<img
					className = "desktopOnly"
					src = {Utils.buildImgLink("wesay.svg")}
					alt = ""
				/>
			</div >
		</div >

	</div >;
};

export {WhatWeSay};