import React, { useState } from "react";
import "./index.scss";
import { INFO_IMAGE } from "../../../filter/components/info-button/consts";
import { DISCLAIMER_TEXTS } from "./texts";
import Accordion from "../../../filter/components/accordion";
import useIsMobile from "../../../../../hooks/useIsMobile";

export default function LegalDisclaimer() {
  const [isOpen, setIsOpen] = useState(false);
  const isMobile = useIsMobile(1000);
  return (
    <div
      onMouseEnter={() => !isMobile && setIsOpen(true)}
      onMouseLeave={() => !isMobile && setIsOpen(false)}
      onClick={() => isMobile && setIsOpen((open) => !open)}
      className="legal-disclaimer-container"
    >
      <div className="closed-view">
        <div className="info-icon-container">
          <img src={INFO_IMAGE} />
        </div>
        <div className="text-container">
          <p>
            {DISCLAIMER_TEXTS.CLOSED.PARAGRAPH}
            <span className={isOpen ? "hide" : ""}>
              {DISCLAIMER_TEXTS.CLOSED.READ_MORE}
            </span>
          </p>
        </div>
      </div>
      <Accordion isOpen={isOpen} className="legal-disclaimer-accordion">
        <p>{DISCLAIMER_TEXTS.OPEN}</p>
      </Accordion>
    </div>
  );
}
