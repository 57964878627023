import React from "react";
import "./index.scss";

export const Hamburger = ({ isOpen, setIsOpen, isOffset }) => {
  return (
    <button
      className="hamburger-container"
      onClick={() => setIsOpen((open) => !open)}
    >
      <svg
        className={`hamburger ${isOpen ? "open" : ""} ${
          isOffset && !isOpen ? "fill-white" : ""
        }`}
        width="20"
        height="16"
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="&#215;&#148;&#215;&#158;&#215;&#145;&#215;&#149;&#215;&#168;&#215;&#146;&#215;&#168;"
          d="M1 1H19M5.5 8H19M10 15H19"
          stroke="#1D3557"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </button>
  );
};
