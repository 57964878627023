import React, { useRef } from "react";

import GridSection from "../../../../../components/product-pages/grid-section";
import ItemRow from "../../../../../components/product-pages/item-row";
import TextSection from "../../../../../components/product-pages/text-section";
import CTAButton from "../../../../../components/product-pages/CTAButton";

import { ANALYTICS } from "../../consts";
import { URLS } from "../../../../../shared/urls";
import { ITEM_LIST } from "./consts";
import { ITEM_LIST_TITLE, PARAGRAPH, TITLE } from "./texts";
import { CTA_BUTTON_TEXT } from "../first-section/texts";

import "./index.scss";

export default function FourthSection() {
    const itemListRef = useRef();
    return <GridSection className="additional-mort-fourth-section">
        <ItemRow
            isTitled
            title={ITEM_LIST_TITLE}
            list={ITEM_LIST}
            reference={itemListRef}
        />
        <TextSection title={TITLE} text={PARAGRAPH} />
        <CTAButton analytics={ANALYTICS.FOURTH_SECTION.CTA} href={URLS.MortgageQue} text={CTA_BUTTON_TEXT}/>
    </GridSection>
}