import React from "react";

import "./index.scss";

const BackgroundImg = ({src = null, ratio = `56.6%`, hoverZoom}) => (
	<div style = {{paddingBottom: ratio}} className = "ImageContainer" >
		<div
			className = {`BackgroundImg ${hoverZoom}`}
			style = {{backgroundImage: `url(${src})`}}
		></div >
	</div >
);

export {BackgroundImg};
