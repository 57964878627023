import React from "react";
import Container from "../container";

import Heading from "../heading";
import TextSection from "../text-section";

import "./index.scss";

export default function MainTitleAndParagraph({
  mainTitle,
  secondaryTitle,
  secondaryParagraph = null,
  paragraph,
  mainTitleIcon,
  mainSubtitle,
  secondaryParagraphTitle = "",
}) {
  return (
    <Container
      className={`main-title-and-par-container ${
        mainSubtitle ? "change-spacing" : ""
      }`}
    >
      <Heading text={mainTitle} icon={mainTitleIcon} />
      {mainSubtitle && <h2>{mainSubtitle}</h2>}
      <TextSection title={secondaryTitle} text={paragraph} />
      <br />
      {secondaryParagraph ? (
        <TextSection
          text={secondaryParagraph}
          title={secondaryParagraphTitle}
        />
      ) : null}
    </Container>
  );
}
