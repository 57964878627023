import React, { useEffect } from "react";
import "./index.scss";
import {
  LabelList,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import { useState } from "react";
import { useSelector } from "react-redux";
import { currentDate } from "../../../../../../redux/features/newsletterSlice";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { ERROR_DISPLAY, labelListProps, lineProps, xAxisProps } from "./consts";
import { NOT_ENOUGH_DATA } from "../graphs-section/texts";

export default function RentMonthly({
  currentMonth,
  lastMonth,
  twoMonthsAgo,
  error,
}) {
  const [data, setData] = useState(null);
  const isMobile = useIsMobile(1000);

  const date = useSelector(currentDate);

  useEffect(() => {
    setData([
      {
        name: date.twoMonthsAgoDateString,
        rent: twoMonthsAgo,
        amt: twoMonthsAgo,
      },
      {
        name: date.lastMonthDateString,
        rent: lastMonth,
        amt: lastMonth,
      },
      {
        name: date.currentMonthDateString,
        rent: currentMonth,
        amt: currentMonth,
      },
    ]);
  }, [currentMonth, lastMonth, twoMonthsAgo]);

  const marginTop = window.innerHeight > 900 ? 100 : 70;

  const lineMargin = {
    top: isMobile ? 60 : marginTop,
    right: 30,
    left: -30,
    bottom: 0,
  };

  if (error)
    return (
      <div className="error-container">
        <p>{NOT_ENOUGH_DATA}</p>
        <div className="image-container">
          <img rel="preload" src={ERROR_DISPLAY} />
        </div>
      </div>
    );

  return (
    <ResponsiveContainer
      className={"chart-responsive-container"}
      width={"100%"}
      height={"100%"}
    >
      <LineChart margin={lineMargin} data={data}>
        <XAxis {...xAxisProps} />
        <YAxis tick={false} axisLine={false} />
        <Line {...lineProps}>
          <LabelList {...labelListProps} aria-label="3-months-average-one-month-view"/>
        </Line>
      </LineChart>
    </ResponsiveContainer>
  );
}
