import React from "react";

import Utils from "../../../shared/utils";
import useCTAAnimationClick from "./hooks/useCTAAnimationClick";

import AnimationContainer from "../animation-container";
import Container from "../container";
import CTA from "../cta";

import { MOUSE_ANIMATION } from "./consts";

import "./index.scss";

export default function CTAAnimationClick ({reference, title, text, CTALink = Utils.getLoginLink(), analytics = {}}) {
    const {showAnimation, isInView} = useCTAAnimationClick({ref: reference})

    return <Container reference={reference} className="cta-animation-click-container">
    {isInView && showAnimation && <AnimationContainer img={MOUSE_ANIMATION} />}
    <CTA analytics={analytics} text={text} CTALink={CTALink} buttonClassName={showAnimation ? "animate-button" : ""} title={title} />
</Container>
}