import React from "react";
import { useRef } from "react";
import CTA from "../../../../../components/product-pages/cta";

import GridSection from "../../../../../components/product-pages/grid-section";
import ItemList from "../../../../../components/product-pages/item-list";
import useIsInViewport from "../../../../../hooks/useIsInViewport";
import { ANALYTICS } from "../../consts";
import LeftSection from "./components/left-section";

import { CTA_TITLE, ITEM_LIST, ITEM_LIST_TITLE } from "./consts";

import "./index.scss";

export default function ThirdSection() {
    const sectionRef = useRef(null)
    const CTARef = useRef(null)
    const isSectionInViewport = useIsInViewport(sectionRef)
    const isCTAInViewport = useIsInViewport(CTARef)

    return <GridSection
        reference={sectionRef}
        className={`third-section-container ${isSectionInViewport ? "in-view" : ""}`}>
        <ItemList title={ITEM_LIST_TITLE} list={ITEM_LIST} />
        <LeftSection />
        <CTA analytics={ANALYTICS.THIRD_SECTION.CTA} reference={CTARef} className={isCTAInViewport ? "cta-in-view" : ""} title={CTA_TITLE} />
    </GridSection>
}