import React, { useRef } from "react";

import GridSection from "../../../../../components/product-pages/grid-section";
import ItemRow from "../../../../../components/product-pages/item-row";
import CTAAnimationClick from "../../../../../components/product-pages/CTAAnimationClick";
import Container from "../../../../../components/product-pages/container";

import { URLS } from "../../../../../shared/urls";
import { ITEM_LIST } from "./consts";
import { ITEM_LIST_TITLE, TITLE } from "./texts";
import { CTA_BUTTON_TEXT } from "../first-section/texts";

import "./index.scss";

export default function FourthSection() {
  const itemListRef = useRef();
  const CTARef = useRef();

  return (
    <GridSection className="premium-mort-fourth-section">
      <ItemRow
        isTitled
        title={ITEM_LIST_TITLE}
        list={ITEM_LIST}
        reference={itemListRef}
      />
      <Container className="animation-cta-container">
        <CTAAnimationClick
          reference={CTARef}
          title={TITLE}
          text={CTA_BUTTON_TEXT}
          CTALink={URLS.MortgageQue}
        />
      </Container>
    </GridSection>
  );
}
