import React from "react";
import Utils from "../../../../../../../shared/utils";
import { texts } from "../../../../consts";
import "./index.scss";

export default function SuccessMessage({visible}) {
    return <div className={`SuccessMessageContainer ${visible ? "visible" : ""}`}>
        <div className="image-container">
            <img src={Utils.buildMortImgLink("Icons/tablet.svg")}/>
        </div>
        <div className="text-container">
            <h3>{texts.form.successMessage}</h3>
        </div>
    </div>
}