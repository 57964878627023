import React from "react";
import { useDispatch } from "react-redux";
import { hidePopup } from "../../../../../../redux/features/promotionalSlice";
import { CLOSE_ICON } from "../../../data-view/components/close-button/consts";

import "./index.scss";

export default function CloseButton({ isPopupOpen }) {
  const dispatch = useDispatch();
  const close = () => dispatch(hidePopup());
  return (
    <button onClick={close} className={`close-button`}>
      <img src={CLOSE_ICON} />
    </button>
  );
}
