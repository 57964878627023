import { useEffect, useState } from "react";
import useIsMobile from "../../../../../hooks/useIsMobile";
import Utils from "../../../../../shared/utils";
import { SendDcWizard } from "../wizard";
import texts from "./texts.json";

import "./index.scss";
import { useLocation } from "react-router-dom";
import { getQueryParamsFromCookie } from "../../../../../hooks/useCampaignParams";

const CheckListData = [
  "חינם",
  "בלי מסמכים, בלי תלושי שכר, בלי תדפיס עובר ושב",
  "התקדמות מהירה עם בעל/ת הדירה",
  "שמירה על פרטיות הדייר",
  "פרופיל דיגיטלי שישמש את הדייר לכל דירה גם בעתיד",
  "הצעה לערבות בטוחה והמשתלמת ביותר",
];

const DropBox = ({ caption, contentShown, triggerFunc, children }) => {
  const isMobile = useIsMobile();

  return (
    <div className="dropBox">
      <div
        className="caption"
        onClick={() => {
          triggerFunc(!contentShown);
        }}
      >
        <h2>{caption}</h2>
        {isMobile && (
          <img
            className={contentShown ? "transformImageDown" : ""}
            src={Utils.buildTenantPageImgLink("downArrow.svg")}
            alt=""
          />
        )}
      </div>
      <div
        className={`dropBoxContainer ${
          contentShown ? "dropBoxContainer--open" : "dropBoxContainer--close"
        }`}
      >
        {(!isMobile || contentShown) && children}
      </div>
    </div>
  );
};

const FirstTextBlock = () => {
  return (
    <>
      <p>
        לחפש דירה בימינו זה אתגר לא פשוט. יש כאלה שסרקו את יד 2, יש כאלה שהפכו
        להיות החברים הכי טובים של דורון ויש כאלה שחרשו את הפייסבוק.
        <br />
        <br />
        <strong>ועכשיו מה? פשוט נכנסים לדירה?</strong>
        <br />
        אז לא כ״כ מהר, ברוב המקרים זה השלב בו תצטרכו להוכיח לבעל הדירה שאתם
        הדיירים המתאימים ביותר. איך?
        <br />
        חלק יבקשו תלושי שכר, חלק יבקשו תדפיס עו״ש וחלק אפילו ישאלו אם יש לכם וי
        כחול בוואטסאפ.
        <br />
        כאן, אנחנו נכנסים לתמונה.
      </p>
      <br />
    </>
  );
};

const SecondTextBlock = ({ isYad2 }) => {
  return (
    <>
      {isYad2 ? (
        <Yad2MainParagraph />
      ) : (
        <p>
          יצרנו עבורכם שאלון קצר שמטרתו לקדם אתכם בצורה המהירה ביותר לעסקת
          השכירות! בסוף מילוי השאלון גם אתם וגם בעל הדירה תקבלו דוח שמרכז את כל
          מה שבעל הדירה צריך לדעת לקראת העסקה ותוכלו לקבל עדיפות והתקדמות מהירה
          לקראת הדירה הנחשקת.
        </p>
      )}
      <div className="checksListContainer">
        {CheckListData.map((data) => (
          <div className="checksList">
            <div className="listItem">
              <p>{data}</p>
              <img
                src={Utils.buildTenantPageImgLink("feather-check.svg")}
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const Yad2OpeningParagraph = () => {
  return (
    <p style={{ paddingBlockEnd: "10px" }}>{texts.yad2.opening_paragraph}</p>
  );
};

const Yad2MainParagraph = () => {
  return <p>{texts.yad2.main_paragraph}</p>;
};

const TopSection = () => {
  const [firstParagraphOpen, setFirstParagraphOpen] = useState(false);
  const [secondParagraphOpen, setSecondParagraphOpen] = useState(false);

  const [isYad2, setIsYad2] = useState(false);

  const location = useLocation();

  const yad2Condition = (params) =>
    params?.utm_source === "yad2" && params?.utm_campaign === "propertybutton";

  useEffect(() => {
    const params = getQueryParamsFromCookie();
    if (params && yad2Condition(params)) {
      setIsYad2(true);
    }
  }, [location]);

  const isMobile = useIsMobile();

  return (
    <div className="TenantTopSection">
      <h1>WeCheck - להתקדמות מהירה עם בעל הדירה</h1>
      <div className={`container ${isYad2 ? "yad2" : ""}`}>
        {isMobile && isYad2 && <Yad2OpeningParagraph />}
        <SendDcWizard isYad2={isYad2} showButtonsHeader />
        <div className="explainPart">
          {isYad2 && !isMobile ? (
            <Yad2OpeningParagraph />
          ) : !isYad2 ? (
            <DropBox
              caption="מי אנחנו ואיך אנחנו עוזרים לכם?"
              contentShown={firstParagraphOpen}
              triggerFunc={setFirstParagraphOpen}
            >
              <FirstTextBlock />
            </DropBox>
          ) : null}
          <DropBox
            caption="הכירו את הדאבל צ׳ק - פרופיל דיגיטלי לעולם השכירות"
            contentShown={secondParagraphOpen}
            triggerFunc={setSecondParagraphOpen}
          >
            <SecondTextBlock isYad2={isYad2} />
          </DropBox>
        </div>
      </div>
    </div>
  );
};

export { TopSection };
