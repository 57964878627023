import Utils from "../../../../../../shared/utils";

import "./index.scss";

const VideoStrip = ({ isYad2 }) => {
  const ctaText = isYad2
    ? "לשליחת הפרופיל שלכם לבעל הדירה"
    : "ליצירת פרופיל דיגיטלי";

  return (
    <div className="VideoStrip">
      <div className="textAndVideo">
        <div className="textBox">
          <p>
            הדוח מורכב מ-3 חלקים קצרים הכוללים שאלות כלליות שיעזרו לבעל הדירה
            להכיר אתכם טוב יותר, לדוגמה - כמה נפשות נכנסות לדירה, למה עזבתם את
            הדירה הקודמת מי משלם את השכ”ד, מאיזה חשבון יצאו הצ’קים וכד׳.
          </p>
          <div className="buttonDiv">
            <a
              onClick={(e) => {
                e.preventDefault();
                Utils.gaEvent(
                  "עמוד אני דייר",
                  "ליצירת פרופיל דיגיטלי - ממה מורכב השאלון",
                  ""
                );
                window.scrollTo(0, 0);
              }}
              className="btn--azure"
            >
              {ctaText}
            </a>
          </div>
        </div>
        <img
          className="videoGif"
          src={Utils.buildImgLink("tennat.gif")}
          alt=""
        />
      </div>
    </div>
  );
};

export { VideoStrip };
