import React from "react";
import "./index.scss";
import {
  ALT_CONTACT_ICONS,
  CONTACT_ICONS,
  CONTACT_TEXT,
  CONTACT_URLS,
} from "../contact-section/consts";

export default function ContactButton({ type, className = "", alt = false }) {
  return (
    <a
      href={CONTACT_URLS[type]}
      target="_blank"
      rel="noopener noreferrer"
      className={`contact-button-container ${className}`}
    >
      {CONTACT_TEXT[type]}
      <img
        src={alt ? ALT_CONTACT_ICONS[type] : CONTACT_ICONS[type]}
        alt={type}
      />
    </a>
  );
}
