export default function useScrollToElement() {
	const scrollIntoViewOptions = {
		behavior: "smooth",
		block   : "start",
		inline  : "nearest"
	};
	const scrollToElement       = (ref) =>
		ref.current.scrollIntoView(scrollIntoViewOptions);
	return scrollToElement;
}
