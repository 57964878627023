import React, { useState, useRef } from "react";
import "./index.scss";
import Container from "../../../../../components/product-pages/container";
import ImageContainer from "../../../../../components/product-pages/image-container";
import SearchList from "../search-list";
import { MAGNIFYING_GLASS_ICON } from "../mobile-closed-display/consts";
import { CHEVRON } from "./consts";
import { useSelector } from "react-redux";
import { NOOP } from "../../../../../../utils";
import useHandleCLickOutside from "../../../../../hooks/useHandleClickOutside";

export default function Autocomplete({
  handleSelect,
  handleSearch,
  searchValue,
  list,
  placeholder,
  isDropdown = false,
  openOnType = false,
  resetSearchValue = NOOP,
  allowEmptySearch = false,
  dataTestId = null,
}) {
  const [isListVisible, setIsListVisible] = useState(false);
  const containerRef = useRef(null);

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const LIST_DISPLAY_LIMIT = 5;

  const isPopupOpen = useSelector(
    (state) => state.promotional.currentPopupIndex
  );

  useHandleCLickOutside({
    reference: containerRef,
    callback: () => setIsListVisible(false),
    disabled: isPopupOpen,
  });

  const filteredList = list
    ?.filter((item) =>
      item.name
        ? item.name.indexOf(searchValue) !== -1
        : item.display_name.indexOf(searchValue) !== -1
    )
    ?.slice(0, LIST_DISPLAY_LIMIT);

  const handleFocus = () => {
    resetSearchValue();
    setIsListVisible(true);
  };

  const onBlur = () => {
    if (isListVisible) {
      return;
    }
    handleSelect();
    setIsListVisible(false);
  };

  const onKeyDown = (e) => {
    if (e.key === "Tab") {
      setIsListVisible(false);
      handleSelect();
    }
  };

  return (
    <div
      data-testid={dataTestId}
      aria-disabled={
        isLoggedIn && (!list || list.length === 0) && !allowEmptySearch
      }
      ref={containerRef}
      className="auto-complete-container"
    >
      <Container className="auto-complete">
        <ImageContainer
          className="icon-container"
          src={MAGNIFYING_GLASS_ICON}
        />
        <input
          className="auto-complete-input"
          placeholder={placeholder}
          value={searchValue}
          onChange={handleSearch}
          onFocus={handleFocus}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          disabled={
            (!isLoggedIn || !list || list.length === 0) && !allowEmptySearch
          }
        />
        {isDropdown && (
          <ImageContainer className="icon-container chevron" src={CHEVRON} />
        )}
      </Container>
      <SearchList
        isListVisible={
          filteredList.length > 0 &&
          isListVisible &&
          (openOnType ? searchValue.length >= 1 : isListVisible)
        }
        setIsListVisible={setIsListVisible}
        handleSelect={handleSelect}
        list={filteredList}
        openOnType={openOnType}
      />
    </div>
  );
}
