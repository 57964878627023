import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useIsMobile from "../../../../../../hooks/useIsMobile";
import Utils from "../../../../../../shared/utils";

import DesktopLinksList from "../desktop-links-list";
import MobileLinksList from "../mobile-links-list";

import "./index.scss";

export default function LinkContainer({
  link,
  refs,
  onNavClick,
  displayWhiteText,
  isNavOpen,
  isNewSiteActive,
  isMortPersonalAreaActive,
}) {
  const isMobile = useIsMobile();

  useEffect(() => {
    if (!isNavOpen) {
      setIsListOpen(false);
    }
  }, [isNavOpen]);

  const isDesktopListedLink = link.hasTooltip && !isMobile;
  const isMobileListedLink = link.hasTooltip && isMobile;

  const [isListOpen, setIsListOpen] = useState(false);

  const replaceStringPartWithSpan = (string, part) =>
    string.replace(part, `<span>${part}</span>`);

  const handleClick = (link) => {
    if (link.external) {
      window.location.href = link.href;
    }
    if (isMobileListedLink) {
      setIsListOpen(!isListOpen);
    } else if (link.scrollTo) {
      onNavClick(refs[link.ref]);
    }
    Utils.gaEvent(link.category, link.action, link.label);
  };

  return (
    <div className="link-and-tooltip-container">
      <Link
        data-tip
        data-for={link.label}
        className={`${link.bold ? "text-bold" : ""} ${
          displayWhiteText ? "white-text" : ""
        }`}
        to={isMobileListedLink ? null : link.link}
        onClick={() => handleClick(link)}
        dangerouslySetInnerHTML={{
          __html: replaceStringPartWithSpan(link.text, "חדש!"),
        }}
      />
      {isMobileListedLink && (
        <MobileLinksList
          isNewSiteActive={isNewSiteActive}
          isMortPersonalAreaActive={isMortPersonalAreaActive}
          isListOpen={isListOpen}
          setIsListOpen={setIsListOpen}
          link={link}
        />
      )}
      {isDesktopListedLink && (
        <DesktopLinksList
          link={link}
          isNewSiteActive={isNewSiteActive}
          isMortPersonalAreaActive={isMortPersonalAreaActive}
        />
      )}
    </div>
  );
}
