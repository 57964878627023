import React from "react";
import "./index.scss";
import { FooterContent } from "../../../../../../components/footer";

export default function DrawerFooter() {
  return (
    <footer className="drawer-footer-container">
      <FooterContent shouldLinkOpenInNewTab/>
    </footer>
  );
}
