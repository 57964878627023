import React from "react";
import MortgageContact from "../../../components/product-pages/mortgage-contact";
import PageContainer from "../../../components/product-pages/page-container";
import FirstSection from "./components/first-section";
import FifthSection from "./components/fifth-section";
import SecondSection from "./components/second-section";
import ThirdSection from "./components/third-section";
import FourthSection from "./components/fourth-section";

import "./index.scss";

export default function PremiumMort() {
  return (
    <PageContainer>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <MortgageContact />
    </PageContainer>
  );
}
