import useIsInViewport from "../useIsInViewport"
import useIsMobile from "../useIsMobile"
import Utils from "../../shared/utils"

import AnimationContainer from "../../components/product-pages/animation-container"
import Container from "../../components/product-pages/container"
import CTA from "../../components/product-pages/cta"

import { PLANE_ANIMATION, PLANE_ANIMATION_MOBILE } from "../../rent/pages/doublecheck/components/second-section/components/bottom-section/consts"

export default function useCTAAnimation({
    className = "",
    buttonClassName = "",
    ctaTitle,
    ref = null,
    ctaText,
    CTALink = Utils.getLoginLink(),
    analytics = {}
}) {
    const isCTAInViewport = useIsInViewport(ref)
    const isMobile = useIsMobile();

    return <Container reference={ref} className={className}>
        {isCTAInViewport && <AnimationContainer img={isMobile ? PLANE_ANIMATION_MOBILE : PLANE_ANIMATION} />}
        <CTA analytics={analytics} CTALink={CTALink} text={ctaText} title={ctaTitle} buttonClassName={isCTAInViewport && buttonClassName} />
    </Container>
}