import React              from "react";
import {useOutletContext} from "react-router-dom";
import "./index.scss";

export default function Video(props) {
	const [refs, videoURLS] = useOutletContext();

	const getTestimonialsUrl = (videoName) => {
		switch (videoName) {
			case "amos":
				return videoURLS.amosVideo;

			case "arie":
				return videoURLS.arieVideo;

			case "chanoh":
				return videoURLS.chanohVideo;

			case "moshe":
				return videoURLS.mosheVideo;

			case "testimonia":
				return videoURLS.mainTestimonilalsVideo;

			case "videoBaale":
				return videoURLS.videoBaaleVideo;

			case "videoDaiar":
				return videoURLS.videoDaiarVideo;

			case "videoNadla":
				return videoURLS.videoNadlaVideo;

			case "yevgeni":
				return videoURLS.yevgeniVideo;

			case "max":
				return videoURLS.maxVideo;

			case "kobi":
				return videoURLS.kobiVideo;

			case "hadar":
				return videoURLS.hadarVideo;

			case "daniel":
				return videoURLS.danielVideo;

			case "eliran":
				return videoURLS.eliranVideo;

			case "liza":
				return videoURLS.lizaVideo;

			case "meira":
				return videoURLS.meiraVideo;

			case "miriam":
				return videoURLS.miriamVideo;

			case "olga":
				return videoURLS.olgaVideo;

			case "nir":
				return videoURLS.nirVideo;

			case "moly":
				return videoURLS.molyVideo;

			case "mort":
				return videoURLS.mortVideo;

			case "newMortMai":
				return videoURLS.newMortMai;

			case "newMortSah":
				return videoURLS.newMortSah;

			case "newMortShi":
				return videoURLS.newMortShi;

			case "newMortSta":
				return videoURLS.newMortSta;

			case "newMortYon":
				return videoURLS.newMortYon;

			case "newMortZvi":
				return videoURLS.newMortZvi;

			default:
				return "";
		}
	};

	if (!videoURLS) return <></>;

	const {visible, onClose, showAd} = props;

	let videoURL;

	if (props.videoURL) {
		videoURL = "https://" + getTestimonialsUrl(props.videoURL);
	}
	else if (props.mortVideo) {
		videoURL = `https://${videoURLS.newMortVideoURL}`;
	}
	else if (props.forMort) {
		videoURL = `https://${videoURLS.mortURL}`;
	}
	else {
		videoURL = showAd
		           ? `https://${videoURLS.youtubeTVURL}`
		           : `https://${videoURLS.youtubeURL}`;
	}

	let videoCtrl = "";
	if (visible) {
		videoCtrl = <iframe src = {videoURL} allowFullScreen />;
	}

	if (props.forMort) {
		return videoURLS.mortURL ? (
			<div >
				<h4 >רוצים הסבר מפורט יותר? צפו בסרטון</h4 >
				<br />
				<iframe width = "100%" height = "315" src = {videoURL} ></iframe >
			</div >
		) : (
			       ""
		       );
	}
	else {
		return (
			<div
				onClick = {onClose}
				className = {`Video Video${visible ? "--show" : "--hidden"}`}
			>
				<div className = "Video__wrapper" >
					<button className = "Video__close" onClick = {onClose} />
					{videoCtrl}
				</div >
			</div >
		);
	}
}
