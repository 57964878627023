import React, {useState}   from "react";
import {Logo}              from "../../../../../components/logo";
import Video               from "../../../../../components/video-player";
import Utils               from "../../../../../shared/utils";
import {FunctionalSection} from "./components/functional-section";
import "./index.scss";

const Title = () => {
	return (
		<div className = "WelcomeTitle" >
			<h1 >
				עדיין חוקרים את הדיירים שלכם?
				<br />
				<span className = "second-line" >
                    תתקדמו ל - <div className="logo-container"><Logo color="white" /></div>
                </span >
				<span className = "third-line" >כי כשמשכירים לא מהמרים</span >
			</h1 >
		</div >
	);
};

const PresentorSection = ({videoToggle}) => {
	return (
		<div
			onClick = {() =>
				videoToggle((videos) => ({...videos, presentorVideo: true}))
			}
			className = "PresentorSection"
		>
			<div className = "image-container presentor" >
				<img
					src = {Utils.buildMortImgLink(
						"NewAssets/presentor-with-squares.png"
					)}
					alt = ""
				/>
			</div >
			<div className = "image-container floating-squares" >
				<img
					src = {Utils.buildMortImgLink(
						"NewAssets/hovering-squares.svg"
					)}
					alt = ""
				/>
			</div >
		</div >
	);
};

const SupervisionAndTerms = () => {
	return (
		<div className = "SupervisionAndTerms" >
			<div className = "image-container" >
				<img
					src = {Utils.buildImgLink("supervision.png", true)}
					alt = "בפיקוח רשות שוק  ההון, רשיון מספר 60509 "
				/>
			</div >
			<p className = "terms-notice" >
				כל השירותים המוצעים על ידי WeCheck בע"מ כפופים
				<br />
				לעמידה בתנאי השירות, בתקנון ובמדיניות הפרטיות של החברה
			</p >
		</div >
	);
};

const Welcome = () => {
	const [videos, setVideos] = useState({
		presentorVideo: false,
		promoVideo    : false
	});
	return (
		<section className = "WelcomeSection" >
			<Video
				showAd = {false}
				onClose = {() => setVideos({...videos, presentorVideo: false})}
				visible = {videos.presentorVideo}
				mortVideo = {true}
			/>
			<Video
				showAd = {false}
				onClose = {() => setVideos({...videos, promoVideo: false})}
				visible = {videos.promoVideo}
			/>
			<div className = "welcome-section-right-side" >
				<Title />
				<FunctionalSection videoToggle = {setVideos} />
			</div >
			<div className = "welcome-section-left-side" >
				<PresentorSection videoToggle = {setVideos} />
				<SupervisionAndTerms />
			</div >
		</section >
	);
};

export {Welcome};
