import Utils from "../../shared/utils";
import {
  DATA_ERROR_MESSAGE,
  FOREIGN_RESIDENT_TEXT,
  FOREIGN_RESIDENT_TITLE,
  NO_ACTIVE_LOANS_CTA,
  NO_ACTIVE_LOANS_TEXT,
  NO_MORT_QUE_FILLED,
} from "./texts";

export const MODAL_TYPES = {
  MORT_DATA_ERROR: "mortDataError",
  MORT_NO_QUE_FILLED: "mortNoQueFilled",
  FOREIGN_RESIDENT: "foreignResident",
  MORT_NO_ACTIVE_LOANS: "mortNoActiveLoans",
};

export const FUNCTIONAL_SECTION = {
  CONTACT: [
    MODAL_TYPES.MORT_DATA_ERROR,
    MODAL_TYPES.FOREIGN_RESIDENT,
    MODAL_TYPES.MORT_NO_ACTIVE_LOANS,
  ],
  APPROVE: [
    MODAL_TYPES.MORT_NO_QUE_FILLED,
  ],
};

export const MODAL_SPREAD = {
  [MODAL_TYPES.MORT_DATA_ERROR]: Utils.buildModalImgLink("spreads/general-error.svg"),
  [MODAL_TYPES.MORT_NO_QUE_FILLED]: Utils.buildModalImgLink("spreads/sofa.svg"),
  [MODAL_TYPES.FOREIGN_RESIDENT]: Utils.buildModalImgLink("spreads/airplane.svg"),
};

export const MODALS = {
  [MODAL_TYPES.MORT_DATA_ERROR]: {
    text: DATA_ERROR_MESSAGE,
    spread: MODAL_SPREAD[MODAL_TYPES.MORT_DATA_ERROR],
  },
  [MODAL_TYPES.MORT_NO_QUE_FILLED]: {
    text: NO_MORT_QUE_FILLED,
    spread: MODAL_SPREAD[MODAL_TYPES.MORT_NO_QUE_FILLED],
  },
  [MODAL_TYPES.FOREIGN_RESIDENT]: {
    title: FOREIGN_RESIDENT_TITLE,
    text: FOREIGN_RESIDENT_TEXT,
    spread: MODAL_SPREAD[MODAL_TYPES.FOREIGN_RESIDENT],
  },
  [MODAL_TYPES.MORT_NO_ACTIVE_LOANS]: {
    text: NO_ACTIVE_LOANS_TEXT,
    spread: MODAL_SPREAD[MODAL_TYPES.MORT_DATA_ERROR],
    cta: NO_ACTIVE_LOANS_CTA
  },
};
