const CTA_CLICK_TRIGGER = `לחיצה על כפתור 'לבדיקת זכאות וקבלת הצעה'`

const EVENT_CATEGORY = `עמוד מוצר - משכנתא מאחדת`

const CTA_ACTION = `לבדיקת זכאות וקבלת הצעה -`

export const ANALYTICS = {
    FIRST_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `${CTA_ACTION} סקשן פותח`
        }
    },
    SECOND_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `${CTA_ACTION} לפני שהריבית עולה`
        }
    },
    THIRD_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `${CTA_ACTION} למה אתם מחכים`
        }
    }
}