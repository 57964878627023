import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config/config";

export const addressApi = createApi({
  reducerPath: "addressApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.serverAddr,
  }),
  endpoints: (builder) => ({
    getStreets: builder.query({
      query: ({ prefix, city }) => {
        return {
          url: `/streets`,
          method: "POST",
          body: {
            prefix,
            city,
          },
        };
      },
    }),
  }),
});

export const { useLazyGetStreetsQuery } = addressApi;
