import PropTypes from "prop-types";
import React     from "react";

import errorsEnums from "../shared/errorsEnums";

import "./css/ErrorLabel.scss";

const ErrorLabel = ({errorType, errorField, children, show}) => {
	let errorRender;

	if (errorType && errorsEnums[errorType]) {
		if (errorField) {
			errorRender = errorsEnums[errorType](errorField);
		}
		else {
			errorRender = errorsEnums[errorType];
		}
	}
	else {
		errorRender = children;
	}

	return (
		<span
			className = "ErrorLabel"
			style = {{display: show ? "block" : "none"}}
		>
            {errorRender}
        </span >
	);
};

ErrorLabel.propTypes = {
	show: PropTypes.bool
};

ErrorLabel.defaultProps = {
	show: true
};

export default ErrorLabel;
