import React from "react";

import Video from "../../../../components/video-player";
import Utils from "../../../../shared/utils";

import "./index.scss";

const TermsSection = () => (
	<div className = "TermsSection" >
		<h2 >ניתנת בתנאים הכי נוחים</h2 >
		<div className = "grayBox" >
			<img
				src = {Utils.buildDashboardImgLink("mortgage/check.svg")}
				alt = ""
			/>
			<h5 >אפשרות לתקופת גרייס - עד 7 שנים</h5 >
		</div >
		<div className = "grayBox" >
			<img
				src = {Utils.buildDashboardImgLink("mortgage/check.svg")}
				alt = ""
			/>
			<h5 >אפשרות לבלון עד 50%</h5 >
		</div >
		<div className = "grayBox" >
			<img
				src = {Utils.buildDashboardImgLink("mortgage/check.svg")}
				alt = ""
			/>
			<h5 >תקופת ההחזר - עד 30 שנה</h5 >
		</div >
		<div className = "grayBox" >
			<img
				src = {Utils.buildDashboardImgLink("mortgage/check.svg")}
				alt = ""
			/>
			<h5 >פירעון מוקדם ללא קנס</h5 >
		</div >
		<br />
		<img
			className = "bricksImage"
			src = {Utils.buildDashboardImgLink("mortgage/briksline.svg")}
			alt = ""
		/>
	</div >
);

const TermsAndVideo = () => (
	<div className = "TermsAndVideo" >
		<div className = "desktopOnly" >
			<div className = "inRow" >
				<TermsSection />
				<Video forMort />
			</div >
		</div >
		<div className = "mobileOnly" >
			<Video forMort />
			<TermsSection />
		</div >
	</div >
);

export {TermsAndVideo};
