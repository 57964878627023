import React from "react";

import { Link } from "react-router-dom";
import { recordButtonClick } from "../../../..";
import { URLS } from "../../../../../../../shared/urls";
import Utils from "../../../../../../../shared/utils";
import ReactPixel from 'react-facebook-pixel';
import "./index.scss";
import { reportCoversion } from "../../../../../../../../helpers/google-ads";
import AnchorTagWithQueryParams from "../../../../../../../components/anchor-tag-with-params";

const VideoIframeButton = ({ videoToggle }) => {
	return (
		<div
			className="VideoIframeButton"
			onClick={() => {
				recordButtonClick(
					"לא חותמים לפני שבודקים",
					"הסרטון שלנו יסביר הכל"
				);
				videoToggle((videos) => ({ ...videos, promoVideo: true }));
			}}
		>
			<div className="play-button-container" >
				<div className="image-container" >
					<img
						src={Utils.buildMortImgLink(
							"NewAssets/red-play-icon.svg"
						)}
						alt="Play icon"
					/>
				</div >
			</div >
			<p >הסרטון שלנו יסביר הכל</p >
		</div >
	);
};

const SlidingAnimation = () => {
	return (
		<object
			type="image/svg+xml"
			data={Utils.buildMortImgLink(
				"NewAssets/background-sliding-animation.svg"
			)}
		>
			Sliding animation
		</object >
	);
};

const ButtonsSection = () => {
	return (
		<div className="ButtonsSection" >
			<AnchorTagWithQueryParams
				href={Utils.getLoginLink()}
				onClick={() => {
					Utils.gaEvent("עמוד ראשי", "אני בעל דירה מרכז", "לוגין");
					ReactPixel.trackCustom('button_owner')
					reportCoversion({ id: "s2wWCJG6_IgYEKyZnf4C" })
				}}
				text="אני בעל דירה"
			/>
			<Link
				to={URLS.Tennant}
				onClick={() => {
					Utils.gaEvent("עמוד ראשי", "אני דייר מרכז", "");
					ReactPixel.trackCustom('button_tenant')
					reportCoversion({ id: "7ojUCN_qqokYEKyZnf4C" })
				}}
			>
				{" "}
				אני דייר
			</Link >
			<AnchorTagWithQueryParams
			    isAgentLink
				href={URLS.AgentLogin}
				onClick={() => {
					Utils.gaEvent("עמוד ראשי", "אני סוכן עמוד ראשי", "");
				}}
				text={"אני סוכן"}
			/>
		</div >
	);
};

const FunctionalSection = ({ videoToggle }) => {
	return (
		<div className="FunctionalSection" >
			<VideoIframeButton videoToggle={videoToggle} />
			<div className="bottom-content-container" >
				<SlidingAnimation />
				<ButtonsSection />
			</div >
		</div >
	);
};

export { FunctionalSection };
