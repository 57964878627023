import React from "react";

import PageContainer from "../../../components/product-pages/page-container";
import FirstSection from "./components/first-section";
import FourthSection from "./components/fourth-section";
import SecondSection from "./components/second-section";
import ThirdSection from "./components/third-section";

import "./index.scss";

export default function DoubleCheck () {
    return <PageContainer>
        <FirstSection/>
        <SecondSection/>
        <ThirdSection/>
        <FourthSection/>
    </PageContainer>
}