import React, { useState } from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setRoomsToFilter } from "../../../../../../redux/features/newsletterSlice";
import { FILTER_ROOMS_CTA } from "../../texts";
import { possibleRooms } from "./consts";
export default function RoomMultiSelect() {
  const dispatch = useDispatch();
  const [isInitialState, setIsInitialState] = useState(true);
  const roomsToFilter = useSelector((state) => state.newsletter.filter.rooms);
  const handleSelect = (room) => {
    if (isInitialState) {
      return dispatch(setRoomsToFilter([room]));
    }
    if (roomsToFilter.includes(room)) {
      return dispatch(
        setRoomsToFilter(roomsToFilter.filter((r) => r !== room))
      );
    }
    return dispatch(setRoomsToFilter([...roomsToFilter, room]));
  };
  return (
    <div className="room-select-container multi-select">
      <h4>{FILTER_ROOMS_CTA}</h4>
      <div className="room-list-container multi-select">
        {possibleRooms.map((room, index) => {
          return (
            <div
              onClick={() => {
                handleSelect(room);
                if (isInitialState) {
                  setIsInitialState(false);
                }
              }}
              key={index}
              className={`single-room ${
                !isInitialState && roomsToFilter.includes(room)
                  ? "selected"
                  : ""
              }`}
            >
              {room}
            </div>
          );
        })}
      </div>
    </div>
  );
}
