import React from "react";
import "./index.scss";

export default function SingleItem({ icon, text, disclaimer, animationDelay }) {
  return (
    <div
      style={{ animationDelay: `${animationDelay}s` }}
      className={`login-single-item-container`}
    >
      <div className="icon-container">
        <img rel="preload" src={icon} />
      </div>
      <p>
        {text}
        {disclaimer && <span>{disclaimer}</span>}
      </p>
    </div>
  );
}
