import React from 'react'
import MortgageContact from '../../../components/product-pages/mortgage-contact'
import PageContainer from '../../../components/product-pages/page-container'
import FirstSection from './components/first-section'
import SecondSection from './components/second-section'
import ThirdSection from './components/third-section'

export default function CompleteMort() {
  return <PageContainer>
    <FirstSection />
    <SecondSection />
    <ThirdSection />
    <MortgageContact />
  </PageContainer>

}
