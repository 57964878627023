import React from "react";
import "./index.scss";
import SingleLoan from "./components/single-loan";
import { LOANS_SPREAD } from "./consts";
import { DISCLAIMER } from "./texts";
import NewRequestButton from "./components/new-request-button";

export default function LoansInfo({ loans }) {
  return (
    <div className="loans-info-container">
      <div className="content-container">
        <div className="loans-and-new-loan-container">
          <div
            style={{ gridTemplateColumns: `repeat(${loans.length}, 1fr)` }}
            className="loans-container"
          >
            {loans.map((loan, index) => {
              return <SingleLoan key={index} {...loan} />;
            })}
            <NewRequestButton loans={loans}/>
          </div>
        </div>
        <img src={LOANS_SPREAD} alt="" />
        <p className="disclaimer">{DISCLAIMER}</p>
      </div>
    </div>
  );
}
