const TennantsTestimonials = {
	videoSection: {
		img            : "daiarim-mesaprim.png",
		videoName      : "videoDaiar",
		eventName      : "וידאו טסטמוניאלס - דיירים",
		flexDirection  : "flexDirectionRow",
		ribbon         : "דיירים מספרים",
		ratingText     :
			"״תוך 3-4 דקות אני ממלא את כל הפרטים שבעל הדירה צריך לדעת עלי, הוא מבין עם מי יש לו עסק והוא יודע שהראש שלו שקט״",
		buttonEventName: "לסיפורים נוספים - דיירים"
	},
	stripSection: [
		{
			img      : "daiarim-olga-lansky.png",
			videoName: "olga",
			eventName: "וידאו אולגה"
		},
		{
			img      : "daiarim-miriam-daseta.png",
			videoName: "miriam",
			eventName: "וידאו מרים"
		},
		{
			img      : "daiarim-nir-samoha.png",
			videoName: "nir",
			eventName: "וידאו ניר"
		},
		{
			img      : "daiarim-meira-levi.png",
			videoName: "meira",
			eventName: "וידאו מאירה"
		},
		{
			img      : "daiarim-liza-bih.png",
			videoName: "liza",
			eventName: "וידאו ליזה"
		},
		{
			img      : "daiarim-eliran-atias.png",
			videoName: "eliran",
			eventName: "וידאו אלירן"
		}
	]
};

const OwnersTestimonials = {
	videoSection: {
		img            : "baaley-dirot-mesaprim.png",
		videoName      : "videoBaale",
		eventName      : "וידאו טסטמוניאלס - בעלי דירות",
		flexDirection  : "flexDirectionRowReverse",
		ribbon         : "בעלי דירות מספרים",
		ratingText     :
			"״יש היום בעיה בקבלת ערבויות, ברגע שנכנס גוף כמו וויצ׳ק הם הערבים ולכן הראש שלי שקט״",
		buttonEventName: "לסיפורים נוספים - בעלי דירות"
	},
	stripSection: [
		{
			img      : "testamos.png",
			videoName: "amos",
			eventName: "וידאו עמוס"
		},
		{
			img      : "testhanoch.png",
			videoName: "chanoh",
			eventName: "וידאו חנוך"
		},
		{
			img      : "testariye.png",
			videoName: "arie",
			eventName: "וידאו אריה"
		},
		{
			img      : "testmoses.png",
			videoName: "moshe",
			eventName: "וידאו משה"
		}
	]
};

const RealtorsTestimonials = {
	videoSection: {
		img            : "sohney-nadlan-mesaprim.png",
		videoName      : "videoNadla",
		eventName      : "וידאו טסטמוניאלס - סוכני נדלן",
		flexDirection  : "flexDirectionRow",
		ribbon         : "סוכני נדלן מספרים",
		ratingText     :
			"תהליך הבדיקה של וויצ׳ק מאוד פשוט, הכל נעשה בצורה דיגיטלית ובאמת ברמה של כמה דקות בודדות.",
		buttonEventName: "לסיפורים נוספים - סוכני נדלן"
	},

	stripSection: [
		{
			img      : "nadlan-kobi-zehavi.png",
			videoName: "kobi",
			eventName: "וידאו קובי"
		},
		{
			img      : "nadlan-max-exelrod.png",
			videoName: "max",
			eventName: "וידאו מקס"
		},
		{
			img      : "nadlan-moly-arbel.png",
			videoName: "moly",
			eventName: "וידאו מולי"
		},
		{
			img      : "nadlan-yevgeni-namir.png",
			videoName: "yevgeni",
			eventName: "וידאו יבגני"
		},
		{
			img      : "nadlan-hadar-zehavi.png",
			videoName: "hadar",
			eventName: "וידאו הדר"
		},
		{
			img      : "nadlan-daniel-lender.png",
			videoName: "daniel",
			eventName: "וידאו דניאל"
		}
	]
};

export {RealtorsTestimonials, OwnersTestimonials, TennantsTestimonials};
