import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setFlags } from "../../redux/features/flagSlice";
import { useLazyGetFlagsQuery } from "../../redux/services/flags";

const FLAG_TYPES = {
  NEW_MORT_SITE_ACTIVE: "NEW_MORT_SITE_ACTIVE",
  MORT_PERSONAL_AREA_ACTIVE: "MORT_PERSONAL_AREA_ACTIVE",
};

export default function useGetAllFlags() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);

  const [getFlags] = useLazyGetFlagsQuery();

  const getLocalFlag = async (flagType) => {
    return JSON.parse(localStorage.getItem(flagType));
  };



  useEffect(() => {
    const setCorrectFlags = async () => {
      const flags = await getFlags();

      const [localSiteFlag, localPersonalAreaFlag] = await Promise.all([
        getLocalFlag(FLAG_TYPES.NEW_MORT_SITE_ACTIVE),
        getLocalFlag(FLAG_TYPES.MORT_PERSONAL_AREA_ACTIVE),
      ]);

      dispatch(
        setFlags({
          isNewMortSiteActive:
            localSiteFlag ?? (flags?.data?.isNewMortSiteActive || false),
          isMortPersonalAreaActive:
            localPersonalAreaFlag ??
            (flags?.data?.isMortPersonalAreaActive || false),
        })
      );
      setIsLoading(false);
    };

    setCorrectFlags();
  }, [dispatch]);

  return isLoading;
}
