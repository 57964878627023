import Utils from "../../../../../shared/utils"

export const ITEM_LIST_TITLE = `יתרונות הדאבל צ׳ק של WeCheck`

export const ITEM_LIST = [
    {
        icon: Utils.buildProductPageImageLink("icons/defense-icon.svg"),
        text: `הערכת סיכון של הדיירים - עם הערכה זו תוכלו לבצע בדיקות רקע לשוכרים הפוטנציאלים ולבחור בשוכרים המתאימים והבטוחים ביותר עבור הנכס שלכם.`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/checkmark-circle-icon.svg"),
        text: `השירות הוא חינמי`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/clock-icon.svg"),
        text: `התהליך הוא מהיר, 3-5 דקות מרגע סיום מילוי השאלון ע"י השוכרים`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/rating-icon.svg"),
        text: `חוסך שאלות וחקירות שונות של השוכרים`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/handshake-icon.svg"),
        text: `שירות של חברת WeCheck בשותפות קבוצת ישראכרט ויד 2, עם איתנות פיננסית, הפועלת בשקיפות מלאה ומפוקחת על ידי רשות שוק ההון.`
    },
]

export const CTA_TITLE = `150 אלף בעלי דירות כבר בדקו, מה איתך?`