import axios               from "axios";
import React, {Component}  from "react";
import {Link, useNavigate} from "react-router-dom";
import Config              from "../../../../config/config";
import {CSDrop, CSItem}    from "../../../components/c-select";

import SectionWrapper                                                           from "../../../components/section-wrapper";
import {URLS}                                                                   from "../../../shared/urls";
import Utils                                                                    from "../../../shared/utils";
import {emailValidator, hebrewOnlyValidator, phoneValidator, requiredValidator} from "../../../shared/validations";
import {Button, ErrorLabel, WckForm}                                            from "../../../UIWck";

import "./index.scss";
import config from "../../../../config/config";

const headerProps = {
	isH1    : true,
	title   : "שמור איתנו על קשר!",
	subTitle: (
		<span >
            יש לך שאלה? אולי תוכלו למצוא את התשובה ממש{" "}
			<Link to = {URLS.QNA} >כאן</Link >
            <br />
            אם זה לא עזר, תמיד אפשר לכתוב לנו ונענה בהקדם
        </span >
	)
};

const initialValues = {
	fullName: "",
	email   : "",
	phone   : "",
	reason  : null,
	content : ""
};

const reasons = [
	"יש לי דירה - איך נרשמים?",
	"אני דייר/ת ויש לי שאלה",
	"איך מזמינים 'דאבל צ'ק'?",
	"איך רוכשים 'צ'ק בטוח'?",
	"איך רוכשים 'הכל מראש'?",
	"חזר לי צ'ק - מה עושים?",
	"אני מתווכ/ת, איך מצטרפים?",
	"תיאום או שינוי שליחות",
	"פניות הציבור",
	"אחר"
];

const Success = () => {
	const navigate = useNavigate();
	return <div className = "Contact__success" >
		<img src = {Utils.buildImgLink("ok.svg", true)} />
		<h3 >פנייתך נקלטה בהצלחה!</h3 >
		<h4 >נציגנו יצרו איתך קשר בהקדם, מחכים לך כאן!</h4 >
		<Button
			type = "outline--primary"
			onClick = {() => window.location = config.HOMEPAGE_URL}
			arrows = {true}
		>
			בחזרה לאתר
		</Button >
	</div >;
};

class Contact extends Component {
	constructor(props) {
		super(props);

		this.state = {
			values        : initialValues,
			sendingRequest: false,
			requestStatus : "IDLE"
		};
	}

	componentDidMount() {
		document.title = "וויצ'ק בשותפות יד2 וישרארט - יצירת קשר, צ'אט אנושי";
	}

	handleSubmit = ({values}) => {
		Utils.gaEvent("עמוד צור קשר", "מילוי פרטים", "שלח");

		this.setState((state) => ({
			...state,
			requestStatus : "ACTIVE",
			sendingRequest: true
		}));
		axios
			.put(`${Config.serverAddr}/contacts`, values)
			.then(() =>
				this.setState((state) => ({
					...state,
					values        : initialValues,
					requestStatus : "SUCCESS",
					sendingRequest: false
				}))
			)
			.catch(() =>
				this.setState((state) => ({
					...state,
					requestStatus : "FAILED",
					sendingRequest: false
				}))
			);
	};

	handleChange = (e, handleChange) => {
		handleChange({
			name : e.target.name,
			value: e.target.value
		});
	};

	handleCSDrop = (value, handleChange) => {
		if (value !== null) {
			handleChange({
				name : "reason",
				value: value >= 0 ? value.toString() : ""
			});
		}
	};

	findReasonIndex = (reason) => reason === this.reason;

	render() {
		const renderHeaderProps =
			      this.state.requestStatus === "SUCCESS" ? {} : headerProps;

		const googleMapsLink = "https://goo.gl/maps/BRt9xvknxCzhFvAc7";

		return (
			<SectionWrapper
				withoutBrikim = {true}
				{...renderHeaderProps}
				className = "Contact"
				sectionClassName = "Contact__section"
				id = "contact"
			>
				{this.state.requestStatus === "SUCCESS" ? (
					<Success history = {this.props.history} />
				) : (
					 <WckForm
						 firstCtrlId = "fullName"
						 render = {({
							            handleSubmit,
							            handleChange,
							            handleTabCycle,
							            handleBlur,
							            values,
							            errors,
							            hasErrors
						            }) => (
							 <form
								 className = "Contact__form"
								 onSubmit = {handleSubmit}
							 >
								 <input
									 type = "text"
									 id = "fullName"
									 name = "fullName"
									 data-hj-allow
									 placeholder = "שם מלא"
									 autoComplete = "off"
									 onBlur = {(e) =>
										 this.handleChange(e, handleBlur)
									 }
									 onChange = {(e) =>
										 this.handleChange(e, handleChange)
									 }
								 />
								 {errors.fullName[0] ? (
									 <ErrorLabel >יש להזין שם מלא</ErrorLabel >
								 ) : (
									  errors.fullName[1] && (
										  <ErrorLabel >
											  יש להזין אותיות בעברית בלבד
										  </ErrorLabel >
									  )
								  )}
								 <input
									 type = "tel"
									 name = "phone"
									 placeholder = "מספר טלפון"
									 autoComplete = "off"
									 data-hj-allow
									 onBlur = {(e) =>
										 this.handleChange(e, handleBlur)
									 }
									 onChange = {(e) =>
										 this.handleChange(e, handleChange)
									 }
								 />
								 {errors.phone[0] ? (
									 <ErrorLabel >יש להזין מספר טלפון</ErrorLabel >
								 ) : (
									  errors.phone[1] && (
										  <ErrorLabel >
											  יש להזין מספר טלפון תקין בלבד
										  </ErrorLabel >
									  )
								  )}
								 <input
									 type = "email"
									 name = "email"
									 placeholder = "אימייל"
									 data-hj-allow
									 autoComplete = "off"
									 onBlur = {(e) =>
										 this.handleChange(e, handleBlur)
									 }
									 onChange = {(e) =>
										 this.handleChange(e, handleChange)
									 }
								 />
								 {errors.email[0] ? (
									 <ErrorLabel >
										 יש להזין כתובת אימייל
									 </ErrorLabel >
								 ) : (
									  errors.email[1] && (
										  <ErrorLabel >
											  יש להזין כתובת אימייל תקינה בלבד
										  </ErrorLabel >
									  )
								  )}

								 <CSDrop
									 onChange = {(value) => {
										 this.reason = value;
										 this.handleCSDrop(
											 reasons.findIndex(
												 this.findReasonIndex
											 ),
											 handleChange
										 );
									 }}
									 onBlur = {(value) =>
										 this.handleCSDrop(value, handleBlur)
									 }
									 placeholder = "רציתי לברר בקשר ל "
								 >
									 {reasons.map((reason, index) => (
										 <CSItem value = {reason} key = {index} >
											 {reason}
										 </CSItem >
									 ))}
								 </CSDrop >
								 {errors.reason && (
									 <ErrorLabel >יש לבחור סיבת פניה</ErrorLabel >
								 )}

								 <textarea
								 	 id = "content"
									 name = "content"
									 placeholder = "תוכן הפנייה"
									 onBlur = {(e) =>
										 this.handleChange(e, handleBlur)
									 }
									 onChange = {(e) =>
										 this.handleChange(e, handleChange)
									 }
								 />
								{errors.content[0] && (
									 <ErrorLabel >יש למלא את תוכן הפנייה</ErrorLabel >
								 )}
								 <button
									 type = "submit"
									 disabled = {
										 hasErrors || this.state.sendingRequest
									 }
								 >
									 שלח
								 </button >
								 <div className = "contactBox" >
									 <img
										 src = {Utils.buildImgLink("group.svg")}
										 alt = ""
									 />
									 <div className = "contactDetails" >
										 <a
											 href = {googleMapsLink}
											 target = "_blank"
											 className = "Contact__form__address"
											 onKeyDown = {(evt) => {
												 handleTabCycle(evt);
											 }}
										 >
											 <p >הקישון 8 בני ברק</p >
										 </a >
										 <a
											 href = "tel:03-3030211"
											 className = "Contact__form__address"
											 onKeyDown = {(evt) => {
												 handleTabCycle(evt);
											 }}
										 >
											 <p > 03-3030211</p >
										 </a >
									 </div >
								 </div >

								<div className="publicInquiries">
									<h4 className="publicInquiriesTitle">הממונה על פניות הציבור - עו"ד דן שרעבי.</h4>
									<p>ניתן להגיש תלונה לממונה על פניות הציבור בדרכים הבאות:</p>
									<p>פתיחת פנייה בטופס המקוון מעלה, יש לבחור בנושא הפנייה "פניות הציבור".</p>
									<p>או לשלוח דואר לכתובתנו - הקישון 8, בני ברק, מיקוד 5120308, לידי וויצק בע"מ.</p>
								</div>


								 {this.state.requestStatus === "FAILED" && (
									 <div className = "textDanger" >
										 הבקשה נכשלה, אנא נסה שוב מאוחר יותר.
									 </div >
								 )}
							 </form >
						 )}
						 validations = {{
							 fullName: [requiredValidator, hebrewOnlyValidator],
							 phone   : [requiredValidator, phoneValidator],
							 email   : [requiredValidator, emailValidator],
							 reason  : requiredValidator,
							 content : [requiredValidator]
						 }}
						 onSubmit = {this.handleSubmit}
					 />
				 )}
			</SectionWrapper >
		);
	}
}

export {Contact};
