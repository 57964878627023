import React from "react";
import Disclaimer from "../../../components/product-pages/disclaimer";
import PageContainer from "../../../components/product-pages/page-container";
import FirstSection from "./components/first-section";
import SecondSection from "./components/second-section";
import ThirdSection from "./components/third-section";
import "./index.scss";
import { DISCLAIMER } from "./texts";

export default function SafeCheck () {
    return <PageContainer>
        <FirstSection/>
        <SecondSection/>
        <ThirdSection/>
        <Disclaimer text={DISCLAIMER}/>
    </PageContainer>
}