export const questions = [
	{
		title  : "האם חברת WeCheck הינה גוף מפוקח?",
		content:
			"חברת WeCheck הינה גוף מפוקח עם רישיון מורחב למתן אשראי מרשות שוק ההון. החברה מעמידה אשראי מספר שנים בשוק השכרת הדירות ובשוק ההלוואות לדיור."
	},
	{
		title  : "מה ההבדל בין משכנתא משלימה למשכנתא פרמיום?",
		content:
			"משכנתא משלימה היא משכנתא המשלימה את המימון שקיבלתם מהבנק. אחוזי המימון האפשריים מהבנקים נעים בין 50% ל 75% ויחד עם המשכנתא המשלימה של wecheck תוכלו לקבל סכום נוסף ולהגיע עד 85% מימון, כך שיכולת הקניה שלכם תשתפר משמעותית. משכנתא פרימיום מאפשרת לכם לקחת 85% מימון ממקום אחד, בקלות ובפשטות."
	},
	{
		title  : "למה כדאי לי לקחת משכנתא ב WeCheck?",
		content:
			"WeCheck הינה חברת פינטק חדשנית המתמחה בשוק הנדל”ן תהליך לקיחת המשכנתא אצלנו פשוט, מהיר ודיגיטלי. תוכלו לקבל אישור עקרוני למשכנתא שלכם בתוך מספר דקות ולהתקדם לעסקה שחלמתם עליה. ב WeCheck עובדים אנשי מקצוע עם ניסיון עשיר בתחום המשכנתאות, כך תוכלו לקבל שירות מקצועי לכל אורך התהליך. החברה יצרה שותפות עם יד 2 וקבוצת ישראכארט כדי להעמיד לרשותכם גוף פיננסי מבוסס ואמין שיכול לסייע לכם במגוון אתגרים בתחום"
	},
	{
		title  : "אני יועצ/ת משכנתאות, האם נדרשת הסכמה מהלקוח על מנת להגיש בקשה למשכנתא?",
		content: "כן. היועץ נדרש להעביר לחברה כתב הסמכה חתום, בנוסח החברה"
	},
	{
		title  : "מה סכום המשכנתא המקסימלי שאוכל לקבל מ-WeCheck?",
		content:
			"ניתן לקבל מ WeCheck משכנתא בסכום של עד 6.5 מיליון ₪, כאשר הסכום המינימלי שניתן לקבל במשכנתא משלימה הינו ₪100,000 ובמשכנתא פרימיום ₪250,000"
	},
	{
		title  : "אני תושב חוץ, האם ניתן לקבל משכנתא מ-WeCheck?",
		content:
			"כן. גם תושבי חוץ יכולים להגיש בקשה למשכנתא מ WeCheck רשימת המדינות מהן ניתן להגיש בקשה תוצג לכם בשאלון הדיגיטלי שלנו."
	},
	{
		title  : "האם נדרש לבצע שמאות ל-WeCheck?",
		content:
			"בתהליך קבלת משכנתא מ-WeCheck נדרש לבצע שמאות. במקרה שיש בידכם שמאות קיימת אשר נערכה לטובת הבנק המממן בדרגה הראשונה, ניתן להשתמש בה ללא צורך בביצוע שמאות נוספת."
	},
	{
		title  : "באיזה שלב רושמים את הבטחונות למשכנתא של WeCheck?",
		content:
			"תהליך רישום הביטחונות בעסקה זהה לתהליך רישום הביטחונות בעת לקיחת משכנתא מהבנק. רישום הביטחונות עבור משכנתא משלימה מ WeCheck נעשה במקביל לבנק, כאשר המשכנתא עבור ההלוואה המשלימה של WeCheck נרשמת בדרגה שניה אחרי הבנק. במידה ואינכם מתמצאים בתהליך זה, נוכל לספק לכם שירות רישום ביטחונות מקצועי ויעיל תמורת תשלום של ₪2,000."
	},
	{
		title  : "מהו שווי הנכס המינימלי שניתן לקבל עבור משכנתא?",
		content: "שווי הנכס המינימלי הינו ₪750,000 ."
	},
	{
		title  : "מי זכאי לקבל 85% מימון מ WeCheck?",
		content:
			"המשכנתא של WeCheck מיועדת לבעלי הכנסה וכושר החזר מתאים. לקוח אשר ברשותו 15% הון עצמי וכושר החזר מתאים יהיה זכאי לקבל 85% מימון. המוצר מתאים לרוכשי דירה יחידה, משפרי דיור ולמשקיעים. ניתן לקבל משכנתא גם כנגד שיעבוד הנכס הקיים שברשותכם למטרות שונות ומגוונות. בנוסף, ניתן לקבל גם משכנתא משלימה של WECHECK, בשיעבוד שני, לאחר משכנתא בנקאית"
	},
	{
		title  : "כיצד עובד התהליך של קבלת משכנתא משלימה?",
		content:
			"ככלל, אנו ממליצים להגיש בקשה למשכנתא משלימה בשלב מוקדם של רכישת הנכס ובטרם חתימה על חוזה המכר, במקביל להגשת הבקשה לבנק. לאחר הגשת הבקשה יועץ משכנתאות מטעם WeCheck יצור עמכם קשר ויסביר בפירוט את התהליך הרצוי."
	},
	{
		title  : "אני יועץ/ת משכנתאות ואשמח לעבוד איתכם, כיצד כדאי להתחיל?",
		content:
			"חברת WECHECK עובדת עם רוב יועצי המשכנתאות בישראל, בשיתוף פעולה עם התאחדות יועצי המשכנתאות ועם חברות היעוץ הגדולות בענף. לכל יועץ ו/או חברת יעוץ יש אפשרות להירשם בקלות באמצעות כפתור “כניסה ליועצים” באתר. בכל זמן ניתן ליצור קשר עם מוקד היועצים שלנו במספר 054-7109227"
	},
	{
		title  : "האם ניתנת האפשרות לקבל משכנתא עד 85% מ-WeCheck לאחר שהנכס נרכש?",
		content:
			"כן. ניתן לקבל משכנתא “לכל מטרה” עד 85% משווי הנכס למטרות שונות, כמו למשל שיפוץ, רכישת ריהוט, החלפת הלוואות קיימות (שאינן קשורות לנכס). במשכנתא בפריסה ארוכה עם תשלום חודשי נמוך יותר, רכישת נכס נוסף ולכל מטרה אחרת."
	},
	{
		title  : "האם ניתן לקבל משכנתא כאשר הרוכש הינו חברה בע\"מ? האם ניתן לקבל משכנתא עבור רגישה של נכס מסחרי?",
		content:
			"המשכנתא של WeCheck מיועדת לרוכשים פרטיים ועבור דירות מגורים בלבד."
	},
	{
		title  : "האם ניתן לקבל משכנתא מ WeCheck כאשר מדובר בנכס בבניה?",
		content:
			"כן, ניתן לקבל משכנתא כאשר הנכס נמצא בתהליך בנייה אולם רק כאשר הבנייה מבוצעת בליווי בנקאי. לא ניתן לקבל משכנתא מ WeCheck כאשר מדובר בבניה עצמית ללא ליווי בנקאי לפרויקט."
	},
	{
		title  : "האם ניתן לבצע פירעון מוקדם למשכנתא של WeCheck ובאילו תנאים?",
		content:
			"בהחלט. המשכנתאות שלנו מועמדות לתקופה מינימלית של שנתיים. לאחר שנתיים ניתן לפרוע את המשכנתא ללא עמלת פירעון מוקדם."
	},

	{
		title  : "אנו רוכשים דירה ורוצים לדעת מתי נקבל את כספי המשכנתא, כיצד זה עובד?",
		content:
			"בכל עסקת רכישה נדרשים הלווים לשלם ראשית את כל ההון העצמי, לאחר תשלום מלוא ההון העצמי, ניתן לקבל את המשכנתא של WeCheck. לדוגמא, בעסקה של 85% מימון נדרשים הלווים לשלם 15% הון עצמי ולאחר מכן יוכלו לקבל את כספי המשכנתא. בעסקה של משכנתא משלימה, תשלום המשכנתא המשלימה יהיה לאחר תשלום ההון העצמי ובדרך כלל לפני קבלת המשכנתא הבנקאית."
	}
];
