import React, { forwardRef } from "react";

import { Link } from "react-router-dom";
import { URLS } from "../../shared/urls";
import Utils from "../../shared/utils";
import { socialList } from "./consts";

import "./index.scss";
import { Logo } from "../logo";
import config from "../../../config/config";

export const FooterContent = ({ shouldLinkOpenInNewTab = false }) => {
  const isMortPersonalAreaPage = window.location.pathname.includes(
    URLS.MortPersonalArea
  );
  return (
    <div
      className={`${"footer-content"} ${
        isMortPersonalAreaPage ? "half-width" : ""
      }`}
    >
      <p>כל הזכויות שמורות WeCheck 2022</p>
      <div className="links-container">
        {isMortPersonalAreaPage && (
          <a
            href={`${config.s3ImagesLink}/marketing/mortgage-price-list.pdf`}
            target="_blank"
            rel="noreferrer"
          >
            תעריפון משכנתא
          </a>
        )}
        <a href={URLS.Legal} target="_blank" rel="noopener noreferrer">
          תקנון האתר{" "}
        </a>
        <Link
          target={shouldLinkOpenInNewTab ? "_blank" : ""}
          rel={shouldLinkOpenInNewTab ? "noopener noreferrer" : ""}
          to={URLS.Acce}
        >
          הצהרת נגישות
        </Link>
      </div>
    </div>
  );
};

const SocialLink = ({
  icon,
  iconName,
  href,
  customCategory,
  category,
  action,
  label,
}) => {
  return (
    <a
      onClick={() =>
        Utils.gaEvent(customCategory ? customCategory : category, action, label)
      }
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img src={icon} alt={iconName} />
    </a>
  );
};

const SocialLinksList = ({ customCategory }) => {
  return (
    <div className="social-links-list">
      {socialList.map((item, index) => {
        return (
          <SocialLink
            icon={item.icon}
            iconName={item.iconName}
            href={item.href}
            category={item.category}
            action={item.action}
            label={item.label}
            key={index}
            customCategory={customCategory}
          />
        );
      })}
    </div>
  );
};

const Footer = forwardRef(({ customCategory, isNewsletter = false }, ref) => {
  return (
    <footer
      ref={ref}
      className={`footer ${isNewsletter ? "display-none" : ""}`}
    >
      <div className="logo-container">
        <Logo color="white" isPromotion={false} animated={false} />
      </div>
      <FooterContent />
      <SocialLinksList customCategory={customCategory} />
    </footer>
  );
});

export { Footer };
