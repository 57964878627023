import React from "react";
import { Link } from "react-router-dom";
import Utils from "../../../../../../shared/utils";

import "./index.scss";

import AnchorTagWithQueryParams from "../../../../../anchor-tag-with-params";

export default function MobileLinksList({
  isListOpen,
  setIsListOpen,
  link,
  isNewSiteActive,
  isMortPersonalAreaActive,
}) {
  return (
    <div className={`additional-links-list ${isListOpen ? "is-open" : ""}`}>
      {link.links.map((innerLink, index) => {
        return innerLink.isPremium &&
          !isNewSiteActive ? null : innerLink.personalArea &&
          !isMortPersonalAreaActive ? null : innerLink.external ? (
          <AnchorTagWithQueryParams
            key={index}
            className={`header-link ${link.bold ? "text-bold" : ""}`}
            href={innerLink.to}
            text={innerLink.label}
          />
        ) : (
          <Link
            onClick={() => {
              setIsListOpen(!isListOpen);
              Utils.gaEvent(
                innerLink.category,
                innerLink.action,
                innerLink.label
              );
            }}
            to={innerLink.to}
            key={index}
          >
            {innerLink.label}
          </Link>
        );
      })}
    </div>
  );
}
