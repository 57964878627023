import Layout from "../components/layout";

import { URLS } from "../shared/urls";
import { questions, pay_questions } from "./consts/qnaQuestions";

import { AccessPage } from "./pages/access";
import { Contact } from "./pages/contact";
import { RentInfo } from "./pages/main";

import { MortQNA as QNA } from "../mortgage-new/pages/qna";
import { TenantPage } from "./pages/tenant";
import { TestimonialsPage } from "./pages/testimonials";

import FourOhFourPage from "../components/404";
import MortgageLandingPage from "./pages/mortgage-landing-page";
import DoubleCheck from "./pages/doublecheck";
import SafeCheck from "./pages/safecheck";
import AllInAdvancePage from "./pages/all-in-advance";
import AdditionalMort from "./pages/additional-mort";
import CompleteMort from "./pages/completed-mort";
import PremiumMort from "./pages/premium-mort";
import Newsletter from "../newsletter";
import Chat from "../chat";
import LoginPage from "../new-login";
import ReducedMort from "./pages/reduced-mort";

export const RentRouter = {
  path: "/",
  element: <Layout />,
  errorElement: <FourOhFourPage />,
  children: [
    {
      path: URLS.QNA,
      element: <QNA questions={questions} extraQuestions={pay_questions} />,
    },
    {
      path: URLS.Contact,
      element: <Contact />,
    },
    {
      path: URLS.Tennant,
      element: <TenantPage />,
    },
    {
      path: URLS.Acce,
      element: <AccessPage />,
    },
    {
      path: URLS.Testimonials,
      element: <TestimonialsPage />,
    },
    {
      path: URLS.MortLandingPage,
      element: <MortgageLandingPage />,
    },
    {
      path: URLS.Doublecheck,
      element: <DoubleCheck />,
    },
    {
      path: URLS.Safecheck,
      element: <SafeCheck />,
    },
    {
      path: URLS.AllInAdvance,
      element: <AllInAdvancePage />,
    },
    {
      path: URLS.AdditionalMort,
      element: <AdditionalMort />,
    },
    {
      path: URLS.CompleteMort,
      element: <CompleteMort />,
    },
    {
      path: URLS.PremiumMort,
      element: <PremiumMort />,
      isPremium: true,
    },
    {
      path: URLS.ReducedMort,
      element: <ReducedMort />,
    },
    {
      path: URLS.Newsletter,
      element: <Newsletter />,
    },
    {
      path: URLS.internalLogin,
      element: <LoginPage />,
    },
    {
      path: URLS.Chat,
      element: <Chat />,
    },
    {
      path: "/",
      element: <RentInfo />,
    },
  ],
};
