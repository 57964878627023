import React from "react";
import "./index.scss";
import AnchorTagWithQueryParams from "../../../../../../components/anchor-tag-with-params";
import { URLS } from "../../../../../../shared/urls";
import { NEW_REQUEST_CTA } from "./texts";

export default function NewRequestButton({loans}) {
  const REDIRECT_URL = `${URLS.MortgageQue}?isPersonalAreaRedirect=true`;

  return (
    <AnchorTagWithQueryParams shouldOpenInNewTab style={{gridColumn: loans.length + 1}} className={'new-request-button-container'} href={REDIRECT_URL}>
      <span>+</span>
      {NEW_REQUEST_CTA}
    </AnchorTagWithQueryParams>
  );
}
