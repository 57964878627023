import React from "react";
import "./index.scss";
import ClosedDisplay from "../closed-display";
import OpenDisplay from "../open-display";
import { useSelector } from "react-redux";
import Accordion from "../../../accordion";

export default function Display() {
  const dateOptions = useSelector((state) => state.newsletter.filter.date);

  return (
    <div className={`display-container ${dateOptions.isVisible ? "slide-up" : ""}`}>
      <ClosedDisplay />
      <Accordion isOpen={dateOptions.isVisible}>
        <OpenDisplay />
      </Accordion>
    </div>
  );
}
