import SectionWrapper         from "../../../components/section-wrapper";
import {TestimonialsSection}  from "../testimonials";
import {TennantsTestimonials} from "../testimonials/consts/testimonialsData";
import {HelpKit}              from "./sections/helpKit";
import {TopSection}           from "./sections/topSection";

import {WeAreForYou} from "./sections/weAreForYou";
import {HowToSection}       from "./sections/howTo";
import {WhatWeSay}   from "./sections/whatWeSay";

import "./index.scss";

const TenantPage = () => <div id = "startOfPage" >
	<SectionWrapper withoutBrikim className = "TenantPage" id = "TenantPage" >
		<TopSection/>
		<HowToSection />
		<TestimonialsSection testimonials = {TennantsTestimonials} eventCategory="עמוד אני דייר"/>
		<WhatWeSay />
		<WeAreForYou />
		<HelpKit />
	</SectionWrapper >
</div >;

export {TenantPage};
