import Utils from "../../../../../shared/utils";

export const LOCATION_MARKER = Utils.buildNewsletterLink("icons/location.svg");

export const CLUSTER_IMAGES_M1 = Utils.buildNewsletterLink("cluster/m1.svg");
export const CLUSTER_IMAGES_M2 = Utils.buildNewsletterLink("cluster/m2.svg");
export const CLUSTER_IMAGES_M3 = Utils.buildNewsletterLink("cluster/m3.svg");

export const CITY_MARKER = Utils.buildNewsletterLink("icons/markers/city.svg");
export const AREA_MARKER = Utils.buildNewsletterLink("icons/markers/area.svg");
export const NEIGHBORHOOD_MARKER = Utils.buildNewsletterLink(
  "icons/markers/neighborhood.svg"
);
export const ASSET_MARKER = Utils.buildNewsletterLink(
  "icons/markers/asset.svg"
);

export const MARKER_Z_INDEX = {
  CITY: 3,
  AREA: 2,
  NEIGHBORHOOD: 1,
  ASSET: 0,
};
