import React from "react";
import "./index.scss";
import useModal from "./hooks/useModal";
import ModalContent from "./components/modal-content";
import ModalFunctionalSection from "./components/functional-section";

export default function Modal() {
  const { modalType, modalProps, modalRef, close } = useModal();
  return (
    <dialog ref={modalRef} className="modal-container">
      <form method="dialog">
        <ModalContent {...modalProps} />
        <ModalFunctionalSection type={modalType} close={close} />
      </form>
    </dialog>
  );
}
