import { URLS } from "../../shared/urls";

const LINE_BREAK = "linebreak";

export const questions = [
  {
    title: "מי אתם - WeCheck?",
    content: [
      {
        title: "היי, אנחנו WeCheck",
        content:
          "ואתם יכולים להסתכל עלינו כמו כדור נגד כאב ראש בכל מה שקשור להשכרת וקניית נכסים.",
      },
      {
        title: "איך אנחנו עושים את זה?",
        content:
          "אנחנו חוקרים ולומדים כל הזמן את האתגרים השונים בהשכרת וקניית נכסים - ויוצרים פתרונות, ראשונים מסוגם, מבוססי טכנולוגיה ייחודית שעונים בצורה מדויקת על מגוון צרכים של משכירים ורוכשים, החל מביטחונות כספיים ועד סיוע בקניית נכסים - לפי היכולות הפיננסיות האמיתיות של הרוכשים. אפילו עכשיו, בזמן שאתם קוראים את זה, אנחנו עובדים על הפתרון החדשני הבא.",
      },
      {
        title: "למה דווקא אנחנו?",
        content:
          'כי אנחנו אנשי מקצוע מנוסים, בעלי ידע פיננסי וטכנולוגי מוכח, מה שמאפשר לנו להציע פתרונות חדשניים וייחודיים שלא היו זמינים עד היום. כי יצרנו שותפות עם יד2 וישראכרט כדי להעמיד לרשותכם גוף פיננסי מבוסס ואמין שבאמת יכול לסייע לכם במגוון אתגרים בתחום הנדל"ן.',
      },
    ],
  },
  {
    title: "איך יד 2 קשורים?",
    content:
      "יד 2 הם שותפים אסטרטגיים ובעלי מניות בחברת WeCheck. יד 2 גם מאפשרים למשכירים המפרסמים מודעות באתר שלהם להפוך ללקוחות WeCheck באופן אוטומטי.",
  },
  {
    title: "איך ישראכארט קשורים?",
    content:
      "קבוצת ישראכרט, חברת כרטיסי האשראי הגדולה בישראל, הם שותפים אסטרטגיים ובעלי מניות בחברת WeCheck.",
  },
  {
    title: "מה זה דאבל צ'ק ולמה צריך את זה?",
    content: `שירות הדאבל צ'ק המהפכני שלנו מהווה פלטפורמה דיגיטלית חדשנית להיכרות עם הדייר החוסכת זמן ומאמץ גם לדייר וגם לבעל הדירה!  השירות, מאפשר לדייר לספק לבעל הדירה את כל המידע הרלוונטי ללא צורך בטפסים ובירוקרטיות. לאחר שהדייר ממלא את השאלון אשר נשלח אליו לנייד, על בסיס טכנולוגיות מתקדמות של בינה מלאכותית (AI) האלגוריתם שלנו מעבד את כלל הנתונים עד להפקת הדוח שנשלח ישירות לנייד של בעל הדירה ושל הדייר. דוח הדאבל צ׳ק מאפשר לנו להעריך את הסיכון בעסקה הרלוונטית ולהציע את שירות הערבות שלנו - צ'ק בטוח. דוח הדאבל צ׳ק וההצעה לערבות תקפים ל-5 ימים בלבד.`,
  },
  {
    title: "איזה מידע יש בדאבל צ'ק?",
    content: [
      {
        title:
          "הדאבל צ'ק כולל את פרטי הדייר הפוטנציאלי (שם, טלפון, כתובת מייל, גיל, מצב משפחתי) ובנוסף 4 תחומים של מידע:",
        list: [
          "מידע כלכלי - האם לדייר הפוטנציאלי יש נכסים(למשל דירה, מכונית או כרטיס אשראי), חשבון בנק מוגבל, האם חזרו לו צ'קים בעבר וכדומה.",
          "מגורים קודמים - היכן גר קודם לכן והאם גם שם גר בשכירות.",
          "תעסוקה ולימודים - האם עובד והיכן, ותק, תמהיל ההכנסה שלו אל מול שכר הדירה, האם למד או לומד.",
          "מידע משפטי - האם הוא בתהליכים של פשיטת רגל וכדומה.",
        ],
      },
    ],
  },
  {
    title: "איך אני מפעיל את פלטפורמת הדאבל צ'ק?",
    content: [
      {
        content:
          'אם אתם בעלי דירה, כל מה שצריך לעשות זה ללחוץ על כפתור "אני בעל דירה", להזין את מספר הטלפון של הדייר ופרטי הנכס שלכם, לעדכן את הדייר על קבלת השאלון שלנו ב-SMS, וזהו! אנחנו כבר עובדים על הדאבל צ׳ק שביקשתם.',
      },
      {
        content:
          'אם אתם דיירים, כל מה שצריך זה ללחוץ על כפתור "אני דייר" ולהזמין את בעל הדירה בה אתם מתעניינים לקבל את דוח הדאבל צ\'ק שלכם. לאחר שבעל הדירה יקבל את ההזמנה (הכל בצורה דיגיטלית כמובן) אתם תקבלו מאיתנו הודעה עם לינק לשאלון הדייר שלנו',
      },
      {
        content:
          "לאחר שתמלאו את שאלון הדייר, אתם ובעל הדירה בה אתם מתעניינים תקבלו את דוח הדאבל צ'ק, עם הצעה לשירות הערבות שלנו (הצעת הערבות נתונה לשיקול דעתה הבלעדי של WeCheck).",
      },
      {
        content:
          "קיבלתם הצעה - מעולה, זה אומר שאנחנו מוכנים להיות ערבים לכך שבמידה והצ'קים של הדייר יחזרו בעל הדירה לא ייפגע ויקבל מאיתנו את התמורה.",
      },
    ],
  },
  {
    title: "למה הדאבל צ'ק בחינם?",
    content:
      "שירות הדאבל צ׳ק הוא שירות בעל ערך רב למהפכה שאנו עושים בשוק השכירות בישראל. נכון להיום, השירות הוא חינמי לחלוטין, ואתם יכולים להשתמש בו ללא הגבלה וללא עלות. שירות הדאבל צ׳ק זמין עבור כל דייר פוטנציאלי לכל נכס שברשותך.",
  },
  {
    title: "אני משכיר/ה את הדירה לשותפים - על מי אני עושה דאבל צ'ק?",
    content:
      "יש לשלוח דאבל צ׳ק לכל אחד מהשותפים בנפרד, ואז מקבלים דוח נפרד לכל דייר. שימו לב, בעת שליחת הדאבל צ׳ק תוכלו לציין את ׳שכר הדירה עבור דייר זה׳ - ושם תוכלו להזין את שכ״ד הרלוונטי עבור כל דייר בנפרד.",
  },
  {
    title: "ואם מישהו אחר משלם/ת את שכר הדירה עבור הדייר/ת?",
    content:
      "אין בעיה, במקרה זה הדאבל צ'ק יכלול מידע על המשלם, בנוסף למידע הרלוונטי על הדייר/ת.",
  },
  {
    title: "מה זה צ'ק בטוח?",
    content: [
      {
        title:
          "צ'ק בטוח הוא שירות חדשני המבטיח לכם שתקבלו את הכסף שמגיע לכם, בהתאם להסכם השכירות ולפי דין, גם אם לדמי השכירות של הדייר אין כיסוי. הכסף יכנס אליכם לחשבון, ואנחנו נטפל בכל מה שצריך.",
      },
      {
        title: "השירות כולל:",
        list: [
          "ערבות מלאה לדמי השכירות של הדייר",
          "פינוי דייר סרבן גם לאחר שחוזה השכירות הסתיים",
          "תשלום שכר הדירה בזמן הליך הפינוי",
          "סיוע במציאת דייר חלופי במקרה של עזיבה פתאומית של הדייר בניגוד להסכם השכירות, ותשלום שכר הדירה בזמן תהליך מציאת הדייר החדש",
          "טיפול משפטי מול הדייר כולל פינוי, במידת הצורך",
          "ניתן להפסיק את השירות בכל רגע ומכל סיבה שהיא",
          "התחייבות להעמדת הערבות ללא תשלום לפני החתימה על החוזה וכניסה לתוקף רק בכניסת הדייר לדירה ולאחר ביצוע תשלום עבור השירות",
          "מומלץ לרכוש מראש, עוד לפני חתימת ההסכם עם הדייר כדי שתהיו מובטחים",
        ],
      },
      {
        disclaimer: "השירות יכול להרכש על ידי בעל הדירה או על ידי הדייר",
      },
    ],
  },
  {
    title: "מה זה הכל מראש?",
    content: [
      {
        title:
          "עם שירות 'הכל מראש' תוכלו לקבל את כל הכסף כבר מההתחלה - בלי להמתין. הבטיחו לעצמכם שנה שלמה של שקט, ותנו לנו להתעסק בתשלומי שכר הדירה במקומכם.",
      },
      {
        title: "השירות כולל:",
        list: [
          "ערבות מלאה לדמי השכירות של הדייר",
          "קבלת כל שכר הדירה בתשלום אחד מראש",
          "פינוי דייר סרבן גם לאחר שחוזה השכירות הסתיים",
          "תשלום שכר הדירה בזמן הליך הפינוי",
          "סיוע במציאת דייר חלופי במקרה של עזיבה פתאומית של הדייר בניגוד להסכם השכירות, ותשלום שכר הדירה בזמן תהליך מציאת הדייר החדש",
          "טיפול משפטי מול הדייר כולל פינוי, במידת הצורך",
          "ניתן להפסיק את השירות בכל רגע ומכל סיבה שהיא",
          "התחייבות להעמדת הערבות ללא תשלום לפני החתימה על החוזה וכניסה לתוקף רק בכניסת הדייר לדירה ולאחר ביצוע תשלום עבור השירות",
          "מומלץ לרכוש מראש, עוד לפני חתימת ההסכם עם הדייר כדי שתהיו מובטחים",
        ],
      },
      {
        disclaimer:
          "רכישת השירות כוללת גם את שירות הערבות, שירות צ'ק בטוח. השירות מיועד לבעלי דירות.",
      },
    ],
  },
  {
    title: "איך זה עובד וכמה זה עולה לי?",
    content: [
      {
        content:
          "עם קבלת ה'דאבל צ'ק', ובהנחה שאנחנו מוכנים לעמוד מאחורי הדייר, נציע לך לרכוש 'הכל מראש' או 'צ'ק בטוח' (שירות 'צ'ק בטוח' מוצע גם לבעל הדירה וגם לדייר). ההצעה שלנו תכלול את המחיר בהתאם למוצר ולרמת הסיכון בעסקה.",
      },
      {
        content: `רכישת השירות מתבצעת בצורה דיגיטלית ולוקחת בממוצע כ-3 דקות!${LINE_BREAK} אין צורך במסמכים וגם לא תצטרכו לחכות לחתימת החוזה מול הדייר/בעל הדירה - ניתן לרכוש לפני שחותמים ולבטל בכל עת.`,
      },
      {
        content:
          "לרכישת הערבות שלנו אין השפעה כלל על תהליך השכרת הדירה (מול דייר/מול בעל הדירה) שאתם מכירים היום, הכל נשאר בדיוק אותו הדבר - רק שהפעם אתם מוגנים לגמרי.",
      },
    ],
  },
  {
    title: "ומה עושים אם יש כשל בתשלום של הדייר/ת?",
    content:
      "קודם כל - לא נלחצים! הרי יש לכם 'צ'ק בטוח'. בעל הבית מתקשר אלינו במספר טלפון 03-3030211, אנחנו נבדוק את הבקשה בנוגע לכשל בתשלום, ואם הכל תקין תוך 14 ימי עסקים הכסף יגיע לחשבון הבנק של בעל הבית. כמו שאמרנו - לא נלחצים.",
  },
  {
    title: "אני דייר, קיבלתי שאלון דאבל צ'ק - מה זה אומר?",
    content: [
      {
        content:
          "שירות הדאבל צ׳ק שלנו מאפשר לכם לספק לבעל הדירה שלכם רק את המידע שהוא צריך לדעת עליכם, בצורה דיגטלית ומהירה, ללא צורך בטפסים ובירוקרטיות (תלושי שכר, תדפיסי חשבון בנק וכו'), אפילו מבלי להפגש.",
      },
      {
        content:
          "השאלון מורכב ממספר שאלות הקשורות לעסקת השכירות (כמו כמה נפשות נכנסות לדירה, למה עזבתם את הדירה הקודמת, מקום העבודה שלכם וכ״ו).",
      },
      {
        content:
          "המידע שלכם ישאר מוגן וישלח אך ורק אליכם ואל בעל הדירה אשר שלח לכם את השאלון. חשוב לדעת שאנחנו מקפידים על כללי הגנת הפרטיות, מפעילים מערכות הגנת סייבר ובעלי רישיון מרשות שוק ההון שגם מפקחת על פעילותינו.",
      },
      {
        content:
          "אם קיבלתם הודעה עם שאלון וויצ'ק, מלאו אותו ב-3 דקות מהנייד ותקבלו את דוח הדאבל צ'ק הדיגיטלי ישירות לנייד! כדאי מאוד למלא את השאלון בהקדם, אפילו אם עדין לא ביקרתם בדירה, בכדי לוודא שאתם ובעל הבית אכן מתכוונים לבצע את עסקת השכירות ולמנוע ביזבוז זמן מיותר לכל הצדדים.",
      },
    ],
  },
  {
    title: "דוח הדאבל צ'ק לא מאפשר רכישת ערבות - מה זה אומר על הדייר?",
    content: [
      {
        content:
          "בכל עסקת שכירות יש סיכון הן כספי והן לעוגמת נפש. תשובת דוח הדאבל צ׳ק שלנו מבוססת על אלגוריתם חכם המתבסס על מערכת בינה מלאכותית (AI), שבוחן את רמת הסיכון בעסקה הרלוונטית (ולא את רמת הסיכון של הדייר באופן ספציפי). אם בחרנו שלא להציע ערבות, רמת הסיכון שאנחנו חישבנו לעסקה לא מאפשרת לנו להציע שירות זה.",
      },
      {
        content: `אנחנו בוויצ׳ק לא מדרגים או נותנים ציונים לדיירים, ובהחלט יתכן שבעסקה אחרת נשמח להציע ערבות עבור דייר/ת זה.`,
      },
      {
        disclaimer: "לקבלת מידע נוסף ניתן לפנות אלינו במספר 03-3030211",
      },
    ],
  },
];

export const pay_questions = [
  {
    title: "מה זה WeCheckPay?",
    content:
      "WeCheckPAY היא מערכת תשלומים דיגיטלית המאפשרת לבעלי דירות ודיירים לבצע ולקבל תשלומי שכר דירה בצורה פשוטה, נוחה ובטוחה, תוך כדי שמירת פרטיות הלקוחות וללא צורך בצ'קים, טפסים או בירוקרטיה.",
  },
  {
    title: "האם השימוש ב-WeCheckPAY כרוך בעלות?",
    content:
      "בשלב זה, השימוש ב-WeCheckPAY הוא חינם ללקוחות WeCheck שרכשו את ערבות ״צ'ק בטוח״ המגנה על כל שכר הדירה שלהם.",
  },
  {
    title: "אני בעל דירה, איך אני מצטרף ל-WeCheckPAY?",
    content:
      "ההצטרפות ל WeCheckPAY אפשרית לאחר רכישת ערבות ״צ'ק בטוח״. תקבלו הודעת SMS להשלמת תהליך הזיהוי והאימות. לאחר שתסיימו את תהליך האימות, הדייר יקבל גם הוא הודעה לאמת את פרטיו, לאשר את התנאים ולהקים הרשאה לחיוב חשבון, וזהו! מערכת התשלומים WeCheckPay עומדת לרשותם ותבצע את תשלומי שכר הדירה.",
  },
  {
    title: "אני דייר, איך אני מצטרף ל-WeCheckPAY?",
    content: `ההצטרפות ל WeCheckPAY אפשרית לאחר רכישת ערבות ״צ'ק בטוח״, בעל הדירה ישלח לכם הודעת SMS עם לינק לתהליך הרישום למערכת WeCheckPay. בלינק, תצטרכו לאמת את הפרטים האישיים שלכם, לאשר את תנאי עסקת השכירות שסיכמתם ולהקים הרשאה לחיוב חשבון.
    וזהו! מהרגע הזה, תשלומי שכר הדירה שלכם משולמים בצורה דיגיטלית! בלי לתפוס מסגרת אשראי ובלי ניירת מיותרת`,
  },
  {
    title: "האם יש צורך בצ'קים פיזיים או בטפסים כלשהם?",
    content:
      "לא, תהליך התשלומים הוא דיגיטלי לחלוטין ואין צורך בצ'קים פיזיים או בטפסים. יש צורך בחתימה על שטר חוב המצורף לתהליך.",
  },
  {
    title: "מה קורה אם הדייר לא מאשר את התנאים או לא מקים הרשאה לחיוב חשבון?",
    content:
      "במקרה שהדייר לא מאשר את התנאים או לא מקים הרשאה לחיוב חשבון, לא נוכל להפעיל את מערכת התשלומים של WeCheckPay. במצב כזה, נציגי WeCheck יצרו קשר להמשך הטיפול. במצב זה על בעל הדירה לדאוג לקבלת אמצעי תשלום חלופי מהדיירים.",
  },
  {
    title:
      "האם ניתן לשלם / לקבל שכר דירה עבור מספר דירות שונות דרך WeCheckPAY?",
    content:
      "כן, ניתן לשלם / לקבל שכר דירה עבור מספר דירות שונות דרך WeCheckPAY, כל עוד יש לכם את ערבות ״צ'ק בטוח״ לכל אחת מהדירות.",
  },
  {
    title: "איך אוכל לבדוק את סטטוס העסקאות שלי ב-WeCheckPAY?",
    content: [
      {
        content: `בעלי דירות - תוכלו למצוא את כל הפרטים על עסקת WeCheckPay באזור האישי שלכם כאן ${URLS.Login}`,
      },
      {
        content:
          "דיירים - תקבלו את כל הפרטים על עסקת WeCheckPay שביצעתם ישירות לנייד שלכם בהודעות טקסט וכן עדכון בכל פעם שבוצע חיוב בגין תשלום שכר הדירה.",
      },
    ],
  },
  {
    title: "מה קורה אם אני רוצה להפסיק להשתמש ב-WeCheckPAY?",
    content:
      "במקרה שתרצו להפסיק להשתמש ב-WeCheckPAY, יש לפנות אלינו באחד מהערוצים הבאים לנוחיותכם - וואטסאפ, טלפון, מייל",
  },
  {
    title: "האם שימוש במערכת תופס מסגרת אשראי?",
    content: "לא! השירות לא תופס מסגרת אשראי, ללא עמלות וללא פיקדון",
  },
];
