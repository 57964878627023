export const STEPS = {
  PHONE: 0,
  CODE: 1,
  AGENT_REGISTER: 2,
};

export const ORIGINS = {
  WECHECK: 1,
  RENT_AI: 5,
}

export const AUTH_STATUS_CODES = {
  USER_BLOCKED: '001',
  RATE_LIMIT_EXCEEDED: '002',
  INVALID_CODE: '003',
};
export const AUTH_ERROR_MESSAGES = {
  USER_BLOCKED: 'משתמש חסום. נא לפנות לשירות לקוחות',
  RATE_LIMIT_EXCEEDED: 'ניסית יותר מדי פעמים. נסה שוב מאוחר יותר',
  INVALID_CODE: 'קוד לא תקין',
  GENERAL_ERROR: 'שגיאה כללית אירעה. נסה שוב מאוחר יותר',
};

export const getAuthErrorMessage = (statusCode, defaultMessage = AUTH_ERROR_MESSAGES.GENERAL_ERROR) => {
  if (!statusCode) return defaultMessage;
  const [statusCodeKey] = Object.entries(AUTH_STATUS_CODES).find(arr => arr[1] === statusCode);
  return AUTH_ERROR_MESSAGES[statusCodeKey] || defaultMessage
}