import React, { useRef } from "react";
import useIsInViewport from "../../../../../hooks/useIsInViewport";

import Container from "../../../../../components/product-pages/container";
import CTAButton from "../../../../../components/product-pages/CTAButton";
import GridSection from "../../../../../components/product-pages/grid-section";
import ItemList from "../../../../../components/product-pages/item-list";

import { ANALYTICS } from "../../consts";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { ITEM_LIST } from "./consts";
import { ITEM_LIST_TITLE } from "./texts";

import "./index.scss";

export default function ThirdSection() {
    const sectionRef = useRef(null)
    const isInView = useIsInViewport(sectionRef)
    
    return <GridSection reference={sectionRef} className={`aia-third-section-container ${isInView ? "in-view" : ""}`}>
        <ItemList list={ITEM_LIST} title={ITEM_LIST_TITLE} />
        <Container className="cta-button-container">
            <CTAButton analytics={ANALYTICS.THIRD_SECTION.CTA} text={CTA_BUTTON_TEXT} />
        </Container>
    </GridSection>
}