import React     from "react";

import {KEYCODES} from "@wecheck/uisharedutils/uiUtils";

import Utils from "../shared/utils";

import "./css/Input.scss";
import "./css/Search.scss";
import "./css/Select.scss";


const VISIBLE_TIMEOUT             = 100;
const ITEM_HEIGHT                 = 40;
const NUMBER_OF_ITEMS_IN_DROPDOWN = 4;

class Search extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			value          : "",
			selected       : props.selected ? props.selected : null,
			open           : false,
			hoverItem      : 0,
			focused        : false,
			selectListStyle: {},
			items          : []
		};

		this.initialValue = false;
	}

	componentDidMount() {
		this.props.onChange({
			name : this.props.name,
			value: this.props.value ? this.props.value : null
		});

		setTimeout(this.SetSelectListStyle);

		document.addEventListener("wheel", this.onWheel);
	}

	componentWillUnmount() {
		document.removeEventListener("wheel", this.onWheel);
	}

	onWheel = e => {
		if (this.ulNode && !this.ulNode.contains(e.target)) {
			this.setState({open: false});
		}
	};

	componentWillReceiveProps(nextProps) {
		if (nextProps.clear) {
			this.setState({
				value   : "",
				selected: null
			});
		}

		if (nextProps.selected && !this.initialValue) {
			this.setState({
				selected: nextProps.selected,
				value   : this.getText(nextProps.selected)
			});
			this.initialValue = true;
		}

		if (nextProps.items && nextProps.items.length) {
			this.setState({items: nextProps.items});
		}
	}

	SetSelectListStyle = () => {
		let selectListStyle = {};

		if (this.node && this.ulNode) {
			const nodeBounding      = this.node.getBoundingClientRect();
			const modalNodeBounding = this.ulNode.getBoundingClientRect();

			let top = nodeBounding.bottom;

			if ((nodeBounding.y + nodeBounding.height + modalNodeBounding.height) > window.innerHeight) {
				top = nodeBounding.top - modalNodeBounding.height;
			}

			if (
				navigator.userAgent.includes("Safari") &&
				!navigator.userAgent.includes("Chrome") &&
				window.innerWidth < 480
			) {
				top += 20;
			}

			selectListStyle = {
				top  : top,
				left : nodeBounding.left,
				width: nodeBounding.width
			};
		}

		this.setState({selectListStyle});
	};

	handleBlur = e => {

		let value = e.target.value;

		const findItemByValue = this.state.items.find(item => this.getText(item) === value);

		if (findItemByValue) {
			this.handleSelect(findItemByValue);
		}
		else if (this.state.selected) {
			value = this.getText(this.state.selected);
		}

		this.setState({
			value,
			focused: value !== ""
		});

		setTimeout(() => {
			this.setState({open: false});
		}, VISIBLE_TIMEOUT);
	};

	getText = item => {
		let text = item.displayText;

		if (!text) {
			text = (item.name !== undefined) ? item.name : item;
		}

		return text;
	};

	handleSelect = item => {

		this.setState({
			selected: item,
			value   : this.getText(item)
		});

		this.props.onSelect({
			name : this.props.name,
			value: item
		});
	};

	handleChange = e => {
		this.SetSelectListStyle();
		this.setState({
			value    : e.target.value,
			open     : true,
			hoverItem: 0
		});

		this.props.onChange({
			name : this.props.name,
			value: e.target.value
		});
	};

	handleKeyDown = e => {
		switch (e.keyCode) {
			case KEYCODES.ENTER:
				if (this.props.items.length) {
					e.preventDefault();
					this.handleSelect(this.props.items[this.state.hoverItem]);
					this.setState({open: false});
				}
				break;

			case KEYCODES.ARROW_DOWN: {
				let newHoverItem = this.state.hoverItem + 1;

				if (newHoverItem > (this.props.items.length - 1)) {
					newHoverItem = this.props.items.length - 1;
				}

				this.setState({
					hoverItem: newHoverItem
				}, () => setTimeout(() => {
					let lastItem = Math.floor(this.ulNode.scrollTop / ITEM_HEIGHT) + NUMBER_OF_ITEMS_IN_DROPDOWN;
					if (newHoverItem >= lastItem) {
						this.ulNode.scrollTop = (newHoverItem - (NUMBER_OF_ITEMS_IN_DROPDOWN - 1)) * ITEM_HEIGHT;
					}
				}, 200));
				break;
			}
			case KEYCODES.ARROW_UP: {
				let newHoverItem = this.state.hoverItem - 1;

				if (newHoverItem < 0) {
					newHoverItem = 0;
				}

				this.setState({
					hoverItem: newHoverItem
				}, () => setTimeout(() => {
					let firstItem = Math.floor(this.ulNode.scrollTop / ITEM_HEIGHT) - 1;
					if (newHoverItem === firstItem) {
						this.ulNode.scrollTop = this.ulNode.scrollTop - ITEM_HEIGHT;
					}
				}, 200));
				break;
			}
			default:
		}
	};

	handleFocus = () => {
		if (this.props.fireAnalytics) {
			this.props.fireAnalytics();
		}

		this.setState({focused: true});
	}

	render() {

		const {name, placeholder, disabled, loader, dropDownPlaceholder, tabIndex, error} = this.props;
		const {value, selected, focused, items}                                           = this.state;

		let dropdownClass = "";

		if (this.state.open) {
			if (items && (items.length > 0 || dropDownPlaceholder)) {
				dropdownClass = "dropdown--open";
			}
		}

		return <div className = "wckForm__Search UIWck__select" >
			<div
				className = {`wckForm__input ${disabled ? "wckForm__input--disabled" : ""} ${error ? "wckForm__input--error" : ""}`}
				ref = {node => this.node = node} >
				{placeholder && !value && (
					<label
						className = {`wckForm__input__label ${focused || selected ? "wckForm__input__label--focused" : ""}`} >
						{error ? error : placeholder}
					</label >
				)}
				<input
					className = {`wckForm__input ${this.props.isCity ? "cityInput" : ""} ${this.props.isStreet ? "streetInput" : ""} `}
					name = {name}
					type = "text"
					id = {name}
					placeholder = {this.props.alternatePlaceHolder ? this.props.alternatePlaceHolder : ""}
					tabIndex = {tabIndex}
					onKeyDown = {this.handleKeyDown}
					disabled = {disabled}
					onChange = {this.handleChange}
					onBlur = {this.handleBlur}
					onFocus = {this.handleFocus}
					value = {value}
					autoComplete = "off" />
				<span className = "wckForm__input__focusBorder" />
			</div >
			<img src = {Utils.buildDashboardImgLink("wecheckIcon.svg", true)} className = "wckForm__Search__loader" alt = ""
			     style = {{display: loader ? "block" : "none"}} />
			<ul className = {`UIWck__select__dropdown ${dropdownClass}`}
			    style = {this.state.selectListStyle}
			    ref = {node => this.ulNode = node} >
				{dropDownPlaceholder ?
				 <li className = "UIWck__select__dropdown__dropDownPlaceholder" >{dropDownPlaceholder}</li > : items && items.map((item, index) => {
						let text = item.displayText;

						if (!text) {
							text = item.name ? item.name : item;
						}

						return <Item onMouseEnter = {() => this.setState({hoverItem: index})}
						             value = {item} key = {index} hover = {index === this.state.hoverItem}
						             onClick = {() => this.handleSelect(item)} >{text}</Item >;
					}
				)}
			</ul >
		</div >;
	}
}

const Item = ({children, onClick, onMouseEnter, selected, hover}) => (
	<li tabIndex = {0}
	    onClick = {onClick}
	    onMouseEnter = {onMouseEnter}
	    className = {selected ? "selected" : hover ? "hover" : ""} >{children}</li >);

export {Search};
