import React, { useRef } from "react";
import "./index.scss";
import { RENT_AI_CARD } from "../../consts";
import useReplaceWithSpan from "../../../../../../../newsletter/hooks/useReplaceWithSpan";
import { Link } from "react-router-dom";
import useIsMobile from "../../../../../../../hooks/useIsMobile";
import { CHEVRON_LIGHTBLUE } from "../../../../../../../newsletter/components/location-cards/components/card/consts";
import VideoPlayer from "../../../../../../../components/video-player-new";

export default function RentAICard() {
  const paragraph = useReplaceWithSpan(
    RENT_AI_CARD.PARAGRAPH,
    RENT_AI_CARD.PART_TO_REPLACE
  );
  const isMobile = useIsMobile(1000);
  const videoPlayerRef = useRef(null);

  const openVideo = () => {
    videoPlayerRef.current.showModal();
  };

  return (
    <div className="rent-ai-card-container">
      <div className="rent-ai-card-content">
        <div className="textual-content">
          <div className="rent-ai-card-icon-container">
            <img src={RENT_AI_CARD.ICON} alt={RENT_AI_CARD.TITLE} />
          </div>
          <div className="rent-ai-card-title-container">
            <h3>{RENT_AI_CARD.TITLE}</h3>
          </div>
          <div className="rent-ai-card-secondary-title-container">
            <h4>{RENT_AI_CARD.SECONDARY_TITLE}</h4>
          </div>
          {!isMobile && (
            <div className="rent-ai-card-paragraph-container">
              <p>{paragraph}</p>
            </div>
          )}
        </div>
        <div className="rent-ai-card-buttons">
          <Link to={RENT_AI_CARD.LINK} className="rent-ai-card-button-cta">
            {RENT_AI_CARD.BUTTON_CTA}
          </Link>
          <button onClick={openVideo} className="rent-ai-card-video-cta">
            <div>{RENT_AI_CARD.VIDEO_CTA}</div>
            <div className="card-image-container">
              <img src={CHEVRON_LIGHTBLUE} alt="chevron" />
            </div>
          </button>
        </div>
      </div>
      <div className="rent-ai-card-spread">
        <img
          src={isMobile ? RENT_AI_CARD.SPREAD_MOBILE : RENT_AI_CARD.SPREAD}
          alt="spread"
        />
      </div>
      <VideoPlayer ref={videoPlayerRef} url={RENT_AI_CARD.VIDEO_LINK} />
    </div>
  );
}
