import React from "react";
import { InfoWindow, Marker } from "@react-google-maps/api";

import {
  AREA_MARKER,
  ASSET_MARKER,
  LOCATION_MARKER,
  MARKER_Z_INDEX,
  NEIGHBORHOOD_MARKER,
} from "./consts";

import "./index.scss";
import {
  RENT,
  ROOM_AMOUNT,
  SQUARED_METERS,
  ASSETS_PROPERTIES,
} from "../../texts";
import { ZOOM_DISPLAY } from "../../consts";
import { useDispatch, useSelector } from "react-redux";
import { setDataView } from "../../../../../../redux/features/newsletterSlice";
import { MONTH_ENUM } from "../../../search/components/month-select/consts";
import { pushToGTM } from "../../../../../../helpers/gtm";

const getDateFromAsset = (date) => {
  const assetDate = new Date(date);
  const month = assetDate.getMonth();
  const year = assetDate.getFullYear();

  return `${MONTH_ENUM[month]} ${year}`;
};

const openDataView = (data, dispatch, isLoggedIn) => {
  if (!isLoggedIn) return;
  dispatch(setDataView(data));
};

export const CityMarker = React.memo(({ city, areas, handleMarkerClick, currentZoom }) => {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  return (
    <Marker
      zIndex={MARKER_Z_INDEX.CITY}
      position={{ lat: city.lat, lng: city.lng }}
      icon={LOCATION_MARKER}
      label={{
        text: currentZoom >= ZOOM_DISPLAY.CITIES.FOCUS ? city.name : " ",
        className: "marker-position",
      }}
      onClick={() => {
        openDataView(city, dispatch, isLoggedIn);
        handleMarkerClick(
          city,
          areas ? ZOOM_DISPLAY.CITIES.FOCUS : ZOOM_DISPLAY.CITIES.FOCUS_NO_AREAS
        );
        pushToGTM("אייקון עיר", { cityName: city.name });
      }}
    />
  );
});

export const AreaMarker = React.memo(
  ({ area, city, handleMarkerClick, visible }) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
    return visible ? (
      <Marker
        zIndex={MARKER_Z_INDEX.AREA}
        position={{ lat: parseFloat(area.lat), lng: parseFloat(area.lng) }}
        icon={AREA_MARKER}
        label={{
          text: `${city.name} - ${area.name}`,
          className: "marker-position",
        }}
        onClick={() => {
          openDataView(
            { ...area, name: `${city.name} - ${area.name}` },
            dispatch,
            isLoggedIn
          );
          handleMarkerClick(
            { ...area, name: `${city.name} - ${area.name}` },
            ZOOM_DISPLAY.AREAS.FOCUS
          );
          pushToGTM("אייקון איזור", { areaName: area.name });
        }}
      />
    ) : null;
  }
);

export const NeighborhoodMarker = React.memo(
  ({ neighborhood, city, handleMarkerClick, visible }) => {
    const dispatch = useDispatch();
    const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

    return visible ? (
      <Marker
        zIndex={MARKER_Z_INDEX.NEIGHBORHOOD}
        position={{
          lat: parseFloat(neighborhood.lat),
          lng: parseFloat(neighborhood.lng),
        }}
        icon={NEIGHBORHOOD_MARKER}
        label={{
          text: `${city.name} - ${neighborhood.display_name}`,
          className: "marker-position",
        }}
        onClick={() => {
          openDataView(
            {
              ...neighborhood,
              name: `${city.name} - ${neighborhood.display_name}`,
            },
            dispatch,
            isLoggedIn
          );
          handleMarkerClick(
            {
              ...neighborhood,
              name: `${city.name} - ${neighborhood.display_name}`,
            },
            ZOOM_DISPLAY.NEIGHBORHOODS.FOCUS
          );
          pushToGTM("אייקון שכונה", {
            neighborhoodName: neighborhood.display_name,
          });
        }}
      />
    ) : null;
  }
);

export const AssetMarker = React.memo(
  ({
    asset,
    currentZoom,
    handleMarkerClick,
    tooltipAsset,
    setTooltipAsset,
    visible,
  }) => {
    const isTooltipVisible =
      tooltipAsset &&
      asset.id === tooltipAsset.id &&
      currentZoom >= ZOOM_DISPLAY.ASSETS.FOCUS;
    return (
      <Marker
        zIndex={MARKER_Z_INDEX.ASSET}
        icon={ASSET_MARKER}
        position={{ lat: parseFloat(asset.lat), lng: parseFloat(asset.lng) }}
        visible={visible}
        onClick={() => {
          handleMarkerClick(
            asset,
            currentZoom >= ZOOM_DISPLAY.ASSETS.FOCUS
              ? currentZoom
              : ZOOM_DISPLAY.ASSETS.FOCUS,
            asset
          );
          pushToGTM("אייקון נכס");
        }}
      >
        {isTooltipVisible && (
          <InfoWindow
            options={{ maxWidth: 208, minWidth: 208 }}
            onCloseClick={() => setTooltipAsset(null)}
          >
            <div className="info-window">
              <div className="title-container">
                <h4>{`${asset.street_name || asset.neighborhood_name}, ${
                  asset.city
                }`}</h4>
                <h5>{getDateFromAsset(asset.date)}</h5>
              </div>
              <div className="content-container">
                <p>
                  {ROOM_AMOUNT}
                  <span>{asset.room_numbers}</span>
                </p>
                <p>
                  {SQUARED_METERS}
                  <span>{asset.property_size}</span>
                </p>
                <p>
                  {RENT}
                  <span>₪{asset.rent_cost.toLocaleString("en-US")}</span>
                </p>
                <div className="content-properties">
                  <div className="property-row">
                    {asset.has_storage_room !== null && (
                      <div
                        className={`property-item ${
                          asset.has_storage_room ? "selected" : ""
                        }`}
                      >
                        {ASSETS_PROPERTIES.STORAGE_ROOM}
                      </div>
                    )}
                    {asset.parkingspace_cnt !== null && (
                      <div
                        className={`property-item ${
                          asset.parkingspace_cnt ? "selected" : ""
                        }`}
                      >
                        {ASSETS_PROPERTIES.PARKING_SPACE}
                      </div>
                    )}
                    {asset.balconies_cnt !== null && (
                      <div
                        className={`property-item ${
                          asset.balconies_cnt > 0 ? "selected" : ""
                        }`}
                      >
                        {ASSETS_PROPERTIES.BALCONY}
                      </div>
                    )}
                  </div>
                  <div className="property-row">
                    {asset.elevator_cnt !== null && (
                      <div
                        className={`property-item ${
                          asset.elevator_cnt > 0 ? "selected" : ""
                        }`}
                      >
                        {ASSETS_PROPERTIES.ELEVATOR}
                      </div>
                    )}
                    {asset.is_saferoom_included !== null && (
                      <div
                        className={`property-item ${
                          asset.is_saferoom_included ? "selected" : ""
                        }`}
                      >
                        {ASSETS_PROPERTIES.SAFE_ROOM}
                      </div>
                    )}
                    {asset.renovated !== null && (
                      <div
                        className={`property-item ${
                          asset.renovated ? "selected" : ""
                        }`}
                      >
                        {ASSETS_PROPERTIES.RENOVATED}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
);
