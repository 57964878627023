import React, { useRef } from "react";
import useCTAAnimation from "../../../../../hooks/product-pages/useCTAAnimation";

import AnimationAndText from "../../../../../components/product-pages/animation-and-text";
import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";

import { IMAGE_URL } from "./consts";
import { CTA_TEXT, CTA_TITLE, IMAGE_TEXT, PARAGRAPH, TITLE } from "./texts";

import "./index.scss";
import { ANALYTICS } from "../../consts";

export default function SecondSection() {
    const CTARef = useRef(null)

    const CTAContainerAnimated = useCTAAnimation({
        className: "airplane-animation-and-cta",
        buttonClassName: "animate-appearance",
        ctaTitle: CTA_TITLE,
        ctaText: CTA_TEXT,
        ref: CTARef,
        analytics: ANALYTICS.SECOND_SECTION.CTA
    })

    return <GridSection className="aia-second-section">
        <TextSection title={TITLE} text={PARAGRAPH} />
        <AnimationAndText animation={IMAGE_URL} text={IMAGE_TEXT} />
        {CTAContainerAnimated}
    </GridSection>
}