import PropTypes          from "prop-types";
import React, {Component} from "react";

import Utils from "../shared/utils";

class Form extends Component {
	static propTypes = {
		render     : PropTypes.func,
		validations: PropTypes.object,
		onSubmit   : PropTypes.func,
		onChange   : PropTypes.func,
		firstCtrlId: PropTypes.string
	};

	constructor(props) {
		super(props);

		this.values      = {};
		this.validations = {};

		const errors = {};

		if (this.props.validations) {
			Object.keys(this.props.validations).forEach((name) => {
				errors[name] = false;
			});
		}

		this.state = {
			errors   : errors,
			hasErrors: false
		};
	}

	componentDidMount = () => this.hasErrors();

	runValidations() {
		if (this.props.validations) {
			Object.keys(this.props.validations).forEach((name) => {
				if (typeof this.props.validations[name] === "object") {
					this.validations[name] = this.props.validations[name].map(
						(validator) =>
							!validator(this.values[name], this.values)
					);
				}
				else {
					this.validations[name] = !this.props.validations[name](
						this.values[name],
						this.values
					);
				}
			});
		}

		return this.validations;
	}

	hasErrors() {
		this.runValidations();
		this.setState({
			hasErrors:
				Object.keys(this.validations).filter((item) => {
					let validation;

					if (typeof this.props.validations[item] === "object") {
						validation =
							this.validations[item].filter((subitem) => subitem)
								.length > 0;
					}
					else {
						validation = this.validations[item];
					}

					return validation;
				}).length > 0
		});
	}

	handleBlur = (e) => {
		this.runValidations();

		const target = e.target ? e.target : e;

		this.setState({
			errors: {
				...this.state.errors,
				[target.name]: this.validations[target.name]
			}
		});
		this.hasErrors();
	};

	handleChange = (target) => {
		this.values[target.name] = target.value;

		this.setState({
			errors: {...this.state.errors, [target.name]: false}
		});

		if (this.props.onChange) {
			this.props.onChange(this.values);
		}

		this.hasErrors();
	};

	handleTabCycle = (evt) => {
		if (this.props.firstCtrlId && evt.keyCode === Utils.KEYCODES.TAB) {
			evt.preventDefault();
			Utils.focus(this.props.firstCtrlId);
		}
	};

	handleSubmit = (e) => {
		e.preventDefault();
		this.runValidations();

		this.setState({
			errors: this.validations
		});

		this.props.onSubmit({
			hasErrors: this.state.hasErrors,
			errors   : this.state.errors,
			values   : this.values
		});
	};

	render() {
		const {render} = this.props;

		return (
			<div className = "wckForm" >
				{render({
					handleSubmit  : this.handleSubmit,
					handleChange  : this.handleChange,
					handleTabCycle: this.handleTabCycle,
					handleBlur    : this.handleBlur,
					hasErrors     : this.state.hasErrors,
					errors        : this.state.errors,
					values        : this.values
				})}
			</div >
		);
	}
}

export default Form;
