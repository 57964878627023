export const CROSS_COUNTRY_SELECT = {
  name: "כלל ארצי",
  city_code: "000",
  lat: null,
  lng: null,
};

export const FEATURE_FLAGS = {
  isNewMortSiteActive: null,
  isMortPersonalAreaActive: null,
};

export const RENT_PRICELIST_MAP_SERVICE = "/rent-pricelist-map";

export const RENT_PRICELIST_CHAT_SERVICE = "/rent-pricelist-chat";

export const AUTH_SERVICE = "/auth";

export const MORTGAGE_PERSONAL_AREA_SERVICE = "/mortgage-personal-area";

export const USER_TYPE = {
  NO_PROPERTIES: 0,
  PROPERTY_NOT_IN_WINDOW: 1,
  PROPERTY_IN_WINDOW: 2,
  PROPERTY_IN_WINDOW_AND_HAS_CLICKS: 3,
  HAS_DOUBLECHECK_LAST_60_DAYS: 4,
};

export const LOGIN_TYPE = {
  NEWSLETTER: "newsletter",
  MORT_PERSONAL_AREA: "mortgage-personal-area",
};

export const LOGIN_METHODS = {
  JUST_PHONE: 0,
  PHONE_AND_ID: 1,
  MOKED: 2,
};

export const POPUPS = {
  [USER_TYPE.NO_PROPERTIES]: {
    0: {
      content: `היי %firstName%! אנחנו מתרגשים להכיר לכם את RentAi\nאת הדייר שלכם, אתם כבר מכירים?`,
      button_cta: `לבדיקת דיירים בחינם לחצו כאן`,
    },
    1: {
      content: `היי %firstName%, עכשיו אתם כבר צעד אחד לפני כולם\nאבל מה אם היינו אומרים לכם שבמקום להתעסק בערבים וצ'ק ביטחון - אפשר פשוט להתקדם לערבות WeCheck`,
      button_cta: `להצטרפות לחצו כאן`,
    },
  },
  [USER_TYPE.PROPERTY_NOT_IN_WINDOW]: {
    0: {
      content: `היי %firstName%, עכשיו שאתם כאן, תהיו בטוחים שאתם צעד אחד לפני כולם\nרוצים להיות בטוחים ששכר הדירה שלכם ב%propertyAddress% משולם?\nקליק אחד ואתם שם!`,
      button_cta: `לערבות מלאה על שכר הדירה לחצו כאן`,
    },
    1: {
      content: `היי %firstName%, ברוכים הבאים לRentAI! כאן תוכלו לגלות את שכר הדירה בכל הארץ\nאבל האם תקבלו החודש את שכר הדירה מהנכס ב%propertyCity%? רק ערבות וויצ׳ק\nמבטיחה שתקבלו את שכר הדירה לחצו כאן`,
      button_cta: `לערבות מלאה על שכר הדירה לחצו כאן`,
    },
  },
  [USER_TYPE.PROPERTY_IN_WINDOW]: {
    0: {
      content: `היי %firstName%,האם כבר יש דיירים שהתעניינו בנכס שלכם ב%propertyAddress%?\nזה בדיוק הזמן לעשות בדיקת רקע בחינם לפוטנציאלים מביניהם`,
      button_cta: `לבדיקת רקע לחצו כאן`,
    },
    1: {
      content: `היי %firstName%, איזה כיף שאתם כאן.\nשמנו לב ששכר הדירה שלכם ב%propertyAddress% לא מוגן אצלנו בוויצ'ק.\nזה בדיוק הזמן לקבל ערבות מלאה.
      `,
      button_cta: `לבדיקת רקע לחצו כאן`,
    },
  },
  [USER_TYPE.PROPERTY_IN_WINDOW_AND_HAS_CLICKS]: {
    0: {
      content: `היי %firstName%! עכשיו כשאתם כאן, אתם מכירים את שכר הדירה בשכונה שלכם!\nראינו שכבר למעלה מ%amountOfClicks% דיירים התעניינו בנכס שלכם ב%propertyAddress%\nזה הזמן להכיר גם אותם.`,
      button_cta: `לבדיקת דיירים בחינם לחצו כאן`,
    },
    1: {
      content: `היי %firstName%,ברוכים הבאים לRentAi. המקום היחידי שנותן ביטחון בשוק השכירות.\nראינו שכבר למעלה מ%amountOfClicks% דיירים התעניינו בנכס שלכם ב%propertyAddress%\nאנחנו כאן לספק לכם ביטחון גם איתם.`,
      button_cta: `לבדיקת רקע על הדיירים בחינם לחצו כאן`,
    },
  },
  [USER_TYPE.HAS_DOUBLECHECK_LAST_60_DAYS]: {
    0: {
      content: `היי %firstName%, עם RentAI - יש על מי לסמוך.\nראינו שכבר בדקתם דיירים לדירה ב%propertyAddress%, להבטחת ערבות מלאה על שכר הדירה`,
      button_cta: `לחצו כאן`,
    },
    1: {
      content: `היי %firstName%, ברוכ/ה הבא/ה לRentAI !עכשיו כשאתם כאן, תהיו בטוחים שאתם כבר נחשבים מקצוענים בשוק השכירות. אתם ממשיכים לבחון דיירים לדירה ב%propertyAddress%?`,
      button_cta: `לבדיקת דיירים נוספים לחצו כאן`,
    },
  },
};
