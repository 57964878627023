import React from "react";
import "./index.scss";
import { BUTTON_TEXT, ALTERED_BUTTON_TEXT } from "./texts";
import CloseButton from "./components/close-button";
import Title from "./components/title";
import { STEPS, TITLE_ENUM } from "./consts";
import IntroFirstPart from "./components/first-part";
import IntroSecondPart from "./components/second-part";
import { useDispatch, useSelector } from "react-redux";
import { changeIntroStep } from "../../../../redux/features/newsletterSlice";
import IntroThirdPart from "./components/third-part";

export default function Intro() {
  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.newsletter.intro.step);

  const stepsComponents = {
    [STEPS.FIRST]: <IntroFirstPart />,
    [STEPS.SECOND]: <IntroSecondPart />,
    [STEPS.THIRD]: <IntroThirdPart />,
  };

  const step = () => {
    return stepsComponents[currentStep] || <IntroFirstPart />;
  };

  const changeStep = () => dispatch(changeIntroStep());

  return (
    <div className="intro-container">
      <CloseButton />
      <Title text={TITLE_ENUM[currentStep]} />
      <div className="intro-parts-container">{step()}</div>
      <button onClick={changeStep} className="search-button">
        {currentStep === STEPS.FIRST ? BUTTON_TEXT : ALTERED_BUTTON_TEXT}
      </button>
    </div>
  );
}
