export const ROOM_AMOUNT = `מספר חדרים:`;
export const RENT = `שכר דירה:`;
export const SQUARED_METERS = `גודל במ"ר:`;

export const ASSETS_PROPERTIES = {
  STORAGE_ROOM: "מחסן",
  PARKING_SPACE: "חניה",
  BALCONY: "מרפסת",
  ELEVATOR: "מעלית",
  SAFE_ROOM: 'ממ"ד',
  RENOVATED: "משופץ",
};
