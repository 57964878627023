import React from "react";

import HeadingMD from "../../../../../../../components/product-pages/heading-md";
import ItemList from "../../../../../../../components/product-pages/item-list";
import Paragraph from "../../../../../../../components/product-pages/paragraph";

import { ITEM_LIST_FIRST_PART, ITEM_LIST_SECOND_PART } from "./consts";
import { DISCLAIMER, ITEM_LIST_TITLE } from "./texts";

import "./index.scss";

export default function BenefitsSection() {
    return <div className="benefits-section-wrapper">
        <div className="benefits-section-container">
            <HeadingMD text={ITEM_LIST_TITLE} />
            <div className="item-lists-container">
                <ItemList list={ITEM_LIST_FIRST_PART} />
                <ItemList list={ITEM_LIST_SECOND_PART} />
            </div>
        </div>
        <Paragraph text={DISCLAIMER} />
    </div>
}