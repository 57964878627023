import React from "react";
import "./index.scss";
import { SAVE_BUTTON_TEXT } from "./texts";
import { useDispatch } from "react-redux";
import { handleDateRangeSelect } from "../../../../../../../../../redux/features/newsletterSlice";

export default function SaveButton() {
  const dispatch = useDispatch();

  const handleSave = () => dispatch(handleDateRangeSelect());
  return (
    <button onClick={handleSave} className="save-button">
      {SAVE_BUTTON_TEXT}
    </button>
  );
}
