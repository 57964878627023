import Utils from "../../../../../shared/utils";

export const ITEM_LIST = [
    {
        icon: Utils.buildProductPageImageLink("icons/confirm-icon.svg"),
        text: `ערבות מלאה לצ'קים של הדיירים שלכם.`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/checkmark-circle-icon.svg"),
        text: `המשך תשלומי השכירות גם בהליכי פינוי`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/money-bags-icon.svg"),
        text: `קבלה של כל הכסף מההתחלה בלי לחכות לכל חודש בחודשו.`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/judgement-icon.svg"),
        text: `אם תצטרכו נספק גם טיפול משפטי מול הדיירים, לרבות הטיפול בפינוי`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/sleep-icon.svg"),
        text: `ראש שקט של שנה שלמה, אתם מקבלים את הכסף ואנחנו מתעסקים בתשלומי שכר הדירה במקומכם.`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/click-icon.svg"),
        text: `תוכלו להפסיק את השירות גם במהלך התקופה, בכל רגע ומכל סיבה`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/handshake-icon.svg"),
        text: `אם תתקלו בדיירים אשר מסרבים להתפנות, אנחנו נטפל בסוגיה גם אם הסכם השכירות הסתיים`
    },
]