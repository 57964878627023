import React, { useRef } from "react";
import { useOutletContext } from "react-router";

import ImageContainer from "../image-container";
import Section from "../section";
import FormContainer from "./components/form";
import SuccessModal from "./components/success-modal";

import { IMAGE_URL } from "./consts";
import useForm from "./hooks/useForm";

import "./index.scss";

export default function MortgageContact () {
    const dialogRef = useRef(null)
    const [refs, videoURLS, mortRefs, isFooterVisible, mortContactFormRef] = useOutletContext();

    const { initialValues, validate, onSubmit } = useForm({dialogRef})

    return <Section reference={mortContactFormRef} className="product-pages-contact-form">
        <FormContainer initialValues={initialValues} validate={validate} onSubmit={onSubmit}/>
        <ImageContainer className="contact-form-image" src={IMAGE_URL}/>
        <SuccessModal reference={dialogRef} />
    </Section>
}