import { useCallback, useEffect } from "react";
import throttle from "lodash/throttle";

export default function useHandleCLickOutside({
  reference,
  callback,
  disabled,
}) {
  const handleClickOutside = useCallback(
    throttle((event) => {
      if (reference.current && !reference.current.contains(event.target)) {
        callback();
      }
    }, 100),
    []
  );

  useEffect(() => {
    if (disabled) return;
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, disabled]);
}
