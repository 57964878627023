export const MAIN_TITLE =  `ברוכים הבאים`
export const PHONE_SUB_TITLE = `לכניסה למערכת הזינו את מספר הטלפון שלכם`
export const PHONE_WITH_ID_SUBTITLE = `לכניסה לאזור האישי הקלידו את הפרטים הבאים`
export const PHONE_SUB_TITLE_MOKED = `לכניסה למערכת הזינו את מספר הטלפון שלכם ושל נציג המכירות`
export const OWNER_PHONE_PLACEHOLDER = `טלפון של בעל הדירה`
export const MOKED_PHONE_PLACEHOLDER = `טלפון של נציג המכירות`
export const CODE_SUB_TITLE = `הקלידו את הקוד שקיבלתם בSMS`
export const NO_CODE_RECEIVED = `לא קיבלתי קוד`
export const ADS_CONSENT = `אני מאשר קבלת דיוור פרסומי הקשור לשירותי וויצ’ק (להסרה – יש להוריד את הסימון בתיבה)`
export const PRIVACY_POLICY = `אני מסכימ/ה לתקנון ולמדיניות הפרטיות של האתר לרבות למסירת המידע לישראכרט לצרכי השירות ולשמירתו`
export const CONTACT_AGGREEMENT = `אני מסכימ/ה לתנאי ההתקשרות`
export const BUTTON_SEND_CODE = `שלח לי קוד`
export const BUTTON_CONFIRM = `אישור`
export const WRONG_PHONE = `המספר שהזנתי לא נכון`

export const FOREIGN_RESIDENT = `תושב חוץ?`
