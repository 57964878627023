import React from "react";
import "./index.scss";
import { FUNCTIONAL_SECTION } from "../../consts";
import ApproveSection from "./components/approve-section";
import Contact from "./components/contact-section";

export default function ModalFunctionalSection({ type }) {
  return (
    <div className="modal-functional-section-container">
      {FUNCTIONAL_SECTION.APPROVE.includes(type) ? (
        <ApproveSection type={type} />
      ) : FUNCTIONAL_SECTION.CONTACT.includes(type) ? (
        <Contact />
      ) : null}
    </div>
  );
}
