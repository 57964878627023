import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useScrollPosition from "../../../hooks/useScrollPosition";
import useScrollToElement from "../../../hooks/useScrollToElement";

export default function useHeader() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  const location = useLocation();

  const isMortSite = location.pathname.includes("mortinfo");
  const isRentAISite = location.pathname.includes("rent-ai");
  const isRentSiteMainPage = location.pathname === "/";
  const isMortLandingPage = location.pathname === "/mortgage-contact";

  const scrollToElement = useScrollToElement();

  const scrollPosition = useScrollPosition();

  const isTopOfPage = scrollPosition <= 50;

  useEffect(() => {
    setIsNavOpen(false);
  }, [location.pathname]);

  useEffect(() => {
    if (isNavOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [isNavOpen]);

  const onNavClick = (ref) => {
    const mainPage = isMortSite ? "/mortinfo" : "/";
    if (location.pathname === mainPage) {
      scrollToElement(ref);
    } else {
      setTimeout(() => {
        scrollToElement(ref);
      }, 400);
    }
    if (isNavOpen) {
      setIsNavOpen(false);
    }
  };

  return {
    onNavClick,
    isMortSite,
    isRentSiteMainPage,
    isNavOpen,
    isTopOfPage,
    setIsNavOpen,
    scrollPosition,
    isMortLandingPage,
    isRentAISite,
  };
}
