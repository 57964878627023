import React, { useRef } from "react";
import useIsInViewport from "../../../hooks/useIsInViewport";
import useIsMobile from "../../../hooks/useIsMobile";
import Utils from "../../../shared/utils";

import AnimationContainer from "../animation-container";
import Container from "../container";
import CTAButton from "../CTAButton";
import ImageContainer from "../image-container";
import MainTitleAndParagraph from "../main-title-and-paragraph";
import Section from "../section";

import { POINT_ANIMATION } from "./consts";

import "./index.scss";

export default function FirstSectionContainer({
  mainTitle,
  mainTitleIcon,
  secondaryTitle,
  secondaryParagraph,
  secondaryParagraphTitle,
  paragraph,
  ctaButtonText,
  imageUrl,
  className = "",
  mainSubtitle = null,
  CTALink = Utils.getLoginLink(),
  analytics = {},
}) {
  const sectionRef = useRef(null);
  const isInView = useIsInViewport(sectionRef);
  const isMobile = useIsMobile();

  return (
    <Section
      reference={sectionRef}
      className={`first-section-container ${className}`}
    >
      <Container
        className={`right-side-container ${isInView ? "in-view" : ""}`}
      >
        <MainTitleAndParagraph
          mainTitle={mainTitle}
          mainTitleIcon={mainTitleIcon}
          secondaryTitle={secondaryTitle}
          paragraph={paragraph}
          secondaryParagraph={secondaryParagraph}
          mainSubtitle={mainSubtitle}
          secondaryParagraphTitle={secondaryParagraphTitle}
        />
        <Container className="animation-and-cta">
          <CTAButton
            analytics={analytics}
            href={CTALink}
            text={ctaButtonText}
          />
          {!isMobile && (
            <div className="hide-animation">
              <AnimationContainer img={POINT_ANIMATION} />
            </div>
          )}
        </Container>
      </Container>
      <ImageContainer src={imageUrl} />
    </Section>
  );
}
