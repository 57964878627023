const VideosData = {
	Shir: {
		name     : "שיר חוזוב",
		title    : "מומחית למימון נדל''ן",
		desc     : "“זוג הגיע אליי עם הון עצמי יחסית נמוך, בעזרת WeCheck גייסנו עוד 130 אלף שקל להון העצמי - הם הצליחו להגשים את החלום ולקנות דירה.”",
		img      : "TestimonialsBox3.png",
		videoName: "newMortShi",
		eventName: "סקשן טסטמוניאלס - סרטון שיר"
	},
	Stav: {
		name     : "סתיו קרול",
		title    : "יועץ עם תיקי רכישה וכל מטרה",
		desc     : "“אנחנו בעצם מגישים את ההצעה בקליק, אני לא צריך לשלוח מסמכים, פקסים ומיילים. פשוט קליק וזה עובר מהתוכנה ישירות ל-WeCheck, הם יודעים להתגמש ולתת מענה ממש ייחודי לכל בנאדם.”",
		img      : "TestimonialsBox2.png",
		videoName: "newMortSta",
		eventName: "סקשן טסטמוניאלס - סרטון סתיו"
	},
	Zvi : {
		name     : "צבי גליק",
		title    : "יועץ עם התמחות במגזר החרדי",
		desc     : "“וויצ’ק יודעת מה זה אישור כולל, יודעת מה זה אישור רב, יודעת מה זה תמיכה משפחתית. אין לנו עדיין מישהו שמתחרה ב-WeCheck במוצר הזה, זו דרגה אחת קדימה.”",
		img      : "TestimonialsBox1.png",
		videoName: "newMortZvi",
		eventName: "סקשן טסטמוניאלס - סרטון צבי"
	}
};

export {VideosData};
