import React from "react";
import Utils from "../../../../../shared/utils";
import { texts } from "../../consts";
import "./index.scss";

const BlobContainer = ({content, icon}) => {
    return <div className="blob-container">
        <div className="image-container">
            <img src={icon}/>
        </div>
        <h4>{content}</h4>
    </div>
}

export default function BottomSection() {
    return <section className="bottom-section">
        <div className="title-container">
            <div className="main-title-container">
                <h2>{texts.bottomSection.title}</h2>
                    <img src={Utils.buildMortImgLink("landing-page/wecheck-logo.svg")} />

            </div>
            <div className="secondary-title-container">
                <h3>{texts.bottomSection.secondaryTitle}</h3>
            </div>
        </div>
        <div className="blobs-container">
            {texts.bottomSection.blobs.map((blob, index) => {
                return <BlobContainer key={index} content={blob.text} icon={blob.icon}/>
            })}
        </div>
    </section>
}