import React from "react";
import "./index.scss";
import { Link } from "react-router-dom";
import config from "../../../../config/config";
import {
  RENT_AI_FEATURES_CODE,
  RENT_AI_SERVICE_CODE,
} from "../../../shared/utils";

export default function LoginButton({
  text,
  backToDataViewAfterLogin = false,
}) {
  return (
    <Link
      to={"#"}
      className="login-button-container"
      onClick={() => {
        window.location.href = `${
          config.envName === "local" ? config.authSiteAddr : config.HOMEPAGE_URL
        }${config.envName === "local" ? "" : "/auth"}?continue=${
          window.location.href
        }&service=${RENT_AI_SERVICE_CODE}&feature=${
          RENT_AI_FEATURES_CODE.RENT_AI_MAP
        }`;
        if (backToDataViewAfterLogin) {
          sessionStorage.setItem("backToDataViewAfterLogin", true);
        }
      }}
    >
      {text}
    </Link>
  );
}
