import React from "react";
import { useRef } from "react";
import useIsInViewport from "../../../hooks/useIsInViewport";
import Container from "../container";
import HeadingMD from "../heading-md";
import SingleItem from "./components/single-item";
import "./index.scss";

export default function ItemRow({ list, title, reference = null, className = "", isTitled }) {
    const isItemListInViewport = useIsInViewport(reference)
    
    return <Container reference={reference} className="item-row-container">
        <HeadingMD text={title} />
        <Container className={`item-row-list-container ${isItemListInViewport ? "item-list-in-viewport" : ""}`}>
            {
                list.map(({ ...props }, index) => {
                    return <SingleItem isTitled={isTitled} animationDelay={index > 0 && index - 1} key={index} {...props} />
                })
            }
        </Container>
    </Container>
}