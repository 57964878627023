import React from "react";
import Container from "../container";
import HeadingMD from "../heading-md";
import Paragraph from "../paragraph";
import "./index.scss";

export default function TextSection({ title, text, className = "" }) {
  return (
    <Container className={`text-section-container ${className}`}>
      {title && <HeadingMD text={title} />}
      {text && <Paragraph text={text} />}
    </Container>
  );
}
