import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useGetUserDetailsQuery,
  useRefreshTokenMutation,
} from "../../../redux/services/login";
import { useLazyGetUserStatusQuery } from "../../../redux/services/newsletter-protected";
import {
  setIsAdmin,
  setIsLoggedIn,
  setUserType,
} from "../../../redux/features/loginSlice";
import { LOGIN_TYPE } from "../../../redux/consts";
import { URLS } from "../../shared/urls";
import { initFilterDate } from "../../../redux/features/newsletterSlice";
import { setUser } from "../../../redux/features/userSlice";

export default function useRentUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const isAdmin = useSelector((state) => state.login.isAdmin);

  const [
    refreshToken,
    { isLoading: isLoadingRefreshToken, error: refreshError },
  ] = useRefreshTokenMutation();

  const [getUserStatus, userStatus] = useLazyGetUserStatusQuery({
    refetchOnFocus: true,
  });

  const {
    data: userData,
    isLoading: isLoadingUserDetails,
    error: userError,
  } = useGetUserDetailsQuery({}, { skip: isLoadingRefreshToken });

  useEffect(() => {
    const sendRefreshToken = async () => {
      const res = await refreshToken();
      if (res && !res?.error) {
        dispatch(setIsLoggedIn(true));
      }
    };
    sendRefreshToken();
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      getUserStatus();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (!userData) return;
    if (userData?.data) {
      dispatch(setIsAdmin(userStatus?.data?.data?.is_admin || false));
      dispatch(setUserType(userStatus?.data?.data?.type || 0));
    } else if (isLoggedIn && !userData.data?.data?.lastPolicyConfirmationDate) {
      dispatch(setIsLoggedIn(false));
      navigate(`${URLS.internalLogin}?type=${LOGIN_TYPE.NEWSLETTER}`);
    }
  }, [userStatus, userData]);

  useEffect(() => {
    dispatch(initFilterDate(isAdmin));
  }, [isAdmin]);

  useEffect(() => {
    dispatch(setUser({ ...userData?.data, ...userStatus?.data?.data }));
  }, [userData, userStatus.data]);

  return {
    isLoading:
      userStatus.isLoading || isLoadingUserDetails || isLoadingRefreshToken,
    isAdmin,
  };
}
