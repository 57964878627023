import React, { useRef, useEffect, useState } from "react";
import "./index.scss";
import { useSelector } from "react-redux";

export default function YearMonthDisplay() {
  const containerRef = useRef(null);
  const [isMounted, setIsMounted] = useState(false);

  const datesArray = useSelector((state) => state.newsletter.datesArray);
  const currentDateIndex = useSelector(
    (state) => state.newsletter.currentDateIndex
  );

  useEffect(() => {
    if (containerRef.current) {
      const selectedElement = containerRef.current.children[currentDateIndex];
      if (selectedElement) {
        const containerWidth = containerRef.current.offsetWidth;
        const elementWidth = selectedElement.offsetWidth;
        const elementLeft = selectedElement.offsetLeft;
        const paddingAndArrowWidth = 2 * 15 + 2 * 25;

        // Subtract half the container width and half the padding and arrow width
        const scrollLeft =
          elementLeft -
          containerWidth / 2 -
          paddingAndArrowWidth / 2 +
          elementWidth / 2;

        containerRef.current.scrollTo({
          top: 0,
          left: scrollLeft,
          behavior: isMounted ? "smooth" : "auto",
        });
      }
      if (!isMounted) {
        setIsMounted(true);
      }
    }
  }, [currentDateIndex]);

  return (
    <div ref={containerRef} className="month-year-container">
      {datesArray.map((date, index) => (
        <div
          key={index}
          className={`month-year ${
            index === currentDateIndex ? "selected" : ""
          }`}
        >
          {date?.dateString}
        </div>
      ))}
    </div>
  );
}
