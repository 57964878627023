import { useEffect } from "react";
import { useState } from "react";
import useHeader from "../../../../components/header/hooks/useHeader";
import { Validators } from "@wecheck/uisharedutils/validators";
import { texts } from "../consts";
import Utils from "../../../../shared/utils";

export default function useContactForm() {
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phone: "",
        dealType: "",
        requestedAmount: ""
    })

    const [inputEntered, setInputEntered] = useState({
        firstName: false,
        lastName: false,
        phone: false,
        dealType: false,
        requestedAmount: false
    })

    const [errors, setErrors] = useState({
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        dealType: undefined,
        requestedAmount: undefined
    })

    const [wasFormClosed, setWasFormClosed] = useState(false);

    const [isFormOpen, setIsFormOpen] = useState(false)

    const [isLoading, setIsLoading] = useState(false);

    const [formSent, setFormSent] = useState(false)

    const enterInput = (name) => {
        setInputEntered({ ...inputEntered, [name]: true })
    }

    const validate = () => {
        let errors
        if (!formData.firstName) {
            errors = { ...errors, firstName: texts.form.errors.firstName.empty }
        } else if (!Validators.hebrewOnly(formData.firstName)) {
            errors = { ...errors, firstName: texts.form.errors.firstName.notHebrew }
        } else {
            errors = { ...errors, firstName: null }
        }
        if (!formData.lastName) {
            errors = { ...errors, lastName: texts.form.errors.lastName.empty }
        } else if (!Validators.hebrewOnly(formData.lastName)) {
            errors = { ...errors, lastName: texts.form.errors.lastName.notHebrew }
        } else {
            errors = { ...errors, lastName: null }
        }
        if (!formData.phone) {
            errors = { ...errors, phone: texts.form.errors.phone.empty }
        } else if (!Validators.phone(formData.phone)) {
            errors = { ...errors, phone: texts.form.errors.phone.error }
        } else {
            errors = { ...errors, phone: null }
        }
        if (!formData.dealType) {
            errors = { ...errors, dealType: texts.form.errors.dealType }
        } else {
            errors = { ...errors, dealType: null }
        }
        if (parseInt(formData.requestedAmount) < 100000 || parseInt(formData.requestedAmount) > 1500000) {
            errors = { ...errors, requestedAmount: texts.form.errors.requiredAmount }
        } else {
            errors = { ...errors, requestedAmount: null }
        }

        return setErrors(errors)
    }

    const handleInput = (name, value) => {
        if (!inputEntered[name]) {
            setTimeout(() => {
                enterInput(name)
            }, 50)
        }
        setFormData({ ...formData, [name]: value })
    }

    const clearInputs = () => setFormData({
        firstName: "",
        lastName: "",
        phone: "",
        dealType: "",
        requestedAmount: ""
    })
    const clearErrors = () => setErrors({
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        dealType: undefined,
        requestedAmount: undefined
    })
    const clearEntered = () => setInputEntered({
        firstName: false,
        lastName: false,
        phone: false,
        dealType: false,
        requestedAmount: false
    })

    const clearForm = () => {
        clearInputs();
        clearEntered();
        clearErrors();
    }

    const scriptUrl = "https://script.google.com/a/macros/wecheck.co.il/s/AKfycbxNn5xInKdaj3_aOA0nYD9UrYEcIaizoNaxp9JFIj_6zJk62I4hGUFLpahTe76UFmRq/exec"

    const submit = async (event, ref) => {
        event.preventDefault()
        Utils.gaEvent(
            texts.events.category,
            texts.events.actions.SEND_FORM.action,
            texts.events.actions.SEND_FORM.label
        )
        setIsLoading(true)
        await fetch(scriptUrl, { method: 'POST', body: new FormData(ref.current) })
            .then(res => {
                clearForm()
                setFormSent(true)
                setIsLoading(false)
            }).catch(err => console.log(err))
    };

    const openForm = () => {
        setIsFormOpen(true)
        Utils.gaEvent(
            texts.events.category,
            texts.events.actions.EXPAND_FORM.action,
            texts.events.actions.EXPAND_FORM.label
        )
    }
    const closeForm = () => {
        if (formSent) {
            setIsFormOpen(false)
            setWasFormClosed(true)
            setFormSent(false)
        } else {
            setIsFormOpen(false)
        }
    }
    const { scrollPosition } = useHeader();

    const buttonDisabled = errors.firstName
        || errors.lastName
        || errors.phone
        || errors.dealType
        || errors.requestedAmount

    useEffect(() => {
        if (scrollPosition <= 100) {
            if (formSent) {
                setWasFormClosed(true);
            }
            setIsFormOpen(false)
        }
    }, [scrollPosition])

    useEffect(() => {
        validate()
    }, [formData])

    return {
        formData,
        handleInput,
        submit,
        isFormOpen,
        openForm,
        closeForm,
        errors,
        inputEntered,
        enterInput,
        isLoading,
        buttonDisabled,
        formSent,
        wasFormClosed
    }
}