import React from "react";
import Container from "../../../../../components/product-pages/container";

import "./index.scss";
import Paragraph from "../../../../../components/product-pages/paragraph";
import {
  IT_IS_FREE,
  LOGIN_BUTTON_TEXT,
  LOGIN_REQUEST,
  LOGIN_REQUEST_DATA_VIEW_OPEN,
} from "./texts";
import useReplaceWithSpan from "../../../../hooks/useReplaceWithSpan";
import LoginButton from "../../../login-button";
import AnimationContainer from "../../../../../components/product-pages/animation-container";
import { BELL_ANIMATION } from "./consts";
import { useSelector } from "react-redux";

export default function LoginHide({ showButton = true }) {
  const alteredText = useReplaceWithSpan(LOGIN_REQUEST, IT_IS_FREE);
  const isDataViewVisible = useSelector(
    (state) => state.newsletter.dataView.isVisible
  );

  return (
    <Container
      className={`login-hide-container ${
        isDataViewVisible ? "less-spacing" : ""
      }`}
    >
      <Container className={`animation-and-text-container`}>
        <AnimationContainer
          className={`bell-animation ${
            !isDataViewVisible ? "show-animation" : ""
          }`}
          img={BELL_ANIMATION}
          placeholder={"bell animation"}
        />
        <Paragraph
          className="login-hide-paragraph"
          text={isDataViewVisible ? LOGIN_REQUEST_DATA_VIEW_OPEN : alteredText}
        />
      </Container>
      {showButton && (
        <LoginButton text={LOGIN_BUTTON_TEXT} backToDataViewAfterLogin />
      )}
    </Container>
  );
}
