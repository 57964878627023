import React from "react";
import useIsMobile from "../../../../../hooks/useIsMobile";
import Utils from "../../../../../shared/utils";
import { texts } from "../../consts";
import "./index.scss";

const replaceStringPartWithSpan = (string, part) => string.replace(part, `<span>${part}</span>`);

const MiddleSectionCard = ({ card }) => {
    return <div className="middle-section-card">
        <div className="top-part-container">
            <h3 dangerouslySetInnerHTML={{
                __html: replaceStringPartWithSpan(card.title, "הנכס שלך")
            }}/>
            <div className="secondary-title-container">
                <h4>{card.secondaryTitle}</h4>
                <img src={Utils.buildMortImgLink("landing-page/wecheck-logo.svg")} />
            </div>
        </div>
        <div className="bottom-part-container">
            <p >{card.content.firstLine}</p>
            <p dangerouslySetInnerHTML={{
                __html: replaceStringPartWithSpan(card.content.secondLine, "פה תוכלו")
            }}/>
        </div>
    </div>
}

const MiddleSectionParagraph = ({ title, content }) => {
    return <div className="middle-section-paragraph">
        <div className="title-container">
            <img src={Utils.buildMortImgLink("landing-page/wecheck-icon.svg")} />
            <h3>{title}</h3>
        </div>
        <p>{content}</p>
    </div>
}

export default function MiddleSection () {
    const isMobile = useIsMobile(1400);
    return <section className="middle-section-container">
        <div className="right-part-container">
            {texts.middleSection.paragraphs.map(
                (par, index) => <MiddleSectionParagraph key={index} title={par.title} content={par.content} />
            )}
        </div>
        <div className="left-part-container">
            {
                texts.middleSection.cards.map((card, index) => {
                    return <MiddleSectionCard key={index} card={card} />
                })
            }
            {isMobile && <img src={Utils.buildMortImgLink("landing-page/middle-part-image.png")}/>}
        </div>
    </section>
}