import React from "react";
import "./index.scss";

export default function Spinner() {
    return <div className="spinner-container">
        <svg className="spinner-background" viewBox="0 0 50 50">
            <circle cx="25" cy="25" r="20" fill="none" strokeWidth="6"></circle>
        </svg>
        <svg className={`spinner`} viewBox="0 0 50 50">
            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="6"></circle>
        </svg>
    </div>
}