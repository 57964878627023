import React from "react";
import { useLottie } from "lottie-react";
import "./index.scss";
import { options } from "./options";

export default function Loader() {
  const { View } = useLottie(options);
  return (
    <div className="loader-container">
      {View}
    </div>
  );
}
