import React, { useRef, useState } from "react";
import { CTA_TEXT, CTA_TITLE, PART_TO_REPLACE } from "./texts";
import useReplaceWithSpan from "../../../../../../newsletter/hooks/useReplaceWithSpan";
import { PlusMinusToggle } from "./components/plus-minus-svg";
import Accordion from "../../../../../../newsletter/components/filter/components/accordion";
import ContactButtons from "../contact";
import useHandleCLickOutside from "../../../../../../hooks/useHandleClickOutside";

import "./index.scss";

export default function PaymentCTA() {
  const alteredText = useReplaceWithSpan(CTA_TEXT, PART_TO_REPLACE);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  useHandleCLickOutside({ reference: ref, callback: () => setIsOpen(false) })

  return (
    <div ref={ref} className="payment-cta-container">
      <h5 onClick={() => setIsOpen(!isOpen)}>
        <PlusMinusToggle isOpen={isOpen} setIsOpen={setIsOpen} />
        {CTA_TITLE}
      </h5>
      <Accordion className="payment-cta-accordion" isOpen={isOpen}>
        <p>{alteredText}</p>
        <ContactButtons />
      </Accordion>
    </div>
  );
}
