import React from "react";
import { useOutletContext } from "react-router-dom";
import { Divider } from "../../../components/divider";
import Utils from "../../../shared/utils";
// import {ProductsSection}                                                from "../../Products";
import TestimonialsVideoSection from "../testimonials/components/video-section";
import {
  OwnersTestimonials,
  RealtorsTestimonials,
  TennantsTestimonials,
} from "../testimonials/consts/testimonialsData";
import { AppStore } from "./components/appstore";
import Partners from "./components/partners";
import { ThePress } from "./components/press";
import { Welcome } from "./components/welcome";
import { WhoAreWeSection } from "./components/who-are-we";
import "./index.scss";
import { Logo } from "../../../components/logo";
import ProductsSection from "./components/products";
import { ProductsSection as OldProductsSection } from "../../Products";
import { useSelector } from "react-redux";

export const recordButtonClick = (action, label) =>
  Utils.gaEvent("עמוד ראשי", action, label);

const RentInfo = () => {
  const [refs] = useOutletContext();
  const EVENT_CATEGORY = "עמוד ראשי";

  const homePage = () => {
    return (
      <>
        <Welcome />

        <ProductsSection reference={refs.ourServices} />

        <div className="section center extra-padding">
          <h1 className="darkBlue">
            <b>למה </b>
            <Logo color="blue" className="logo" />
            <b>? </b>
          </h1>
        </div>
        <Divider />
        <Divider />
        <TestimonialsVideoSection
          video={TennantsTestimonials.videoSection}
          withButton
          eventCategory={EVENT_CATEGORY}
        />
        <TestimonialsVideoSection
          video={OwnersTestimonials.videoSection}
          withButton
          eventCategory={EVENT_CATEGORY}
        />
        <TestimonialsVideoSection
          video={RealtorsTestimonials.videoSection}
          withButton
          eventCategory={EVENT_CATEGORY}
        />
        <Divider />
        <ThePress />
        <AppStore />
        <Partners reference={refs.ourPartners} />
        <WhoAreWeSection reference={refs.whoAreWe} />
      </>
    );
  };

  return (
    <div className="RentInfo">
      <div className="inner-content">{homePage()}</div>
    </div>
  );
};

export { RentInfo };
