import React, { useMemo } from "react";
import Utils from "../../shared/utils";
import "./index.scss";
import logoAnimation from "./logo.json";
import Lottie from "lottie-react";

const Logo = ({ color = "blue", animated = false }) => {
  const imageLink = useMemo(() => {
    const colorName = color ? `${color}-` : "";

    return Utils.buildImgLink(`header/logo/${colorName}logo-desktop-yellow-strip.svg`);
  }, [color]);

  return animated ? (
    <Lottie animationData={logoAnimation} loop className="animatedLogo" />
  ) : (
    <img className="logo" src={imageLink} alt="weCheckLogo" />
  );
};

export { Logo };
