import Utils from "../../../../../../shared/utils";

export const MORT_CONTACT_PHONE = "054-7109227";
export const WHATSAPP_LINK = `https://bit.ly/3FkiQdy`;

export const CONTACT_US_CTA = `נציגנו זמינים בין השעות 09:00 - 18:00`;

export const CONTACT_TYPES = {
  WHATSAPP: "whatsapp",
  EMAIL: "email",
  PHONE: "phone",
};

export const CONTACT_URLS = {
  [CONTACT_TYPES.WHATSAPP]: WHATSAPP_LINK,
  [CONTACT_TYPES.PHONE]: `tel:${MORT_CONTACT_PHONE}`,
};

export const CONTACT_ICONS = {
  [CONTACT_TYPES.WHATSAPP]: Utils.buildModalImgLink("icons/whatsapp.svg"),
  [CONTACT_TYPES.PHONE]: Utils.buildModalImgLink("icons/phone.svg"),
};

export const ALT_CONTACT_ICONS = {
  [CONTACT_TYPES.WHATSAPP]: Utils.buildMortPersonalAreaImgLink("icons/whatsapp.svg"),
  [CONTACT_TYPES.PHONE]: Utils.buildMortPersonalAreaImgLink("icons/phone.svg"),
}

export const CONTACT_TEXT = {
  [CONTACT_TYPES.WHATSAPP]: `Whatsapp`,
  [CONTACT_TYPES.PHONE]: MORT_CONTACT_PHONE,
}

export const UNDERSTOOD_CTA = `אוקיי, הבנתי`
