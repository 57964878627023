import Utils from "../../../../../shared/utils";

export const ITEM_LIST = [
    {
        icon: Utils.buildProductPageImageLink("icons/eighty-five-icon.svg"),
        text: `עם WeCheck ניתן להגיע למימון מירבי של עד 85% משווי הנכס (עם שיעבוד נכס קיים בדרגה שנייה מעבר לבנק)`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/buildings-icon.svg"),
        text: `חברת WeCheck, בשותפות קבוצת ישראכרט ויד 2, בעלת איתנות פיננסית, הפועלת בשקיפות מלאה ומפוקחת על ידי רשות שוק ההון בישראל.`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/graph-icon.svg"),
        text: `גמישות- ניתן לפרוע את הלוואה ללא עמלות לאחר 24 חודשים או לחילופין להקטין את התשלום החודשי.`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/spaceship-icon.svg"),
        text: `אפשרות לקבלת גרייס אשר תאפשר הפחתה נוספת בהחזרים החודשיים`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/calendar-icon.svg"),
        text: `הקטנת ההחזרים החודשיים עד כדי ⅔ מההחזרים המקוריים ע"י הוזלת ריביות ופריסה לתקופה ארוכה של עד 30 שנה`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/chat-icon.svg"),
        text: `אם תצטרכו עזרה אישית ואנושית, גם זה ישנו.`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/click-icon.svg"),
        text: `תהליך קבלת משכנתא ב-WeCheck הוא קצר, פשוט ודיגיטלי`
    }
]
