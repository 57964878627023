import { useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../../../redux/features/modalSlice";

export default function useModal() {
  const dispatch = useDispatch();
  const modalRef = useRef(null);

  const isOpen = useSelector((state) => state.modal.isOpen);
  const modalType = useSelector((state) => state.modal.modalType);
  const modalProps = useSelector((state) => state.modal.modalProps);

  useEffect(() => {
    if (isOpen && modalRef?.current) {
      modalRef.current.showModal();
    } else if (!isOpen && modalRef?.current) {
      modalRef.current.close();
    }
  }, [isOpen]);

  const open = ({ type }) => dispatch(openModal({ modalType: type }));

  const close = () => dispatch(closeModal());

  return {
    isOpen,
    modalType,
    modalProps,
    modalRef,
    open,
    close,
  };
}
