import React, {useRef} from "react";

import useIsInViewport from "../../../../../hooks/useIsInViewport";
import useIsMobile     from "../../../../../hooks/useIsMobile";

import {URLS} from "../../../../../shared/urls";
import Utils  from "../../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../../components/logo";

const OurLoans = ({reference, isMortPage}) => {
	const isMobile     = useIsMobile();
	const rightCardRef = useRef(null);
	const leftCardRef  = useRef(null);
	const titleRef     = useRef(null);
	const ctaRef       = useRef(null);

	return (
		<section
			ref = {reference}
			className = {`LoansContainer ${isMortPage ? "mort-page" : ""}`}
		>
			<div
				ref = {titleRef}
				className = {`title-container ${
					useIsInViewport(titleRef) ? "in-view" : ""
				}`}
			>
				<h2 >ההלוואות שלנו</h2 >
			</div >
			<div className = "content-container" >
				<div className = "decorative-image" >
					<img
						src = {Utils.buildImgLink(
							"mort/OurLoansDecorationRight.svg"
						)}
						alt = "Decoration"
						className = "Decoration"
					/>
				</div >
				<div className = "cards" >
					<div
						ref = {rightCardRef}
						className = {`card-container ${
							useIsInViewport(rightCardRef) ? "in-view" : ""
						}`}
					>
						<div className = "image-container" >
							<img
								src = {Utils.buildImgLink(
									"mort/OurLoansMashlima.svg"
								)}
								alt = "משכנתא משלימה"
								className = "MortIcon"
							/>
						</div >
						<div className = "title-container" >
							<h3 >משכנתא משלימה</h3 >
							<h4 >משכנתא בנוסף למשכנתא של הבנק</h4 >
						</div >
						<h5 >השלמה לעד - 85% מימון</h5 >
						<div className = "content-container" >
							<p >
								המשכנתא הזו רלוונטית לאותם אנשים שמעוניינים לקחת
								משכנתא בדרגה ראשונה מהבנק, כאשר אחוזי המימון
								האפשריים בבנקים נעים בין 50%-75% משווי הנכס,
								תלוי בסוג העסקה.
								<br />
								כאן WeCheck נכנסת לתמונה.
							</p >
							<br />
							<p >
								אנחנו בWeCheck נשמח לעזור ולהשלים עבורכם את החלק
								׳החסר׳ באמצעות מתן משכנתא בדרגה שנייה לבנק, -
								ככה שתוכלו להגיע ל-85% מימון עבור הנכס שעליו
								תמיד חלמתם.
							</p >
						</div >
					</div >
					<div
						ref = {leftCardRef}
						className = {`card-container ${
							useIsInViewport(leftCardRef) ? "in-view" : ""
						}`}
					>
						<div className = "image-container" >
							<img
								src = {Utils.buildImgLink(
									"mort/OurLoansPremium.svg"
								)}
								alt = "משכנתא משלימה"
								className = "MortIcon"
							/>
						</div >
						<div className = "title-container" >
							<h3 >משכנתא פרימיום</h3 >
							<h4 >כל המשכנתא במקום אחד</h4 >
						</div >
						<h5 >מימון כולל עד - 85%</h5 >
						<div className = "content-container" >
							<p >
								ההצעה הזו רלוונטית לאותם האנשים שרוצים את החיים
								שלהם פשוטים.
								<br />
								במקום להכפיל את ההתעסקות, את הטפסים, הבירוקרטיות
								והאישורים - תוכלו לסגור הכל במקום אחד.
								<br />
								ומה הכי חשוב? שתוכלו לקבל 85% מימון, בפעם אחת -
								ככה פשוט.
								<br />
								אז תחסכו לעצמכם את ההתעסקות - ובואו לסגור את
								העסקה החשובה בחייכם בקלות ובפשטות.
							</p >
						</div >
					</div >
				</div >
				<div className = "decorative-image" >
					<img
						src = {Utils.buildImgLink(
							"mort/OurLoansDecorationLeft.svg"
						)}
						alt = "Decoration"
						className = "Decoration"
					/>
				</div >
			</div >
			<div
				ref = {ctaRef}
				className = {`cta-container ${
					useIsInViewport(ctaRef) ? "in-view" : ""
				}`}
			>
				<p >
                    <span >
                        אגב, ניתן לקבל משכנתא של
                        <Logo color={isMobile ? "white" : "blue"} />

                    </span >
					<span > בעת רכישת נכס חדש או כנגד נכס קיים שברשותכם</span >
				</p >
				<a
					onClick = {() =>
						Utils.gaEvent(
							"אתר שיווקי -￼ משכנתאות",
							"בדיקת זכאות וקבלת הצעה -￼ סקשן מוצרים",
							""
						)
					}
					href = {URLS.MortgageQue}
					className = "btn--azure"
				>
					לבדיקת זכאות וקבלת הצעה
				</a >
			</div >
		</section >
	);
};

export {OurLoans};
