import React, {memo} from "react";

import {Divider}                                                        from "../../../components/divider";
import {TestimonialsStrip}                                              from "./components/testimonials-strip";
import TestimonialsVideoSection                                         from "./components/video-section";

import "./components/video-section/index.scss";
import {OwnersTestimonials, RealtorsTestimonials, TennantsTestimonials} from "./consts/testimonialsData";

const TestimonialsTop = memo(() => (
	<>
		<div className = "section TestimonialsHeader" >
			<div className = "row flexDirectionColumn center" >
				<h1 className = "textColorPrimary bold text-center" >
					רוצים לדעת מה מספרים עלינו?
				</h1 >
				<h2 className = "textColorPrimary text-center" >
					כאן תוכלו לשמוע
				</h2 >
			</div >
		</div >
	</>
));

const TestimonialsSection = ({testimonials, eventCategory}) => (
	<>
		<TestimonialsVideoSection
			video = {testimonials.videoSection}
			eventCategory = {eventCategory ? eventCategory : "עמוד טסטמוניאלס"}
			grayBackground = "grayBackground"
		/>
		<TestimonialsStrip
			testimonials = {testimonials.stripSection}
			eventCategory = {eventCategory ? eventCategory : "עמוד טסטמוניאלס"}
			grayBackground = "grayBackground"
		/>
	</>
);

const TestimonialsPage = () => (
	<>
		<div className = "TestimonialsPage" >
			<Divider height = "calc(5vw + 10px)" />
			<TestimonialsTop />
			<Divider height = "calc(5vw + 10px)" />
			<TestimonialsSection testimonials = {TennantsTestimonials} />
			<Divider />
			<TestimonialsSection testimonials = {OwnersTestimonials} />
			<Divider />
			<TestimonialsSection testimonials = {RealtorsTestimonials} />
			<Divider height = "20vw" />
		</div >
	</>
);

export {TestimonialsPage,TestimonialsSection};
