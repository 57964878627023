import React, { useRef } from "react";
import useCTAAnimation from "../../../../../hooks/product-pages/useCTAAnimation";

import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";
import BenefitsSection from "./components/benefits-section";
import AnimationAndText from "../../../../../components/product-pages/animation-and-text";

import { CTA_TITLE, PARAGRAPH, PARAGRAPH_TITLE, IMAGE_TEXT } from "./texts";
import { IMAGE_URL } from "./consts";

import "./index.scss";
import { ANALYTICS } from "../../consts";
import { CTA_BUTTON_TEXT } from "./texts";
import useReplaceWithSpan from "../../../../../newsletter/hooks/useReplaceWithSpan";

export default function SecondSection() {
    const CTARef = useRef(null)

    const PART_TO_REPLACE = "חדש!"

    const CTAContainerAnimated = useCTAAnimation({
        className: "airplane-animation-and-cta",
        buttonClassName: "animate-appearance",
        ctaTitle: CTA_TITLE,
        ref: CTARef,
        analytics: ANALYTICS.SECOND_SECTION.CTA,
        ctaText: CTA_BUTTON_TEXT
    })

    const text = useReplaceWithSpan(PARAGRAPH, PART_TO_REPLACE)

    return <GridSection className="safecheck-second-section-container">
        <TextSection className="safecheck-second-section-paragraph" title={PARAGRAPH_TITLE} text={text} />
        <AnimationAndText animation={IMAGE_URL} text={IMAGE_TEXT} />
        <BenefitsSection />
        {CTAContainerAnimated}
    </GridSection>
}
