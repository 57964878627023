import Utils from "../../../../../shared/utils";

import "./index.scss";

const WeAreForYou = () => (
	<div className = "WeAreForYouSection" >
		<div className="container">
			<div className = "textContent" >
				<h2 >בשביל זה אנחנו כאן</h2 >
				<br />
				<p >
					וויצ'ק, בשותפות של יד2 וישראכרט, מאפשרת לכם להציג את עצמכם בפני
					בעלי הדירות על בסיס שאלון אחיד ומדוייק.
					<br/>
					אנו
					מספקים פתרונות למגוון רחב של אפשרויות שכירות: בין אם אתם נכנסים
					לדירת יחיד, בין אם אתם זוג, משפחה, שותפים ואפילו אם לא אתם אלו
					שעומדים לשלם את שכר הדירה - אלא ההורים, אנו פועלים מתוך אחריות
					מלאה שעבור כל עסקת שכירות שאתם עתידים לקחת על עצמכם, תקבלו שיקוף
					של הנתונים בדיוק כפי שהם.
					<br />
					<br />
					<strong >
						אגב, אם לא ציינו, חשוב שתדעו שאנחנו חברה הנמצאת תחת פיקוח של
						רשות שוק ההון (רשיון מס׳ 60509), רמת אבטחת המידע שלנו הינה
						מהגבוהות בשוק.
						<br/>
						אנו מתחייבים להגנה מלאה על הפרטיות שלכם וכל
						מידע שתתנו לנו ישמר בצורה המיטבית ובשום מקרה לא יועבר ללא
						הסכמתכם.
					</strong >
				</p >
			</div >
			<div className = "logos" >
				<img src = {Utils.buildImgLink("logos.svg")} alt = "" />
			</div >
		</div>

	</div >
);

export {WeAreForYou};

