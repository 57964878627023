import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import config from "../../config/config";

export const flagsApi = createApi({
  reducerPath: "flagsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.mortServerAddr,
  }),
  endpoints: (builder) => ({
    getFlags: builder.query({
      query: () => {
        return {
          url: `/mortgage/config`,
          method: "GET",
        };
      },
      transformResponse: (response) => {
        return {
            isNewMortSiteActive: response.firsMortgageFlag,
            isMortPersonalAreaActive: response.isMortPersonalAreaActive,
        };
      },
    }),
  }),
});

export const { useLazyGetFlagsQuery } = flagsApi;
