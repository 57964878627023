import React from "react";
import { useRef } from "react";
import CTA from "../../../../../components/product-pages/cta";
import GridSection from "../../../../../components/product-pages/grid-section";
import ImageContainer from "../../../../../components/product-pages/image-container";
import TextSection from "../../../../../components/product-pages/text-section";
import useIsInViewport from "../../../../../hooks/useIsInViewport";
import { ANALYTICS } from "../../consts";
import { CONTENT, CTA_TITLE, IMAGE_URL, TITLE } from "./consts";
import "./index.scss";

export default function FourthSection () {
    const CTARef = useRef(null)
    const sectionRef = useRef(null)
    const isCTAInViewport = useIsInViewport(CTARef)
    const isSectionInViewport = useIsInViewport(sectionRef)
    return <GridSection reference={sectionRef} className={`fourth-section-container ${isSectionInViewport ? "in-view" : ""}`}>
        <TextSection title={TITLE} text={CONTENT}/>
        <ImageContainer src={IMAGE_URL}/>
        <CTA analytics={ANALYTICS.FOURTH_SECTION.CTA} buttonClassName="cta-button-container" className={isCTAInViewport ? "cta-in-view" : ""} reference={CTARef} title={CTA_TITLE}/>
    </GridSection>
}