import React from "react";

import {LogoLine} from "../../../../components/logo-line";
import Utils      from "../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../components/logo";

const ExplainSection = () => (
	<div className = "ExplainSection" >
		<h2 >איך זה עובד?</h2 >

		<div className = "topPart" >
			<div className = "desktopOnly" >
				<LogoLine
					before = "כך משכנתא משלימה של"
					after = "מאפשרת לכם לרכוש נכס שאתם באמת רוצים"
				></LogoLine >
			</div >
			<div className = "mobileOnly" >
				<h3 >כך משכנתא משלימה</h3 >
				<LogoLine before = "של" after = "מאפשרת לכם" ></LogoLine >
				<h3 >לרכוש נכס שאתם באמת רוצים</h3 >
			</div >
		</div >

		<div className = "twoCubes" >
			<div className = "firstCube" >
				<div className = "desktopOnly" >
					<br />
					<div className = "textWithLogo" >
						<h3 >
							הנכס שלך <strong >בלי</strong >
						</h3 >
						<Logo color="blue" />

					</div >
				</div >
				<div className = "mobileOnly" >
					<h5 >
						הנכס שלך <strong >בלי</strong >
					</h5 >
					<Logo color="blue" />

				</div >
				<br />

				<div className = "grayBox" >
					<h5 >הון עצמי</h5 >
				</div >
				<img
					className = "symbol"
					src = {Utils.buildDashboardImgLink("mortgage/plus.svg")}
					alt = ""
				/>
				<div className = "grayBox" >
					<h5 >משכנתא בנקאית</h5 >
				</div >
				<img
					className = "symbol"
					src = {Utils.buildDashboardImgLink("mortgage/equ.svg")}
					alt = ""
				/>
				<div className = "grayBoxHouse" >
					<img
						className = "house"
						src = {Utils.buildDashboardImgLink(
							"mortgage/smallhome.svg"
						)}
						alt = ""
					/>
					<h5 >נכס</h5 >
				</div >
				<img
					className = "symbol"
					src = {Utils.buildDashboardImgLink("mortgage/arrow-down.svg")}
					alt = ""
				/>

				<h5 >
					<strong >פה תוכלו לקבל</strong >
				</h5 >
				<h5 >
					<strong > מימון 75%-50% </strong >
				</h5 >
				<br />
			</div >

			<div className = "secondCube" >
				<div className = "desktopOnly" >
					<br />
					<div className = "textWithLogo" >
						<h3 >
							הנכס שלך <strong >עם</strong >
						</h3 >
						<Logo color="blue" />

					</div >
				</div >
				<div className = "mobileOnly" >
					<h5 >
						הנכס שלך <strong >עם</strong >
					</h5 >
					<Logo color="blue" />
				</div >
				<br />
				<div className = "grayBox" >
					<h5 >הון עצמי</h5 >
				</div >
				<img
					className = "symbol"
					src = {Utils.buildDashboardImgLink("mortgage/plus.svg")}
					alt = ""
				/>
				<div className = "grayBox" >
					<h5 >משכנתא בנקאית</h5 >
				</div >
				<img
					className = "symbol"
					src = {Utils.buildDashboardImgLink("mortgage/plus.svg")}
					alt = ""
				/>
				<div className = "blueBox" >
					<div className = "desktopOnly" >
						<LogoLine before = "משכנתא משלימה של" isWhite />
					</div >
					<div className = "mobileOnly" >
						<h5 >משכנתא משלימה</h5 >
						<h5 >של WeCheck</h5 >
					</div >
				</div >
				<img
					className = "symbol"
					src = {Utils.buildDashboardImgLink("mortgage/equ.svg")}
					alt = ""
				/>

				<div className = "grayBoxHouse" >
					<img
						className = "house"
						src = {Utils.buildDashboardImgLink(
							"mortgage/bighome.svg"
						)}
						alt = ""
					/>
					<h5 >נכס</h5 >
				</div >
				<img
					className = "symbol"
					src = {Utils.buildDashboardImgLink("mortgage/arrow-down.svg")}
					alt = ""
				/>
				<div className = "blueBoxBig" >
					<h4 >פה תוכלו לקבל</h4 >
					<h4 >מימון 85%-75% </h4 >
				</div >
				<div className = "desktopOnly" >
					<br />
				</div >
			</div >
		</div >
	</div >
);

export {ExplainSection};
