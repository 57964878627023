import React, { useEffect, useRef } from "react";

import LoginHide from "./components/login-hide";
import "./index.scss";
import useIsMobile from "../../../hooks/useIsMobile";
import ByRentCostFormContainer from "./components/form";
import ByAreaFormContainer from "./components/by-area-form";
import { useDispatch, useSelector } from "react-redux";
import { FIRST_BREAKPOINT } from "../../consts";
import Container from "../../../components/product-pages/container";
import useHandleCLickOutside from "../../../hooks/useHandleClickOutside";
import { DATAVIEW_TABS } from "../../consts";
import { setDataViewActiveTab } from "../../../../redux/features/newsletterSlice";
import usePrevious from "../../hooks/usePrevious";

export default function Search({ isOpen, setIsOpen, setIsSearchOpen }) {
  const dispatch = useDispatch();
  const isMobile = useIsMobile(FIRST_BREAKPOINT);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const isDataViewVisible = useSelector(
    (state) => state.newsletter.dataView.isVisible
  );
  const isDataViewWasVisible = usePrevious(isDataViewVisible);
  const dataViewActiveTab = useSelector(
    (state) => state.newsletter.dataView.activeTab
  );
  const isFilterOpen = useSelector((state) => state.newsletter.filter.isOpen);
  const isChatOpen = useSelector((state) => state.newsletter.chat.isOpen);

  const searchRef = useRef(null);

  const isPopupOpen = useSelector(
    (state) => state.promotional.currentPopupIndex
  );

  useEffect(() => {
    if (!isDataViewVisible && isDataViewWasVisible) {
      dispatch(setDataViewActiveTab(DATAVIEW_TABS.DATA_BY_LOCATION));
    }
  }, [dispatch, isDataViewVisible, isDataViewWasVisible]);

  useHandleCLickOutside({
    reference: searchRef,
    callback: () => setIsOpen(false),
    disabled: isPopupOpen,
  });

  return (
    <Container
      reference={searchRef}
      className={`search-component-container ${
        isMobile && isOpen ? "is-open" : ""
      } ${isLoggedIn ? "is-logged-in" : ""} ${
        isDataViewVisible ? "data-view-visible" : ""
      } ${isMobile && isChatOpen ? "slide-down" : ""}`}
    >
      {isMobile && (
        <div
          onClick={() => isOpen && setIsOpen(false)}
          className="hidden-click-to-close"
        ></div>
      )}
      {dataViewActiveTab === DATAVIEW_TABS.RENT_COST &&
        (isLoggedIn || !isMobile) && (
          <ByRentCostFormContainer
            isDataViewVisible={isDataViewVisible || isFilterOpen}
          />
        )}
      {dataViewActiveTab === DATAVIEW_TABS.DATA_BY_LOCATION &&
        (isLoggedIn || !isMobile) && (
          <ByAreaFormContainer
            isDataViewVisible={isDataViewVisible || isFilterOpen}
            setIsSearchOpen={setIsSearchOpen}
          />
        )}
      {!isLoggedIn && <LoginHide />}
    </Container>
  );
}
