import React from "react";
import "./index.scss";
import { LOGIN_LINE, LOGIN_LINK_SPAN } from "./texts";
import { Link } from "react-router-dom";
import { URLS } from "../../../../../shared/urls";
import { LOGIN_TYPE } from "../../../../../../redux/consts";

export default function LoginLine({ isVisible }) {
  return (
    <p className="login-line">
      {LOGIN_LINE}
      <Link tabIndex={isVisible ? 0 : -1} to={`${URLS.internalLogin}?type=${LOGIN_TYPE.NEWSLETTER}`}>
        {LOGIN_LINK_SPAN}
      </Link>
    </p>
  );
}
