import React from "react";
import Container from "../container";
import HeadingMD from "../heading-md";
import LineWithIcon from "../line-with-icon";
import "./index.scss";

export default function ItemList({list, title}) {
    return <Container className="item-list-container">
        <HeadingMD text={title}/>
        <Container className="item-list-list-container">
        {
            list.map(({...props}, index) => {
                return <LineWithIcon key={index} {...props} />
            })
        }
        </Container>
    </Container>
}