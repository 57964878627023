export const FIRST_BREAKPOINT = 1000;
export const START_DAY = 5;

export const DATAVIEW_TABS = {
  RENT_COST: 0,
  DATA_BY_LOCATION: 1,
};

export const CTA_TEXT = "לחישוב שכר הדירה שלי";
export const CTA_TEXT_AFTER_USED_ONE_TIME = "לחץ כאן לבדיקת נכס נוסף";

export const MONTH_NUMBER_TO_HEBREW_NAME = {
  0: "ינואר",
  1: "פברואר",
  2: "מרץ",
  3: "אפריל",
  4: "מאי",
  5: "יוני",
  6: "יולי",
  7: "אוגוסט",
  8: "ספטמבר",
  9: "אוקטובר",
  10: "נובמבר",
  11: "דצמבר",
};
