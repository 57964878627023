import React from "react";
import Container from "../container";
import "./index.scss";

export default function LineWithIcon({ text, icon, title = "" }) {
  return (
    <Container className="line-with-icon-container">
      <div className="icon-container">
        <img src={icon} />
      </div>
      <div className="text-container">
        {title && <h3>{title}</h3>}
        <p>{text}</p>
      </div>
    </Container>
  );
}
