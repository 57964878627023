const CTA_CLICK_TRIGGER = `לחיצה על כפתור 'לבדיקת זכאות וקבלת הצעה'`

const EVENT_CATEGORY = `עמוד מוצר - משכנתא משלימה`

export const ANALYTICS = {
    FIRST_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `לבדיקת זכאות וקבלת הצעה - סקשן פותח`
        }
    },
    SECOND_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `רוצים לקבל הצעה תוך כמה דקות - לבדיקת זכאות`
        },
        VIDEO: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: `הפעלת סרטון`,
            ACTION: `הפעלת סרטון`
        }
    },
    THIRD_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `למה אתם מחכים - לבדיקת זכאות`
        }
    },
    FOURTH_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `סקשן תנאים - לבדיקת זכאות`
        }
    }
}