import React from "react";
import "./index.scss";
import { useSelector } from "react-redux";

export default function MapView() {
  const dataView = useSelector((state) => state.newsletter?.dataView);
  return (
    <div className="map-view-container">
      <h3>{dataView?.mapTitle}</h3>
      <div className="map-image-container">
        <img src={dataView?.map} alt="map" />
      </div>
    </div>
  );
}
