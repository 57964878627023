import Utils from "../../../../../shared/utils";

export const RED_TRIANGLE = Utils.buildNewsletterLink("icons/triangle-red.svg");
export const GREEN_TRIANGLE = Utils.buildNewsletterLink(
    "icons/triangle-green.svg"
);
export const BLUE_LOCK = Utils.buildNewsletterLink("icons/lock-blue.svg");
export const CHEVRON_LIGHTBLUE = Utils.buildNewsletterLink(
    "icons/chevron-light-blue.svg"
);
