import React from "react";
import Container from "../../../../../../../components/product-pages/container";
import HeadingMD from "../../../../../../../components/product-pages/heading-md";
import ImageContainer from "../../../../../../../components/product-pages/image-container";
import Paragraph from "../../../../../../../components/product-pages/paragraph";
import "./index.scss";;

export default function SingleCard({ title, text, icon }) {
    return <Container className="single-card">
        <ImageContainer src={icon} />
        <Container className="content-container">
            <HeadingMD text={title} />
            <Paragraph text={text} />
        </Container>
    </Container>
}