export const SEARCH_BUTTON_TEXT = `לחישוב שכר הדירה שלי`;
export const SEARCH_BUTTON_TEXT_MOBILE = "להצגת הנתונים";

export const CITY_AUTOCOMPLETE_PLACEHOLDER = `בחרו עיר להצגת הנתונים`;
export const STREET_AUTOCOMPLETE_PLACEHOLDER = `בחרו רחוב להצגת הנתונים`;

export const ROOM_SELECT_TITLE = `בחרו מספר חדרים`;
export const ASSETS_PROPERTIES_MULTI_SELECT_TITLE = `מאפייני הנכס`;

export const TITLE = "חישוב שכר הדירה שלי";
