import { URLS } from "../../shared/urls";
import Utils from "../../shared/utils";

export const ProductsInfo = [
	{
		headerImg: Utils.buildImgLink("dblHeader.svg"),
		title: "דאבל צ׳ק",
        link: URLS.Doublecheck,
		eventAction: "קרא עוד - דאבל צ'ק",
		subTitle: (
			<p >
				בדיקת רקע לדיירים - <strong >בחינם</strong >
			</p >
		),
		content: (
			<p >
				שירות הדאבל צ׳ק המהפכני שלנו מהווה גשר בין בעל בית לדייר
				הפוטנציאלי.
				<br />
				השירות מאפשר לדייר לספק לבעל הדירה את כל המידע הרלוונטי
				<strong > ללא צורך בטפסים ובירוקרטיות!</strong >
				<br />
				לאחר שהדייר ממלא את השאלון אשר נשלח אליו לנייד, על בסיס
				טכנולוגיות מתקדמות של בינה מלאכותית (AI), האלגוריתם שלנו מעבד את
				כלל הנתונים עד להפקת הדוח.
			</p >
		)
	},
	{
		headerImg: Utils.buildImgLink("scHeader.svg"),
		title: "צ׳ק בטוח",
        link: URLS.Safecheck,
		eventAction: "קרא עוד - צ'ק בטוח",
		subTitle: (
			<p >
				<strong >הצ׳ק חזר? תרגעו, שכר הדירה שלכם בטוח!</strong >
			</p >
		),
		content: (
			<p >
				צ'ק בטוח הוא שירות חדשני המבטיח לכם שתקבלו את הכסף שמגיע לכם, בהתאם להסכם השכירות ולפי דין,
				<strong > גם אם לצ'קים של הדייר אין כיסוי. </strong >
				<br />
				הכסף יכנס אליכם לחשבון, ואנחנו נטפל בכל מה שצריך.
				<br />
				<br />
				<strong >
					השירות יכול להרכש על ידי בעל הדירה או על ידי הדייר
				</strong >
			</p >
		)
	},
	{
		headerImg: Utils.buildImgLink("aiaHeader.svg"),
		title: "הכל מראש",
        link: URLS.AllInAdvance,
		eventAction: "קרא עוד - הכל מראש",
		subTitle: (
			<p >
				שכר דירה<strong > מראש לשנה שלמה</strong >- שתוכלו לישון בשקט
			</p >
		),
		content: (
			<p >
				עם שירות 'הכל מראש' תוכלו לקבל את
				<strong > כל הכסף כבר מההתחלה </strong >
				- בלי להמתין. הבטיחו לעצמכם שנה שלמה של שקט, ותנו לנו להתעסק
				בתשלומי שכר הדירה במקומכם.
				<br />
				<br />
				<strong >
					רכישת השירות כוללת גם את שירות הערבות, צ'ק בטוח. השירות
					מיועד לבעלי דירות.
				</strong >
			</p >
		)
	}
];

export const texts = {
    mainTitle: "המוצרים שלנו",
    bottomTitle: "מספקת ערבות מלאה לצ׳קים של שכר הדירה"
}