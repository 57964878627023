import Utils from "../../../../../shared/utils";

export const ITEM_LIST = [
    {
        icon: Utils.buildProductPageImageLink("icons/money-bags-icon.svg"),
        title: `סכומי הלוואות שניתן לקבל`,
        text: `₪250,000 - ₪1,500,000`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/schedule-icon.svg"),
        title: `תקופת החזר`,
        text: `עד 30 שנים`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/wallet-icon.svg"),
        title: `פרעון ללא קנס`,
        text: `בכפוף לתקופת הלוואה\nמינימלית של 24 חודשים`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/rising-graph-icon.svg"),
        title: `תשלום מופחת`,
        text: `החזר חודשי מופחת\nלמשך 24 חודשים`
    },
    {
        icon: Utils.buildProductPageImageLink("icons/calendar-icon.svg"),
        title: `אפשרות לתקופת גרייס`,
        text: `עד שנתיים`
    }
]