import { useEffect, useRef, useState } from "react";

export default function useCodeInput({ error, setCode }) {
  const inputRef = useRef(null);
  const [showError, setShowError] = useState(false);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (error) {
      setCode("");
      setShowError(true);
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  const handleChange = (event) => {
    const newValue = event.target.value;
    event.preventDefault();

    if (!newValue || /^\d+$/.test(newValue)) {
      setShowError(false);
      setCode(newValue);
    }
  };

  return {
    inputRef,
    handleChange,
    showError
  };
}
