import React, {useRef} from "react";
import useIsInViewport from "../../../../../hooks/useIsInViewport";
import Utils           from "../../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../../components/logo";

const WhoWeAre = ({reference}) => {
	const leftSideRef  = useRef(null);
	const rightSideRef = useRef(null);
	return (
		<section ref = {reference} className = "WhoWeAre" >
			<div
				ref = {rightSideRef}
				className = {`right-side-container ${
					useIsInViewport(rightSideRef) ? "in-view" : ""
				}`}
			>
				<h2 >מי אנחנו</h2 >
				<div className = "content-container" >
					<div className = "paragraph-container" >
						<h3 >
							<span >היי, אנחנו </span >
		
							<Logo color="blue" />

						</h3 >
						<p >
							ואתם יכולים להסתכל עלינו כמו כדור נגד כאב ראש בכל מה
							שקשור להשכרת וקניית נכסים
						</p >
					</div >
					<div className = "paragraph-container" >
						<h3 >איך אנחנו עושים את זה?</h3 >
						<p >
							אנחנו חוקרים ולומדים כל הזמן את האתגרים השונים
							בהשכרת וקניית
							<br /> נכסים - ויוצרים פתרונות ראשונים מסוגם, מבוססי
							טכנולוגיה ייחודית
							<br /> שעונים בצורה מדויקת על מגוון צרכים של משכירים
							ורוכשים, החל
							<br /> מביטחונות כספיים ועד סיוע בקניית נכסים - לפי
							היכולות הפיננסיות
							<br /> האמיתיות של הרוכשים.
						</p >
						<br />
						<p >
							אפילו עכשיו, בזמן שאתם קוראים את זה
							<br /> אנחנו עובדים על הפתרון החדשני הבא.
						</p >
					</div >
					<div className = "paragraph-container" >
						<h3 >למה דווקא אנחנו?</h3 >
						<p >
							כי אנחנו אנשי מקצוע מנוסים, עם ידע פיננסי
							<br /> וטכנולוגי מוכח, מה שמאפשר לנו להציע פתרונות
							<br /> חדשניים וייחודיים שלא היו זמינים עד היום
						</p >
						<br />
						<p >
							כי יצרנו שותפות עם יד2 ישראכרט כדי להעמיד לרשותכם
							גוף פיננסי מבוסס
							<br />
							ואמין שבאמת יכול לסייע לכם במגוון אתגרים בתחום
							הנדל"ן
						</p >
						<br />
						<p >
							זהו מקווים שלא דיברנו יותר מדי על עצמנו, אחרי הכל -
							אנחנו כאן כדי להקל עליכם
						</p >
					</div >
				</div >
			</div >
			<div
				ref = {leftSideRef}
				className = {`left-side-container ${
					useIsInViewport(leftSideRef) ? "in-view" : ""
				}`}
			>
				<h4 >השותפים שלנו</h4 >
				<div className = "image-container" >
					<img
						src = {Utils.buildImgLink("mort/WhoWeArePartners.svg")}
						alt = "השותפים שלנו"
						className = "OurPartners"
					/>
				</div >
			</div >
		</section >
	);
};
export {WhoWeAre};
