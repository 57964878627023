import Utils from "../../../../../shared/utils";

export const ITEM_LIST_TITLE = `יתרונות משכנתא פרימיום של WeCheck`;

export const ITEM_LIST_FIRST = [
  {
    icon: Utils.buildProductPageImageLink("icons/eighty-five-icon.svg"),
    text: `היכולת לקבל מימון גבוה של עד 85% משווי הנכס היא נקודת מפתח אשר מבדילה את "משכנתא פרימיום" מאפשרויות המשכנתא הקיימות בשוק`,
    title: "עד 85% מימון",
  },
  {
    icon: Utils.buildProductPageImageLink("icons/spaceship-icon.svg"),
    text: `חסכנו לכם זמן ובירוקרטיה ע"י הגשת בקשה דיגיטלית תוך מספר דקות וקבלת אישור עקרוני מיד בסיום הגשת הבקשה`,
    title: "תהליך פשוט ומהיר",
  },
];

export const ITEM_LIST_SECOND = [
  {
    icon: Utils.buildProductPageImageLink("icons/defense-icon.svg"),
    text: `WeCheck בשותפות יד 2 וישראכרט, בעלת איתנות פיננסית מפוקחת ע"י רשות שוק ההון. 
מספר רישיון: 60509`,
    title: "תהליך בטוח ומפוקח",
  },
  {
    icon: Utils.buildProductPageImageLink("icons/handshake-icon.svg"),
    text: `המוצר מספק פתרון גמיש הן בתנאי מימון והן באפשרויות ההחזר עם אופציה לפריסה עד 30 שנה. כך תוכלו לתכנן את החזר המשכנתא באופן המיטבי בהתאם ליכולת ההחזר שלכם`,
    title: "פתרון גמיש",
  },
];

export const ITEM_LIST_THIRD = [
  {
    icon: Utils.buildProductPageImageLink("icons/graph-icon.svg"),
    text: "כדי להבטיח שתקבלו את ההצעה הטובה ביותר.",
    title: `ריביות אטרקטיביות`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/shield-icon.svg"),
    text: `אנו לוקחים על עצמנו את כל התהליך, מרגע החתימה על הסכם ההלוואה ועד לביצוע המשכנתא בפועל כדי להקל עליכם כמה שיותר (למעט ברכישת דירה המוגדרת כדירה יחידה)`,
    title: "שירות בטחונות מקיף",
  },
];
