import React from "react";
import { CONTACT_TYPES, CONTACT_US_CTA, UNDERSTOOD_CTA } from "./consts";
import ContactButton from "../contact-button";
import ModalSubmitButton from "../submit-button";

import "./index.scss";
import useModal from "../../../../hooks/useModal";
import { MODALS } from "../../../../consts";

export default function Contact() {
  const {close: closeModal, modalType} = useModal();
  return (
    <div className="modal-contact-container">
      <p>{MODALS[modalType]?.cta ? MODALS[modalType].cta : CONTACT_US_CTA}</p>
      <div className="contact-buttons-container">
        <ContactButton type={CONTACT_TYPES.WHATSAPP} />
        <ContactButton type={CONTACT_TYPES.PHONE} />
        <ModalSubmitButton confirm={UNDERSTOOD_CTA} onClick={closeModal} />
      </div>
    </div>
  );
}
