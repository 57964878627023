import React from "react";
import Container from "../../../../../../../components/product-pages/container";
import ImageContainer from "../../../../../../../components/product-pages/image-container";
import TextSection from "../../../../../../../components/product-pages/text-section";
import { CONTENT, IMAGE_URL, TITLE } from "./consts";
import "./index.scss";

export default function LeftSection () {
    return <Container className="left-section-container">
        <TextSection title={TITLE} text={CONTENT}/>
        <ImageContainer src={IMAGE_URL}/>
    </Container>
}