import Utils from "../../../../../../../shared/utils";

export const CARD_LIST = [
    {
        icon: Utils.buildProductPageImageLink("mort/additional-mort/faces/face-man-regular.svg"),
        title: `רוכשי דירה ראשונה/נכס ראשון`,
        text: `רוכשי דירה ראשונה יכולים לקבל מימון מהבנק בשיעור מירבי של 75%. עם המשכנתא של WeCheck ניתן לקבל עד 85% מימון משווי הנכס ולרכוש בית עם 15% הון עצמי בלבד!`
    },
    {
        icon: Utils.buildProductPageImageLink("mort/additional-mort/faces/face-woman-regular.svg"),
        title: `משפרי דיור`,
        text: `אנשים המעוניינים להחליף את דירתם, בדרך כלל לדירה משופרת ויקרה יותר. משפרי דיור יכולים לקבל מהבנק משכנתא בגובה של עד 70% מימון. עם המשכנתא של WeCheck תוכלו לקבל מימון של עד 85% משווי הנכס הנרכש.`
    },
    {
        icon: Utils.buildProductPageImageLink("mort/additional-mort/faces/face-woman-eyes.svg"),
        title: `תושבי חוץ`,
        text: `לקוחות המעוניינים במשכנתא, אשר מתגוררים בחו״ל או בעלי מקורות הכנסה מחו״ל אשר מוגדרים כתושבי חוץ לטובת קבלת משכנתא, מוגבלים בשיעור המימון הניתן לקבל לרכישת נכס בישראל. שיעור המימון המירבי הניתן לקבל מהבנק עבור הנכס הנרכש, במקרה של תושב חוץ, הוא 50% בלבד. כלומר בכדי להשלים את עסקת הרכישה  ההון העצמי הנדרש לתושבי חוץ הוא 50%.\nעם המשכנתא המשלימה של WeCheck ניתן להגיע למימון של עד 85% מהנכס הנרכש, כלומר נדרש 15% הון עצמי בלבד.`
    },
    {
        icon: Utils.buildProductPageImageLink("mort/additional-mort/faces/face-man-eyes.svg"),
        title: `משכנתא לכל מטרה`,
        text: `בעלי נכסים למגורים יכולים לקבל משכנתא לכל מטרה, כגון: החזר הלוואות אחרות, חופשה בחו"ל, שיפוץ הנכס, רכישת רכב, הקמת עסק ועוד. המשכנתא לכל מטרה מיועדת לבעלי נכס קיים ומחייבת שיעבוד הנכס בדרגה שנייה אחרי הבנק. הבנקים יכולים לתת משכנתא של עד 50% משווי הנכס לכל מטרה. עם המשכנתא המשלימה של WeCheck ניתן לקבל עד 85% מימון משווי הנכס לכל מטרה, לדוגמה: אם שווי הנכס שלכם הוא 2,000,000 ש"ח ויתרת המשכנתא היא 1,100,000 ש"ח, במצב זה לא ניתן לקבל משכנתא נוספת מהבנק אך כן ניתן לקבל משכנתא משלימה מ- WeCheck על הנכס הקיים בסכום של 600,000 ₪. המשכנתא מהבנק בנוסף למשכנתא של WeCheck מהוות ביחד 85% מימון משווי הנכס.`
    },
    {
        icon: Utils.buildProductPageImageLink("mort/additional-mort/faces/face-man-glasses.svg"),
        title: `רכישת דירה/נכס להשקעה`,
        text: `בנק ישראל החיל מגבלות מהותיות על רוכשי נכס להשקעה המבקשים לקבל משכנתא מהבנק.  המגבלות כוללות מגבלה של קבלת משכנתא עד 50% בלבד משווי הנכס הנרכש ובנוסף הלקוח אינו יכול למשכן את הנכס הקיים שלו ולקבל עבורו מימון לטובת הנכס להשקעה שהוא רוצה לרכוש וזאת גם במקרים שהנכס הקיים ברשותו ללא משכנתאות בכלל. משקיעים רבים אינם מקבלים מהבנקים את המימון הנדרש ואינם יכולים לבצע את עסקה לרכישת נכס להשקעה. עם המשכנתא המשלימה של WeCheck אפשר לקבל עד 85% מימון לרכישת הנכס להשקעה ובנוסף ניתן לקבל גם עד 85% משווי הנכס הקיים. במקרים רבים זה ההבדל בין ביצוע העסקה או אי ביצוע העסקה.`
    },
]