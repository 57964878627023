import FourOhFourPage from "../components/404";
import Layout from "../components/layout";
import { MortCalculator } from "../mortgage-new/pages/calculator";
import { URLS } from "../shared/urls";
import { MortInfo } from "./info";
import PersonalArea from "./personal-area";

export const MortgageRouter = ({ isMortPersonalAreaActive }) => ({
  path: URLS.MortInfo,
  element: <Layout />,
  errorElement: <FourOhFourPage />,
  children: [
    {
      path: URLS.MortInfo,
      element: <MortInfo />,
    },
    {
      path: URLS.MortCalc,
      element: <MortCalculator />,
    },
    isMortPersonalAreaActive ? {
      path: URLS.MortPersonalArea,
      element: <PersonalArea />,
    } : null
  ].filter(Boolean),
});
