import { URLS } from "../../shared/urls";
import config from "../../../config/config";

const MORTGAGE_EVENT_CATEGORY = "תפריט עליון משכנתאות";
const RENT_EVENT_CATEGORY = "תפריט עליון השכרה";
const CLICK_ACTION = "לחיצה";

const MAIN_PAGE_EVENT_CATEGORY = "עמוד ראשי";

export const links = {
  mortgage: [
    {
      text: "לבדיקת זכאות משכנתא משלימה",
      link: URLS.MortgageQue,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "לבדיקת זכאות משכנתא משלימה",
      external: true,
      bold: true,
    },
    {
      text: "מחשבון משכנתא משלימה",
      link: URLS.MortCalc,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד מחשבון משכנתא משלימה",
    },
    {
      text: "ההלוואות שלנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "ההלוואות שלנו",
      scrollTo: true,
      ref: "mortLoans",
    },
    {
      text: "מי אנחנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "מי אנחנו",
      scrollTo: true,
      ref: "mortWhoWeAre",
    },
    {
      text: "מספרים עלינו",
      link: URLS.MortTestimonials,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "מספרים עלינו",
    },
    {
      text: "שאלות ותשובות",
      link: URLS.MortQNA,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד שאלות ותשובות",
    },
    {
      text: "צור קשר",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "צור קשר",
      scrollTo: true,
      ref: "mortContact",
    },
  ],
  rent: [
    {
      text: "השכרה",
      category: MAIN_PAGE_EVENT_CATEGORY,
      action: `Header - השכרה`,
      link: null,
      label: "השכרה",
      hasTooltip: true,
      links: [
        {
          label: "ראשי",
          to: `${config.HOMEPAGE_URL}${URLS.RentIntro}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - דאבל צ'ק`,
          external: true,
        },
        {
          label: "דאבל צ'ק",
          to: `${config.HOMEPAGE_URL}${URLS.Doublecheck}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - דאבל צ'ק`,
          external: true,
        },
        {
          label: "צ'ק בטוח",
          to: `${config.HOMEPAGE_URL}${URLS.Safecheck}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - צ'ק בטוח`,
          external: true,
        },
        {
          label: "WeCheckPay",
          to: `${config.HOMEPAGE_URL}${URLS.WeCheckPay}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - WeCheckPay`,
          external: true,
        },
        {
          label: "הכל מראש",
          to: `${config.HOMEPAGE_URL}${URLS.AllInAdvance}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - הכל מראש`,
          external: true,
        },
        {
          label: "חוזה שכירות",
          to: `${config.HOMEPAGE_URL}${URLS.RentAgreement}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - חוזה שכירות`,
          external: true,
        },
        {
          label: "RentAI",
          to: URLS.Newsletter,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - rentAI`,
          external: false,
        },
        {
          label: "שירות ותמיכה",
          to: `${config.HOMEPAGE_URL}${URLS.RentSupport}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - שירות ותמיכה השכרה`,
          external: true,
        },
      ],
    },
    {
      text: "משכנתאות",
      link: null,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד משכנתאות",
      hasTooltip: true,
      links: [
        {
          label: "ראשי",
          to: URLS.MortgageIntro,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא משלימה`,
          external: true,
        },
        {
          label: "משכנתא משלימה",
          to: URLS.AdditionalMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא משלימה`,
          external: true,
        },
        {
          label: "משכנתא מאחדת",
          to: URLS.CompleteMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא מאוחדת`,
          external: true,
        },
        {
          label: "משכנתא במסלול מופחת",
          to: URLS.ReducedMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא במסלול מופחת`,
          external: true,
        },
        {
          label: "משכנתא פרמיום",
          to: URLS.PremiumMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא פרמיום`,
          external: true,
          isPremium: true,
        },
        {
          label: "חילוץ הון עצמי",
          to: `/products${URLS.EquityExtractionMort}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא חילוץ הון עצמי`,
          external: true,
        },
        {
          label: "שירות ותמיכה",
          to: URLS.MortgageSupport,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתאות - שירות ותמיכה`,
          external: true,
        },
      ],
    },
    {
      text: "אודותנו",
      link: null,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד אודות",
      hasTooltip: true,
      links: [
        {
          label: "קצת עלינו",
          to: URLS.About,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - קצת עלינו`,
          external: true,
        },
        {
          label: "המייסדים",
          to: URLS.OurFounders,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - מייסדים`,
          external: true,
        },
        {
          label: "WeCheck בתקשורת",
          to: URLS.Testimonials,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - אנחנו בתקשורת`,
          external: true,
        },
        {
          label: "לקוחות מספרים",
          to: URLS.Media,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - לקוחות מספרים`,
          external: true,
        },
      ],
    },
    {
      text: "בלוג",
      link: URLS.Blog,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "בלוג",
      hasTooltip: true,
      links: [
        {
          label: "מידע מקצועי - השכרה",
          to: URLS.Blog,
          external: true,
        },
        {
          label: "חוזה שכירות",
          to: "https://blog.wecheck.co.il/%d7%97%d7%95%d7%96%d7%94-%d7%a9%d7%9b%d7%99%d7%a8%d7%95%d7%aa/",
          external: true,
        },
        {
          label: "שטר חוב",
          to: "https://blog.wecheck.co.il/%d7%a9%d7%98%d7%a8-%d7%97%d7%95%d7%91/",
          external: true,
        },
        {
          label: "ערבות בנקאית שכירות",
          to: "https://blog.wecheck.co.il/%d7%a2%d7%a8%d7%91%d7%95%d7%aa-%d7%91%d7%a0%d7%a7%d7%90%d7%99%d7%aa-%d7%9c%d7%a9%d7%9b%d7%99%d7%a8%d7%95%d7%aa/",
          external: true,
        },
        {
          label: "חישוב תשואה על הדירה",
          to: "https://blog.wecheck.co.il/%d7%97%d7%99%d7%a9%d7%95%d7%91-%d7%aa%d7%a9%d7%95%d7%90%d7%94-%d7%a2%d7%9c-%d7%93%d7%99%d7%a8%d7%94/",
          external: true,
        },
        {
          label: "מידע מקצועי - משכנתאות והלוואות",
          to: URLS.Blog,
          external: true,
        },
        {
          label: "איחוד הלוואות",
          to: "https://blog.wecheck.co.il/%d7%90%d7%99%d7%97%d7%95%d7%93-%d7%94%d7%9c%d7%95%d7%95%d7%90%d7%95%d7%aa/",
          external: true,
        },
        {
          label: "משכנתא על נכס קיים",
          to: "https://blog.wecheck.co.il/%d7%9e%d7%a9%d7%9b%d7%a0%d7%aa%d7%90-%d7%a2%d7%9c-%d7%a0%d7%9b%d7%a1-%d7%a7%d7%99%d7%99%d7%9d/",
          external: true,
        },
        {
          label: "הון עצמי למשכנתא",
          to: "https://blog.wecheck.co.il/%d7%94%d7%95%d7%9f-%d7%a2%d7%a6%d7%9e%d7%99-%d7%9c%d7%9e%d7%a9%d7%9b%d7%a0%d7%aa%d7%90/",
          external: true,
        },
        {
          label: "משכנתא חוץ בנקאית",
          to: "https://blog.wecheck.co.il/%d7%9e%d7%a9%d7%9b%d7%a0%d7%aa%d7%90-%d7%97%d7%95%d7%a5-%d7%91%d7%a0%d7%a7%d7%90%d7%99%d7%aa/",
          external: true,
        },
        {
          label: "הלוואה כנגד הנכס",
          to: "https://blog.wecheck.co.il/%d7%94%d7%9c%d7%95%d7%95%d7%90%d7%94-%d7%9b%d7%a0%d7%92%d7%93-%d7%a0%d7%9b%d7%a1/",
          external: true,
        },
      ],
    },
    {
      text: "שאלות ותשובות",
      link: URLS.QNA,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד שאלות ותשובות",
      external: true,
    },
  ],
  mortgageOld: [
    {
      text: "לבדיקת זכאות משכנתא משלימה",
      link: URLS.MortgageQue,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "לבדיקת זכאות משכנתא משלימה",
      external: true,
      bold: true,
    },
    {
      text: "מחשבון משכנתא משלימה",
      link: URLS.MortCalc,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד מחשבון משכנתא משלימה",
    },
    {
      text: "מי אנחנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "מי אנחנו",
      scrollTo: true,
      ref: "mortWhoWeAreOld",
    },
    {
      text: "השותפים שלנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "השותפים שלנו",
      scrollTo: true,
      ref: "mortPartnersOld",
    },
    {
      text: "צור קשר",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "צור קשר",
      scrollTo: true,
      ref: "mortContact",
    },
  ],
};

export const buttons = {
  mortgage: [
    {
      text: "כניסה ליועצים",
      color: "red",
      link: URLS.MortgageQue + "?agent=1",
      external: true,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "כניסת יועץ",
    },
    {
      text: "איזור אישי",
      color: "blue",
      link: URLS.MortPersonalArea,
      personalArea: true,
    },
  ],
  rent: [
    {
      text: "אזור אישי",
      color: "dark-blue",
      link: URLS.IntroToPersonalAreas,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "כניסת לאזור אישי",
      external: true,
    },
  ],
  rentAI: [
    {
      text: "אזור אישי",
      color: "dark-blue",
      overRideDefaultQueryParams: true,
      link: `${URLS.IntroToPersonalAreas}?utm_source=RentAI&utm_medium=personal-areas&utm_campaign=shai`,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "כניסת לאזור אישי",
      external: true,
    },
  ],
};

export const logo = {
  category: "תפריט עליון לוגו",
  action: "לחיצה על לוגו WeCheck",
  label: "לוגו וויצ'ק - Header",
};
