import React, { useEffect, useState } from "react";
import { ANIMATION_DURATION } from "./consts";
import "./index.scss";

function SingleAnimation({ src, alt }) {
  return (
    <object
      className="flowing-animation-animated"
      type="image/svg+xml"
      rel="prefetch"
      data={src}
    >
      {alt}
    </object>
  );
}

export default function FlowingAnimation({ title, list }) {
  const [currentAnimation, setCurrentAnimation] = useState(0);

  useEffect(() => {
    const swapAnimationTimeout = setTimeout(() => {
      setCurrentAnimation((currentAnimation + 1) % list.length);
    }, ANIMATION_DURATION);
    return () => clearTimeout(swapAnimationTimeout);
  }, [currentAnimation, list.length]);

  return (
    <div className="item-row-container flowing-animation-container">
      <h3 className="flowing-animation-title">{title}</h3>
      <div className="flowing-animation-animated-container">
        <SingleAnimation
          src={list[currentAnimation].src}
          alt={list[currentAnimation].alt}
        />
      </div>
    </div>
  );
}
