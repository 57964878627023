import Utils from "../../../../../../shared/utils";

export const DETAILS_OBJECT = {
  NAME: {
    label: `שם`,
    icon: Utils.buildMortPersonalAreaImgLink("drawer/icons/name.svg"),
  },
  ID: {
    label: `ת.ז`,
    icon: Utils.buildMortPersonalAreaImgLink("drawer/icons/id.svg"),
  },
  PHONE: {
    label: `טלפון`,
    icon: Utils.buildMortPersonalAreaImgLink("drawer/icons/phone.svg"),
  },
  EMAIL: {
    label: `אימייל`,
    icon: Utils.buildMortPersonalAreaImgLink("drawer/icons/email.svg"),
  },
};
