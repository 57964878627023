import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import CloseButton from "./components/close-button";
import RoomSelect from "../room-select";

import { ROOM_SELECT_TITLE } from "../search/components/form/texts";

import "./index.scss";
import InfoSection from "./components/info-section";
import GraphsSection from "./components/graphs-section";
import LoginLine from "./components/login-line";
import useIsMobile from "../../../hooks/useIsMobile";
import { FIRST_BREAKPOINT } from "../../consts";
import ScrollIndicator from "./components/scroll-indicator";
import { useGetAvgRentForThreeMonthsProtectedQuery } from "../../../../redux/services/newsletter-protected";
import {
  closeDataView,
  currentDate,
  selectArea,
  selectCity,
  setDataView,
} from "../../../../redux/features/newsletterSlice";
import Loader from "./components/loader";
import { NOT_ENOUGH_DATA_GENERAL, SEND_TO_LOGIN_IMAGE } from "./consts";
import {
  BEFORE_LOGIN_CTA,
  NOT_ENOUGH_DATA_BUTTON_CTA,
  NOT_ENOUGH_DATA_TEXT,
  NOT_ENOUGH_DATA_FOR_CITY_TEXT,
} from "./texts";
import { LOGIN_BUTTON_TEXT } from "../search/components/login-hide/texts";
import { Link } from "react-router-dom";
import useHandleCLickOutside from "../../../hooks/useHandleClickOutside";
import config from "../../../../config/config";
import {
  RENT_AI_FEATURES_CODE,
  RENT_AI_SERVICE_CODE,
} from "../../../shared/utils";

const NoDataAvailable = ({
  selectedCity,
  selectedArea,
  selectedNeighborhood,
}) => {
  const dispatch = useDispatch();

  const handleClick = () => {
    if (selectedNeighborhood) {
      if (selectedNeighborhood?.area_id) {
        dispatch(selectArea(selectedNeighborhood?.area_id));
      } else {
        dispatch(selectCity(selectedNeighborhood?.city_id));
      }
    } else if (selectedArea) {
      dispatch(selectCity(selectedArea?.city_id));
    }
  };

  return (
    <div className="before-login-container">
      <div className="image-container">
        <img src={NOT_ENOUGH_DATA_GENERAL} />
      </div>
      {selectedArea || selectedNeighborhood ? (
        <>
          <p>{NOT_ENOUGH_DATA_TEXT}</p>
          <button onClick={handleClick} className="search-button">
            {NOT_ENOUGH_DATA_BUTTON_CTA}
          </button>
        </>
      ) : (
        <p>{NOT_ENOUGH_DATA_FOR_CITY_TEXT}</p>
      )}
    </div>
  );
};

export default function DataView() {
  const dispatch = useDispatch();
  const dataViewRef = useRef();

  const dataView = useSelector((state) => state.newsletter.dataView);

  const isPopupOpen = useSelector(
    (state) => state.promotional.currentPopupIndex
  );

  useHandleCLickOutside({
    reference: dataViewRef,
    callback: () => dispatch(closeDataView()),
    disabled: isPopupOpen,
  });

  const isMobile = useIsMobile(FIRST_BREAKPOINT);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const selectedCity = useSelector((state) => state.newsletter.selectedCity);
  const selectedArea = useSelector((state) => state.newsletter.selectedArea);
  const selectedNeighborhood = useSelector(
    (state) => state.newsletter.selectedNeighborhood
  );

  const areas = useSelector((state) => state.newsletter.areas);
  const cities = useSelector((state) => state.newsletter.cities);

  const dataViewRoomNumber = useSelector(
    (state) => state.newsletter.dataView?.numberOfRooms
  );

  const isBeforeLogin = useSelector(
    (state) => state.newsletter.dataView.isBeforeLogin
  );

  const date = useSelector(currentDate);

  const { data, error, isFetching } = useGetAvgRentForThreeMonthsProtectedQuery(
    {
      currentMonth: date.month,
      year: date.year,
      cityId: selectedCity?.id,
      neighborhoodId: selectedNeighborhood?.id,
      areaId: selectedArea?.id,
      numberOfRooms: dataViewRoomNumber,
    },
    { skip: !dataView.isVisible || !isLoggedIn || !dataViewRoomNumber }
  );

  useEffect(() => {
    if (selectedNeighborhood || !dataView?.isVisible) return;
    if (selectedArea) {
      dispatch(
        setDataView({
          ...selectedArea,
          name: `${selectedArea.name} - ${selectedCity.name}`,
        })
      );
    } else if (selectedCity) {
      dispatch(
        setDataView({
          ...selectedCity,
          name: `${selectedCity.name}`,
        })
      );
    }
  }, [selectedArea, selectedCity, selectedNeighborhood]);

  useEffect(() => {
    if (dataView?.isVisible) {
      dispatch(
        setDataView({
          ...dataView,
          isDateChange: true,
        })
      );
    }
  }, [date]);

  const [isOpen, setIsOpen] = React.useState(false);
  const [boxDisplay, setBoxDisplay] = React.useState("none");

  React.useEffect(() => {
    if (dataView.isVisible) {
      setBoxDisplay("block");
      setTimeout(() => {
        setIsOpen(true);
      }, 0);
    } else {
      setIsOpen(false);
      setTimeout(() => {
        setBoxDisplay("none");
      }, 500);
    }
  }, [dataView.isVisible]);

  return (
    <div
      style={{ display: boxDisplay }}
      tabIndex={dataView.isVisible ? 0 : -1}
      ref={dataViewRef}
      className={`data-view-container ${isOpen ? "data-view-visible" : ""} `}
    >
      <div className="heading-container">
        <h2>{dataView?.title}</h2>
        {isLoggedIn ? (
          <RoomSelect title={ROOM_SELECT_TITLE} isDataView />
        ) : (
          <LoginLine isVisible={dataView?.isVisible} />
        )}
      </div>
      {isBeforeLogin ? (
        <div className="before-login-container">
          <div className="image-container">
            <img src={SEND_TO_LOGIN_IMAGE} />
          </div>
          <p>{BEFORE_LOGIN_CTA}</p>
          <Link
            onClick={() => {
              window.location.href = `${
                config.envName === "local"
                  ? config.authSiteAddr
                  : config.HOMEPAGE_URL
              }${config.envName === "local" ? "" : "/auth"}?continue=${
                window.location.href
              }&service=${RENT_AI_SERVICE_CODE}&feature=${
                RENT_AI_FEATURES_CODE.RENT_AI_MAP
              }`;
            }}
            to={`#`}
            className="search-button"
          >
            {LOGIN_BUTTON_TEXT}
          </Link>
        </div>
      ) : (
        <>
          {isFetching ? (
            <Loader />
          ) : data?.data?.currentMonth?.error ||
            data?.data?.lastMonth?.error ? (
            <NoDataAvailable
              selectedCity={selectedCity}
              selectedArea={selectedArea}
              selectedNeighborhood={selectedNeighborhood}
              dataView={dataView}
              areas={areas}
              cities={cities}
            />
          ) : (
            <>
              <InfoSection
                data={data?.data || dataView?.data}
                isLoading={isFetching}
                roomNumber={dataViewRoomNumber}
              />

              <GraphsSection
                dataViewData={data?.data || dataView?.data}
                currentDate={currentDate}
                isMobile={isMobile}
              />
            </>
          )}

          {isMobile &&
            !data?.data?.currentMonth?.error &&
            !data?.data?.lastMonth?.error && <ScrollIndicator />}
        </>
      )}
      <CloseButton isVisible={dataView?.isVisible} />
    </div>
  );
}
