import React  from "react";
import {Link} from "react-router-dom";
import {URLS} from "../../../../../shared/urls";
import Utils  from "../../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../../components/logo";

const Calc = () => (
	<section className = "CalcSectionOwner" >
		<div className = "background-container" />
		<div className = "content-container" >
			<div className = "right-side-container" >
				<div className = "image-container" >
					<img
						src = {Utils.buildImgLink("mort/Calculator.svg")}
						alt = "מחשבון"
					/>
				</div >
				<h2 >
					<span >מחשבון משכנתא</span >
					<br />
					<span >משלימה</span >
				</h2 >
			</div >
			<div className = "left-side-container" >
				<h3 >
					<span >רוצים להבין עד כמה</span >
					<span >
                        משכנתא משלימה של
                        <Logo color="blue" />
                    </span >

					<span >יכולה לעזור לכם להגיע לנכס שאתם רוצים?</span >
				</h3 >
				<Link
					onClick = {() =>
						Utils.gaEvent(
							"אתר שיווקי -￼ משכנתאות",
							"מחשבון משכנתא - סקשן מחשבון",
							""
						)
					}
					to = {URLS.MortCalc}
				>
					בואו נעשה חשבון פשוט
				</Link >
				<p >
					<span >זמנית, המחשבון שלנו זמין עבור חישובי</span >
					<br />
					<b >משכנתא משלימה בלבד</b >
				</p >
			</div >
		</div >
	</section >
);
export {Calc};
