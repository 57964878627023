import React from "react";
import Container from "../../../../../../../components/product-pages/container";

import Heading from "../../../../../../../components/product-pages/heading";
import Paragraph from "../../../../../../../components/product-pages/paragraph";
import TextSection from "../../../../../../../components/product-pages/text-section";
import { MAIN_HEADER_ICON } from "../../consts";

import { FIRST_PARAGRAPH, MAIN_TITLE, SECONDARY_TITLE, SECOND_PARAGRAPH } from "./consts";

import "./index.scss";

export default function TextContainer() {
    return <Container className="text-container">
        <Container>
            <Heading text={MAIN_TITLE} icon={MAIN_HEADER_ICON}/>
            <Paragraph text={FIRST_PARAGRAPH} />
        </Container>
        <TextSection title={SECONDARY_TITLE} text={SECOND_PARAGRAPH} />
    </Container>
}