import React from "react";

import FirstSectionContainer from "../../../../../components/product-pages/first-section";

import { IMAGE_URL, MAIN_TITLE_ICON } from "./consts";
import { MAIN_TITLE, PARAGRAPH, SECONDARY_TITLE } from "./texts";
import { CTA_BUTTON_TEXT } from "../../texts";

import "./index.scss";
import { ANALYTICS } from "../../consts";

export default function FirstSection() {
    return <FirstSectionContainer mainTitle={MAIN_TITLE}
        mainTitleIcon={MAIN_TITLE_ICON}
        secondaryTitle={SECONDARY_TITLE}
        paragraph={PARAGRAPH}
        ctaButtonText={CTA_BUTTON_TEXT}
        imageUrl={IMAGE_URL}
        analytics={ANALYTICS.FIRST_SECTION.CTA}
    />
}

