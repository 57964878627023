import React from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import { handleInfoDisplay } from "../../../../../../redux/features/newsletterSlice";
import { INFO_IMAGE } from "./consts";

export default function InfoButton() {
    const dispatch = useDispatch();

    const handleDisplay = () => {
        dispatch(handleInfoDisplay());
    };

    return (
        <button onClick={handleDisplay} className="info-button">
            <img src={INFO_IMAGE} />
        </button>
    );
}
