import React, {Component} from "react";

import Utils from "../../../../../shared/utils";

import "./index.scss";

const Articles = [
	{
		img      : "globs2.png",
		url      : "https://www.globes.co.il/news/article.aspx?did=1001419872",
		eventName: "כתבו גלובס 2"
	},
	{
		img      : "themarker2.png",
		url      : "https://www.themarker.com/realestate/2022-10-27/ty-article-magazine/.premium/00000184-0e37-d465-adc6-0eb793440000",
		eventName: "כתבו דה מרקר 2"
	},
	{
		img      : "ynet2.png",
		url      : "https://www.ynet.co.il/economy/article/hyapjct11i",
		eventName: "כתבו ynet 2"
	},
	{
		img      : "tzahi.png",
		url      : "https://open.spotify.com/episode/3RqX15lw9EmK4hHiugdZTh",
		eventName: "כתבו צחי"
	},
	{
		img      : "reshetb.png",
		url      : "https://www.facebook.com/WeCheck.4U/videos/1023372118601127",
		eventName: "כתבו רשת ב"
	},
	{
		img      : "nadlan2.png",
		url      : "https://www.youtube.com/watch?v=wuWRDVDlC-U&fbclid=IwAR0IHZGHbc-1VZBZziym7qBSTIj-ELUvNwbHCkOju90tKtTGi6lzWiTmFO8",
		eventName: "כתבו נדלן משפחתי"
	},
	{
		img      : "ice.png",
		url      : "https://www.ice.co.il/digital-140/news/article/843379?fbclid=IwAR0thW9muJDi_qFed5yoPV2tKX1cV2KenZjRCDIcQX0w31kq-4O5tZGxnkE",
		eventName: "כתבו אייס"
	},
	{
		img      : "history2.png",
		url      : "https://open.spotify.com/episode/1jawOoGUl7TYx8Mp1FXC5i?si=44tOMd2dREejNcF8VvZJmw&nd=1&fbclid=IwAR0lrWmgqSWIEqT37aLzG5iv0WHMVMDCOS9f4h7XCPhVi1mR8CAN-d2Tmcs",
		eventName: "כתבו היסטוריה 2"
	},
	{
		img      : "themarker.png",
		url      : "https://www.themarker.com/realestate/.premium-1.9565987?v=6D6ED3C7299A9777E532ACB7155DEBCD",
		eventName: "כתבו דהמרקר"
	},
	{
		img      : "nadlan.png",
		url      : "https://www.nadlancenter.co.il/article/2305",
		eventName: "כתבו מרכזנדלן"
	},
	{
		img      : "ynet.png",
		url      : "https://www.ynet.co.il/articles/0,7340,L-5474703,00.html",
		eventName: "כתבו וואינדלן"
	},
	{
		img      : "hayom.png",
		url      : "https://www.israelhayom.co.il/business/article/4828881/",
		eventName: "כתבו ישראל היום"
	},
	{
		img      : "spotify.png",
		url      : " https://open.spotify.com/episode/4ghia0CbFVCaaEeKBi8S2c?fbclid=IwAR2WPGsrnv1V6O1TjTvRxhWWpsBprBE-_ksyVvue3NClGN-VuPovV5rZc2Y",
		eventName: "כתבו ספוטיפי"
	},
	{
		img      : "globes.png",
		url      : "https://www.globes.co.il/news/article.aspx?did=1001333055",
		eventName: "כתבו גלובס"
	},
	{
		img      : "history.png",
		url      : "https://www.osimhistoria.com/dtech/ep02-wecheck",
		eventName: "כתבו היסטוריה"
	},
	{
		img      : "mako.png",
		url      : "https://www.mako.co.il/finances-finances-economy-newcast/q2_2019/Article-8ea82800de82b61027.htm?partner=mobileAutomaticChannel",
		eventName: "כתבו מאקו"
	},
	{
		img      : "yad2.png",
		url      : "https://magazine.yad2.co.il/real-estate/%D7%91%D7%A2%D7%9C%D7%99%D7%9D-%D7%A9%D7%9C-%D7%93%D7%99%D7%A8%D7%94-%D7%9C%D7%94%D7%A9%D7%9B%D7%A8%D7%94-%D7%99%D7%A9-%D7%9B%D7%9E%D7%94-%D7%93%D7%91%D7%A8%D7%99%D7%9D-%D7%A9%D7%9B%D7%93%D7%90%D7%99/16926",
		eventName: "כתבו יד2"
	},
	{
		img      : "calcalist.png",
		url      : "https://www.calcalist.co.il/internet/articles/0,7340,L-3738928,00.html",
		eventName: "כתבו כלכליסט"
	},
	{
		img      : "themarker3.png",
		url      : "https://www.themarker.com/realestate/2023-01-22/ty-article-magazine/.premium/00000185-c9e9-d73c-a9f7-f9fbe7080000?utm_source=App_Share&utm_medium=iOS_Native",
		eventName: "כתבו דהמרקר 3"
	},
	{
		img      : "pocketgames.png",
		url      : "https://www.kan.org.il/Item/?itemId=143481",
		eventName: "כתבו משחקי הכיס"
	},
	{
		img      : "calcalist2.png",
		url      : "https://www.calcalist.co.il/real-estate/article/bjgtkxrwi",
		eventName: "כתבו כלכליסט 2"
	},
	{
		img      : "themarker4.png",
		url      : "https://www.themarker.com/realestate/2022-12-04/ty-article/.premium/00000184-d986-dc05-adae-fff73aaa0000",
		eventName: "כתבו דהמרקר 4"
	}
];

class ThePress extends Component {
	constructor(props) {
		super(props);

		this.articles = [];

		for (let i = 0; i < 15; i ++) {
			this.articles = [...Articles, ...this.articles];
		}

		this.pos = {top: 0, left: 0, x: 0, y: 0};
	}

	componentDidMount() {
		this.ele = document.getElementById("prcontainer");
		this.ele.scrollBy(- 1000000, 0);

		this.ele.addEventListener("mousedown", this.mouseDownHandler);
	}

	mouseDownHandler = (e) => {
		this.pos = {
			// The current scroll
			left: this.ele.scrollLeft,
			top : this.ele.scrollTop,
			// Get the current mouse position
			x: e.clientX,
			y: e.clientY
		};

		this.ele.style.userSelect = "none";

		document.addEventListener("mousemove", this.mouseMoveHandler);
		document.addEventListener("mouseup", this.mouseUpHandler);
	};

	mouseMoveHandler = (e) => {
		// How far the mouse has been moved
		const dx = e.clientX - this.pos.x;
		const dy = e.clientY - this.pos.y;

		// Scroll the element
		this.ele.scrollTop  = this.pos.top - dy;
		this.ele.scrollLeft = this.pos.left - dx;

		this.ignoreClick = true;
	};

	mouseUpHandler = (e) => {
		e.preventDefault();
		this.ele.style.removeProperty("user-select");

		document.removeEventListener("mousemove", this.mouseMoveHandler);
		document.removeEventListener("mouseup", this.mouseUpHandler);
	};

	handleLinkClick = (e) => {
		if (this.ignoreClick) {
			e.preventDefault();
		}

		this.ignoreClick = false;
	};

	render = () => {
		return (
			<div className = "ThePressSection" >
				<h1 >כתבו עלינו</h1 >
				<div className = "pressImages" id = "prcontainer" >
					{this.articles.map((article, index) => (
						<a
							href = {article.url}
							target = "_blank"
							onClick = {this.handleLinkClick}
						>
							<div
								key = {index}
								onClick = {() =>
									Utils.gaEvent(
										"עמוד ראשי",
										"",
										article.eventName
									)
								}
							>
								<img
									src = {Utils.buildPrImgLink(article.img)}
									alt = ""
								/>
							</div >
						</a >
					))}
				</div >
				<div
					className = "SectionWrapper__brikim"
					data-font-size = "16"
					data-font-size-type = "px"
				></div >
			</div >
		);
	};
}

export {ThePress};
