import React, { useState } from "react";

import { ErrorMessage, useField } from "formik";

import Container from "../../../container";

import "./index.scss";

export default function Select ({ options, label, ...props }) {
    const [field, meta, helpers] = useField(props.name);
    const [isListOpen, setIsListOpen] = useState();
    const [currentDisplayedValue, setCurrentDisplayedValue] = useState('');

    const { value } = meta;
    const { setValue } = helpers;

    const handleSelect = (option) => {
        setValue(option.VALUE)
        setCurrentDisplayedValue(option.LABEL)
        setIsListOpen(false)
    }

    return (
        <Container onClick={() => setIsListOpen(!isListOpen)} className="form-select-container">
            <input {...field} {...props} />
            <label>{label}</label>
            <div className="select-value">{currentDisplayedValue}</div>
            <Container className={`options-list ${isListOpen ? "is-open" : ""}`}>
                {
                    options.map((option, index) => {
                        return <div key={index} onClick={() => handleSelect(option)}>{option.LABEL}</div>
                    })
                }
            </Container>
            <ErrorMessage className="error-message" name={props.name} component="p"/>
        </Container>
    );
};