import React from "react";

import FirstSectionContainer from "../../../../../components/product-pages/first-section";

import { URLS } from "../../../../../shared/urls";
import { ANALYTICS } from "../../consts";
import { IMAGE_URL, MAIN_TITLE_ICON } from "./consts";
import {
  CTA_BUTTON_TEXT,
  MAIN_SUBTITLE,
  MAIN_TITLE,
  PARAGRAPH,
  SECONDARY_TITLE,
  SECONDARY_PARAGRAPH,
  SECONDARY_PARAGRAPH_TITLE,
} from "./texts";

export default function FirstSection() {
  return (
    <FirstSectionContainer
      mainTitle={MAIN_TITLE}
      className="white"
      mainTitleIcon={MAIN_TITLE_ICON}
      secondaryTitle={SECONDARY_TITLE}
      secondaryParagraphTitle={SECONDARY_PARAGRAPH_TITLE}
      secondaryParagraph={SECONDARY_PARAGRAPH}
      paragraph={PARAGRAPH}
      imageUrl={IMAGE_URL}
      ctaButtonText={CTA_BUTTON_TEXT}
      mainSubtitle={MAIN_SUBTITLE}
      CTALink={URLS.MortgageQue}
      analytics={ANALYTICS.FIRST_SECTION.CTA}
    />
  );
}
