import React     from "react";

import "./css/Input.scss";

class Input extends React.Component {
	static RegexFilters = {
		numbersOnly: /[^0-9.]/
	};

	static defaultProps = {
		type    : "text",
		disabled: false
	};

	constructor(props) {
		super(props);

		this.state = {
			focused: false,
			value  : props.value ? props.value : ""
		};
	}

	componentDidUpdate = () => this.checkFocus();

	componentDidMount = () => this.checkFocus();

	componentWillReceiveProps(nextProps) {
		if (nextProps.valueControlled && this.state.value !== nextProps.value) {
			this.setState({value: nextProps.value});

			if (nextProps.onChange) {
				nextProps.onChange({
					name : this.props.name,
					value: nextProps.value
				});
			}
		}
	}

	checkFocus = () => {
		const focusedState = this.state.value !== "" && this.state.value !== undefined && this.state.value !== null;

		if (!this.state.focused && focusedState) {
			this.setState({focused: focusedState});
		}
	};

	handleBlur = e => {
		this.setState({
			focused: e.target.value !== ""
		});

		if (this.props.onBlur) {
			this.props.onBlur({
				name : this.props.name,
				value: e.target.value
			});
		}
	};

	handleChange = e => {
		let value = e.target.value;
		if (this.props.regexFilter) {
			value = value.replace(this.props.regexFilter, "");
		}

		this.setState({value});

		if (this.props.onChange) {
			this.props.onChange({
				name: this.props.name,
				value
			});
		}
	};

	handleFocus = () => this.setState({focused: true});

	render() {
		const {focused, value}                                                               = this.state;
		const {name, type, placeholder, error, disabled, readOnly, htmlPlaceholder, onClick,autofocus} = this.props;

		let className = "";
		if (!this.props.simple) {
			className = "inputEx";
		}

		return <div style = {this.props.divStyle ? this.props.divStyle : {}}
		            className = {`wckForm__input ${disabled ? "wckForm__input--disabled" : ""} ${error ? "wckForm__input--error" : ""}`} >
			<input className = {className}
			       style = {this.props.style ? this.props.style : placeholder ? {} : {paddingTop: "0"}}
			       value = {value === null ? "" : value}
			       name = {name}
			       type = {type ? type : "text"}
			       autoFocus = {autofocus ? 1 : 0}
			       id = {name}
			       disabled = {disabled}
			       onClick = {onClick}
			       placeholder = {htmlPlaceholder}
			       readOnly = {readOnly}
			       onChange = {this.handleChange}
			       onBlur = {this.handleBlur}
			       onFocus = {this.handleFocus}
			       autoComplete = "off" />
			<span className = "wckForm__input__focusBorder" />
		</div >;
	}
}

export {Input};
