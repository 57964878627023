import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RENT_PRICELIST_MAP_SERVICE } from "../consts";
import config from "../../config/config";

export const newsletterApi = createApi({
  reducerPath: "newsletterApi",
  baseQuery: fetchBaseQuery({
    baseUrl: config.serverlessAddr
      ? config.serverlessAddr
      : config.RENT_PRICELIST_MAP_LOCAL,
  }),
  endpoints: (builder) => ({
    getCities: builder.query({
      query: () => {
        return `${RENT_PRICELIST_MAP_SERVICE}/cities`;
      },
    }),
    getAreas: builder.query({
      query: () => {
        return `${RENT_PRICELIST_MAP_SERVICE}/areas`;
      },
    }),
    getNeighborhoods: builder.query({
      query: () => {
        return `${RENT_PRICELIST_MAP_SERVICE}/neighborhoods`;
      },
    }),
    getAvgRentForThreeMonths: builder.query({
      query: () => {
        return {
          url: `${RENT_PRICELIST_MAP_SERVICE}/calc/avg-rent-three-months`,
          method: "GET",
        };
      },
    }),
  }),
});

export const {
  useGetCitiesQuery,
  useGetAreasQuery,
  useGetNeighborhoodsQuery,
  useGetAvgRentForThreeMonthsQuery,
} = newsletterApi;
