import React, { useState } from "react";
import "./index.scss";

export const PlusMinusToggle = ({ isOpen }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`plus ${isOpen ? "minus" : ""}`}
      width="10"
      height="10"
      viewBox="0 0 160 160"
      fill="#1d3557"
      stroke="#1d3557"
    >
      <rect className="vertical-line" x="70" width="20" height="160" />
      <rect className="horizontal-line" y="70" width="160" height="20" />
    </svg>
  );
};
