import React from "react";

import AnimationContainer from "../animation-container";
import Container from "../container";
import HeadingMD from "../heading-md";

import "./index.scss";

export default function AnimationAndText({animation, text}) {
    return <Container className="animation-and-text">
        <AnimationContainer img={animation} />
        <HeadingMD text={text} />
    </Container>
}