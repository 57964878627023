import Slider             from "@appigram/react-rangeslider";

import "@appigram/react-rangeslider/lib/index.css";
import axios              from "axios";
import React, {Component} from "react";
import {findDOMNode}      from "react-dom";
import ReactTooltip       from "react-tooltip";

import config           from "../../../../../../config/config";
import {CSDrop, CSItem} from "../../../../../components/c-select";
import {LogoLine}       from "../../../../../components/logo-line";
import {URLS}           from "../../../../../shared/urls";
import Utils            from "../../../../../shared/utils";
import {MortEnums}      from "../../../../enums/MortEnums";
import "./index.scss";

const PurchaseTypeText = ["נכס יחיד", "משפר דיור", "נכס להשקעה"];

const PurchaseTypeExitingText = ["נכס יחיד", "מספר נכסים"];

const ComputeText = ["ההון העצמי שלי", "ההחזר החודשי שלי", "שווי הנכס"];

const progressCaption = ["הון עצמי", "החזר חודשי", "שווי הנכס"];

const progressTip = [
	"הון עצמי - ההון העצמי הוא הסכום שיש ברשותכם להשקעה בנכס, בטרם לקיחת המשכנתא",
	"החזר חודשי - ההחזר החודשי הכולל שתוכלו לשלם בחודש",
	"שווי הנכס - מחיר הנכס שתרצו לקנות"
];

const MaxMinValues = [
	{
		min  : 105000,
		max  : 1500000,
		jumps: 10000,
		error: "טווחים 105,000 - 1,500,000"
	},
	{
		min  : 3000,
		max  : 30000,
		jumps: 1000,
		error: "טווחים 3,000 - 30,000"
	},
	{
		min  : 1000000,
		max  : 7000000,
		jumps: 100000,
		error: "טווחים 1,000,000 - 7,000,000"
	}
];

let RemainMortLimits = {
	min  : 100000,
	max  : 4000000,
	jumps: 50000,
	error: "טווחים 100,000 - 4,000,000"
};

class CalcSettings extends Component {
	constructor(props) {
		super(props);

		const minSelfFortune = Math.round(
			props.mortgageLimits.mortgageSelfFortuneFactorMin *
			props.mortgageLimits.mortgagePropertyValueMin
		);

		MaxMinValues[0] = {
			min  : minSelfFortune,
			max  : props.mortgageLimits.mortgagePropertyValueMax,
			jumps: 100000,
			error: `טווחים  ${Utils.numberWithComma(
				minSelfFortune
			)}-${Utils.numberWithComma(
				props.mortgageLimits.mortgagePropertyValueMax
			)}`
		};

		MaxMinValues[1] = {
			min  : props.mortgageLimits.mortgageMonthlyPayMin,
			max  : props.mortgageLimits.mortgageMonthlyPayMax,
			jumps: 1000,
			error: `טווחים  ${Utils.numberWithComma(
				props.mortgageLimits.mortgageMonthlyPayMin
			)}-${Utils.numberWithComma(
				props.mortgageLimits.mortgageMonthlyPayMax
			)}`
		};

		MaxMinValues[2] = {
			min  : props.mortgageLimits.mortgagePropertyValueMin,
			max  : props.mortgageLimits.mortgagePropertyValueMax,
			jumps: 100000,
			error: `טווחים  ${Utils.numberWithComma(
				props.mortgageLimits.mortgagePropertyValueMin
			)}-${Utils.numberWithComma(
				props.mortgageLimits.mortgagePropertyValueMax
			)}`
		};

		RemainMortLimits = {
			min  : 0,
			max  : props.mortgageLimits.currMortgageMax,
			jumps: 50000,
			error: `טווחים  ${0}-${Utils.numberWithComma(
				props.mortgageLimits.currMortgageMax
			)}`
		};

		this.state = {
			propertyType  : props.propertyType,
			purchaseType  : props.purchaseType,
			calcType      : props.calcType,
			limits        : MaxMinValues[props.calcType],
			sum           : 0,
			remainSum     : 0,
			sumError      : false,
			remainSumError: false,
			haveResult    : false
		};
	}

	componentDidMount = () =>
		Utils.gaVirtual("משכנתא משלימה/מחשבון משכנתא/חישוב משכנתא");

	setPropertyType = (propertyType) => {
		if (propertyType === MortEnums.PropertyType.Existing) {
			this.setState({
				propertyType,
				calcType  : MortEnums.CalcType.PropertyValue,
				sum       : 0,
				limits    : MaxMinValues[MortEnums.CalcType.PropertyValue],
				haveResult: false
			});
		}
		else {
			this.setState({
				propertyType,
				sum         : 0,
				purchaseType: 0,
				haveResult  : false
			});
		}
	};

	setExistingPurchaseType = (value) => {
		const index = PurchaseTypeExitingText.findIndex(
			(text) => text === value
		);
		this.setState({purchaseType: index, haveResult: false});
		setTimeout(() => this.getResultsFromServer(), 0);
	};

	setPurchaseType = (value) => {
		const index = PurchaseTypeText.findIndex((text) => text === value);
		this.setState({purchaseType: index, haveResult: false});
		setTimeout(() => this.getResultsFromServer(), 0);
	};

	setCalcType = (value) => {
		const index = ComputeText.findIndex((text) => text === value);
		this.setState({
			calcType  : index,
			limits    : MaxMinValues[index],
			haveResult: false,
			sum       : 0
		});
	};

	handleSliderSumChange = (value) => {
		if (!isNaN(value)) {
			this.setState({sum: value, sumError: false});
		}
	};

	checkValidDataForServer = () => {
		let validData;

		const {sum, limits, remainSum} = this.state;

		const sumInt       = parseInt(sum);
		const remainSumInt = parseInt(remainSum);

		validData        = sumInt >= limits.min && sumInt <= limits.max;
		const isExisting =
			      this.state.propertyType === MortEnums.PropertyType.Existing;
		if (isExisting) {
			validData =
				validData &&
				remainSumInt >= RemainMortLimits.min &&
				remainSumInt <= RemainMortLimits.max;
		}

		return validData;
	};

	getResultsFromServer = async () => {
		if (this.checkValidDataForServer()) {
			let url;

			const isNew =
				      this.state.propertyType === MortEnums.PropertyType.New;

			if (isNew) {
				switch (this.state.calcType) {
					case MortEnums.CalcType.MyMoney:
						url = `/deal/${this.state.purchaseType}/selfFort/${this.state.sum}/calcMortBySelfFortune`;
						break;

					case MortEnums.CalcType.Monthly:
						url = `/deal/${this.state.purchaseType}/monthPay/${this.state.sum}/calcMortByMonthlyPayback`;
						break;

					case MortEnums.CalcType.PropertyValue:
						url = `/deal/${this.state.purchaseType}/propertyVal/${this.state.sum}/calcMortByPropertyValue`;
						break;

					default:
						return;
				}
			}
			else {
				this.setState({haveResult: false});
				const assetType =
					      this.state.purchaseType ===
					      MortEnums.PurchaseType.FirstProperty
					      ? MortEnums.AssetType.Single
					      : MortEnums.AssetType.Investment;

				url = `/asset/${assetType}/currMort/${this.state.sum}/leftMort/${this.state.remainSum}/calcMortAnotherMort`;
			}
			const response = await axios.get(config.mortServerAddr + url);
			if (response.data) {
				const {
					      maxPossiblePropertyValue,
					      maxPossiblePropertyValueWithWeCheckLoan,
					      weCheckLoanAmount,
					      weCheckLoanMonthlyPayback,
					      propertyValue,
					      selfFortune,
					      selfFortuneRequired,
					      selfFortuneRequiredWithWeCheckLoan,
					      mortBankMonthlyPaybackWithWeCheckLoan,
					      weCheckMaxMortgageAmount,
					      weCheckMortgageMonthlyPayback,
					      propertyValueNoWCK
				      } = response.data;

				let myHon;
				switch (this.state.calcType) {
					case MortEnums.CalcType.MyMoney:
						myHon = weCheckLoanAmount + this.state.sum;
						break;

					case MortEnums.CalcType.Monthly:
						myHon = selfFortune + weCheckLoanAmount;
						break;

					case MortEnums.CalcType.PropertyValue:
						break;
				}

				this.setState({
					haveResult              : true,
					propertyValue           : propertyValue
					                          ? propertyValue
					                          : maxPossiblePropertyValue,
					honWithWecheck          : myHon,
					propertyValueWithWeCheck: Math.round(
						maxPossiblePropertyValueWithWeCheckLoan
					),
					loanAmount              : weCheckLoanAmount,
					monthlyPay              :
						weCheckLoanMonthlyPayback +
						mortBankMonthlyPaybackWithWeCheckLoan,
					minimalHon              : selfFortune,
					requiredHon             : selfFortuneRequired,
					requiredHonWithWecheck  : selfFortuneRequiredWithWeCheckLoan,
					weCheckMaxMortgageAmount,
					weCheckMortgageMonthlyPayback,
					propertyValueNoWCK
				});
			}
		}
	};

	sumChanged = (e) => {
		const value      = e.target.value;
		const cleanValue = parseInt(Utils.removeNumberCommas(value));

		if (cleanValue > this.state.limits.max) {
			this.setState({sum: "", sumError: true, haveResult: false});
		}
		else {
			this.setState({sum: cleanValue, sumError: false});
			setTimeout(() => this.getResultsFromServer(), 0);
		}
	};

	handleSliderRemainSumChange = (value) => {
		if (!isNaN(value)) {
			this.setState({remainSum: value, sumError: false});
		}
	};

	remainSumChanged = (e) => {
		const value      = e.target.value;
		const cleanValue = parseInt(Utils.removeNumberCommas(value));

		if (cleanValue > RemainMortLimits.max) {
			this.setState({remainSum: "", remainSumError: true});
		}
		else {
			this.setState({remainSum: cleanValue, remainSumError: false});
			setTimeout(() => this.getResultsFromServer(), 0);
		}
	};

	progressScreen = () => {
		const isNew = this.state.propertyType === MortEnums.PropertyType.New;
		return (
			<div className = "progressSection" >
				<h5 >
					{progressCaption[this.state.calcType]}
					<span
						ref = {(node) => (this.elementRef = node)}
						data-tip = "tooltip1"
						data-delay-hide = "100"
						data-for = "second-tip"
						onClick = {() => {
							ReactTooltip.hide(findDOMNode(this.elementRef));
						}}
					>
                        <img
	                        className = "smallImg"
	                        src = {Utils.buildDashboardImgLink(
		                        "mortgage/tiptool.svg"
	                        )}
                        ></img >
                    </span >
					<ReactTooltip
						id = "second-tip"
						event = {window.innerWidth < 480 ? "click" : ""}
						type = "info"
						multiline
						data-event-off = "mouseleave"
						delayHide = {100}
						class = "toolTip"
						effect = "solid"
					>
						<p className = "toolTip" >
							{progressTip[this.state.calcType]}
						</p >
					</ReactTooltip >
				</h5 >
				<div className = "slider" >
					<Slider
						min = {this.state.limits.min}
						max = {this.state.limits.max}
						value = {this.state.sum}
						step = {this.state.limits.jumps}
						tooltip = {false}
						onChange = {this.handleSliderSumChange}
						onChangeComplete = {this.getResultsFromServer}
					/>
				</div >
				<input
					id = "inputField"
					autoFocus
					type = "text"
					name = "inputField"
					value = {Utils.numberWithComma(this.state.sum)}
					className = {this.state.sumError ? "inputError" : ""}
					placeholder = {
						this.state.sumError
						? this.state.limits.error
						: "או הקלידו את הסכום"
					}
					onChange = {this.sumChanged}
				/>

				{!isNew && (
					<>
						<h5 >
							יתרת משכנתא קיימת
							<span
								ref = {(node) => (this.elementRef = node)}
								data-tip = "tooltip"
								data-delay-hide = "100"
								data-for = "second-tip3"
								onClick = {() => {
									ReactTooltip.hide(
										findDOMNode(this.elementRef)
									);
								}}
							>
                                <img
	                                className = "smallImg"
	                                src = {Utils.buildDashboardImgLink(
		                                "mortgage/tiptool.svg"
	                                )}
                                ></img >
                            </span >
							<ReactTooltip
								id = "second-tip3"
								event = {window.innerWidth < 480 ? "click" : ""}
								type = "info"
								multiline
								data-event-off = "mouseleave"
								delayHide = {100}
								class = "toolTip"
								effect = "solid"
							>
								<p className = "toolTip" >
									יתרת משכנתא קיימת - סכום המשכנתא הנותר
									להלוואה.
								</p >
							</ReactTooltip >
						</h5 >
						<div className = "slider" >
							<Slider
								min = {RemainMortLimits.min}
								max = {RemainMortLimits.max}
								value = {this.state.remainSum}
								step = {RemainMortLimits.jumps}
								tooltip = {false}
								onChange = {this.handleSliderRemainSumChange}
								onChangeComplete = {this.getResultsFromServer}
							/>
						</div >
						<input
							id = "inputField"
							autoFocus
							type = "text"
							name = "inputField"
							value = {Utils.numberWithComma(this.state.remainSum)}
							className = {
								this.state.remainSumError ? "inputError" : ""
							}
							placeholder = {
								this.state.remainSumError
								? RemainMortLimits.error
								: "או הקלידו את הסכום"
							}
							onChange = {this.remainSumChanged}
						/>
					</>
				)}
			</div >
		);
	};

	settingsScreen = () => {
		const isNew = this.state.propertyType === MortEnums.PropertyType.New;

		return (
			<div className = "CalcSettings" >
				<div className = "assetChoices" >
					<div className = "propertyButtons" >
						<div
							onClick = {() =>
								this.setPropertyType(MortEnums.PropertyType.New)
							}
							className = {
								isNew
								? "tabDiv tabDiv--blue"
								: "tabDiv tabDiv--lightBlue"
							}
						>
							<p >נכס חדש</p >
						</div >

						<div
							onClick = {() =>
								this.setPropertyType(
									MortEnums.PropertyType.Existing
								)
							}
							className = {
								this.state.propertyType ===
								MortEnums.PropertyType.Existing
								? "tabDiv tabDiv--blue"
								: "tabDiv tabDiv--lightBlue"
							}
						>
							<p >נכס קיים</p >
						</div >
					</div >
					<div className = "dealType" >
						{isNew ? (
							<div className = "dealTypeButtons" >
								<div className = "comboDiv" >
									<p >משכנתא עבור</p >
									<CSDrop
										id = "propertyTypeList"
										default = {
											PurchaseTypeText[
												this.state.purchaseType
												]
										}
										onChange = {(value) =>
											this.setPurchaseType(value)
										}
									>
										{PurchaseTypeText.map(
											(reason, index) => (
												<CSItem
													value = {reason}
													key = {index}
												>
													{reason}
												</CSItem >
											)
										)}
									</CSDrop >
								</div >
								<div className = "comboDivLeft comboDivLong" >
									<p >לחשב לפי</p >
									<CSDrop
										default = {
											ComputeText[this.state.calcType]
										}
										onChange = {(value) =>
											this.setCalcType(value)
										}
									>
										{ComputeText.map((reason, index) => (
											<CSItem value = {reason} key = {index} >
												{reason}
											</CSItem >
										))}
									</CSDrop >
								</div >
							</div >
						) : (
							 <div className = "dealTypeButtons" >
								 <div className = "comboDivLong" >
									 <p >משכנתא עבור</p >
									 <CSDrop
										 id = "propertyTypeList1"
										 default = {
											 PurchaseTypeExitingText[
												 this.state.purchaseType
												 ]
										 }
										 onChange = {(value) =>
											 this.setExistingPurchaseType(value)
										 }
									 >
										 {PurchaseTypeExitingText.map(
											 (reason, index) => (
												 <CSItem
													 value = {reason}
													 key = {index}
												 >
													 {reason}
												 </CSItem >
											 )
										 )}
									 </CSDrop >
								 </div >
							 </div >
						 )}
					</div >
				</div >
			</div >
		);
	};

	reportQueEvent = () => {
		Utils.gaEvent("מחשבון משכנתא", "לבדיקת זכאות", "");
		Utils.gaEvent("הגעה לשאלון משכנתאות", "מחשבון משכנתאות", "");
	};

	getResultsScreen = () => {
		let {
			    haveResult,
			    loanAmount,
			    propertyValue,
			    honWithWecheck,
			    propertyValueWithWeCheck,
			    monthlyPay,
			    minimalHon,
			    requiredHon,
			    requiredHonWithWecheck,
			    weCheckMaxMortgageAmount,
			    weCheckMortgageMonthlyPayback,
			    propertyValueNoWCK
		    } = this.state;

		const sum = Utils.numberWithComma(this.state.sum);

		if (haveResult) {
			weCheckMaxMortgageAmount      = weCheckMaxMortgageAmount
			                                ? Utils.numberWithComma(weCheckMaxMortgageAmount)
			                                : "";
			weCheckMortgageMonthlyPayback = weCheckMortgageMonthlyPayback
			                                ? Utils.numberWithComma(weCheckMortgageMonthlyPayback)
			                                : "";
			honWithWecheck                = honWithWecheck
			                                ? Utils.numberWithComma(honWithWecheck)
			                                : "";
			requiredHonWithWecheck        = requiredHonWithWecheck
			                                ? Utils.numberWithComma(requiredHonWithWecheck)
			                                : "";

			if (this.state.calcType === MortEnums.CalcType.MyMoney) {
				requiredHon = sum;
			}
			else {
				requiredHon = requiredHon
				              ? Utils.numberWithComma(requiredHon)
				              : "";
			}

			if (this.state.calcType === MortEnums.CalcType.Monthly) {
				monthlyPay  = sum;
				requiredHon = Utils.numberWithComma(minimalHon);
			}
			else {
				monthlyPay = monthlyPay
				             ? Utils.numberWithComma(monthlyPay)
				             : "";
			}

			loanAmount               = loanAmount ? Utils.numberWithComma(loanAmount) : "";
			propertyValue            = propertyValue
			                           ? Utils.numberWithComma(propertyValue)
			                           : "";
			propertyValueWithWeCheck = propertyValueWithWeCheck
			                           ? Utils.numberWithComma(propertyValueWithWeCheck)
			                           : "";
			propertyValueNoWCK       = propertyValueNoWCK
			                           ? Utils.numberWithComma(propertyValueNoWCK)
			                           : "";
		}
		else {
			loanAmount                    = undefined;
			propertyValue                 = undefined;
			honWithWecheck                = undefined;
			propertyValueWithWeCheck      = undefined;
			monthlyPay                    = undefined;
			minimalHon                    = undefined;
			requiredHon                   = undefined;
			requiredHonWithWecheck        = undefined;
			weCheckMaxMortgageAmount      = undefined;
			weCheckMortgageMonthlyPayback = undefined;
			propertyValueNoWCK            = undefined;
		}

		const disableMyHon       = this.state.calcType === MortEnums.CalcType.MyMoney;
		const disableMonthlyPage =
			      this.state.calcType === MortEnums.CalcType.Monthly;
		const disablePropertyVal =
			      this.state.calcType === MortEnums.CalcType.PropertyValue;

		const isNew            = this.state.propertyType === MortEnums.PropertyType.New;
		const isByMonthlyValye =
			      this.state.calcType === MortEnums.CalcType.Monthly;

		const PropertyValueWithWecheck = (
			<div className = "textAndResultBox" >
				<LogoLine before = "שווי הנכס עם " />
				{requiredHonWithWecheck ||
				 propertyValueWithWeCheck ||
				 propertyValue ? (
					 <p
						 className = {
							 "boldGreen" +
							 (disablePropertyVal ? " disableField" : "")
						 }
					 >
						 {requiredHonWithWecheck
						  ? sum
						  : propertyValueWithWeCheck
						    ? propertyValueWithWeCheck
						    : propertyValue}{" "}
						 &#8362;
					 </p >
				 ) : (
					 <p ></p >
				 )}
			</div >
		);

		const EstimatedMonthlyReturn = (
			<div className = "desktopOnly textAndResultBox" >
				<LogoLine before = "החזר חודשי משוער - כולל לבנק ול- " />
				{monthlyPay ? (
					<p className = {disableMonthlyPage ? "disableField" : ""} >
						{monthlyPay} &#8362;
					</p >
				) : (
					 <p ></p >
				 )}
			</div >
		);

		const EstimatedMonthlyReturnMobile = (
			<div className = "mobileOnly textAndResultBox" >
				<h3 >החזר חודשי משוער</h3 >
				{monthlyPay ? (
					<p className = {disableMonthlyPage ? "disableField" : ""} >
						{monthlyPay} &#8362;
					</p >
				) : (
					 <p ></p >
				 )}
				<LogoLine before = "החזר כולל לבנק ול- " />
			</div >
		);

		return (
			<div className = "resultsSection" >
				{isNew ? (
					<>
						<div className = "textAndResultBox" >
							<LogoLine before = "משכנתא משלימה" />
							{loanAmount ? <p >{loanAmount} &#8362;</p > : <p ></p >}
						</div >
						<div className = "twoBoxes" >
							<div className = "textAndResultBox" >
								<h3 >הון עצמי</h3 >
								{requiredHon ? (
									<p
										className = {
											disableMyHon ? "disableField" : ""
										}
									>
										{requiredHon} &#8362;
									</p >
								) : (
									 <p ></p >
								 )}
							</div >
							<div className = "textAndResultBox" >
								<LogoLine before = "הון עצמי + " />
								{requiredHonWithWecheck || honWithWecheck ? (
									<p className = "boldGreen" >
										{requiredHonWithWecheck
										 ? requiredHonWithWecheck
										 : honWithWecheck}{" "}
										&#8362;
									</p >
								) : (
									 <p className = "boldGreen" ></p >
								 )}
							</div >
						</div >
						<div className = "twoBoxes" >
							{!isByMonthlyValye && (
								<div className = "textAndResultBox" >
									<LogoLine before = "שווי הנכס ללא " />
									{requiredHonWithWecheck ||
									 propertyValueWithWeCheck ||
									 propertyValueNoWCK ? (
										 <p
											 className = {
												 disablePropertyVal
												 ? "disableField"
												 : ""
											 }
										 >
											 {requiredHonWithWecheck
											  ? sum
											  : propertyValueWithWeCheck
											    ? propertyValue
											    : propertyValueNoWCK}{" "}
											 &#8362;
										 </p >
									 ) : (
										 <p ></p >
									 )}
								</div >
							)}
							{PropertyValueWithWecheck}
							{isByMonthlyValye && EstimatedMonthlyReturn}
							{isByMonthlyValye && EstimatedMonthlyReturnMobile}
						</div >
						{!isByMonthlyValye && (
							<div className = "twoBoxes" >
								<div ></div >
								{EstimatedMonthlyReturn}
								{EstimatedMonthlyReturnMobile}
							</div >
						)}
						{this.state.haveResult ? (
							<div className = {"offer btn--oldred"} >
								<a
									href = {URLS.MortgageQue}
									onClick = {this.reportQueEvent}
								>
									<span >לבדיקת זכאות </span >
									<i className = "arrow left" ></i >
								</a >
							</div >
						) : (
							 ""
						 )}
					</>
				) : (
					 <>
						 <div className = "textAndResultBox" >
							 <LogoLine before = "משכנתא משלימה" />
							 {weCheckMaxMortgageAmount ? (
								 <p className = "boldGreen" >
									 {weCheckMaxMortgageAmount} &#8362;
								 </p >
							 ) : (
								  <p ></p >
							  )}
						 </div >
						 <div className = "twoBoxes" >
							 <div className = "textAndResultBox" >
								 <h3 >החזר חודשי משוער</h3 >
								 {weCheckMortgageMonthlyPayback ? (
									 <p className = "boldGreen" >
										 {weCheckMortgageMonthlyPayback} &#8362;
									 </p >
								 ) : (
									  <p ></p >
								  )}
							 </div >
						 </div >
						 {this.state.haveResult ? (
							 <div className = {"offer btn--oldred"} >
								 <a
									 href = {URLS.MortgageQue}
									 onClick = {this.reportQueEvent}
								 >
									 <span >לבדיקת זכאות </span >
									 <i className = "arrow left" ></i >
								 </a >
							 </div >
						 ) : (
							  ""
						  )}
					 </>
				 )}
			</div >
		);
	};

	render = () => (
		<div className = "CalculatorSettings" >
			<div className = "content-container" >
				{this.settingsScreen()}
				{this.progressScreen()}
				{this.getResultsScreen()}
			</div >
		</div >
	);
}

export {CalcSettings};
