import React, {useState} from "react";
import {BackgroundImg}   from "../../../../../components/background-img";
import {Divider}         from "../../../../../components/divider";
import Video             from "../../../../../components/video-player";
import {URLS}            from "../../../../../shared/urls";
import Utils             from "../../../../../shared/utils";

import "./index.scss";

const TestimonialsVideoSection = ({
	                                  video,
	                                  withButton,
	                                  eventCategory,
	                                  grayBackground
                                  }) => {
	const [showVideo, setShowVideo]     = useState(false);
	const [videoUrl, setVideoUrl]       = useState("");
	const [ignoreClick, setIgnoreClick] = useState(false);

	const handleLinkClick = (e, videoUrl, eventName, eventCategory) => {
		if (ignoreClick) {
			e.preventDefault();
		}

		setIgnoreClick(false);
		Utils.gaEvent(eventCategory, eventName, eventName);

		setShowVideo(true);
		setVideoUrl(videoUrl);
	};

	return (
		<div className = "TestimonialsVideoSection" >
			<Video
				onClose = {() => {
					setShowVideo(false);
				}}
				visible = {showVideo}
				videoURL = {videoUrl}
			/>
			<div className = "section" >
				<div className = {`row ${video.flexDirection} ${grayBackground}`} >
					<div className = "col pointer" >
						<a
							onClick = {(e) =>
								handleLinkClick(
									e,
									video.videoName,
									video.eventName,
									eventCategory
								)
							}
						>
							<BackgroundImg
								src = {
									video.imgLink
									? video.imgLink
									: Utils.buildTestimonialsImgLink(
										video.img
									)
								}
								hoverZoom = "hoverZoom"
							/>
						</a >
						<p className = "ribbon" >{video.ribbon} </p >
					</div >
					<div className = "col center" >
						<div className = "colInner center" >
							<div className = "ratingStars" >
								<div className = "starSmall" ></div >
								<div className = "starMedium" ></div >
								<div className = "starLarge" ></div >
								<div className = "starMedium" ></div >
								<div className = "starSmall" ></div >
							</div >
							<p className = "ratingText" >{video.ratingText}</p >
							{withButton ? (
								<>
									<Divider />
									<a
										onClick = {() => {
											Utils.gaEvent(
												eventCategory,
												video.buttonEventName,
												video.buttonEventName
											);
										}}
										href = {URLS.Testimonials}
										className = "btn--azure  medium"
									>
										לסיפורים נוספים ›{" "}
									</a >
								</>
							) : (
								 ""
							 )}
						</div >
					</div >
				</div >
			</div >
		</div >
	);
};

export default TestimonialsVideoSection;
