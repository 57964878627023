import React from "react";
import FunctionalSection from "./components/functional-section";
import AnimatedSteps from "./components/animated-steps";
import useLogin from "./hooks/useLogin";
import "./index.scss";

export default function LoginPage() {
  const {
    adsApproved,
    policyConfirmed,
    phone,
    code,
    idNum,
    step,
    isSending,
    isLoggingIn,
    isGettingUserDetails,
    codeError,
    phoneError,
    shouldApproveTerms,
    sendOTP,
    onSubmit,
    existingUserData,
    isFetchingUserStatus,
    isMortLogin,
  } = useLogin();

  return (
    <div className="login-container">
      <div className="max-width-container">
        <FunctionalSection
          sendPhone={() => sendOTP(phone)}
          onSubmit={onSubmit}
          isLoading={
            isSending ||
            isLoggingIn ||
            isGettingUserDetails ||
            isFetchingUserStatus
          }
          phone={phone}
          code={code}
          idNum={idNum}
          step={step}
          adsApproved={adsApproved}
          policyConfirmed={policyConfirmed}
          codeError={codeError}
          phoneError={phoneError}
          isMoked={false}
          isRealtor={false}
          shouldApproveTerms={shouldApproveTerms}
          existingUserData={existingUserData}
          isMortLogin={isMortLogin}
        />
        <AnimatedSteps isRealtor={false} isMortLogin={isMortLogin} />
      </div>
    </div>
  );
}
