import React from "react";
import "./index.scss";
import AnimationContainer from "../../../../../components/product-pages/animation-container";
import { FIRST_PART_ANIMATION } from "../../consts";
import { INTRO_FIRST_PART } from "../../texts";
import Paragraph from "../../../../../components/product-pages/paragraph";
import ImageContainer from "../../../../../components/product-pages/image-container";

export default function IntroFirstPart() {
  return (
    <div className="intro-part first-part-container">
      <Paragraph text={INTRO_FIRST_PART.SUBTITLE} />
      <AnimationContainer img={FIRST_PART_ANIMATION} />
      <div className="paragraphs-container">
        <Paragraph
          className="paragraph-first-part"
          text={INTRO_FIRST_PART.TEXT.FIRST_PART}
        />
        <br />
        <div className="info-boxes-container">
          {INTRO_FIRST_PART.INFO_LINES.map(({ TEXT, IMAGE }, index) => (
            <div key={index} className="info-box">
              <ImageContainer className="icon-container" src={IMAGE} />
              <Paragraph text={TEXT} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
