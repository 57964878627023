import React from "react";
import "./index.scss";
import { Gender } from "@wecheck/uisharedutils/uiUtils";
import { LOGOUT_CTA } from "./texts";
import { useDispatch, useSelector } from "react-redux";
import { useLogoutMutation } from "../../../../../redux/services/login";
import { setIsLoggedIn } from "../../../../../redux/features/loginSlice";

export default function LogoutButton() {
  const dispatch = useDispatch();
  const gender = useSelector((state) => state.user?.gender);
  const isMale = gender === Gender.male;

  const [logout, { isLoading }] = useLogoutMutation();

  const logoutHandler = async () => {
    await logout();
    dispatch(setIsLoggedIn(false));
    window.location.href = "/";
  };

  return (
    <button
      disabled={isLoading}
      onClick={logoutHandler}
      className="logout-button"
    >
      {isMale ? LOGOUT_CTA.MALE : LOGOUT_CTA.FEMALE}
    </button>
  );
}
