import React, { Component } from "react";
import Video from "../../../../../components/video-player";

import Utils from "../../../../../shared/utils";

import "./index.scss";

class TestimonialsStrip extends Component {
	constructor(props) {
		super(props);

		this.pos = { top: 0, left: 0, x: 0, y: 0 };

		this.ref = React.createRef();

		this.state = {
			showVideo: false,
			videoUrl: "",
			testimonials: this.props.testimonials,
			eventCategory: this.props.eventCategory
		};
	}

	async componentDidMount() {
		this.ele = this.ref.current;
		this.ele.scrollBy(- 1000000, 0);
		this.ele.addEventListener("mousedown", this.mouseDownHandler);
	}

	mouseDownHandler = (e) => {
		this.pos = {
			// The current scroll
			left: this.ele.scrollLeft,
			top: this.ele.scrollTop,
			// Get the current mouse position
			x: e.clientX,
			y: e.clientY
		};

		this.ele.style.userSelect = "none";

		document.addEventListener("mousemove", this.mouseMoveHandler);
		document.addEventListener("mouseup", this.mouseUpHandler);
	};

	mouseMoveHandler = (e) => {
		// How far the mouse has been moved
		const dx = e.clientX - this.pos.x;
		const dy = e.clientY - this.pos.y;

		// Scroll the element
		this.ele.scrollTop = this.pos.top - dy;
		this.ele.scrollLeft = this.pos.left - dx;

		this.ignoreClick = true;
	};

	mouseUpHandler = (e) => {
		e.preventDefault();
		this.ele.style.removeProperty("user-select");

		document.removeEventListener("mousemove", this.mouseMoveHandler);
		document.removeEventListener("mouseup", this.mouseUpHandler);
	};

	handleLinkClick = (e, videoUrl, eventName, eventCategory) => {
		if (this.ignoreClick) {
			e.preventDefault();
		}

		this.ignoreClick = false;
		Utils.gaEvent(eventCategory, eventName, eventName);

		this.setState({ showVideo: true, videoUrl });
	};

	render = () => (
		<div className="TestimonialsStrip" >
			<Video
				onClose={() => {
					this.setState({ showVideo: false });
				}}
				visible={this.state.showVideo}
				videoURL={this.state.videoUrl}
			/>
			<div className="pressImages" id="tscontainer" ref={this.ref} >
				{this.state.testimonials.map((testimonial, index) => (
					<div className="child" >
						<a
							onClick={(e) =>
								this.handleLinkClick(
									e,
									testimonial.videoName,
									testimonial.eventName,
									this.state.eventCategory
								)
							}
						>
							<div key={index} >
								<img
									src={this.props.isMortSite ? Utils.buildMortTestimonialsImgLink(
										testimonial.img
									)
										: Utils.buildTestimonialsImgLink(
											testimonial.img
										)
									}
									alt=""
								/>
							</div >
						</a >
					</div >
				))}
			</div >
		</div >
	);
}

export { TestimonialsStrip };
