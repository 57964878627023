import React from "react";

import Utils from "../../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../../components/logo";

const WhoAreWeSection = ({reference}) => (
	<div ref = {reference} className = "WhoAreWe" id = "whoAreWe" >
		<img
			className = "manImg"
			src = {Utils.buildDashboardImgLink("mortgage/mortman.svg")}
			alt = ""
		/>

		<div className = "wording" >
			<h1 >מי אנחנו</h1 >
			<div className = "textWithLogo" >
				<h5 className = "redH5" >הי, אנחנו</h5 >
				<Logo color="blue" />

			</div >
			<h5 >
				ואתם יכולים להסתכל עלינו כמו כדור נגד כאב ראש בכל מה שקשור
				להשכרת וקניית נכסים.
			</h5 >
			<h5 className = "redH5" >איך אנחנו עושים את זה?</h5 >
			<h5 >
				אנחנו חוקרים ולומדים כל הזמן את האתגרים השונים בהשכרת וקניית
				נכסים - ויוצרים פתרונות ,ראשונים מסוגם, מבוססי טכנולוגיה ייחודית
				שעונים בצורה מדויקת על מגוון צרכים של משכירים ורוכשים, החל
				מביטחונות כספיים ועד סיוע בקניית נכסים - לפי היכולות הפיננסיות
				האמיתיות של הרוכשים.
			</h5 >
			<br />
			<h5 >
				אפילו עכשיו, בזמן שאתם קוראים את זה , אנחנו עובדים על הפתרון
				החדשני הבא.
			</h5 >
			<h5 className = "redH5" >למה דווקא אנחנו?</h5 >
			<h5 >
				כי אנחנו אנשי מקצוע מנוסים, בעלי ידע פיננסי וטכנולוגי מוכח, מה
				שמאפשר לנו להציע פתרונות חדשניים וייחודיים שלא היו זמינים עד
				היום.
			</h5 >
			<br />
			<h5 >
				כי יצרנו שותפות עם יד2 וישראכרט כדי להעמיד לרשותכם גוף פיננסי
				מבוסס ואמין שבאמת יכול לסייע לכם במגוון אתגרים בתחום הנדל"ן.
			</h5 >
			<br />
			<h5 >
				זהו, מקווים שלא דיברנו יותר מדי על עצמנו ,אחרי הכל – אנחנו כאן
				כדי להקל עליכם.
			</h5 >
			<br />
			<br />
		</div >
	</div >
);

export {WhoAreWeSection};
