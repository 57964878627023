import config from "../../../../config/config";

export const ZOOM_DISPLAY = {
  ASSETS: {
    VISIBLE: 15,
    FOCUS: 19,
  },
  AREAS: {
    VISIBLE: 15,
    FOCUS: 16,
  },
  NEIGHBORHOODS: {
    VISIBLE: 16,
    FOCUS: 17,
  },
  CITIES: {
    FOCUS: 15,
    FOCUS_NO_AREAS: 16,
  },
};

export const ASSETS_FILTER_FACTOR = 0.1;

export const MAP_CONTAINER_STYLE = {
  height: "100%",
  width: "100vw",
  position: "absolute",
  top: "0",
  right: "0",
};

export const options = {
  id: "google-map-script",
  googleMapsApiKey: config.googleMapsApiKey ? config.googleMapsApiKey : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  region: "IL",
  language: "he",
};
