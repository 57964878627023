export const PARAGRAPHS = [
  {
    title: `משכנתא פרימיום של WeCheck- משכנתא חוץ בנקאית`,
    text: `כשניגשים לחיפוש משכנתא, חשוב לבדוק את כל האפשרויות שלכם ולשקול גופים חוץ בנקאיים בנוסף לבנקים המסורתיים.למה? כי למשכנתא חוץ בנקאית ישנם יתרונות רבים -  תנאי הלוואה גמישים יותר, זמני אישור מהירים ותוכניות מימון מיוחדות שאינן זמינות בבנקים. משכנתא חוץ בנקאית יכולה גם להיות טובה עבור לווים שזקוקים למשכנתאות באחוזי מימון גבוהים , הון עצמי לא מספק לתנאי הבנק, עצמאיים או בעלי סיבות פיננסיות ייחודיות אחרות. עם משכנתא פרימיום של WeCheck תוכלו למצוא הלוואה מתאימה לצרכים הפיננסים הספציפיים שלכם.`,
  },
  {
    title: `משכנתא לוקחים רק מארגון המפוקח על ידי רשות שוק ההון`,
    text: `ארגונים בפיקוח רשות שוק ההון, מציעים מספר יתרונות על פני הלוואות מארגונים לא מפוקחים. ארגונים אלה מוסדרים על ידי גוף ממשלתי, מה שאומר שהם חייבים לציית לכללים ולתקנות קפדניים, תוך הבטחת שקיפות, אחריות ויחס הוגן ללקוחות. בנוסף, הרשות דורשת מארגונים מפוקחים לציית להוראות צרכניות שונות, להקפיד על הגנות מידע וסייבר ולאמץ מדיניות לטיפול בתלונות ציבור, אשר מספקות ליווי משפטי במקרה של מחלוקת או תלונות כלשהן.`,
  },
];

export const CTA_TITLE = `אז לפני שהריבית עולה`;
