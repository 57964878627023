import React, { useState } from "react";
import { PlusMinusIcon } from "../../../components/plus-minus-icon";
import Utils from "../../../shared/utils";
import "./index.scss";

// Function to handle breaks and parse links
const addBreaks = (string) =>
  string
    .replace(/\. /g, ".\n")
    .replace(/\b(linebreak)\b/g, "\n")
    .replace(/(https?:\/\/[^\s]+)/g, '<a href="$1" target="_blank">$1</a>');

const ComplexParagraph = ({ title, content, list, disclaimer }) => {
  return (
    <div className="complex-paragraph">
      {title && <h4 dangerouslySetInnerHTML={{ __html: addBreaks(title) }} />}
      {content && (
        <p dangerouslySetInnerHTML={{ __html: addBreaks(content) }} />
      )}
      {list && (
        <ul>
          {list.map((item, index) => {
            return (
              <li
                key={index}
                dangerouslySetInnerHTML={{ __html: addBreaks(item) }}
              />
            );
          })}
        </ul>
      )}
      {disclaimer && (
        <p
          className="disclaimer"
          dangerouslySetInnerHTML={{ __html: addBreaks(disclaimer) }}
        />
      )}
    </div>
  );
};

const SingleQuestion = ({
  title,
  content,
  selected,
  index,
  handleSelectedQuestion,
}) => {
  return (
    <div className={`SingleQuestion ${selected === index ? "selected" : ""}`}>
      <div className="question-container">
        <div
          className="title-container"
          onClick={() => handleSelectedQuestion(index)}
        >
          <h3>{title}</h3>
          <PlusMinusIcon active={selected === index} />
        </div>
        {Array.isArray(content) ? (
          <div
            className={`content-container ${
              selected === index ? "selected" : ""
            }`}
          >
            {content.map((par, index) => {
              return (
                <ComplexParagraph
                  key={index}
                  title={par.title}
                  content={par.content}
                  list={par.list}
                  disclaimer={par.disclaimer}
                />
              );
            })}
          </div>
        ) : (
          <p
            className={`content-container ${
              selected === index ? "selected" : ""
            }`}
            dangerouslySetInnerHTML={{ __html: addBreaks(content) }}
          />
        )}
      </div>
    </div>
  );
};

const MortQNA = ({ questions, extraQuestions }) => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [selectedExtraQuestion, setSelectedExtraQuestion] = useState(null);

  const handleSelectedQuestion = (index) => {
    setSelectedQuestion((selected) => {
      return selected === index ? null : index;
    });
  };

  const handleSelectedExtraQuestion = (index) => {
    setSelectedExtraQuestion((selected) => {
      return selected === index ? null : index;
    });
  };

  return (
    <div className="MortQNA">
      <div className="title-container">
        <h1>יש לכם שאלות?</h1>
        <h2>אספנו בשבילכם את השאלות הכי נפוצות</h2>
      </div>
      <div className="questions-container">
        <div className="questions-list-container">
          {questions.map((question, index) => {
            return index <= questions.length / 2 ? (
              <SingleQuestion
                selected={selectedQuestion}
                index={index}
                handleSelectedQuestion={handleSelectedQuestion}
                key={index}
                title={question.title}
                content={question.content}
              />
            ) : (
              <></>
            );
          })}
        </div>
        <div className="questions-list-container">
          {questions.map((question, index) => {
            return index > questions.length / 2 ? (
              <SingleQuestion
                selected={selectedQuestion}
                index={index}
                handleSelectedQuestion={handleSelectedQuestion}
                key={index}
                title={question.title}
                content={question.content}
              />
            ) : (
              <></>
            );
          })}
        </div>
        <div className="image-container">
          <img
            rel="prefetch"
            src={Utils.buildMortImgLink("QNA/person-image.png")}
            alt=""
          />
        </div>
      </div>
      {extraQuestions && (
        <>
          <div className="title-container">
            <h2>שאלות נפוצות על WeCheckPay</h2>
          </div>
          <div className="questions-container">
            <div className="questions-list-container">
              {extraQuestions.map((question, index) => {
                return index <= extraQuestions.length / 2 ? (
                  <SingleQuestion
                    selected={selectedExtraQuestion}
                    index={index}
                    handleSelectedQuestion={handleSelectedExtraQuestion}
                    key={index}
                    title={question.title}
                    content={question.content}
                  />
                ) : (
                  <></>
                );
              })}
            </div>
            <div className="questions-list-container">
              {extraQuestions.map((question, index) => {
                return index > extraQuestions.length / 2 ? (
                  <SingleQuestion
                    selected={selectedExtraQuestion}
                    index={index}
                    handleSelectedQuestion={handleSelectedExtraQuestion}
                    key={index}
                    title={question.title}
                    content={question.content}
                  />
                ) : (
                  <></>
                );
              })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export { MortQNA };
