import React, {Component} from "react";

import "./css/ButtonWindowChoose.scss";

class ButtonWindowChoose extends Component {
	constructor(props) {
		super(props);

		this.state = {open: false};
	}

	toggleState = () => {
		if (this.props.subOptions) {
			const isOpen = this.state.open;
			this.setState({open: !this.state.open});
			this.props.buttonOpened(isOpen ? undefined : this.props.index);
		}
		else {
			this.props.onChoose(this.props.index);
		}
	};

	render() {
		return (
			<div
				className = {`ButtonWindowChoose ButtonWindowChoose${
					this.state.open ? "--open" : ""
				} `}
				onClick = {this.toggleState}
			>
				{this.state.open ? (
					<div >
						<div className = "closeButton" onClick = {this.toggleState} >
							<a href = "#" className = "close" />
						</div >
						<div className = "textContent" >
							<p >{this.props.text}</p >
							<p className = "smallText" >
								<small >{this.props.subText}</small >
							</p >
						</div >
						<div
							className = "subOption"
							onClick = {() =>
								this.props.onChoose(this.props.index, 0)
							}
						>
							<p >{this.props.subOptions[0]}</p >
						</div >
						<div
							className = "subOption"
							onClick = {() =>
								this.props.onChoose(this.props.index, 1)
							}
						>
							<p >{this.props.subOptions[1]}</p >
						</div >
						<div
							className = "subOption"
							onClick = {() =>
								this.props.onChoose(this.props.index, 2)
							}
						>
							<p >{this.props.subOptions[2]}</p >
						</div >
					</div >
				) : (
					 <div className = "singleTextContent" >
						 <p >{this.props.text}</p >
					 </div >
				 )}
			</div >
		);
	}
}

export {ButtonWindowChoose};
