import React from "react";
import "./index.scss";
import { LOGIN_TEXT } from "./texts";
import { Link } from "react-router-dom";
import { URLS } from "../../shared/urls";
import { LOGIN_TYPE } from "../../../redux/consts";

export default function LoginButton() {
  return (
    <Link to={`${URLS.internalLogin}?type=${LOGIN_TYPE.NEWSLETTER}`} className="login-button">
      {LOGIN_TEXT}
    </Link>
  );
}
