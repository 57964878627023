import React from "react";
import "./index.scss";
import { CLOSE_ICON } from "./consts";
import { useDispatch } from "react-redux";
import { closeDataView } from "../../../../../../redux/features/newsletterSlice";

export default function CloseButton({ isVisible }) {
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(closeDataView());
  };

  return (
    <button
      tabIndex={isVisible ? 0 : -1}
      onClick={handleClick}
      className="close-button"
    >
      <img src={CLOSE_ICON} />
    </button>
  );
}
