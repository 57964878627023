import React from "react";
import useCodeInput from "./hooks/useCodeInput";
import { getAuthErrorMessage, AUTH_ERROR_MESSAGES } from "../../consts";

import "./index.scss";

export default function CodeInput({ code, setCode, error }) {
  const {
    inputRef,
    handleChange,
    showError
  } = useCodeInput({ error, setCode });

  return (
    <>
      <div className={`code-input-container ${showError ? "error" : ""}`}>
        <div className="code-input-separator">
          <input
            ref={inputRef}
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            tabIndex={0}
            autoComplete="one-time-code"
            className={`single-code-input ${showError ? "error" : ""}`}
            aria-label={`One Time Code`}
            value={code || ""}
            onChange={handleChange}
          />
        </div>
      </div>
      {showError && <div className="error-message">{getAuthErrorMessage(error?.data?.error?.statusCode)}</div>}
    </>
  );
}
