import React, {useRef, useState} from "react";
import {Link}                    from "react-router-dom";
import {BackgroundImg}           from "../../../../../components/background-img";
import Video                     from "../../../../../components/video-player";
import useIsInViewport           from "../../../../../hooks/useIsInViewport";
import {URLS}                    from "../../../../../shared/urls";
import Utils                     from "../../../../../shared/utils";
import {WeCheckSection}          from "../../../../../UIWck/Section";
import {VideosData}              from "./consts";

import "./index.scss";

const MortgageAdvisersTestimonials = () => {
	const [showVideo, setShowVideo]     = useState(false);
	const [videoUrl, setVideoUrl]       = useState("");
	const [ignoreClick, setIgnoreClick] = useState(false);

	const titleRef              = useRef(null);
	const testimonialsRef       = useRef(null);
	const testimonialsRefMobile = useRef(null);

	const handleLinkClick = (e, videoUrl, eventName) => {
		if (ignoreClick) {
			e.preventDefault();
		}

		setIgnoreClick(false);
		Utils.gaEvent("אתר שיווקי -￼ משכנתאות", eventName, "");

		setVideoUrl(videoUrl);
		setShowVideo(true);
	};

	const TestimonialsBox = ({videoData}) => (
		<div className = "row testimonialsBox" >
			<div className = "col desktop4" >
				<a
					onClick = {(e) =>
						handleLinkClick(
							e,
							videoData.videoName,
							videoData.eventName
						)
					}
				>
					<BackgroundImg
						src = {Utils.buildMortTestimonialsImgLink(videoData.img)}
						hoverZoom = "hoverZoom"
						ratio = "80%"
					/>
				</a >
			</div >
			<div className = "col desktop8 padding1" >
				<h4 >{videoData.name}</h4 >
				<h5 >{videoData.title}</h5 >
				<p >{videoData.desc}</p >
			</div >
		</div >
	);

	const yoniVideo = (
		<div >
			<a
				onClick = {(e) =>
					handleLinkClick(
						e,
						"newMortSah",
						"סקשן טסטמוניאלס - סרטון ראשי"
					)
				}
			>
				<BackgroundImg
					src = {Utils.buildMortTestimonialsImgLink(
						"TestimonialsMain.png"
					)}
					hoverZoom = "hoverZoom"
				/>
			</a >
		</div >
	);

	const startSection = (
		<div className = "row" >
			<div className = "col" >
				<div >
					<div className = "ratingStars" >
						<div className = "starSmall" ></div >
						<div className = "starMedium" ></div >
						<div className = "starLarge" ></div >
						<div className = "starMedium" ></div >
						<div className = "starSmall" ></div >
					</div >
					<p className = "IntroText" >
						"הפתרון של וויצ'ק הוא יפיפה, זה חוסך לי המון זמן
						ומשאבים. הם יודעים לתת מענה ייחודי לכל בן אדם. אנחנו
						עוזרים לאנשים לקנות בית.״
					</p >
				</div >
			</div >
		</div >
	);

	return (
		<WeCheckSection className = "MortgageAdvisersTestimonials padding3" >
			<Video
				onClose = {() => {
					setShowVideo(false);
				}}
				visible = {showVideo}
				videoURL = {videoUrl}
			/>
			<div
				ref = {titleRef}
				className = {`row title-container ${
					useIsInViewport(titleRef) ? "in-view" : ""
				}`}
			>
				<div className = "col" >
					<h2 >יועצי משכנאות מספרים</h2 >
				</div >
			</div >

			<div
				ref = {testimonialsRefMobile}
				className = {`mobileGrid ${
					useIsInViewport(testimonialsRefMobile) ? "in-view" : ""
				}`}
			>
				{startSection}
				<br />
				{yoniVideo}
				<TestimonialsBox videoData = {VideosData["Shir"]} />
				<TestimonialsBox videoData = {VideosData["Stav"]} />
				<TestimonialsBox videoData = {VideosData["Zvi"]} />
			</div >

			<div
				ref = {testimonialsRef}
				className = {`Grid ${
					useIsInViewport(testimonialsRef) ? "in-view" : ""
				}`}
			>
				<div className = "Item1" >{startSection}</div >

				<div className = "Item2" >{yoniVideo}</div >

				<div className = "Item3" >
					<TestimonialsBox videoData = {VideosData["Shir"]} />
				</div >

				<div className = "Item4" >
					<TestimonialsBox videoData = {VideosData["Stav"]} />
				</div >
				<div className = "Item5" >
					<TestimonialsBox videoData = {VideosData["Zvi"]} />
				</div >
			</div >

			<div className = "row" >
				<div className = "col desktopOnly" >
					<img
						src = {Utils.buildImgLink(
							"mort/MortgageAdvisersTestimonialsDecoration.svg"
						)}
						alt = "Decoration"
						className = "Decoration"
					/>
				</div >
				<div className = "col center" >
					<Link
						onClick = {() =>
							Utils.gaEvent(
								"אתר שיווקי -￼ משכנתאות",
								"סקשן טסטמוניאס - לסיפורים￼ נוספים",
								""
							)
						}
						to = {URLS.MortTestimonials}
						className = "btn--azure"
					>
						לסיפורים נוספים
					</Link >
				</div >
				<div className = "col desktopOnly" >
					<img
						src = {Utils.buildImgLink(
							"mort/MortgageAdvisersTestimonialsMan.svg"
						)}
						alt = "Decoration"
					/>
				</div >
			</div >
		</WeCheckSection >
	);
};

export {MortgageAdvisersTestimonials};
