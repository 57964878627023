import { useRef, useEffect } from "react";

function usePrevious(value) {
  // Use useRef to store the previous value, as its object persist across renders
  const ref = useRef();

  // Use useEffect to update the previous value when the value changes
  useEffect(() => {
    ref.current = value;
  }, [value]); // Only re-run if value changes

  // Return the previous value (which was captured before the update)
  return ref.current;
}

export default usePrevious;
