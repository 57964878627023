import React from "react";
import { useRef } from "react";
import { useOutletContext } from "react-router-dom";
import useHeader from "../../../../../components/header/hooks/useHeader";
import useIsInViewport from "../../../../../hooks/useIsInViewport";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { texts } from "../../consts";
import useContactForm from "../../hooks/useContactForm";
import CloseButton from "./components/close-button";
import Spinner from "./components/loading-button/components/spinner";
import SuccessMessage from "./components/success-message";
import "./index.scss";

const InputError = ({ error }) => {
    return error && <p className="error">{error}</p>
}

const FormInput = ({ placeholder, name, handleInput, type, value, error, nameToSync }) => {
    return <div className="form-input">
        <input
            type={type}
            onChange={(e) => handleInput(name, e.target.value)}
            placeholder={placeholder}
            value={value}
            name={nameToSync}
            id={name}
        />
        <InputError error={error} />
    </div>
}

const FormSelect = ({ placeholder, name, handleInput, error, value, nameToSync }) => {
    return <div className="form-input">
        <select name={nameToSync} value={value} onChange={(e) => handleInput(name, e.target.value)} >
            <option value="" disabled>{placeholder}</option>
            {texts.form.options.map((option, index) => {
                return <option value={option} key={index}>{option}</option>
            })}
        </select>
        <InputError error={error} />
    </div>
}

export default function MortgageContactForm() {
    const { formData,
        handleInput,
        submit,
        isFormOpen,
        openForm,
        closeForm,
        errors,
        inputEntered,
        enterInput,
        isLoading,
        buttonDisabled,
        formSent,
        wasFormClosed
    } = useContactForm();
    const formContainerRef = useRef(null);
    const formRef = useRef(null);
    const { scrollPosition } = useHeader();
    const isMobile = useIsMobile(1400);
    const isFixedPosition = useIsInViewport(formContainerRef, 1, isMobile);
    const [refs, videoURLS, mortRefs, isFooterVisible] = useOutletContext();
    const topOfPage = isMobile ? scrollPosition <= 100 : scrollPosition === 0;
    const showShrunkVersion = isMobile && !isFormOpen && isFixedPosition && !topOfPage;
    const showCloseButton = (isMobile && isFormOpen && !wasFormClosed) || (!isMobile && formSent && !wasFormClosed)

    return <div
        ref={formContainerRef}
        className={`MortgageContactFormContainer 
    ${isFooterVisible && !wasFormClosed
                ? "absolute-position"
                : isFixedPosition && !topOfPage && !wasFormClosed
                    ? "fixed-position"
                    : ""} 
    ${isFormOpen || wasFormClosed
                ? "open"
                : "closed"}`}>
        {showShrunkVersion && !wasFormClosed && <div className="mobile-form-not-open">
            <h3>{texts.form.title}</h3>
            <button onClick={openForm}>{texts.form.buttons.open}</button>
        </div>}
        <SuccessMessage visible={formSent && !showShrunkVersion && !wasFormClosed} />
        <div className={`form-content ${isFormOpen || wasFormClosed ? "open" : "closed"}`}>
            {showCloseButton && <CloseButton close={closeForm} />}
            <h3 className={formSent && !wasFormClosed ? "hidden" : ""}>{texts.form.title}</h3>
            <form ref={formRef} onSubmit={(e) => submit(e, formRef)} className={`contact-form ${formSent && !wasFormClosed ? "hidden" : ""}`}>
                <div className="form-inputs">
                    {
                        texts.form.inputs.map((input, index) => {
                            if (input.type === "select") return <FormSelect
                                key={index}
                                placeholder={input.placeholder}
                                name={input.name}
                                nameToSync={input.nameToSync}
                                value={formData[input.name]}
                                enterInput={enterInput}
                                error={inputEntered[input.name] && errors[input.name]}
                                handleInput={handleInput} />
                            return <FormInput
                                key={index}
                                placeholder={input.placeholder}
                                name={input.name}
                                nameToSync={input.nameToSync}
                                type={input.type}
                                value={formData[input.name]}
                                enterInput={enterInput}
                                error={inputEntered[input.name] && errors[input.name]}
                                handleInput={handleInput} />
                        })
                    }
                </div>
                <button disabled={buttonDisabled} type="submit">{isLoading ? <Spinner /> : texts.form.buttons.submit}</button>
            </form>
        </div>
    </div>
}