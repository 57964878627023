import React, { useEffect } from "react";
import "./index.scss";
import { LAST_MONTH, RENT_HEIGHT, ROOM_NUMBER, SUPPLY } from "./texts";
import {
  GREEN_TRIANGLE,
  RED_TRIANGLE,
} from "../../../location-cards/components/card/consts";
import { useCountUp } from "react-countup";

const DataIndicator = ({ value, indicator, isUp }) => {
  const countUpRef = React.useRef(null);
  const { update } = useCountUp({
    ref: countUpRef,
    start: 0,
    end: value,
    duration: 2,
    delay: 0,
    decimals: 2,
  });
  useEffect(() => {
    if (value && Number(value)) {
      update(value);
    } else {
      update(0)
    }
  }, [update, value]);

  return (
    <h4 className="data-indicator">
      {indicator}
      <span>
        <span
          className={`data-indicator-difference-value ${
            isUp ? "positive" : "negative"
          }`}
        >
              <span ref={countUpRef} />%
          <img src={isUp ? GREEN_TRIANGLE : RED_TRIANGLE} />
        </span>
        {LAST_MONTH}
      </span>
    </h4>
  );
};

export default function InfoSection({ roomNumber, isLoading, data }) {
  return (
    <div className="info-section-container">
      <div className="room-number">
        <h4>
          {ROOM_NUMBER} <span>{roomNumber}</span>
        </h4>
      </div>
      <div className="rent-difference">
        <DataIndicator
          name={"monthToMonth"}
          isLoading={isLoading}
          value={data?.monthToMonthDifference?.difference}
          indicator={RENT_HEIGHT}
          isUp={data?.monthToMonthDifference?.up}
        />
      </div>
      <div className="supply-difference">
        <DataIndicator
          name={"supply"}
          isLoading={isLoading}
          value={data?.supplyDifference?.difference}
          indicator={SUPPLY}
          isUp={data?.supplyDifference?.up}
        />
      </div>
    </div>
  );
}
