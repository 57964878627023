const CTA_CLICK_TRIGGER = `לחיצה על כפתור`

const EVENT_CATEGORY = `עמוד מוצר - צ'ק בטוח`

export const ANALYTICS = {
    FIRST_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: `${CTA_CLICK_TRIGGER} 'להבטחת שכר הדירה'`,
            ACTION: `להבטחת שכר הדירה - סקשן פותח`
        }
    },
    SECOND_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: `${CTA_CLICK_TRIGGER} 'לקבלת ערבות'`,
            ACTION: `לקבלת ערבות לתשלומי שכר הדירה`
        }
    },
    THIRD_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: `${CTA_CLICK_TRIGGER} 'להבטחת שכר הדירה'`,
            ACTION: `להבטחת שכר הדירה`
        }
    },
}