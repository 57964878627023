import Utils from "../../../shared/utils"
import { URLS } from "../../../shared/urls"

const DEAL_TYPE = [
    {
        LABEL: "רכישה - נכס ראשון",
        VALUE: 0
    },
    {
        LABEL: "רכישה - משפרי דיור",
        VALUE: 1
    },
    {
        LABEL: "רכישה - נכס להשקעה",
        VALUE: 2
    },
    {
        LABEL: "נכס קיים - בבעלותי נכס אחד",
        VALUE: 3
    },
    {
        LABEL: "נכס קיים - בבעלותי מספר נכסים",
        VALUE: 4
    },
    {
        LABEL: "לא ידוע",
        VALUE: 5
    }
]

export const FORM = [
    {
        name: "fullName",
        label: "שם מלא",
    },
    {
        name: "phone",
        label: "מספר טלפון",
        type: "tel",
        inputmode: "numeric",
        pattern: "[-+]?[0-9]*[.,]?[0-9]+"
    },
    {
        name: "requestedAmount",
        label: "סכום מבוקש מ-WeCheck (אם ידוע)",
        type: "number"
    },
    {
        name: "dealType",
        label: "סוג עסקה",
        type: "select",
        options: DEAL_TYPE,
    }
]

export const IMAGE_URL = Utils.buildProductPageImageLink("mort/contact-image.svg")

export const SOURCE_ENUM = {
    [URLS.AdditionalMort]: 'עמוד מוצר משכנתא משלימה',
    [URLS.CompleteMort]: 'עמוד מוצר משכנתא מאחדת',
    [URLS.PremiumMort]: 'עמוד מוצר משכנתא פרמיום',
    [URLS.ReducedMort]: 'עמוד מוצר משכנתא מסלול מופחת',
}