import React, { useEffect, useState } from "react";
import "./index.scss";
import SingleItem from "./components/single-item";
import {
  ANIMATED_LIST_NEWSLETTER,
  ANIMATED_LIST_TITLE_NEWSLETTER,
  STEP_ANIMATION_LEFT,
  STEP_ANIMATION_RIGHT,
} from "./consts";
import FlowingAnimation from "../flowing-animation";
import { MORT_PERSONAL_AREA_ANIMATIONS } from "../flowing-animation/consts";
import { MORT_PERSONAL_AREA_TITLE } from "../flowing-animation/texts";

function AnimatedRow({ list, displaySecondAnimation, title }) {
  return (
    <div className="item-row-container">
      <h3>{title}</h3>
      <div className={`item-row-list-container `}>
        {list.map(({ ...props }, index) => {
          return (
            <SingleItem
              animationDelay={index > 0 && index}
              key={index}
              {...props}
            />
          );
        })}
      </div>
      <div className="step-animation-container">
        <div className="animation-container">
          <object
            rel="prefetch"
            type="image/svg+xml"
            data={STEP_ANIMATION_RIGHT}
          />
        </div>
        {displaySecondAnimation && (
          <div className="animation-container">
            <object
              rel="prefetch"
              type="image/svg+xml"
              data={STEP_ANIMATION_LEFT}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default function AnimatedSteps({ isRealtor, isMortLogin }) {
  const [displaySecondAnimation, setDisplaySecondAnimation] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setDisplaySecondAnimation(true);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="animated-steps-container">
      <div className="half-circle" />
      <div className="half-circle" />
      {isMortLogin ? (
        <FlowingAnimation
          list={MORT_PERSONAL_AREA_ANIMATIONS}
          title={MORT_PERSONAL_AREA_TITLE}
        />
      ) : (
        <AnimatedRow
          displaySecondAnimation={displaySecondAnimation}
          list={ANIMATED_LIST_NEWSLETTER}
          title={ANIMATED_LIST_TITLE_NEWSLETTER}
        />
      )}
    </div>
  );
}
