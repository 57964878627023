import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useSendPhoneMutation,
  useSendPhoneWithIdMutation,
} from "../../../redux/services/login";
import {
  init,
  setExistingUserData,
  setIsLoggedIn,
  setLoginType,
  setOtpResponse,
  setStep,
} from "../../../redux/features/loginSlice";
import { URLS } from "../../shared/urls";
import Utils from "../../shared/utils";
import { ORIGINS, STEPS } from "../consts";
import { useEffect } from "react";
import {
  useLazyGetUserStatusQuery,
  useUpdateUserDetailsMutation,
} from "../../../redux/services/newsletter-protected";
import {
  useUpdateMortUserMutation,
  useLazyConfirmExistingUserQuery,
  useLazyGetMortUserStatusQuery,
} from "../../../redux/services/mortgage-personal-area";
import useModal from "../../components/modal/hooks/useModal";
import { MODAL_TYPES } from "../../components/modal/consts";
import { LOGIN_TYPE } from "../../../redux/consts";
import { HttpStatusCode } from "axios";

export default function useLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const shouldApproveTerms = useSelector(
    (state) => state.login.shouldApproveTerms
  );

  const adsApproved = useSelector((state) => state.login.adsApproved);
  const policyConfirmed = useSelector((state) => state.login.policyConfirmed);
  const phone = useSelector((state) => state.login.phone);
  const code = useSelector((state) => state.login.code);
  const step = useSelector((state) => state.login.currentStep);
  const idNum = useSelector((state) => state.login.idNum);
  const otpSent = useSelector((state) => state.login.otpSent);
  const existingUserData = useSelector((state) => state.login.existingUserData);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const loginType = useSelector((state) => state.login.type);

  const isMortLogin = loginType === LOGIN_TYPE.MORT_PERSONAL_AREA;

  const { open: openModal } = useModal();

  const [sendPhone, { isLoading: isSending, error: phoneError }] =
    useSendPhoneMutation();
  const [
    sendPhoneWithId,
    { isLoading: isSendingWithId, error: phoneErrorWithId },
  ] = useSendPhoneWithIdMutation();
  const [login, { isLoading: isLoggingIn, error: codeError }] =
    useLoginMutation();
  const [updateUserDetails, { isLoading: isGettingUserDetails }] =
    useUpdateUserDetailsMutation();
  const [updateMortUser, { isLoading: isCheckingMortUser }] =
    useUpdateMortUserMutation();

  const [getUserStatus, userStatus] = useLazyGetUserStatusQuery();

  const [getMortUserStatus, mortUserStatus] = useLazyGetMortUserStatusQuery();

  const [confirmExistingMortUser, existingMortUserQuery] =
    useLazyConfirmExistingUserQuery();

  const handleMortSendPhone = async () => {
    const response = await confirmExistingMortUser(phone);
    if (
      response.isError &&
      response.error?.status === HttpStatusCode.NotFound
    ) {
      openModal({ type: MODAL_TYPES.MORT_NO_QUE_FILLED });
      return false;
    }
    return await sendPhoneWithId({
      phone,
      idNum,
      origin: ORIGINS.WECHECK,
    }).unwrap();
  };

  const sendOTP = async () => {
    const response = isMortLogin
      ? await handleMortSendPhone()
      : await sendPhone({ phone, origin: ORIGINS.RENT_AI }).unwrap();
    if (response) {
      if (isMortLogin) {
        const res = await getMortUserStatus();
        if (res?.isError) return;
        dispatch(setOtpResponse(response?.data));
      } else {
        await getUserStatus();
        dispatch(setOtpResponse(response?.data));
      }
    }
  };

  const loginAsync = async () => {
    const response = await login({ code, adsApproved }).unwrap();
    if (response) {
      const res = isMortLogin
        ? await updateMortUser()
        : await updateUserDetails({
          isApprovedAds:
            shouldApproveTerms ||
              (!existingUserData?.adsApproved && adsApproved)
              ? adsApproved
              : false,
        });
      if (res) {
        !isMortLogin && Utils.gaVirtual('לוגין RentAI - התחברות בהצלחה');
        dispatch(setIsLoggedIn(true));
      }
    }
  };

  const onSubmit = async () => {
    if (step === STEPS.PHONE) {
      await sendOTP();
    } else if (step === STEPS.CODE) {
      await loginAsync();
    }
  };

  useEffect(() => {
    dispatch(init());
    const params = new URLSearchParams(location.search);
    const type = params.get("type");
    type && dispatch(setLoginType(type));
  }, []);

  useEffect(() => {
    if (isLoggedIn) {
      isMortLogin ? navigate(URLS.MortPersonalArea) : navigate(URLS.Newsletter);
    }
  }, [isLoggedIn]);

  const handleExistingUserData = (user) =>
    dispatch(
      setExistingUserData({
        policyConfirmed: user?.policy_confirmed_date || false,
        isAdmin: user?.is_admin || false,
      })
    );

  useEffect(() => {
    if (!isMortLogin || mortUserStatus?.isLoading)
      return;
    if (mortUserStatus?.error?.status === HttpStatusCode.NotFound) {
      openModal({ type: MODAL_TYPES.MORT_NO_QUE_FILLED });
    } else if (mortUserStatus?.data?.data) {
      handleExistingUserData(mortUserStatus?.data?.data);
    }
  }, [mortUserStatus]);

  useEffect(() => {
    if (isMortLogin) return;
    handleExistingUserData(userStatus?.data?.data);
  }, [userStatus]);

  useEffect(() => {
    if (otpSent) dispatch(setStep(STEPS.CODE));
  }, [dispatch, otpSent]);

  useEffect(() => {
    if (phoneErrorWithId?.status === HttpStatusCode.Forbidden) {
      openModal({ type: MODAL_TYPES.MORT_DATA_ERROR });
    }
  }, [phoneErrorWithId]);

  return {
    adsApproved,
    policyConfirmed,
    phone,
    code,
    step,
    idNum,
    isMortLogin,
    isSending:
      isSending ||
      isSendingWithId ||
      existingMortUserQuery?.isLoading,
    isFetchingUserStatus:
      userStatus?.isLoading ||
      mortUserStatus?.isLoading,
    isLoggingIn,
    phoneError,
    codeError,
    onSubmit,
    sendOTP,
    shouldApproveTerms,
    existingUserData,
    isGettingUserDetails: isGettingUserDetails || isCheckingMortUser,
  };
}
