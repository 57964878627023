import React from "react";
import "./index.scss";
import Container from "../../../../../components/product-pages/container";

export default function Accordion({ isOpen, children, className = "" }) {
  return (
    <Container
      className={`accordion-container ${isOpen ? "is-open" : ""} ${className}`}
    >
      {children}
    </Container>
  );
}
