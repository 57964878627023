import React from "react";
import Utils from "../../../shared/utils";
import BottomSection from "./components/bottom-section";
import MortgageContactForm from "./components/contact-form";
import MiddleSection from "./components/middle-section";
import TopSection from "./components/top-section";
import VideoSection from "./components/video-section";
import "./index.scss";

export default function MortgageLandingPage() {
    return <div className="MortgageLandingPageContainer">
        <TopSection />
        <MortgageContactForm />
        <MiddleSection />
        <BottomSection />
        <VideoSection />
    </div>
}