import React, { useEffect } from "react";

import SectionWrapper from "../../../components/section-wrapper";

import "./index.scss";

const AccessPage = React.memo((props) => {
  useEffect(() => {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  });

  return (
    <div className="AccessPage">
      <SectionWrapper withoutBrikim className="SafeCheck" id="">
        <h1>הסדרי נגישות</h1>

        <article>
          <h3>מבוא</h3>
          <p>
            האינטרנט מהווה כיום את המאגר הגדול ביותר לחופש המידע עבור כלל
            המשתמשים, ומשתמשים בעלי מוגבלויות בפרט. ככזה, אנו שמים חשיבות רבה
            במתן אפשרות שווה לאנשים עם מוגבלות לשימוש במידע המוצג באתר, ולאפשר
            חווית גלישה טובה יותר.
          </p>
          <p>
            אנו שואפים להבטיח כי השירותים הדיגיטליים יהיו נגישים לאנשים עם
            מוגבלויות, ועל כן הושקעו משאבים רבים להקל את השימוש באתר עבור אנשים
            עם מוגבלויות, ככל האפשר, מתוך אמונה כי לכל אדם מגיעה הזכות לחיות
            בשוויון, כבוד, נוחות ועצמאות.
          </p>
          <p>
            כדי לממש הבטחה זו, אנו שואפים לדבוק ככל האפשר בהמלצות התקן הישראלי
            (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA ומסמך WCAG2.1 הבינלאומי.
          </p>
          <br />
        </article>

        <article>
          <h3>כיצד עובדת ההנגשה באתר?</h3>
          <p>
            באתר מוצב תפריט הנגשה של חברת
            <a href="https://www.nagich.co.il/"> הנגשת אתרים</a> - נגיש בקליק. 
            לחיצה על כפתור הנגישות מאפשרת את פתיחת התפריט המכיל כפתורי ההנגשה.
            לאחר בחירת נושא בתפריט יש להמתין לטעינת הדף.
          </p>
          <p>
            התוכנה פועלת בדפדפנים הפופולריים: Chrome, Firefox, Safari, Opera.
            אחריות השימוש והיישום באתר חלה על בעלי האתר ו/או מי מטעמו לרבות
            התוכן המוצג באתר בכפוף למגבלות{" "}
            <a href="https://www.nagich.co.il/html5/sbs.py?_id=8684&did=5905&G=8684">
              ולתנאי השימוש
            </a>{" "}
            בתוכנה.
          </p>
          <br />
        </article>

        <article>
          <h3>אפשרויות הנגישות בתפריט</h3>
          <ol>
            <li>התאמה לניווט מקלדת - מתן אפשרות לניווט ע"י מקלדת </li>
            <li>
              התאמה לקורא מסך - התאמת האתר עבור טכנולוגיות מסייעות כגון NVDA ,
              JAWS
            </li>
            <li>חסימת הבהובים - עצירת אלמנטים נעים וחסימת הבהובים</li>
            <li>הגדלת פונט האתר ל-4 גדלים שונים</li>
            <li>התאמות ניגודיות - שינוי ניגודיות צבעי האתר</li>
            <li>התאמת האתר לעיוורי צבעים</li>
            <li>שינוי הפונט לקריא יותר</li>
            <li>הגדלת הסמן ושינוי צבעו לשחור או לבן</li>
            <li>הגדלת התצוגה לכ-200%</li>
            <li>הדגשת קישורים באתר</li>
            <li>הצגת תיאור אלטרנטיבי לתמונות</li>
            <li>הצהרת נגישות</li>
            <li>שליחת משוב נגישות</li>
          </ol>
          <br />
        </article>

        <article>
          <h3>שימוש ברכיבים ואתרי צד שלישי</h3>
          <p>
            כאשר נעשה שימוש ברכיבים או אתרי אינטרנט של צד שלישי באתר כגון
            פייסבוק, אינסטגרם, יוטיוב, טוויטר, צ׳אטים חיצוניים ואחרים שאינם
            בשליטתנו, ייתכן והדבר יציב אתגרים בשימוש באתר בידי אנשים בעלי
            מוגבלויות שאין ביכולתנו לתקן.
          </p>
          <br />
          <p>להלן מספר דוגמאות למדיניות הנגישות באתרי צד שלישי:</p>
          <ol>
            <li>
              מדיניות הנגישות של{" "}
              <a href="https://www.facebook.com/help/273947702950567">
                פייסבוק
              </a>
            </li>
            <li>
              מדיניות הנגישות של{" "}
              <a href="https://support.google.com/youtube/answer/189278?hl=en">
                יוטיוב
              </a>
            </li>
            <li>
              מדיניות הנגישות של{" "}
              <a href="https://help.instagram.com/1178723545597542">אינסטגרם</a>
            </li>
            <li>
              מדיניות הנגישות של{" "}
              <a href="https://help.twitter.com/en/using-x/picture-descriptions">
                טוויטר
              </a>
            </li>
          </ol>
          <br />
        </article>

        <article>
          <h3>פניה לרכז הנגישות</h3>
          <p>
            אם במהלך הגלישה באתר נתקלתם בקושי בנושא נגישות, צוות הנגישות של
            החברה עומד לרשותכם במגוון ערוצים לפנייה בנושאי נגישות, נשמח לקבל מכם
            משוב.
          </p>
          <h4>רכז נגישות - מר יותם כהן</h4>
          <p className="withSpacing">
            ניתן להגיש תלונה לרכזת הנגישות באחת מהדרכים הבאות:
          </p>
          <p className="withSpacing">
            <strong>טלפון:</strong> 03-3030211
          </p>
          <p className="withSpacing">
            <strong>כתובת למשלוח דואר:</strong> הקישון 8, בני ברק, מיקוד
            5120308, לידי וויצק בע"מ 
          </p>
          <br />
        </article>

        <article>
          <h3>פרסום הסדרי נגישות בחברה  (בהתאם לארגון, אופיו ושרותיו)</h3>
          <p>פירוט הסדרי הנגישות הפיזיים:</p>
          <ol>
            <li>
              ספר חניות נכים בעסק או בסביבה  -{" "}
              <strong>בסביבה כ-100 מטר מבית העסק קיימות 2 חניות נכים</strong>
            </li>
            <li>
              דרכי גישה לעסק - <strong>דרכי הגישה לעסק מונגשות</strong>
            </li>
            <li>
              תיאור דלתות כניסה (יש משמעות לרוחב הדלת) -{" "}
              <strong>רחבות ומונגשות</strong>
            </li>
            <li>
              אזורי המתנה, מקומות ישיבה - <strong>קיים</strong>
            </li>
            <li>
              שירותי נכים בעסק או בבניין או בקומה -{" "}
              <strong>צמוד לבניין A בקומת קרקע</strong>
            </li>
            <li>
              מעליות גישה בבניין - <strong>קיים</strong>
            </li>
            <li>
              דרכי התקשרות חלופיות לטלפון (מייל, פקס, ווטצאפ, sms) -{" "}
              <strong>ווטצאפ ,מייל</strong>
            </li>
            <li>
              מידע בפורמטים נגישים, פרסומים נגישים - <strong>קיים באתר</strong>
            </li>
            <li>
              נהלי שירות נגישים:{" "}
              <strong>
                ניתן להכניס חיות שירות למתחם. עזרה במילוי טפסים. פטור מתור
              </strong>
            </li>
          </ol>
          <p className="disclaimer">תאריך עדכון אחרון: 15/02/2024</p>
          <br />
        </article>

        <article>
          <h3>הבהרה</h3>
          <p>
            חרף מאמצנו לאפשר גלישה באתר נגיש עבור כל דפי האתר, יתכן ויתגלו דפים
            באתר שטרם הונגשו, או שטרם נמצא הפתרון הטכנולוגי המתאים.
          </p>
          <br />
          <p>
            אנו ממשיכים במאמצים לשפר את נגישות האתר, ככל האפשר, וזאת מתוך אמונה
            ומחויבות מוסרית לאפשר שימוש באתר לכלל האוכלוסייה לרבות אנשים עם
            מוגבלויות.
          </p>
          <br />
        </article>
      </SectionWrapper>
    </div>
  );
});

export { AccessPage };
