import React from "react";
import Utils from "../../../shared/utils";

import AnchorTagWithQueryParams from "../../anchor-tag-with-params";

import { CTA_BUTTON_TEXT } from "./consts";

import "./index.scss";

export default function CTAButton({text = CTA_BUTTON_TEXT, className = "", href = Utils.getLoginLink(), analytics = {}}) {
    const {CATEGORY, TRIGGER, ACTION} = analytics

    return <AnchorTagWithQueryParams
        text={text}
        href={href}
        onClick={() => {
            Utils.gaEvent(CATEGORY, ACTION, TRIGGER)
        }}
        className={`cta-button ${className}`}
    />
}