const CTA_CLICK_TRIGGER = `לחיצה על כפתור לקבלת שכר הדירה מראש`

const EVENT_CATEGORY = `עמוד מוצר - צ'ק בטוח`

export const ANALYTICS = {
    FIRST_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `לקבלת שכר הדירה מראש - סקשן פותח`
        }
    },
    SECOND_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `הבטיחו לעצמכם שנה של שקט - לקבלת שכר הדירה מראש`
        }
    },
    THIRD_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `יתרונות - לקבלת שכר הדירה מראש`
        }
    },
    FOURTH_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `אלפי לקוחות כבר קיבלו - לקבלת שכר הדירה מראש`
        }
    }
}