import React from "react";
import { useState } from "react";
import Video from "../../../../../components/video-player";
import Utils from "../../../../../shared/utils";
import { texts } from "../../consts";
import "./index.scss";

export default function VideoSection () {
    const [showVideo, setShowVideo] = useState(false);
    
    return <section className="VideoSection">
        <Video
				onClose = {() => {
					setShowVideo(false);
				}}
				visible = {showVideo}
				videoURL = {"mort"}
			/>
        <div className="background"/>
        <div className="title-container">
            <h2>{texts.videoSection.title}</h2>
        </div>
        <div onClick={() => {
            setShowVideo(true)
            Utils.gaEvent(
               texts.events.category,
               texts.events.actions.PLAY_VIDEO.action,
               texts.events.actions.PLAY_VIDEO.label
            )
        }} className="video-image">
            <div className="image-container">
            <img src={Utils.buildMortImgLink("Icons/play.svg")}/>
            </div>
        </div>
    </section>
}