import React from "react";
import "./index.scss";
import { INFO_BUTTON_TEXT, INFO_TEXT } from "./texts";
import { useDispatch, useSelector } from "react-redux";
import { handleInfoDisplay } from "../../../../../../redux/features/newsletterSlice";

export default function DisplayInfo() {
  const dispatch = useDispatch();

  const handleDisplay = () => {
    dispatch(handleInfoDisplay());
  };
  const isInfoOpen = useSelector(
    (state) => state.newsletter.filter.infoDisplay
  );

  return (
    <div className={`display-info-container ${isInfoOpen ? "is-open" : ""}`}>
      <div className="text-container">
        <p>{INFO_TEXT}</p>
      </div>
      <button onClick={handleDisplay}>{INFO_BUTTON_TEXT}</button>
    </div>
  );
}
