import React, { useRef } from "react";
import useCTAAnimation from "../../../../../../../hooks/product-pages/useCTAAnimation";

import ItemRow from "../../../../../../../components/product-pages/item-row";
import Container from "../../../../../../../components/product-pages/container";

import { ITEM_ROW, ITEM_ROW_TITLE } from "../../consts";
import { CTA_SECTION_TITLE } from "./texts";

import "./index.scss";
import { ANALYTICS } from "../../../../consts";

export default function BottomSection() {
    const CTARef = useRef(null)
    const itemRowRef = useRef(null)

    const CTAContainerAnimated = useCTAAnimation({
        className: "airplane-animation-and-cta",
        buttonClassName: "animate-appearance",
        ctaTitle: CTA_SECTION_TITLE,
        ref: CTARef,
        analytics: ANALYTICS.SECOND_SECTION.CTA
    })

    return <Container className="bottom-section-container">
        <ItemRow
            reference={itemRowRef}
            title={ITEM_ROW_TITLE}
            list={ITEM_ROW} />
        {CTAContainerAnimated}
    </Container>
}