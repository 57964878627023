import React from "react";

import Utils from "../shared/utils";

const FeaturesList = ({features}) =>
	features.map((feature, index) => (
		<div className = "IconInfoLine" key = {index} >
			<div >
				<img src = {Utils.buildImgLink("bluecheck.svg")} alt = "" />
			</div >
			<div className = "infoLine" >
				<p value = {feature} >{feature}</p >
			</div >
		</div >
	));

export {FeaturesList};
