import Utils from "../../../../../shared/utils";

export const ITEM_LIST_TITLE = `דו"ח הדאבל צ'ק של WeCheck כולל:`

export const ITEM_LIST = [
    {
        icon: Utils.buildProductPageImageLink("icons/group-icon.svg"),
        text: "פרטי הדיירים הפוטנציאליים- שם, טלפון, כתובת מייל, גיל, מצב משפחתי"
    },
    {
        icon: Utils.buildProductPageImageLink("icons/alert-icon.svg"),
        text: "מידע כלכלי - האם לדייר הפוטנציאלי יש נכסים (למשל דירה, מכונית או כרטיס אשראי), חשבון בנק מוגבל, הכנסות של משק הבית, האם חזרו לו צ'קים בעבר וכדומה."
    },
    {
        icon: Utils.buildProductPageImageLink("icons/scales-icon.svg"),
        text: "מידע משפטי - האם הוא בתהליכים של פשיטת רגל וכדומה."
    },
    {
        icon: Utils.buildProductPageImageLink("icons/buildings-icon.svg"),
        text: "מגורים קודמים - מדוע הוא עזב את הדירה הקודמת והאם גם שם גר בשכירות."
    },
    {
        icon: Utils.buildProductPageImageLink("icons/presentation-icon.svg"),
        text: "תעסוקה ולימודים - האם עובדים והיכן, ותק במקום העבודה, תמהיל ההכנסה שלהם אל מול שכר הדירה, האם למדו או לומדים."
    },
]

export const ITEM_ROW_TITLE = "איך זה עובד?"

export const ITEM_ROW = [
    {
        icon: Utils.buildProductPageImageLink("icons/checklist-icon.svg"),
        text: "מזינים את פרטיי הדייר\nממלאים רק את שם ומספר\nהטלפון של הדייר/ת"
    },
    {
        icon: Utils.buildProductPageImageLink("icons/document-icon.svg"),
        text: "מעדכנים את פרטי הנכס\nממלאים את פרטי הנכס\nופרטי הרשמה"
    },
    {
        icon: Utils.buildProductPageImageLink("icons/spaceship-icon.svg"),
        text: "אנחנו שולחים לדייר/ת שאלון קצר\nאוספים נתונים נוספים ומעבדים\nאת המידע לדוח הדאבל צ'ק"
    },
    {
        icon: Utils.buildProductPageImageLink("icons/pawn-icon.svg"),
        text: "הדאבל צ'ק בדרך אליכם!\nהדאבל צ'ק המוכן לצפיה\nישלח אליכם ואל הדייר/ת"
    },
    {
        icon: Utils.buildProductPageImageLink("icons/umbrella-icon.svg"),
        text: "קיבלתם הצעה לשירותי ערבות?\nמעולה! זה אומר שתוכלו להבטיח\nלעצמכם את שכר הדירה"
    },
]

export const VIDEO_URL = "https://www.youtube.com/watch?v=agcAIXXuoPo";