import React from "react";
import "./index.scss";

export default function ResendCode({ onClick, disabled, text, countdown }) {
  const handleClick = (event) => {
    if (!disabled) {
      onClick(event);
    }
  };

  return (
    <button
      type="button"
      disabled={disabled}
      onClick={handleClick}
      className="resend-code"
      aria-label="Resend Code"
    >
      {text} {countdown ? `(${countdown})` : ""}
    </button>
  );
}
