import { configureStore } from "@reduxjs/toolkit";
import newsletterReducer from "./features/newsletterSlice";
import loginReducer from "./features/loginSlice";
import promotionalReducer from "./features/promotionalSlice";
import userReducer from "./features/userSlice";
import modalReducer from "./features/modalSlice";
import loansReducer from "./features/loansSlice";
import flagsReducer from "./features/flagSlice";
import { loginApi } from "./services/login";
import { newsletterApi } from "./services/newsletter";
import { newsletterProtectedApi } from "./services/newsletter-protected";
import { mortgagePersonalAreaApi } from "./services/mortgage-personal-area";
import { setupListeners } from "@reduxjs/toolkit/dist/query";
import { flagsApi } from "./services/flags";
import { addressApi } from "./services/address";

export const store = configureStore({
  reducer: {
    newsletter: newsletterReducer,
    login: loginReducer,
    promotional: promotionalReducer,
    user: userReducer,
    modal: modalReducer,
    loans: loansReducer,
    flags: flagsReducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [newsletterApi.reducerPath]: newsletterApi.reducer,
    [newsletterProtectedApi.reducerPath]: newsletterProtectedApi.reducer,
    [mortgagePersonalAreaApi.reducerPath]: mortgagePersonalAreaApi.reducer,
    [flagsApi.reducerPath]: flagsApi.reducer,
    [addressApi.reducerPath]: addressApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(
      loginApi.middleware,
      newsletterApi.middleware,
      newsletterProtectedApi.middleware,
      mortgagePersonalAreaApi.middleware,
      flagsApi.middleware,
      addressApi.middleware
    ),
});

setupListeners(store.dispatch);
