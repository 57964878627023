import Layout from "../components/layout";
import { URLS } from "../shared/urls";
import { MortCalculator } from "./pages/calculator";
import { MortInfoNew } from "./pages/main";
import { MortQNA } from "./pages/qna";
import { MortTestimonialsPage } from "./pages/testimonials";
import { questions } from "./pages/qna/consts";
import FourOhFourPage from "../components/404";
import PersonalArea from "../mortgage/personal-area";

import "./index.scss";

export const NewMortgageRouter = ({ isMortPersonalAreaActive }) => ({
  path: URLS.MortInfo,
  element: <Layout />,
  errorElement: <FourOhFourPage />,
  children: [
    {
      path: URLS.MortInfo,
      element: <MortInfoNew />,
    },
    {
      path: URLS.MortCalc,
      element: <MortCalculator />,
    },
    {
      path: URLS.MortQNA,
      element: <MortQNA questions={questions} />,
    },
    {
      path: URLS.MortTestimonials,
      element: <MortTestimonialsPage />,
    },
    isMortPersonalAreaActive
      ? {
          path: URLS.MortPersonalArea,
          element: <PersonalArea />,
        }
      : null,
  ].filter(Boolean),
});
