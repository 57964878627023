import React  from "react";
import {Link} from "react-router-dom";

import {LogoLine} from "../../../../components/logo-line";
import {URLS}     from "../../../../shared/urls";
import Utils      from "../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../components/logo";

const TopSection = () => (
	<div className = "TopSection" >
		<div className = "textPart highZ" >
			<div className = "desktopOnly" >
				<h2 >
					אז מה זה בעצם <strong >משכנתא משלימה?</strong >
				</h2 >
			</div >

			<div className = "mobileOnly" >
				<h3 >אז מה זה בעצם</h3 >
				<h2 >משכנתא משלימה?</h2 >
			</div >

			<p >
				מכירים את זה שהבנק קובע את גודל המשכנתא שהוא נותן לכם – לפי ההון
				העצמי שלכם?
			</p >

			<div className = "desktopOnly" >
				<p >
					אבל לפעמים, ההון העצמי לא מספיק
					<strong > לנכס שאתם באמת רוצים. </strong >
					מצד שני, יכולת ההחזר החודשית שלכם מאפשרת לכם יותר.
				</p >
				<LogoLine before = "בדיוק בשביל זה יצרנו את המשכנתא המשלימה של" ></LogoLine >
			</div >

			<div className = "mobileOnly" >
				<br />
				<p >אבל לפעמים, ההון העצמי לא מספיק</p >
				<p >
					<strong > לנכס שאתם באמת רוצים. </strong >
					מצד שני, יכולת ההחזר החודשית שלכם מאפשרת לכם יותר.
				</p >
				<br />
				<p >בדיוק בשביל זה יצרנו את המשכנתא המשלימה</p >
				<LogoLine before = "של" ></LogoLine >
			</div >

			<br />
			<img
				className = "bricksImage"
				src = {Utils.buildDashboardImgLink("mortgage/briksline.svg")}
				alt = ""
			/>

			<div className = "desktopOnly" >
				<div className = "logoLine" >
					<p >אגב, ניתן לקבל משכנתא משלימה של </p >
					<Logo color="blue" />
					<p >
						בעת רכישת נכס יחיד, חדש\להשקעה, <strong > או </strong >{" "}
						כנגד נכס קיים שברשותכם.{" "}
					</p >
				</div >
			</div >

			<div className = "smallLogo mobileOnly" >
				<LogoLine before = "אגב, ניתן לקבל משכנתא משלימה של" ></LogoLine >
				<p >
					בעת רכישת נכס חדש/נכס להשקעה, <strong > או </strong > כנגד נכס
					קיים שברשותכם.{" "}
				</p >
			</div >

			<div className = "buttonBox" >
				<a
					onClick = {() => {
						Utils.gaEvent("עמוד ראשי משכנתאות", "לבדיקת זכאות", "");
						Utils.gaEvent("הגעה לשאלון משכנתאות", "עמוד ראשי", "");
					}}
					href = {URLS.MortgageQue}
					className = "btn--oldred"
				>
					{" "}
					לבדיקת זכאות
				</a >
				<Link
					onClick = {() =>
						Utils.gaEvent(
							"עמוד ראשי משכנתאות",
							"מחשבון משכנתא משלימה",
							""
						)
					}
					to = {URLS.MortCalc}
					className = "btn--white"
				>
					<img
						className = "calcImg"
						src = {Utils.buildDashboardImgLink(
							"mortgage/redcalc.svg"
						)}
						alt = ""
					/>
					מחשבון משכנתא משלימה
				</Link >
			</div >
		</div >
	</div >
);

export {TopSection};
