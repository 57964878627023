import React from "react";

import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";

import {
  SECOND_SECTION_TEXT,
  SECOND_SECTION_TITLE,
  SECTION_TEXT,
  SECTION_TITLE,
} from "./texts";

import "./index.scss";
import { ITEM_LIST, SECOND_SECTION_IMAGE } from "./consts";
import ItemList from "../../../../../components/product-pages/item-list";
import AnimationContainer from "../../../../../components/product-pages/animation-container";
import Container from "../../../../../components/product-pages/container";

export default function SecondSection() {
  return (
    <GridSection className="reduced-mort-second-section">
      <AnimationContainer
        className="reduced-mort-second-section__animation"
        img={SECOND_SECTION_IMAGE}
      />
      <TextSection title={SECTION_TITLE} text={SECTION_TEXT} />
      <Container className="reduced-mort-second-section__bottom-section">
        <TextSection title={SECOND_SECTION_TITLE} text={SECOND_SECTION_TEXT} />
        <ItemList list={ITEM_LIST} />
      </Container>
    </GridSection>
  );
}
