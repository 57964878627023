import Utils from "../../shared/utils";

const GENERAL_CATEGORY = "אתר שיווקי - Footer"

export const socialList = [
    {
        icon    : Utils.buildMortImgLink("NewAssets/facebook-icon.svg"),
        iconName: "Facebook icon",
        href    : "https://www.facebook.com/WeCheck.4U/",
        category: GENERAL_CATEGORY,
        action  : "לחיצה על לוגו Facebook",
        label   : "לוגו פייסבוק - Footer"
    },
    {
        icon    : Utils.buildMortImgLink("NewAssets/instagram-icon.svg"),
        iconName: "Instagram icon",
        href    : "https://www.instagram.com/wecheck_ltd/",
        category: GENERAL_CATEGORY,
        action  : "לחיצה על לוגו Instagram",
        label   : "לוגו אינסטגרם - Footer"
    },
    {
        icon    : Utils.buildMortImgLink("NewAssets/linkedin-icon.svg"),
        iconName: "LinkedIn icon",
        href    : "https://www.linkedin.com/company/72698846/admin/",
        category: GENERAL_CATEGORY,
        action  : "לחיצה על לוגו LinkedIn",
        label   : "לוגו לינקדאין - Footer"
    }
];