import React, { useRef } from "react";

import AnimationContainer from "../../../../../components/product-pages/animation-container";
import Container from "../../../../../components/product-pages/container";
import CTAButton from "../../../../../components/product-pages/CTAButton";
import Section from "../../../../../components/product-pages/section";
import useIsInViewport from "../../../../../hooks/useIsInViewport";
import useIsMobile from "../../../../../hooks/useIsMobile";
import TextContainer from "./components/text-container";

import { ANALYTICS } from "../../consts";
import { FIRST_SECTION_ANIMATION, POINT_ANIMATION } from "./consts";

import "./index.scss";

export default function FirstSection() {
    const rightSideRef = useRef(null)
    const isInViewport = useIsInViewport(rightSideRef)
    const isMobile = useIsMobile();
    return <Section className="doublecheck-first-section-container">
        <Container reference={rightSideRef} className={`right-side-container ${isInViewport ? "in-viewport" : ""}`}>
            <TextContainer />
            <Container className="animation-and-cta">
                <CTAButton analytics={ANALYTICS.FIRST_SECTION.CTA}/>
                {!isMobile && <div className="hide-animation">
                    <AnimationContainer img={POINT_ANIMATION} />
                </div>}
            </Container>
        </Container>
        <AnimationContainer img={FIRST_SECTION_ANIMATION} />
    </Section>
}