import React from "react";
import "./index.scss";

export default function TextSection({ text, title = "" }) {
  return (
    <div className="modal-text-section-container">
      {title && <h2>{title}</h2>}
      <p>{text}</p>
    </div>
  );
}
