import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedProperties } from "../../../../../../../../redux/features/newsletterSlice";

const POSSIBLE_PROPERTIES = ["משופץ", "מרפסת", 'ממ"ד', "חניה"];

export default function PropertyMultiSelect({ title }) {
  const dispatch = useDispatch();

  const dataViewSearchProperties = useSelector(
    (state) => state.newsletter.dataView?.selectedProperties
  );

  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const handleSelect = (property) => {
    if (!isLoggedIn) return;
    const updatedProperties = dataViewSearchProperties.includes(property)
      ? dataViewSearchProperties.filter((item) => item !== property)
      : [...dataViewSearchProperties, property];

    dispatch(setSelectedProperties(updatedProperties));
  };

  return (
    <div className="property-select-container">
      <h4>{title}</h4>
      <div className="property-list-container">
        {POSSIBLE_PROPERTIES.map((property, index) => {
          return (
            <div
              data-testid={`search-single-property-${property}`}
              onClick={() => handleSelect(property)}
              key={index}
              className={`single-property ${
                dataViewSearchProperties?.includes(property) ? "selected" : ""
              }`}
            >
              {property}
            </div>
          );
        })}
      </div>
    </div>
  );
}
