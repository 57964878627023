import Utils from "../../../../../../../shared/utils";

import "./index.scss";

const HeaderButton = ({ number, text }) => (
  <div className="HeaderButton">
    <h4>{number}</h4>
    <h5>{text}</h5>
  </div>
);

const ButtonsHeader = ({ isYad2 }) => {
  const thirdButtonText = isYad2 ? "הפרופיל הדיגיטלי נשלח לבעל הדירה" : "הפרופיל הדיגיטלי אצלכם בנייד" 
  return (
    <div className="ButtonsHeader">
      <HeaderButton text="ממלאים את הפרטים מטה" number="1" />
      <img
        className="leftArrow"
        src={Utils.buildImgLink("leftArrow.svg")}
        alt=""
      />
      <HeaderButton text="מקבלים שאלון ב - SMS" number="2" />
      <img
        className="leftArrow"
        src={Utils.buildImgLink("leftArrow.svg")}
        alt=""
      />
      <HeaderButton text={thirdButtonText} number="3" />
    </div>
  );
};

export { ButtonsHeader };
