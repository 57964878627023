import { createApi } from "@reduxjs/toolkit/query/react";
import { AUTH_SERVICE } from "../consts";
import config from "../../config/config";
import { axiosBaseQuery } from "../../api-client";

export const loginApi = createApi({
  reducerPath: "loginApi",
  baseQuery: axiosBaseQuery({
    baseUrl: config.serverlessAddr ? config.serverlessAddr : config.AUTH_LOCAL,
  }),
  endpoints: (builder) => ({
    sendPhone: builder.mutation({
      query: ({phone, origin}) => {
        return {
          url: `${AUTH_SERVICE}/send-otp`,
          method: "POST",
          body: {
            phoneNumber: phone,
            origin,
          },
        };
      },
      extraOptions: {
        keepUnusedDataFor: 0,
      },
    }),
    sendPhoneWithId: builder.mutation({
      query: ({phone, idNum, origin}) => {
        return {
          url: `${AUTH_SERVICE}/send-otp-with-id`,
          method: "POST",
          body: {
            phoneNumber: phone,
            idNum,
            origin,
          },
        };
      },
      extraOptions: {
        keepUnusedDataFor: 0,
      },
    }),
    login: builder.mutation({
      query: ({code, adsApproved}) => {
        return {
          url: `${AUTH_SERVICE}/login`,
          method: "POST",
          body: {
            otp: code,
            approvedAds: adsApproved,
          },
        };
      },
      extraOptions: {
        keepUnusedDataFor: 0,
      },
    }),
    logout: builder.mutation({
      query: () => {
        return {
          url: `${AUTH_SERVICE}/logout`,
          method: "POST",
        };
      },
    }),
    getUserDetails: builder.query({
      query: () => {
        return {
          url: `${AUTH_SERVICE}/user-details`,
          method: "GET",
        };
      },
    }),
    refreshToken: builder.mutation({
      query: () => {
        return {
          url: `${AUTH_SERVICE}/refresh-token`,
          method: "POST",
        };
      },
      extraOptions: {
        keepUnusedDataFor: 0,
      },
    }),
  }),
});

export const {
  useSendPhoneMutation,
  useLoginMutation,
  useLogoutMutation,
  useGetUserDetailsQuery,
  useRefreshTokenMutation,
  useSendPhoneWithIdMutation,
} = loginApi;

export const { endpoints } = loginApi;
