import React from "react";

import {useOutletContext} from "react-router-dom";

import {Calc}                         from "./components/calculator";
import {MortContactUs}                from "./components/contact";
import {OurLoans}                     from "./components/loans";
import {MainSection}                  from "./components/main";
import {TheWeCheckMortgage}           from "./components/mortgage";
import {SoWhatIsTheProcess}           from "./components/process";
import {MortgageAdvisersTestimonials} from "./components/testimonials";
import {WhoWeAre}                     from "./components/who-are-we";
import {WhyWeCheck}                   from "./components/why-wecheck";

import "./index.scss";

function MortInfoNew() {
	const [refs, videoURLS, mortRefs] = useOutletContext();

	return (
		<div className = "MortInfoNew" >
			<MainSection />
			<OurLoans reference = {mortRefs.mortLoans} />
			<WhyWeCheck />
			<MortgageAdvisersTestimonials />
			<TheWeCheckMortgage />
			<SoWhatIsTheProcess />
			<Calc />
			<WhoWeAre reference = {mortRefs.mortWhoWeAre} />
			<MortContactUs reference = {mortRefs.mortContact} />
		</div >
	);
}

export {MortInfoNew};
