import Utils from "../../../shared/utils";

export const MAP_IMAGES = {
    TEL_AVIV: {
        BUSINESS: Utils.buildNewsletterLink("maps/tel-aviv/business.svg"),
        CENTER: Utils.buildNewsletterLink("maps/tel-aviv/center.svg"),
        FULL: Utils.buildNewsletterLink("maps/tel-aviv/full.svg"),
        JAFFA: Utils.buildNewsletterLink("maps/tel-aviv/jaffa.svg"),
        NEW_NORTH: Utils.buildNewsletterLink("maps/tel-aviv/new-north.svg"),
        NORTH_EAST: Utils.buildNewsletterLink("maps/tel-aviv/north-east.svg"),
        NORTH: Utils.buildNewsletterLink("maps/tel-aviv/north.svg"),
        OLD_NORTH: Utils.buildNewsletterLink("maps/tel-aviv/old-north.svg"),
        SOUTH_EAST: Utils.buildNewsletterLink("maps/tel-aviv/south-east.svg"),
        SOUTH: Utils.buildNewsletterLink("maps/tel-aviv/south.svg"),
    },
    RISHON: {
        CENTER: Utils.buildNewsletterLink("maps/rishon/center.svg"),
        EAST_PROVIDENCE: Utils.buildNewsletterLink(
            "maps/rishon/east-providence.svg"
        ),
        EAST: Utils.buildNewsletterLink("maps/rishon/east.svg"),
        FULL: Utils.buildNewsletterLink("maps/rishon/full.svg"),
        NORTH: Utils.buildNewsletterLink("maps/rishon/north.svg"),
        WEST: Utils.buildNewsletterLink("maps/rishon/west.svg"),
    },
    HAIFA: {
        BAY: Utils.buildNewsletterLink("maps/haifa/bay.svg"),
        CARMEL_HEIGHTS: Utils.buildNewsletterLink(
            "maps/haifa/carmel-heights.svg"
        ),
        FULL: Utils.buildNewsletterLink("maps/haifa/full.svg"),
        HACARMEL: Utils.buildNewsletterLink("maps/haifa/hacarmel.svg"),
        HADAR: Utils.buildNewsletterLink("maps/haifa/hadar.svg"),
        KIRYAT_HAIM_SHMUEL: Utils.buildNewsletterLink(
            "maps/haifa/kiryat-haim-shmuel.svg"
        ),
        NAVE_SHAANAN_HEIGHTS: Utils.buildNewsletterLink(
            "maps/haifa/nave-shaanan-heights.svg"
        ),
        NAVE_SHANNAN_IZRAELIA: Utils.buildNewsletterLink(
            "maps/haifa/nave-shannan-izraelia.svg"
        ),
        UNDERCITY: Utils.buildNewsletterLink("maps/haifa/undercity.svg"),
        WEST: Utils.buildNewsletterLink("maps/haifa/west.svg"),
    },
    JERUSALEM: {
        CENTER: Utils.buildNewsletterLink("maps/jerusalem/center.svg"),
        EAST: Utils.buildNewsletterLink("maps/jerusalem/east.svg"),
        FULL: Utils.buildNewsletterLink("maps/jerusalem/full.svg"),
        NORTH_WEST_ALONIM: Utils.buildNewsletterLink(
            "maps/jerusalem/north-west-alonim.svg"
        ),
        NRTH: Utils.buildNewsletterLink("maps/jerusalem/nrth.svg"),
        ORANIM: Utils.buildNewsletterLink("maps/jerusalem/oranim.svg"),
        SOUTH: Utils.buildNewsletterLink("maps/jerusalem/south.svg"),
        WEST: Utils.buildNewsletterLink("maps/jerusalem/west.svg"),
    },
    BEER_SHEVA: {
        EAST: Utils.buildNewsletterLink("maps/beer-sheva/east.svg"),
        FULL: Utils.buildNewsletterLink("maps/beer-sheva/full.svg"),
        NORTH: Utils.buildNewsletterLink("maps/beer-sheva/north.svg"),
        SOUTH: Utils.buildNewsletterLink("maps/beer-sheva/south.svg"),
        WEST: Utils.buildNewsletterLink("maps/beer-sheva/west.svg"),
    },
    PETACH_TIKVA: {
        ARLOZEROV_TRUMPELDOR: Utils.buildNewsletterLink(
            "maps/petach-tikva/arlozerov-trumpeldor.svg"
        ),
        BELINSON: Utils.buildNewsletterLink("maps/petach-tikva/belinson.svg"),
        CENTER: Utils.buildNewsletterLink("maps/petach-tikva/center.svg"),
        EAST: Utils.buildNewsletterLink("maps/petach-tikva/east.svg"),
        FULL: Utils.buildNewsletterLink("maps/petach-tikva/full.svg"),
        KFAR_GANIM: Utils.buildNewsletterLink(
            "maps/petach-tikva/kfar-ganim.svg"
        ),
        MATALON: Utils.buildNewsletterLink("maps/petach-tikva/matalon.svg"),
        SIRKIN_HADAR_GANIM: Utils.buildNewsletterLink(
            "maps/petach-tikva/sirkin-hadar-ganim.svg"
        ),
        SOUTH_EAST: Utils.buildNewsletterLink(
            "maps/petach-tikva/south-east.svg"
        ),
        SOUTH: Utils.buildNewsletterLink("maps/petach-tikva/south.svg"),
    },
    OTHER: {
        ISRAEL: Utils.buildNewsletterLink("maps/other/israel.svg"),
        PLACEHOLDER: Utils.buildNewsletterLink("maps/other/placeholder.svg"),
    },
};
