import React, { useEffect, useMemo, useState } from "react";
import { getQueryParamsFromCookie } from "../../hooks/useCampaignParams";

export default function AnchorTagWithQueryParams({
  href,
  text,
  onClick,
  isAgentLink,
  className,
  children,
  overRideDefaultQueryParams,
  style = {},
  shouldOpenInNewTab,
}) {
  const [paramsToSend, setParamsToSend] = useState("");
  const params = getQueryParamsFromCookie();

  const formattedParams = useMemo(
    () => new URLSearchParams(params).toString(),
    [params]
  );

  useEffect(() => {
    setParamsToSend(
      params
        ? formattedParams && isAgentLink
          ? `&${formattedParams}`
          : `?${formattedParams}`
        : ""
    );
  }, [formattedParams]);

  return (
    <a
      style={style}
      target={shouldOpenInNewTab ? "_blank" : ""}
      rel={shouldOpenInNewTab ? "noopener noreferrer" : ""}
      className={className ?? ""}
      href={overRideDefaultQueryParams ? href : `${href}${paramsToSend}`}
      onClick={onClick}
    >
      {children ?? text}
    </a>
  );
}
