import Utils from "../../../shared/utils";

export const SEND_TO_LOGIN_IMAGE = Utils.buildNewsletterLink(
  "general/send-to-login.svg"
);

export const NOT_ENOUGH_DATA_GENERAL =
  Utils.buildNewsletterLink("errors/general.svg");

export const TEMP_CROSS_COUNTRY_VALUES = {
  currentMonth: {
    averageRent: 4465,
  },
  lastMonth: {
    averageRent: 4442,
  },
  monthToMonthDifference: {
    up: true,
    difference: 0.52,
  },
  // not needed currently, left for visibility sake
  // supplyDifference: {
  //     up: false,
  //     difference: 0,
  // },
  twoMonthsAgo: {
    averageRent: 4372,
  },
  lastYear: {
    averageRent: 4515,
  },
};
