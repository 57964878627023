import Utils from "../../../../../shared/utils";

export const ITEM_LIST = [
  {
    icon: Utils.buildProductPageImageLink("icons/submit-loan-icon.svg"),
    title: `הגשת בקשה`,
    text: `תוכלו למלא את הבקשה למשכנתא דרך האתר שלנו, בכל זמן ומכל מקום.`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/fast-reply-icon.svg"),
    title: `תשובה מהירה`,
    text: `תקבלו תשובה עקרונית תוך דקות, כדי שתוכלו להתקדם בתהליך במהירות.`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/personal-guidence-icon.svg"),
    title: `ייעוץ אישי`,
    text: `נציג מטעמנו יקיים איתכם שיחת ייעוץ כדי להבין את הצרכים וההעדפות שלכם, להתקדם ולעזור בתהליך.`,
  },
];
