import React, { useRef } from "react";

import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";
import CTAAnimationClick from "../../../../../components/product-pages/CTAAnimationClick";
import Container from "../../../../../components/product-pages/container";
import HeadingMD from "../../../../../components/product-pages/heading-md";

import { ANALYTICS } from "../../consts";
import { URLS } from "../../../../../shared/urls";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { CTA_TITLE, PARAGRAPHS, TITLE } from "./texts";

import "./index.scss";

export default function ThirdSection() {
    const CTARef = useRef()

    return <GridSection className="premium-mort-third-section">
        <HeadingMD text={TITLE} />
        {
            PARAGRAPHS.map(({ ...props }, index) => {
                return <TextSection key={index} {...props} />
            })
        }
        <Container className="animation-cta-container">
            <CTAAnimationClick
                analytics={ANALYTICS.THIRD_SECTION.CTA}
                reference={CTARef}
                title={CTA_TITLE}
                text={CTA_BUTTON_TEXT}
                CTALink={URLS.MortgageQue}
            />
        </Container>
    </GridSection>
}