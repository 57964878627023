import React from "react";
import GridSection from "../../../../../components/product-pages/grid-section";
import Card from "./components/card";
import { CARDS, TITLE } from "./consts";
import "./index.scss";
import RentAICard from "./components/rent-ai";

export default function ProductsSection() {
  return (
    <div className="products-section-wrapper">
      <h2>{TITLE}</h2>
      <GridSection className="products-section-container">
        <Card card={CARDS.DOUBLE_CHECK} />
        <Card card={CARDS.SAFE_CHEQUE} />
        <Card card={CARDS.ALL_IN_ADVANCE} />
        <RentAICard />
      </GridSection>
    </div>
  );
}
