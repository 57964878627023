import React, { useEffect } from "react";
import { useState } from "react";
import { Bar, BarChart, Cell, ResponsiveContainer, XAxis } from "recharts";

import "./index.scss";
import { useSelector } from "react-redux";
import { currentDate } from "../../../../../../redux/features/newsletterSlice";
import useIsMobile from "../../../../../hooks/useIsMobile";
import {
  BAR_CHART_COLORS,
  CATEGORY_GAP,
  ERROR_DISPLAY,
  MARGINS,
  RADIUS,
  xAxisProps,
} from "./consts";
import { NOT_ENOUGH_DATA } from "../graphs-section/texts";

export default function RentYearly({ currentYear, lastYear, error }) {
  const [data, setData] = useState(null);
  const date = useSelector(currentDate);
  const isMobile = useIsMobile(1000);

  useEffect(() => {
    setData([
      {
        name: date.lastYearDateString,
        rent: lastYear,
        color: "#3D5A80",
      },
      {
        name: date.currentMonthDateString,
        rent: currentYear,
      },
    ]);
  }, [currentYear, lastYear, date]);

  const CustomizedLabel = (props) => {
    const { x, y, stroke, value } = props;

    return (
      <text
        x={isNaN(x) ? "" : x + 33}
        y={isNaN(y) ? "" : y}
        dy={-4}
        fill={"#1D3557"}
        fontSize={14}
        fontWeight={700}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  };

  const marginTop = window.innerHeight > 900 ? MARGINS.DESK : MARGINS.MOBILE;

  if (error)
    return (
      <div className="error-container ">
        <p>{NOT_ENOUGH_DATA}</p>
        <div className="image-container">
          <img rel="preload" src={ERROR_DISPLAY} />
        </div>
      </div>
    );

  return (
    <ResponsiveContainer
      className={"bar-chart-responsive-container"}
      width={"100%"}
      height={"100%"}
    >
      <BarChart
        margin={{
          top: isMobile ? 40 : marginTop,
          right: 0,
          left: 0,
          bottom: 0,
        }}
        barCategoryGap={CATEGORY_GAP}
        data={data}
      >
        <Bar label={<CustomizedLabel />} radius={RADIUS} dataKey="rent">
          <Cell fill={BAR_CHART_COLORS.first} />
          <Cell fill={BAR_CHART_COLORS.second} />
        </Bar>
        <XAxis {...xAxisProps} />
      </BarChart>
    </ResponsiveContainer>
  );
}
