export const TITLE = `אילו אפשרויות החזר קיימות?`;

export const PARAGRAPHS = [
  {
    title: `לוח שפיצר (סילוקין)`,
    text: `כדי להבין מהו לוח סילוקין מסוג שפיצר, נבין קודם כל מהו לוח סילוקין.
לוח הסילוקין מציג את סכום ההחזר החודשי בהתאם לנתוני ההלוואה הכוללים את סכום ההלוואה , הריבית ותקופת ההלוואה.
לוח הסילוקין הנפוץ ביותר והמקובל בבנקים ובגופים הפיננסים הוא לוח סילוקין מסוג "שפיצר".
בשיטה זו, ההחזר החודשי הכולל הוא קבוע (למעט מקרים בהם ההלוואה צמודה למדד או שחל שינוי בריבית או בתקופת ההלוואה המקורית).
בהלוואות/משכנתאות ההחזר החודשי מורכב  מתשלום קרן ותשלום ריבית.
בהלוואת משכנתא המשולמת באמצעות שיטה זו, רוב ההחזר החודשי בתחילת חיי ההלוואה משולם לטובת תשלום הריבית
ועם ההתקדמות בחיי ההלוואה, תשלום  הריבית  הולך וקטן ואילו תשלום  הקרן הולך וגדל.
בכל שלב בחיי ההלוואה ניתן להפיק לוח סילוקין אשר מפרט את נתוני ההלוואה ואת התשלומים. `,
  },
  {
    title: `גרייס`,
    text: `הלוואת גרייס היא הלוואה בה מתחילים לשלם את קרן ההלוואה רק מספר חודשים אחרי לקיחת ההלוואה ולכן, ההחזר החודשי יהיה נמוך יותר בטווח הקצר.
הלוואת גרייס יכולה להיות שימושית בעיקר עבור לקוחות שצריכים גמישות מסוימת בהחזר החודשי בטווח הקצר.
לדוגמא, לקוחות המעוניינים לרכוש נכס חדש לפני מכירת הנכס הקיים.
חשוב לזכור שבתום תקופת הגרייס, תשלומי ההלוואה יכללו תשלום של הקרן והריבית.
ב WeCheck ניתן לקבל תקופת גרייס של עד שנתיים`,
  },
];

export const CTA_TITLE = `למה אתם מחכים?`;
