import PropTypes from "prop-types";
import React     from "react";

import "./index.scss";

const SectionWrapper = ({
	                        id,
	                        title,
	                        subTitle,
	                        className,
	                        sectionClassName,
	                        children,
	                        withoutBrikim,
	                        isH1
                        }) => (
	<section
		className = {`SectionWrapper ${sectionClassName ? sectionClassName : ""}`}
		id = {id}
	>
		<div className = "SectionWrapper__title" >
			{isH1 ? <h1 >{title}</h1 > : <h2 >{title}</h2 >}
			{subTitle ? subTitle : ""}
		</div >
		<div
			className = {`SectionWrapper__container ${
				className ? className : ""
			}`}
		>
			{children}
		</div >
		{!withoutBrikim && <div className = "SectionWrapper__brikim" />}
	</section >
);

SectionWrapper.propTypes = {
	title           : PropTypes.any,
	subTitle        : PropTypes.any,
	className       : PropTypes.string,
	sectionClassName: PropTypes.string,
	withoutBrikim   : PropTypes.bool
};

export default SectionWrapper;
