import React from "react";
import "./index.scss";
import { INTRO_SECOND_PART } from "../../texts";
import { SECOND_PART_IMAGE } from "../../consts";
import ImageContainer from "../../../../../components/product-pages/image-container";
import Paragraph from "../../../../../components/product-pages/paragraph";

export default function IntroSecondPart() {
  return (
    <div className="intro-part second-part-container">
      <Paragraph text={INTRO_SECOND_PART.SUBTITLE} />
      <ImageContainer className="icon-container" src={SECOND_PART_IMAGE} />
      <div className="paragraphs-container">
        <Paragraph
          className="paragraph-first-part"
          text={INTRO_SECOND_PART.TEXT.FIRST_PART}
        />
        <Paragraph
          className="paragraph-second-part"
          text={INTRO_SECOND_PART.TEXT.SECOND_PART}
        />
      </div>
    </div>
  );
}
