import axios              from "axios";
import React, {Component} from "react";

import {Validators} from "@wecheck/uisharedutils/validators";
import Config       from "../../../config/config";

import {Input}  from "../Input";
import {Search} from "../Search";
import Utils    from "../../shared/utils";

import "./index.scss";

const ErrorMsg = {
	required         : field => `יש להזין ${field}`,
	selectionRequired: field => `יש לבחור ${field}`
};

class CityField extends Component {
	static FIELDS = {
		CITY         : "CITY",
		STREET_NAME  : "STREET_NAME",
		STREET_NUMBER: "STREET_NUMBER"
	};

	constructor(props) {
		super(props);

		this.defaultAddress = props.defaultAddress;
		this.initialize     = false;

		this.state = {
			city          : null,
			streetName    : null,
			streetNumber  : this.defaultAddress ? this.defaultAddress.streetNumber : null,
			clearStreet   : false,
			errors        : {
				city        : false,
				streetName  : false,
				streetNumber: [false, false]
			},
			cities        : [],
			cityPrefix    : "",
			streets       : [],
			streetPrefix  : "",
			citiesPending : false,
			streetsPending: false
		};

		if (this.defaultAddress && this.defaultAddress.city) {
			this.initialize = true;
			this.getCities(this.defaultAddress.city).then();
		}

		this.hasInputs = {
			hasCity        : props.fields.includes(CityField.FIELDS.CITY),
			hasStreetName  : props.fields.includes(CityField.FIELDS.STREET_NAME),
			hasStreetNumber: props.fields.includes(CityField.FIELDS.STREET_NUMBER)
		};
	}

	updateDefaultAddress = () => {
		if (this.defaultAddress) {
			if (this.defaultAddress.city && this.state.cities.length && !this.state.city) {
				this.setDefaultAddress(0);
			}

			if (this.defaultAddress.streetName && this.state.streets.length && !this.state.streetName) {
				this.setDefaultAddress(1);
			}
		}
	};

	clearAddress = () => {
		this.setState({
			cities      : [],
			cityPrefix  : "",
			streets     : [],
			streetPrefix: ""
		});
	};

	setDefaultAddress = step => {
		this.initialize = false;
		switch (step) {
			case 0:
				if (!this.state.city) {
					if (this.defaultAddress && this.defaultAddress.city) {
						// This is to solve a very rare case, where a getCities can
						// return several options and the first one is
						// NOT the right one
						// for example a city named: גבעתי
						// the first option will be גבעתיים
						// which is not the correct one
						if (this.state.cities && this.state.cities.length) {
							this.state.cities[0].cityName = this.defaultAddress.city;
						}
					}
					this.handleChange({
						...this.state,
						city: {value: this.state.cities[0]}
					}, () => {
						if (this.defaultAddress.streetName) {
							this.initialize = true;
							this.getStreets(this.state.city.value.cityName, this.defaultAddress.streetName).then();
						}
					});
				}
				break;
			case 1:
				if (!this.state.streetName) {
					this.handleChange({
						...this.state,
						streetName: {displayText: this.state.streets[0], value: this.state.streets[0]}
					}, () => {
						if (this.defaultAddress.streetNumber) {
							this.handleChange({
								...this.state,
								streetNumber: this.defaultAddress.streetNumber
							});
						}
					});
				}
				break;
			default:
		}
	};

	handleChange(state, callback) {
		this.props.handleChange({
			name : this.props.name,
			value: {
				city        : state.city && state.city.value,
				streetName  : state.clearStreet ? null : (state.streetName && state.streetName.value),
				streetNumber: state.streetNumber
			}
		});
		this.setState(state, callback);
	};

	handleBlur = e => {
		const state = this.state;

		this.validator(e);

		this.props.handleBlur({
			name : this.props.name,
			value: {
				city        : state.city && state.city.value,
				streetName  : state.clearStreet ? null : (state.streetName && state.streetName.value),
				streetNumber: state.streetNumber
			}
		});
	};

	static isValidAddress = address => {
		const cityValid   = address.city && address.city.cityName && address.city.cityName.length;
		const streetValid = address.city.hasStreets ? (address.streetName &&
			address.streetName.length) : true;

		return cityValid && streetValid;
	};

	validator = e => {
		let errors = this.state.errors;

		if (e) {
			switch (e.name) {
				case "city":
					errors.city = !Validators.required(this.state.city);
					break;
				case "streetName":
					errors.streetName = !Validators.required(this.state.streetName);
					break;
				case "streetNumber":
					errors.streetNumber = [!Validators.required(this.state.streetNumber), !Validators.numbersOnly(this.state.streetNumber)];
					break;
				default:
			}
		}
		else {
			errors = {
				city        : !Validators.required(this.state.city),
				streetName  : !Validators.required(this.state.streetName),
				streetNumber: [!Validators.required(this.state.streetNumber), !Validators.numbersOnly(this.state.streetNumber)]
			};
		}

		if (this.state.city && !this.state.city.value.hasStreets) {
			errors.streetNumber = [false, false];
		}

		this.setState({
			errors
		});
	};

	getCities = async cityPrefix => {
		this.clearAddress();
		this.setState({citiesPending: true});

		await axios
			.post(`${Config.serverAddr}/cities`, {prefix: cityPrefix})
			.then(response => {
				this.setState({
					citiesPending: false,
					cities       : response.data,
					cityPrefix
				}, this.updateDefaultAddress);
			})
			.catch(error => {
					this.setState({citiesPending: false});
				}
			);
	};

	getStreets = async (city, streetName) => {
		this.setState({streetsPending: true, streets: [], streetPrefix: ""});

		await axios
			.post(`${Config.serverAddr}/streets`, {city, prefix: streetName})
			.then(response => {
				this.setState({
					streetsPending: false,
					streets       : response.data,
					streetPrefix  : streetName
				}, this.updateDefaultAddress);
			})
			.catch(error => {
					this.setState({streetsPending: false});
				}
			);
	};

	render() {
		const {readOnly, customPlaceHolder, alternatePlaceHolder} = this.props;
		const {
			      errors, cities,
			      streets,
			      citiesPending,
			      streetsPending
		      }                                                   = this.state;

		const citySelected = this.state.city && {displayText: this.state.city.value.cityName, value: this.state.city};

		const streetSelected = this.state.streetName;

		const cityInput = (
			<>
				<Search name = "city"
				        isCity
				        placeholder = "עיר"
				        alternatePlaceHolder = "עיר"
				        selected = {citySelected}
				        tabIndex = {1}
				        disabled = {readOnly}
				        fireAnalytics = {() => this.props.fireAnalytics("city")}
				        items = {cities && cities.map(city => ({displayText: city.cityName, value: city}))}
				        error = {errors.city && ErrorMsg.selectionRequired("עיר")}
				        onChange = {({value}) => {
					        this.setState({
						        errors: {
							        ...this.state.errors,
							        city: false
						        }
					        });

					        if (value === "") {
						        this.clearAddress();
					        }
					        else {
						        this.getCities(value).then();
					        }
				        }}
				        onSelect = {e => {
					        // This clears the list of streets, otherwise, the previous list remains
					        if (e.value.value) {
						        if (!this.state.city || (this.state.city && this.state.city.value && (this.state.city.value !== e.value.value))) {
							        this.setState({treets: [], streetPrefix: ""});

							        this.handleChange({
								        ...this.state,
								        city        : e.value,
								        streetName  : null,
								        streetNumber: null,
								        clearStreet : true
							        }, () => {
								        this.validator(e);
							        });
						        }
					        }
				        }}
				        onBlur = {this.handleBlur}
				        loader = {citiesPending} />
			</>
		);

		const hasStreets = (this.state.city && this.state.city.value.hasStreets);

		const streetDisabled       = !this.state.city || !this.state.city.value.hasStreets;
		const streetNumberDisabled = !this.state.city || !this.state.streetName || !this.state.city.value.hasStreets;

		const streetInput = (
			<>
				<Search name = "streetName"
				        alternatePlaceHolder = "רחוב"
				        isStreet
				        selected = {streetSelected}
				        tabIndex = {2}
				        fireAnalytics = {() => this.props.fireAnalytics("streetName")}
				        disabled = {streetDisabled || readOnly}
				        items = {streets && streets.map(street => ({displayText: street, value: street}))}
				        error = {(errors.streetName && hasStreets) && ErrorMsg.selectionRequired("רחוב")}

				        onChange = {({value}) => {
					        if (this.state.clearStreet) {
						        this.setState({clearStreet: false});
					        }
					        if (this.state.city && value !== "") {
						        this.getStreets(this.state.city.value.cityName, value).then();
					        }
					        else {
						        this.clearAddress();
					        }
				        }}
				        onSelect = {e => {
					        this.handleChange({
						        ...this.state,
						        streetName: e.value
					        }, () => {
						        this.validator(e);
					        });
				        }}
				        loader = {streetsPending}
				        clear = {this.state.clearStreet}
				        onBlur = {this.handleBlur} />
			</>

		);

		const streetNumberInput = (
			<>
				<Input name = "streetNumber"
				       value = {this.state.streetNumber}
				       placeholder = "מספר בית"
				       type = "number"
				       tabIndex = {3}
				       disabled = {streetNumberDisabled || readOnly}
				       error = {!streetNumberDisabled && (
					       errors.streetNumber[0] ? ErrorMsg.required("מס׳ בית") :
					       errors.streetNumber[1] && ErrorMsg.numbersOnly
				       )}
				       onChange = {e => {
					       this.handleChange({
						       ...this.state,
						       streetNumber: e.value
					       }, () => {
						       this.validator(e);
					       });
				       }}
				       onBlur = {this.handleBlur.bind(this)} />
			</>
		);

		const renderInputs = (
			<>
				{this.hasInputs.hasCity && cityInput}
				{this.hasInputs.hasStreetName && streetInput}
				{this.hasInputs.hasStreetNumber && streetNumberInput}
			</>
		);

		return <div className = "CityField" >
			<div className = {`CityField__loader${this.initialize ? "--show" : ""}`} >
				<img src = {Utils.buildDashboardImgLink("wecheckIcon.svg", true)} alt = "" />
			</div >

			{this.props.wrapper ? this.props.wrapper({
				cityInput,
				streetInput,
				streetNumberInput
			}) : renderInputs}
		</div >;
	}
}

export {CityField};
