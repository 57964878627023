import React, {useRef} from "react";
import { Link } from "react-router-dom";
import useIsInViewport from "../../../../../hooks/useIsInViewport";

import {URLS} from "../../../../../shared/urls";
import Utils  from "../../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../../components/logo";

function MainSection({isMortPage}) {
	const rightSideRef = useRef(null);
	const leftSideRef  = useRef(null);
	return (
		<section className = {`MainSection ${isMortPage ? "mort-page" : ""}`} >
			<div
				ref = {rightSideRef}
				className = {`right-side ${
					useIsInViewport(rightSideRef) ? "in-view" : ""
				}`}
			>
				<div className = "title-container" >
					<h1 >
						<span >ברוכים הבאים</span >
						<span >
                            למשכנתא של{" "}
							
                        <Logo color="blue" />

                        </span >
					</h1 >
					<h2 >עד 85% מימון עבור נכס החלומות שלכם</h2 >
				</div >
				<div className = "content-container" >
					<p >
						מכירים את זה שהבנק קובע את גודל המשכנתא שהוא נותן לכם
						-לפי ההון העצמי שלכם?
						<br />
						אבל לפעמים, ההון העצמי שלכם לא מספיק
						<strong > לנכס שאתם באמת רוצים</strong > . מצד שני, יכולת
						ההחזר החודשית שלכם מאפשרת לכם יותר.
					</p >
					<p >
						<span >בדיוק בשביל זה יצרנו את המשכנתא של </span >

						<Logo color="blue" />

					</p >
				</div >
				{!isMortPage && (
					<div className = "buttons-container" >
						<a
							onClick = {() =>
								Utils.gaEvent(
									"אתר שיווקי -￼ משכנתאות",
									"בדיקת זכאות וקבלת הצעה -￼ מרכז",
									""
								)
							}
							href = {URLS.MortgageQue}
							className = "btn--azure"
						>
							לבדיקת זכאות וקבלת הצעה
						</a >
						<Link
							onClick = {() =>
								Utils.gaEvent(
									"אתר שיווקי -￼ משכנתאות",
									"מחשבון משכנתא - מרכז",
									""
								)
							}
							to = {URLS.MortCalc}
							className = "btn--oldred"
						>
							מעניין! קחו אותי למחשבון
						</Link>
					</div >
				)}
			</div >
			<div
				ref = {leftSideRef}
				className = {`left-side ${
					useIsInViewport(leftSideRef) ? "in-view" : ""
				}`}
			>
				<img
					src = {Utils.buildImgLink("mort/mainSectionImage.svg")}
					alt = ""
				/>
			</div >
		</section >
	);
}

export {MainSection};
