import React, { useState } from "react";
import "./index.scss";
import {
  COPIED,
  LOAN_NUMBER,
  LOAN_ORIGINAL_AMOUNT,
  NEXT_PAYMENT,
  REMAINING_LOAN_AMOUNT,
} from "./texts";
import { formatAddress } from "./utils";
import Utils from "../../../../../../shared/utils";
import { COPY_ICON, CHEVRON_DOWN } from "./consts";
import Accordion from "../../../../../../newsletter/components/filter/components/accordion";
import PaymentCTA from "../payment-cta";
import LoanPaid from "../loan-paid";

export default function SingleLoan({
  deal_identifier,
  total_loan_amount,
  total_remaining_loan_amount,
  total_next_payment,
  streetName,
  streetNumber,
  cityName,
  dealStatus,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const loanPaid = dealStatus === 18; //eg: 18 = paid in full

  const handleCopy = async () => {
    setIsCopied(false);
    try {
      await navigator.clipboard.writeText(deal_identifier.toString());
      setIsCopied(true);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div
      className={`single-loan-container ${loanPaid ? "paid" : ""} ${
        isOpen ? "is-open" : ""
      }`}
    >
      {loanPaid && <LoanPaid showInfo={showInfo} setShowInfo={setShowInfo} />}
      <div className="single-loan-header">
        <div className="right-side">
          <h5>{formatAddress(streetName, streetNumber, cityName)}</h5>
          <p>
            {REMAINING_LOAN_AMOUNT} <span className="shekel-container">₪</span>
            {loanPaid
              ? "0"
              : Utils.numberWithComma(total_remaining_loan_amount)}
          </p>
        </div>
        <div className="left-side">
          <h5>
            <span>
              <span className="shekel-container">₪</span>
              {loanPaid ? "0" : Utils.numberWithComma(total_next_payment)}
            </span>
            <span className="next-payment-cta">{NEXT_PAYMENT}</span>
          </h5>
        </div>
      </div>
      <Accordion isOpen={isOpen} className="single-loan-data">
        <div className="data-container">
          <p>
            <span className="indicator">{LOAN_NUMBER}</span>
            <span className="deal-identifier-container">
              <span>{deal_identifier}</span>
              <span className="button-and-tooltip-container">
                <button onClick={handleCopy} className="copy-button">
                  <img src={COPY_ICON} alt="copy icon" />
                </button>
                <span className={`tooltip ${isCopied ? `active` : ""}`}>
                  {COPIED}
                </span>
              </span>
            </span>
          </p>
          <p>
            <span className="indicator">{LOAN_ORIGINAL_AMOUNT}</span>
            <span>
              <span className="shekel-container">₪</span>
              {Utils.numberWithComma(total_loan_amount)}
            </span>
          </p>
        </div>
        <PaymentCTA />
      </Accordion>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className={`open-more-info ${isOpen ? "is-open" : ""}`}
      >
        <img src={CHEVRON_DOWN} alt="chevron down" />
      </button>
    </div>
  );
}
