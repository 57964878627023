import { createSlice } from "@reduxjs/toolkit";
import { POPUPS } from "../consts";

const initialState = {
  availablePopups: POPUPS,
  viewed: 0,
  currentPopupIndex: null,
  viewOptions: false,
};

export const promotionalSlice = createSlice({
  name: "promotional",
  initialState,
  reducers: {
    displayPopup: (state, action) => {
      state.availablePopups[action.payload.userType][
        action.payload.popUpIndex
      ].viewed = true;
      state.viewed += 1;
      state.currentPopupIndex = action.payload.popUpIndex;
    },
    hidePopup: (state, action) => {
      state.currentPopupIndex = null;
      state.viewOptions = false;
    },
    moveToOptions: (state, action) => {
      state.viewOptions = true;
    },
  },
});

export const currentPopup = (state, userType) =>
  state.promotional.availablePopups?.[userType || 0]?.[
    state.promotional.currentPopupIndex
  ];

export const { displayPopup, hidePopup, moveToOptions } =
  promotionalSlice.actions;

export default promotionalSlice.reducer;
