import Utils from "../../../../../shared/utils";

const additionalMortContent = {
	title   : "משכנתא משלימה",
	subTitle: "משכנתא בנוסף למשכנתא של הבנק",
	image   : Utils.buildMortImgLink("Icons/additional-mort.svg"),
	content : [
		"עד 30 שנה",
		"עד 5 שנים",
		"₪1,500,000 - ₪100,000",
		"בכפוף לתקופת הלוואה מינימלית של 24 חודשים"
	]
};
const premiumMortContent    = {
	title   : "משכנתא פרמיום",
	subTitle: "כל המשכנתא במקום אחד",
	image   : Utils.buildMortImgLink("Icons/premium-mort.svg"),
	content : [
		"עד 30 שנה",
		"עד שנתיים",
		"₪6,500,000 - ₪100,000",
		"בכפוף לתקופת הלוואה מינימלית של 24 חודשים"
	]
};
const rows                  = [
	{
		title: "תקופת החזר",
		image: Utils.buildMortImgLink("Icons/plaque.svg")
	},
	{
		title: "אפשרויות לתקופת גרייס",
		image: Utils.buildMortImgLink("Icons/pie.svg")
	},
	{
		title: "סכומי הלוואות",
		image: Utils.buildMortImgLink("Icons/suitcase.svg")
	},
	{
		title: "פרעון מוקדם ללא קנס",
		image: Utils.buildMortImgLink("Icons/wallet.svg")
	}
];

export {additionalMortContent, premiumMortContent, rows};
