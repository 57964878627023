import {useState, useEffect} from "react";

import Utils from "../../../../../../../shared/utils";

import "./index.scss";
import "../../index.scss";

const NameAndPhone = ({
	                      namePlaceHolder,
	                      phonePlaceHolder,
	                      currName,
	                      currPhone,
	                      dataChanged,
	                      fireAnalytics,
	                      existingPhone
                      }) => {

	const [firstName, setFirstName] = useState(currName);
	const [phone, setPhone] = useState(currPhone);
	const [showPhoneError, setShowPhoneError] = useState(false);

	useEffect(() => {
		dataChanged(firstName, phone);
		setShowPhoneError(existingPhone && (phone === existingPhone));
	}, [firstName, phone]);

	return <div className = "NameAndPhone" >
		<div class = "InputContainer" >
			<input type = "text" name = "name" placeholder = {namePlaceHolder} defaultValue = {currName}
			       autoComplete = "off"
			       onFocus = {() => fireAnalytics("name")}
			       onChange = {(e) => {
				       setFirstName(e.target.value);
			       }}
			/>
			<img src = {Utils.buildTenantPageImgLink("name.svg")} alt = "" />
		</div >
		<div className = "InputContainer" >
			<input type = "tel" name = "phone" placeholder = {phonePlaceHolder} defaultValue = {currPhone}
			       autoComplete = "off"
			       onFocus = {() => fireAnalytics("phone")}
			       onChange = {(e) => {
				       setPhone(e.target.value);
			       }}
			/>
			<img src = {Utils.buildTenantPageImgLink("phone.svg")} alt = "" />
		</div >
		{showPhoneError &&
			<label className="errorLabel">מספר הטלפון זהה למספר שהזנת עבור בעל הדירה. אנא עדכן למספר המתאים.</label>}
	</div >;
};

export {NameAndPhone};