import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectRoomNumber,
  setDataViewRoomNumber,
} from "../../../../redux/features/newsletterSlice";
import { possibleRooms } from "../filter/components/room-multi-select/consts";

export default function RoomSelect({ title, isDataView = false, dataTestId = null }) {
  const dispatch = useDispatch();

  const searchRoomNumber = useSelector(
    (state) => state.newsletter.selectedRoomAmount
  );

  const dataViewRoomNumber = useSelector(
    (state) => state.newsletter.dataView?.numberOfRooms
  );

  const selectedRoomNumber = isDataView ? dataViewRoomNumber : searchRoomNumber;

  const handleSelect = (room) => {
    if (isDataView) {
      dispatch(setDataViewRoomNumber(room));
    } else {
      dispatch(selectRoomNumber(room));
    }
  };

  return (
    <div className="room-select-container" data-testid={dataTestId}>
      <h4>{title}</h4>
      <div className="room-list-container">
        {possibleRooms.map((room, index) => {
          return (
            <div
              data-testid={`single-room-${room}`}
              onClick={() => handleSelect(room)}
              key={index}
              className={`single-room ${
                selectedRoomNumber === room ? "selected" : ""
              }`}
            >
              {room}
            </div>
          );
        })}
      </div>
    </div>
  );
}
