import React from "react";

export default function useReplaceWithSpan(string, substring) {
    const parts = string.split(substring);
    const replacedString = parts.map((part, index) => {
        if (index !== parts.length - 1) {
            return (
                <React.Fragment key={index}>
                    {part}
                    <span>{substring}</span>
                </React.Fragment>
            );
        }
        return part;
    });
    return replacedString;
}
