import React               from "react";
import {recordButtonClick} from "../..";
import {Logo}              from "../../../../../components/logo";
import useIsMobile         from "../../../../../hooks/useIsMobile";
import Utils               from "../../../../../shared/utils";

import "./index.scss";

const APPSTORE_URL     = "https://apps.apple.com/us/app/wecheck/id1482243828";
const GOOGLE_PLAY_URL  =
	      "https://play.google.com/store/apps/details?id=com.wecheck.app";
const PhoneImageMobile = Utils.buildMortImgLink(
	"NewAssets/phone-image-mobile.png"
);
const PresentorImage   = Utils.buildMortImgLink("NewAssets/presentor-image.png");

const TitleAndButtons = () => {
	return (
		<div className = "TitleAndButtons" >
			<div className = "title-section" >
				<h2 >אנחנו גם באפליקציה!</h2 >
				<h3 >
					אפליקציית <div className="logo-container" ><Logo color="white" /></div> זמינה להורדה עכשיו ב:
				</h3 >
			</div >
			<div className = "buttons-section" >
				<a
					target = "_blank"
					rel = "noopener noreferrer"
					href = {APPSTORE_URL}
					onClick = {() => {
						recordButtonClick("הורדת אפליקציה", "איפון");
					}}
				>
					App store
					<img
						src = {Utils.buildMortImgLink(
							"NewAssets/appstore-icon.svg"
						)}
						alt = ""
					/>
				</a >
				<a
					target = "_blank"
					rel = "noopener noreferrer"
					href = {GOOGLE_PLAY_URL}
					onClick = {() => {
						recordButtonClick("הורדת אפליקציה", "אנדרואיד");
					}}
				>
					Google play
					<img
						src = {Utils.buildMortImgLink(
							"NewAssets/google-play-icon.svg"
						)}
						alt = ""
					/>
				</a >
			</div >
		</div >
	);
};

const AppStore = () => {
	const isMobile = useIsMobile();
	return (
		<section className = "AppStoreSection" >
			<TitleAndButtons />
			<div className = "presentor-image-container" >
				<img
					src = {isMobile ? PhoneImageMobile : PresentorImage}
					alt = ""
				/>
			</div >
		</section >
	);
};

export {AppStore};
