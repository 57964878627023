import { SQUARE_METER } from "./consts";
import "./index.scss";

export default function Slider({ value = 115, handleChange, dataTestId = null }) {
  const handleSliderChange = (event) => {
    const newValue = Number(event.target.value);
    handleChange(newValue);
  };

  // Calculate the gradient percentage
  const percentage = ((value - 30) / (200 - 30)) * 100;

  return (
    <div className="slider-container">
      <div className="slider-label">
        {value} {SQUARE_METER}
      </div>
      <input
        data-testid={dataTestId}
        type="range"
        min="30"
        step="5"
        max="200"
        value={value}
        onChange={handleSliderChange}
        className="slider"
        id="myRange"
        style={{
          background: `linear-gradient(to left, #1799fc 0%, #1799fc ${percentage}%, white ${percentage}%, white 100%)`,
        }}
      />
    </div>
  );
}
