const MortEnums = {
	PropertyType: {
		New     : 0,
		Existing: 1
	},

	AssetType: {
		Single    : 0,
		Investment: 1
	},

	PurchaseType: {
		FirstProperty: 0,
		Renovation   : 1,
		Investment   : 2
	},

	CalcType: {
		MyMoney      : 0,
		Monthly      : 1,
		PropertyValue: 2
	}
};

export {MortEnums};
