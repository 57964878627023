import React, {useRef} from "react";
import useCTAAnimation from "../../../../../hooks/product-pages/useCTAAnimation";

import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";

import {ANALYTICS} from "../../consts";
import {URLS} from "../../../../../shared/urls";
import {CTA_BUTTON_TEXT} from "../first-section/texts";
import {SECTION_TEXT, SECTION_TITLE} from "./texts";

import "./index.scss";
import ImageContainer from "../../../../../components/product-pages/image-container";
import {SECOND_SECTION_IMAGE} from "./consts";

export default function SecondSection() {
    const CTARef = useRef()
    const CTAContainerAnimated = useCTAAnimation({
        className: "airplane-animation-and-cta",
        buttonClassName: "animate-appearance",
        ctaText: CTA_BUTTON_TEXT,
        ref: CTARef,
        CTALink: URLS.MortgageQue,
        analytics: ANALYTICS.SECOND_SECTION.CTA
    })

    return <GridSection className="complete-mort-second-section">
        <TextSection title={SECTION_TITLE} text={SECTION_TEXT} />
        <ImageContainer src={SECOND_SECTION_IMAGE}/>

        {CTAContainerAnimated}

    </GridSection>
}
