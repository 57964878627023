import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { redirect } from "react-router-dom";
import map from "lodash/map";

// Redux features
import { setIsLoggedIn } from "../../../../redux/features/loginSlice";
import { setUser } from "../../../../redux/features/userSlice";
import { setLoans } from "../../../../redux/features/loansSlice";

// Services
import {
  useGetUserDetailsQuery,
  useRefreshTokenMutation,
  useLogoutMutation,
} from "../../../../redux/services/login";
import {
  useLazyGetLoansQuery,
  useLazyGetMortUserStatusQuery,
  useLazyGetQueStatusQuery,
  useLazyGetRequestsStatusQuery,
} from "../../../../redux/services/mortgage-personal-area";

// Constants
import { URLS } from "../../../shared/urls";
import { LOGIN_METHODS } from "../../../../redux/consts";
import { HttpStatusCode } from "axios";
import useModal from "../../../components/modal/hooks/useModal";
import { MODAL_TYPES } from "../../../components/modal/consts";
import config from "../../../../config/config";
import {
  MORTGAGE_FEATURES_CODE,
  MORTGAGE_SERVICE_CODE,
} from "../../../shared/utils";

const REQUEST_ID_KEY = "id";
const REDIRECT_URL = `${URLS.MortgageQue}?isPersonalAreaRedirect=true`;

export default function usePersonalArea() {
  const dispatch = useDispatch();
  const [isInitialized, setIsInitialized] = useState(false);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const loans = useSelector((state) => state.loans.loans);

  const { open: openModal } = useModal();

  const [refreshToken, { isLoading: isLoadingRefreshToken }] =
    useRefreshTokenMutation();
  const [logout] = useLogoutMutation();
  const [getMortUserStatus, mortUserStatus] = useLazyGetMortUserStatusQuery({
    refetchOnFocus: true,
  });
  const [getQueStatus, queQuery] = useLazyGetQueStatusQuery();
  const [getRequestsStatus, requestStatusQuery] =
    useLazyGetRequestsStatusQuery();
  const [getLoans, loansQuery] = useLazyGetLoansQuery();

  const sendRefreshToken = async () => {
    const res = await refreshToken();
    if (res && !res?.error) {
      dispatch(setIsLoggedIn(true));
    }
  };

  const {
    data: userData,
    isLoading: isLoadingUserDetails,
    error: userError,
  } = useGetUserDetailsQuery({}, { skip: isLoadingRefreshToken });

  const redirectHandler = (status) => {
    if (status === HttpStatusCode.NoContent) {
      window.location.href = REDIRECT_URL;
    } else {
      console.error("Unexpected status:", status);
    }
  };

  const checkUserStatus = async () => {
    const res = await getMortUserStatus();
    if (res?.error?.status === HttpStatusCode.Forbidden) {
      await logout();
      return redirect(
        `${config.HOMEPAGE_URL}${
          config.envName === "local" ? "" : "/auth"
        }type=${LOGIN_METHODS.PHONE_AND_ID}&continue=${
          window.location.href
        }&service=${MORTGAGE_SERVICE_CODE}&feature=${
          MORTGAGE_FEATURES_CODE.MORTGAGE_PERSONAL_AREA
        }`
      );
    }
    if (res?.isError || !res?.data?.data?.policy_confirmed_date) {
      dispatch(setIsLoggedIn(false));
    }
  };

  const loansHandler = async () => {
    try {
      const queStatus = await getQueStatus();
      if (queStatus?.data?.status === HttpStatusCode.NoContent) {
        return redirectHandler(queStatus?.data?.status);
      }

      const requestsStatus = await getRequestsStatus();

      if (requestsStatus?.data?.status === HttpStatusCode.NoContent) {
        openModal({ type: MODAL_TYPES.MORT_NO_ACTIVE_LOANS });
        return dispatch(setIsLoggedIn(false));
      }

      const loansQueryString = map(
        requestsStatus?.data?.data?.data,
        REQUEST_ID_KEY
      ).join(",");

      const loansStatus = await getLoans(loansQueryString);

      if (loansStatus?.data?.status === HttpStatusCode.NoContent) {
        openModal({ type: MODAL_TYPES.MORT_NO_ACTIVE_LOANS });
        return dispatch(setIsLoggedIn(false));
      }
      dispatch(setLoans(loansStatus?.data?.data));
    } catch (error) {}
  };

  const init = async () => {
    try {
      await sendRefreshToken();
      await checkUserStatus();
      await loansHandler();
    } catch (error) {
      console.error("An error occurred:", error);
    } finally {
      setIsInitialized(true);
    }
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    dispatch(setUser({ ...userData?.data, ...mortUserStatus?.data?.data }));
  }, [userData, mortUserStatus]);

  useEffect(() => {
    if (
      isLoggedIn ||
      isLoggedIn === null ||
      isLoadingRefreshToken ||
      mortUserStatus.isLoading
    ) {
      return;
    }
    redirect(
      `${config.HOMEPAGE_URL}${config.envName === "local" ? "" : "/auth"}type=${
        LOGIN_METHODS.PHONE_AND_ID
      }&continue=${
        window.location.href
      }&service=${MORTGAGE_SERVICE_CODE}&feature=${
        MORTGAGE_FEATURES_CODE.MORTGAGE_PERSONAL_AREA
      }`
    );
  }, [isLoggedIn]);

  return {
    loans,
    isLoading:
      mortUserStatus.isLoading ||
      isLoadingRefreshToken ||
      loansQuery.isLoading ||
      queQuery.isLoading ||
      requestStatusQuery.isLoading ||
      isLoadingUserDetails ||
      !isInitialized,
  };
}
