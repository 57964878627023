import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  propertyCity: "",
  propertyStreet: "",
  numberOfClicks: null,
  isPersonalAreaDrawerOpen: false,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.firstName = action.payload?.firstName;
      state.lastName = action.payload?.lastName;
      state.email = action.payload?.email;
      state.phone = action.payload?.phone;
      state.idNum = action.payload?.identifier;
      state.gender = action.payload?.genderTypId;
      state.propertyCity = action.payload?.city;
      state.propertyStreet = action.payload?.street_name;
      state.numberOfClicks = action.payload?.yad_showed_phone_count;
      state.lastMortPersonalAreaLogin =
        action.payload?.personal_area_last_login;
    },
    handleDrawer: (state, action) => {
      state.isPersonalAreaDrawerOpen = action.payload;
    },
  },
});

export const { setUser, handleDrawer } = userSlice.actions;

export default userSlice.reducer;
