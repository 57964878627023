import React from "react";

import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";
import CTAButton from "../../../../../components/product-pages/CTAButton";

import { ANALYTICS } from "../../consts";
import { URLS } from "../../../../../shared/urls";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { PARAGRAPHS } from "./texts";

import "./index.scss";
import Container from "../../../../../components/product-pages/container";

export default function FifthSection() {
  return (
    <GridSection className="premium-mort-fifth-section">
      {PARAGRAPHS.map(({ ...props }, index) => {
        return <TextSection key={index} {...props} />;
      })}
      <Container className="cta-button-container">
        <CTAButton
          text={CTA_BUTTON_TEXT}
          analytics={ANALYTICS.FIFTH_SECTION.CTA}
          href={URLS.MortgageQue}
        />
      </Container>
    </GridSection>
  );
}
