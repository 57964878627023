import React from "react";
import { GoogleMap } from "@react-google-maps/api";

import { MAP_OPTIONS, MAP_OPTIONS_MOBILE } from "./options";

import "./index.scss";
import { MAP_CONTAINER_STYLE } from "./consts";
import useIsMobile from "../../../hooks/useIsMobile";
import { useDispatch } from "react-redux";
import { setIsChatOpen } from "../../../../redux/features/newsletterSlice";

const Map = ({
  onLoad,
  onUnmount,
  renderAreaMarkers,
  renderAssetMarkers,
  renderNeighborhoodMarkers,
  renderCityMarkers,
  isLoaded,
}) => {
  const isMobile = useIsMobile(1000);
  const dispatch = useDispatch();
  return (
    isLoaded && (
      <GoogleMap
        mapContainerClassName="map-container"
        mapContainerStyle={MAP_CONTAINER_STYLE}
        center={MAP_OPTIONS.center}
        zoom={MAP_OPTIONS.zoom}
        options={isMobile ? MAP_OPTIONS_MOBILE : MAP_OPTIONS}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onClick={() => dispatch(setIsChatOpen(false))}
      >
        {renderCityMarkers}
        {renderAreaMarkers}
        {renderNeighborhoodMarkers}
        {renderAssetMarkers}
      </GoogleMap>
    )
  );
};

export default Map;
