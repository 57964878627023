import Utils from "../../../shared/utils";

export const texts = {
    topSection: {
        title: "אז מה זה בעצם\nמשכנתא משלימה?",
        secondaryTitle: "איך גם אתם יכולים להגיע ל - 85% מימון עבור נכס החלומות שלכם",
        paragraph: "המשכנתא המשלימה של WeCheck תעזור לכם להגיע מהנכס המצוי - לנכס הרצוי.\nאיך בדיוק? פשוט מאוד - הבנק מציע משכנתא על פי ההון העצמי שיש ברשותכם, אבל מה אם ההון העצמי שלכם לא מספיק עבור הנכס שאתם באמת רוצים?"
    },
    middleSection: {
        paragraphs: [
            {
                title: "עבור המצב הזה",
                content: "יש את המשכנתא המשלימה של WeCheck, הלוואה בתנאי משכנתא בדרגה שנייה (לאחר הבנק), שתעזור לכם לקבל מהבנק מימון גדול יותר ולהגיע לנכס שאתם באמת רוצים."
            },
            {
                title: "למי זה מיועד?",
                content: "לרוכשי נכס חדש / נכס להשקעה ואפילו עבור נכס קיים שברשותכם (לדוגמא אם תרצו הלוואה לצורך שיפוץ, הלוואה לרכישת נכס נוסף ואפילו לצורך החזר הלוואות - משכנתא לכל מטרה)."
            }
        ],
        cards: [
            {
                title: "הנכס שלך בלי",
                secondaryTitle: "משכנתא משלימה של",
                content: {
                    firstLine: "הון עצמי + משכנתא בנקאית",
                    secondLine: "פה תוכלו לקבל מימון של 75%-50%"
                }
            },
            {
                title: "הנכס שלך עם",
                secondaryTitle: "משכנתא משלימה של",
                content: {
                    firstLine: "הון עצמי + משכנתא בנקאית + משכנתא משלימה",
                    secondLine: "פה תוכלו לקבל מימון של 85% עם 15% הון עצמי בלבד"
                }
            }

        ]
    },
    bottomSection: {
        title: "המשכנתא המשלימה של",
        secondaryTitle: "ניתנת בתנאים הכי נוחים!",
        blobs: [
            {
                text: "סכומי הלוואות שניתן לקבל\n₪100,000 -₪1,500,000",
                icon: Utils.buildMortImgLink("Icons/moneybag.svg")
            },
            {
                text: "תקופת החזר\nעד 30 שנים",
                icon: Utils.buildMortImgLink("Icons/plaque.svg")
            },
            {
                text: "פרעון ללא קנס\nבכפוף לתקופת הלוואה מינימלית של 24 חודשים",
                icon: Utils.buildMortImgLink("Icons/wallet.svg")
            },
            {
                text: "אפשרות לתקופת גרייס\n עד 5 שנים",
                icon: Utils.buildMortImgLink("Icons/pie.svg")
            }

        ]
    },
    videoSection: {
        title: "רוצים הסבר מפורט יותר? צפו בסרטון"
    },
    form: {
        title: "השאירו פרטים וניצור עמכם קשר בהקדם",
        inputs: [
            {
                name: "firstName",
                placeholder: "שם פרטי",
                type: "text",
                nameToSync: "שם פרטי"
            },
            {
                name: "lastName",
                placeholder: "שם משפחה",
                type: "text",
                nameToSync: "שם משפחה"
            },
            {
                name: "phone",
                placeholder: "טלפון",
                type: "number",
                nameToSync: "מספר טלפון"

            },
            {
                name: "dealType",
                placeholder: "סוג העסקה",
                type: "select",
                nameToSync: "סוג עסקה"
            },
            {
                name: "requestedAmount",
                placeholder: "סכום מבוקש מ-WeCheck (אם ידוע)",
                type: "number",
                nameToSync: "סכום מבוקש"
            }
        ],
        options: [
            "רכישה - נכס ראשון",
            "רכישה - משפרי דיור",
            "רכישה - נכס להשקעה",
            "נכס קיים - בבעלותי נכס אחד",
            "נכס קיים - בבעלותי מספר נכסים",
            "לא ידוע"
        ],
        buttons: {
            submit: "שלח",
            open: "להשארת פרטים"
        },
        errors: {
            firstName: {
                empty: "יש להזין שם פרטי",
                notHebrew: "יש להזין אותיות בעברית בלבד"
            },
            lastName: {
                empty: "יש להזין שם משפחה",
                notHebrew: "יש להזין אותיות בעברית בלבד"
            },
            fullName: {
                empty: "יש להזין שם מלא",
                notHebrew: "יש להזין אותיות בעברית בלבד"
            },
            phone: {
                empty: "יש להזין מספר טלפון",
                error: "יש להזין מספר טלפון תקין בלבד"
            },
            dealType: "יש לבחור סוג עסקה",
            requiredAmount: 'יש לבחור סכום בין 100,000 ש"ח ל-1,500,000 ש"ח'
        },
        successMessage: "הפרטים נשלחו בהצלחה!\nנציגינו יצרו איתך קשר בקרוב",
    },
    events: {
        category: "דף נחיתה עמוד מוצר שיווקי - משכנתא משלימה",
        actions: {
            SEND_FORM: {
                label: "כפתור שלח - טופס השארת פרטים",
                action: "לחיצה על כפתור שלח"
            },
            EXPAND_FORM: {
                label: "כפתור להשארת בפרטים - טופס השארת פרטים",
                action: "לחיצה על כפתור להשארת פרטים"
            },
            PLAY_VIDEO: {
                label: "סרטון ההסבר - סקשן סרטון",
                action: "לחיצה על כפתור פליי"
            }
        }
    }
}
