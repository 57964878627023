import React, { useEffect, useLayoutEffect, useRef } from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

import useIsInViewport from "../../hooks/useIsInViewport";
import useVideos from "../../hooks/useVideos";
import useHeader from "../header/hooks/useHeader";

import { Footer } from "../footer";
import { Header } from "../header";
import { PhoneBanner } from "../phone-banner";

import "./index.scss";

import ReactPixel from "react-facebook-pixel";
import { gtag, install } from "ga-gtag";
import { texts } from "../../rent/pages/mortgage-landing-page/consts";
import { Helmet } from "react-helmet";
import { OUTBRAIN_PIXEL } from "./scripts";
import TaboolaTracking from "react-tracking-taboola";
import { URLS } from "../../shared/urls";
import { useSelector } from "react-redux";

export default function Layout() {
  const location = useLocation();

  const footerRef = useRef(null);
  const isFooterVisible = useIsInViewport(footerRef, 0.2, true);
  const whoAreWeRef = useRef(null);
  const ourServicesRef = useRef(null);
  const ourPartnersRef = useRef(null);
  const mortPartnersRef = useRef(null);
  const mortWhoWeAreRef = useRef(null);
  const mortPartnersRefOld = useRef(null);
  const mortWhoWeAreRefOld = useRef(null);
  const mortContactRef = useRef(null);
  const mortLoansRef = useRef(null);
  const mortContactFormRef = useRef(null);
  const videoURLS = useVideos();

  const { isMortLandingPage } = useHeader();

  const params = new URLSearchParams(location.search);
  const route = params.get("route");

  const isDataViewVisible = useSelector(
    (state) => state.newsletter.dataView.isVisible
  );

  const refs = {
    whoAreWe: whoAreWeRef,
    ourServices: ourServicesRef,
    ourPartners: ourPartnersRef,
  };

  const mortRefs = {
    mortWhoWeAre: mortWhoWeAreRef,
    mortPartners: mortPartnersRef,
    mortWhoWeAreOld: mortWhoWeAreRefOld,
    mortPartnersOld: mortPartnersRefOld,
    mortContact: mortContactRef,
    mortLoans: mortLoansRef,
  };

  const getCorrectRefs = () =>
    location.pathname.includes("mortinfo") ? mortRefs : refs;

  const isMortProductPage =
    location.pathname === URLS.AdditionalMort ||
    location.pathname === URLS.CompleteMort ||
    location.pathname === URLS.ReducedMort ||
    location.pathname === URLS.PremiumMort;

  const isMortPersonalArea = location.pathname === URLS.MortPersonalArea;  
  const isNewsletter = location.pathname === URLS.Newsletter || isMortPersonalArea;

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useEffect(() => {
    ReactPixel.init("1925308764235211");
    install("AW-801590444");
  }, []);

  useEffect(() => {
    ReactPixel.pageView();
    gtag("js", new Date());
    gtag("config", "AW-801590444");
    TaboolaTracking.trackPageview();
  }, [location.pathname]);

  return (
    <main className={`Website LayoutContainer ${isNewsletter ? "overflow-hidden" : ""}`}>
      <Helmet>{OUTBRAIN_PIXEL}</Helmet>
      <Header
        customCategory={isMortLandingPage ? texts.events.category : null}
        refs={getCorrectRefs()}
        hidden={isNewsletter && isDataViewVisible}
        isNewsletter={isNewsletter}
        isMortPersonalArea={isMortPersonalArea}
      />
      <div className="inner-content">
        {route ? (
          <Navigate to={`/${route}`} replace />
        ) : (
          <Outlet
            context={[
              refs,
              videoURLS,
              mortRefs,
              isFooterVisible,
              mortContactFormRef,
            ]}
          />
        )}
        {!isMortLandingPage && !isNewsletter && isMortProductPage && (
          <PhoneBanner
            mortContactRef={mortContactFormRef}
            isMortProductPage={isMortProductPage}
            isFooterVisible={isFooterVisible}
          />
        )}
      </div>
      <Footer
        customCategory={isMortLandingPage ? texts.events.category : null}
        ref={footerRef}
        isNewsletter={isNewsletter}
      />
    </main>
  );
}
