import React from "react";

import Container from "../../../container";

import { SUB_TITLE, TITLE } from "./texts";

import "./index.scss";

export default function Header () {
    return <Container className="header-container-mort-form">
        <h2>{TITLE}</h2>
        <h3>{SUB_TITLE}</h3>
    </Container>
}