import { texts } from "../../../../rent/pages/mortgage-landing-page/consts"

import axios from "axios";
import config from "../../../../../config/config";

import { Validators } from "@wecheck/uisharedutils/validators";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { SOURCE_ENUM } from "../consts";
import Utils from "../../../../shared/utils";

const EVENT_ACTION = `שליחת טופס השארת פרטים`
const EVENT_TRIGGER = `לחיצה על כפתור 'שליחה' לאחר מילוי הטופס`

export default function useForm({ dialogRef }) {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [requestSource, setRequestSource] = useState('')

    const initialValues = {
        fullName: '',
        phone: '',
        requestedAmount: '',
        dealType: undefined
    }

    const location = useLocation()

    useEffect(() => {
        setRequestSource(SOURCE_ENUM[location.pathname])
    }, [location.pathname])

    const validate = (values) => {
        const errors = {}
        if (!values.fullName) {
            errors.fullName = texts.form.errors.fullName.empty
        } else if (!Validators.hebrewOnly(values.fullName)) {
            errors.fullName = texts.form.errors.fullName.notHebrew
        }
        if (!values.phone) {
            errors.phone = texts.form.errors.phone.empty
        } else if (!Validators.phone(values.phone)) {
            errors.phone = texts.form.errors.phone.error
        }
        if (values.dealType !== 0 && !values.dealType) {
            errors.dealType = texts.form.errors.dealType
        }
        if (parseInt(values.requestedAmount) < 100000 || parseInt(values.requestedAmount) > 1500000) {
            errors.requestedAmount = texts.form.errors.requiredAmount
        }

        return errors
    }

    const onSubmit = (values, { setSubmitting }) => {
        setSubmitting(true);
        return axios.put(`${config.mortServerAddr}/contactMortConsultant/25`, { ...values, requestSource }).then((res) => {
            setSubmitting(false)
            dialogRef.current.showModal()
            Utils.gaEvent(requestSource, EVENT_ACTION, EVENT_TRIGGER)
        })
    }

    return { initialValues, validate, onSubmit, isModalOpen, setIsModalOpen }
}


