import Utils from "../../../../../shared/utils";

export const CHEVRON_BOX = Utils.buildNewsletterLink("icons/chevron-box.svg");

export const MONTH_ENUM = {
    0: "ינואר",
    1: "פברואר",
    2: "מרץ",
    3: "אפריל",
    4: "מאי",
    5: "יוני",
    6: "יולי",
    7: "אוגוסט",
    8: "ספטמבר",
    9: "אוקטובר",
    10: "נובמבר",
    11: "דצמבר",
};
