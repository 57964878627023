const CTA_CLICK_TRIGGER = `לחיצה על כפתור 'לבדיקת זכאות וקבלת הצעה'`;

const EVENT_CATEGORY = `עמוד מוצר - משכנתא פרמיום`;

const EVENT_ACTION = `לבדיקת זכאות וקבלת הצעה -`;

export const ANALYTICS = {
  FIRST_SECTION: {
    CTA: {
      CATEGORY: EVENT_CATEGORY,
      TRIGGER: CTA_CLICK_TRIGGER,
      ACTION: `${EVENT_ACTION} סקשן פותח`,
    },
  },
  SECOND_SECTION: {
    CTA: {
      CATEGORY: EVENT_CATEGORY,
      TRIGGER: CTA_CLICK_TRIGGER,
      ACTION: `${EVENT_ACTION} סקשן יתרונות`,
    },
  },
  THIRD_SECTION: {
    CTA: {
      CATEGORY: EVENT_CATEGORY,
      TRIGGER: CTA_CLICK_TRIGGER,
      ACTION: `${EVENT_ACTION} סוגי החזרים קיימים`,
    },
  },
  FIFTH_SECTION: {
    CTA: {
      CATEGORY: EVENT_CATEGORY,
      TRIGGER: CTA_CLICK_TRIGGER,
      ACTION: `${EVENT_ACTION} משכנתא חוץ בנקאית`,
    },
  },
};
