import axios              from "axios";
import React, {Component} from "react";
import {Link}             from "react-router-dom";

import config from "../../../../../../config/config";
import {URLS} from "../../../../../shared/urls";
import Utils  from "../../../../../shared/utils";

import {
	emailValidator,
	hebrewOnlyValidator,
	phoneValidator,
	requiredValidator
}                            from "../../../../../shared/validations";
import {ErrorLabel, WckForm} from "../../../../../UIWck";

import "./index.scss";

const initialValues = {
	fullName: "",
	email   : "",
	phone   : "",
	reason  : 9,
	content : ""
};

class MortContactUs extends Component {
	constructor(props) {
		super(props);

		this.state = {sendingRequest: false};
	}

	handleSubmit = ({values}) => {
		Utils.gaEvent("אתר שיווקי -￼ משכנתאות", "יצירת קשר - שלח פרטים", "");

		values.reason = 9;

		this.setState((state) => ({
			...state,
			requestStatus : "ACTIVE",
			sendingRequest: true
		}));
		axios
			.put(`${config.serverAddr}/contacts`, values)
			.then(() =>
				this.setState((state) => ({
					...state,
					values        : initialValues,
					requestStatus : "SUCCESS",
					sendingRequest: false
				}))
			)
			.catch(() =>
				this.setState((state) => ({
					...state,
					requestStatus : "FAILED",
					sendingRequest: false
				}))
			);
	};

	handleChange = (e, handleChange) => {
		handleChange({
			name : e.target.name,
			value: e.target.value
		});
	};

	render = () => (
		<section ref = {this.props.reference} className = "MortContactUs" >
			<div className = "image-container" >
				<img
					src = {Utils.buildDashboardImgLink("mortgage/morwoman.svg")}
					alt = ""
				/>
			</div >
			<div className = "form-container" >
				<div className = "title-container" >
					<h2 >שמרו איתנו על קשר!</h2 >
					<h4 >
						יש לכם שאלה? אולי תוכלו למצוא את התשובה{" "}
						<Link to = {URLS.MortQNA} >ממש כאן</Link >
						<br />
						אם זה לא עזר, תמיד אפשר לכתוב לנו ונענה בהקדם
					</h4 >
				</div >
				<WckForm
					firstCtrlId = "fullName"
					render = {({
						           handleSubmit,
						           handleChange,
						           handleTabCycle,
						           handleBlur,
						           values,
						           errors,
						           hasErrors
					           }) => (
						<form className = "contact-form" onSubmit = {handleSubmit} >
							<input
								type = "text"
								id = "fullName"
								name = "fullName"
								data-hj-allow
								placeholder = "שם מלא"
								autoComplete = "off"
								onBlur = {(e) => this.handleChange(e, handleBlur)}
								onChange = {(e) =>
									this.handleChange(e, handleChange)
								}
								n
							/>
							{errors.fullName[0] ? (
								<ErrorLabel >יש להזין שם מלא</ErrorLabel >
							) : (
								 errors.fullName[1] && (
									 <ErrorLabel >
										 יש להזין אותיות בעברית בלבד
									 </ErrorLabel >
								 )
							 )}
							<input
								type = "tel"
								name = "phone"
								data-hj-allow
								placeholder = "טלפון"
								autoComplete = "off"
								onBlur = {(e) => this.handleChange(e, handleBlur)}
								onChange = {(e) =>
									this.handleChange(e, handleChange)
								}
							/>
							{errors.phone[0] ? (
								<ErrorLabel >יש להזין מספר טלפון</ErrorLabel >
							) : (
								 errors.phone[1] && (
									 <ErrorLabel >
										 יש להזין מספר טלפון תקין בלבד
									 </ErrorLabel >
								 )
							 )}
							<input
								type = "email"
								name = "email"
								data-hj-allow
								placeholder = "מייל"
								autoComplete = "off"
								onBlur = {(e) => this.handleChange(e, handleBlur)}
								onChange = {(e) =>
									this.handleChange(e, handleChange)
								}
							/>
							{errors.email[0] ? (
								<ErrorLabel >יש להזין כתובת אימייל</ErrorLabel >
							) : (
								 errors.email[1] && (
									 <ErrorLabel >
										 יש להזין כתובת אימייל תקינה בלבד
									 </ErrorLabel >
								 )
							 )}

							<textarea
								name = "content"
								placeholder = "במה נוכל לעזור?"
								onBlur = {(e) => this.handleChange(e, handleBlur)}
								onChange = {(e) =>
									this.handleChange(e, handleChange)
								}
							/>

							<button
								type = "submit"
								disabled = {
									hasErrors || this.state.sendingRequest
								}
							>
								שלח
							</button >

							{this.state.requestStatus === "FAILED" && (
								<div className = "textDanger" >
									הבקשה נכשלה, אנא נסו שוב מאוחר יותר.
								</div >
							)}
						</form >
					)}
					validations = {{
						fullName: [requiredValidator, hebrewOnlyValidator],
						phone   : [requiredValidator, phoneValidator],
						email   : [requiredValidator, emailValidator],
						content : [requiredValidator]
					}}
					onSubmit = {this.handleSubmit}
				/>
				{this.state.requestStatus === "SUCCESS" && (
					<div className = "successDiv" >
						<img src = {Utils.buildImgLink("ok.svg", true)} />
						<h4 >פנייתך נקלטה בהצלחה!</h4 >
					</div >
				)}
			</div >
		</section >
	);
}

export {MortContactUs};
