import React from "react";
import { useLottie } from "lottie-react";
import "./index.scss";
import { SCROLL_INDICATION } from "./texts";
import { options } from "./options";

export default function ScrollIndicator() {
  const { View } = useLottie(options);
  return (
    <div className="scroll-indicator-container">
      {View}
      <p>{SCROLL_INDICATION}</p>
    </div>
  );
}
