import React, { useRef } from "react";
import useCTAAnimation from "../../../../../hooks/product-pages/useCTAAnimation";

import GridSection from "../../../../../components/product-pages/grid-section";
import HeadingMD from "../../../../../components/product-pages/heading-md";
import ItemList from "../../../../../components/product-pages/item-list";

import { ITEM_LIST_FIRST, ITEM_LIST_SECOND, ITEM_LIST_THIRD, ITEM_LIST_TITLE } from "./consts";
import "./index.scss";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { URLS } from "../../../../../shared/urls";
import { ANALYTICS } from "../../consts";

export default function SecondSection() {
    const CTARef = useRef()
    const CTAContainerAnimated = useCTAAnimation({
        className: "airplane-animation-and-cta",
        buttonClassName: "animate-appearance",
        ctaText: CTA_BUTTON_TEXT,
        ref: CTARef,
        CTALink: URLS.MortgageQue,
        analytics: ANALYTICS.SECOND_SECTION.CTA
    })

    return <GridSection className="premium-mort-second-section">
        <HeadingMD text={ITEM_LIST_TITLE} />
        <ItemList list={ITEM_LIST_FIRST} />
        <ItemList list={ITEM_LIST_SECOND} />
        <ItemList list={ITEM_LIST_THIRD} />
        {CTAContainerAnimated}
    </GridSection>
}