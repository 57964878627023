import React, {useRef} from "react";
import useIsInViewport from "../../../../../hooks/useIsInViewport";

import Container from "../../../../../components/product-pages/container";
import CTA from "../../../../../components/product-pages/cta";
import ImageContainer from "../../../../../components/product-pages/image-container";
import Section from "../../../../../components/product-pages/section";
import TextSection from "../../../../../components/product-pages/text-section";

import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { IMAGE_URL } from "./consts";
import { CTA_TITLE, PARAGRAPH, TITLE } from "./texts";

import "./index.scss";
import { ANALYTICS } from "../../consts";

export default function FourthSection() {
    const sectionRef = useRef(null);
    const isInView = useIsInViewport(sectionRef);

    return <Section reference={sectionRef} className="aia-fourth-section">
        <Container className={`text-and-cta ${isInView ? "in-view" : ""}`}>
            <TextSection title={TITLE} text={PARAGRAPH} />
            <CTA analytics={ANALYTICS.FOURTH_SECTION.CTA} title={CTA_TITLE} text={CTA_BUTTON_TEXT} />
        </Container>
        <ImageContainer className="safecheck-image" src={IMAGE_URL} />
    </Section>
}