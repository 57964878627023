import React from "react";
import "./index.scss";
import { useDispatch, useSelector } from "react-redux";
import { EDIT_ICON } from "./consts";
import { MONTH_ENUM } from "../../../../../search/components/month-select/consts";
import { openDateRangePick } from "../../../../../../../../redux/features/newsletterSlice";

export default function ClosedDisplay() {
  const dispatch = useDispatch();
  const dateOptions = useSelector((state) => state.newsletter.filter.date);

  const openDateRange = () => {
    if (dateOptions.isVisible) return;
    dispatch(openDateRangePick());
  };

  const monthDisplay = dateOptions.to
    ? `${MONTH_ENUM[dateOptions.from - 1]} - ${MONTH_ENUM[dateOptions.to - 1]}`
    : `${MONTH_ENUM[dateOptions.from - 1]}`;

  return (
    <div className="closed-display-container" onClick={openDateRange}>
      <div className="text-container">
        {monthDisplay}
        <span>{dateOptions.availableYears[dateOptions.currentYearIndex]}</span>
      </div>
      <div className="icon-container">
        <img src={EDIT_ICON} alt="edit" />
      </div>
    </div>
  );
}
