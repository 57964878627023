import React from "react";
import Utils from "../../../shared/utils";
import Container from "../container";
import "./index.scss";

export default function YoutubeVideo({ videoUrl, analytics = {} }) {
    const videoId = videoUrl.split('v=')[1];

    const {CATEGORY, TRIGGER, ACTION} = analytics
  
    return (
      <Container onClick={() => Utils.gaEvent(CATEGORY, ACTION, TRIGGER)} className="video-container">
        <iframe
          src={`https://www.youtube.com/embed/${videoId}`}
          title="Embedded YouTube video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Container>
    );
  }