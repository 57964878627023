import React from "react";
import "./index.scss";

export const PlusMinusIcon = ({active}) => {
	return (
		<div className = {`plus-minus-icon ${active ? "animate" : ""}`} >
			<div className = "line horizontal" />
			<div className = "line diagonal" />
		</div >
	);
};
