import Utils from "../../../shared/utils";

export const MORT_PERSONAL_AREA_ANIMATIONS = [
    {
        src: Utils.buildLoginImgLink("animations/computer.svg"),
        alt: "מחשב",
    },
    {
        src: Utils.buildLoginImgLink("animations/hand.svg"),
        alt: "טלפון",
    },
    // {
    //     src: Utils.buildLoginImgLink("animations/documents.svg"),
    //     alt: "מנעול",
    // },
]

export const ANIMATION_DURATION = 4500;