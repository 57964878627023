import Utils from "../../../shared/utils";
import { INTRO_FIRST_PART, INTRO_THIRD_PART, INTRO_SECOND_PART } from "./texts";

export const FIRST_PART_ANIMATION = Utils.buildNewsletterLink(
  "animations/intro/first-part.svg"
);
export const SECOND_PART_IMAGE = Utils.buildNewsletterLink(
  "icons/rent-ai-intro-image.svg"
);
export const SECOND_PART_ANIMATION_CLOUDS = Utils.buildNewsletterLink(
  "animations/intro/second-part-clouds.svg"
);
export const THIRD_PART_ANIMATION = Utils.buildNewsletterLink(
  "animations/intro/third-part.svg"
);

export const STEPS = {
  FIRST: 0,
  SECOND: 1,
  THIRD: 2,
};

export const TITLE_ENUM = {
  [STEPS.FIRST]: INTRO_FIRST_PART.TITLE,
  [STEPS.SECOND]: INTRO_SECOND_PART.TITLE,
  [STEPS.THIRD]: INTRO_THIRD_PART.TITLE,
};
