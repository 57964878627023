export const MONTH_ENUM_ABBREVIATED = {
  0: "ינואר",
  1: "פבר׳",
  2: "מרץ",
  3: "אפריל",
  4: "מאי",
  5: "יוני",
  6: "יולי",
  7: "אוג׳",
  8: "ספט׳",
  9: "אוק׳",
  10: "נוב׳",
  11: "דצמ׳",
};
