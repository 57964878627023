import React, { useEffect, useRef } from "react";
import "./index.scss";
import {
  MainContainer,
  ChatContainer,
  MessageList,
  Message,
  MessageInput,
  TypingIndicator,
} from "@chatscope/chat-ui-kit-react";
import styles from "@chatscope/chat-ui-kit-styles/dist/default/styles.min.css";
import apiClient from "../../api-client";
import LoginButton from "./login-button";
import ChatHeader from "./chat-header";
import { RENT_PRICELIST_CHAT_SERVICE } from "../../redux/consts";
import config from "../../config/config";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsChatOpen,
  setIsWaitingForResponse,
  setMessages,
} from "../../redux/features/newsletterSlice";
import useHandleCLickOutside from "../hooks/useHandleClickOutside";
import { pushToGTM } from "../../helpers/gtm";
import useIsMobile from "../hooks/useIsMobile";

export const FIRST_MESSAGE = {
  type: "text",
  position: "single",
  content: {
    text: `איך אוכל לעזור?`,
  },
  direction: "incoming",
};

const GENERAL_ERROR_MESSAGE = {
  type: "text",
  position: "single",
  content: { text: "לא בטוח שהבנתי 😵‍💫 אפשר לנסח את השאלה מחדש?" },
  direction: "incoming",
};

export default function ChatUI({ isLoggedIn, handleChatInputFocus }) {
  const dispatch = useDispatch();
  const messages = useSelector((state) => state.newsletter.chat.messages);
  const isChatOpen = useSelector((state) => state.newsletter.chat.isOpen);
  const chatSessionID = useSelector((state) => state.newsletter.chat.sessionID);
  const isMobile = useIsMobile(1000);
  const isWaitingForResponse = useSelector(
    (state) => state.newsletter.chat.isWaitingForResponse
  );
  const messageListRef = useRef(null);

  const inputRef = useRef(null);

  const chatContainerRef = useRef(null);

  const isPopupOpen = useSelector(
    (state) => state.promotional.currentPopupIndex
  );

  useHandleCLickOutside({
    reference: chatContainerRef,
    callback: () => null,
    disabled: isPopupOpen,
  });

  const appendMsg = (msg) => {
    dispatch(setMessages(msg));
  };
  async function handleSend(text) {
    dispatch(setIsWaitingForResponse(true));
    if (text.trim()) {
      appendMsg({
        type: "text",
        position: "single",
        content: { text },
        direction: "outgoing",
      });

      pushToGTM("צ׳אט - הודעה נשלחת", {
        content: text,
      });

      try {
        const chatBaseURL = `${
          config.serverlessAddr
            ? config.serverlessAddr
            : config.RENT_PRICELIST_CHAT_LOCAL
        }${RENT_PRICELIST_CHAT_SERVICE}`;

        const completeURL = `${chatBaseURL}/complete?searchTerm=${text}&sessionID=${chatSessionID}`;
        const lastMessageURL = `${chatBaseURL}/last-message?sessionID=${chatSessionID}`;

        await apiClient({
          url: completeURL,
          method: "GET",
        });

        await new Promise((resolve) => {
          const lastMessagePollingInterval = setInterval(async () => {
            const res = await apiClient({
              url: lastMessageURL,
              method: "GET",
            });

            const answer = res.data?.data?.answer;
            if (!answer) return;

            appendMsg({
              type: "text",
              position: "single",
              content: { text: answer },
              direction: "incoming",
            });

            pushToGTM("צ׳אט - הודעה מתקבלת", {
              content: answer,
            });

            resolve(clearInterval(lastMessagePollingInterval));
          }, 5000);
        });
      } catch (err) {
        appendMsg(GENERAL_ERROR_MESSAGE);
      } finally {
        dispatch(setIsWaitingForResponse(false));
      }
    }
  }

  useEffect(() => {
    if (isWaitingForResponse) {
      messageListRef.current.scrollToBottom();
    }
  }, [isWaitingForResponse]);

  return (
    <div
      ref={chatContainerRef}
      className={`chat-container ${isLoggedIn ? "is-loggedin" : ""} ${
        isChatOpen ? "is-open" : ""
      }`}
    >
      {!isMobile && (
        <ChatHeader
          isChatOpen={isChatOpen}
          onClick={() => dispatch(setIsChatOpen(!isChatOpen))}
        />
      )}
      <MainContainer>
        <ChatContainer>
          <MessageList
            ref={messageListRef}
            scrollBehavior="auto"
            className={`${isChatOpen ? "is-open" : ""}`}
          >
            {messages.map((msg, i) => (
              <Message
                key={i}
                model={{
                  message: msg.content.text,
                  direction: msg.direction,
                  position: msg.position,
                }}
              />
            ))}
            {isLoggedIn === false && <LoginButton />}
            {isWaitingForResponse && <TypingIndicator />}
          </MessageList>
          <MessageInput
            ref={inputRef}
            onInput={(e) => dispatch(setIsChatOpen(true))}
            onClick={() => dispatch(setIsChatOpen(true))}
            placeholder="להתכתבות עם RentAI"
            sendOnReturnDisabled={isWaitingForResponse}
            sendDisabled={isWaitingForResponse}
            onSend={(_, text) => handleSend(text)}
            disabled={!isLoggedIn}
            attachButton={false}
            onFocus={() => handleChatInputFocus(true)}
            onBlur={() => handleChatInputFocus(false)}
          />
        </ChatContainer>
      </MainContainer>
    </div>
  );
}
