import React, { useRef } from "react";
import useCTAAnimation from "../../../../../hooks/product-pages/useCTAAnimation";

import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";
import YoutubeVideo from "../../../../../components/product-pages/youtube-video";

import { ANALYTICS } from "../../consts";
import { URLS } from "../../../../../shared/urls";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { VIDEO_URL } from "./consts";
import { CTA_TITLE, PARAGRAPH, TITLE } from "./texts";

import "./index.scss";

export default function SecondSection() {
    const CTARef = useRef(null)
    const CTAContainerAnimated = useCTAAnimation({
        className: "airplane-animation-and-cta",
        buttonClassName: "animate-appearance",
        ctaTitle: CTA_TITLE,
        ctaText: CTA_BUTTON_TEXT,
        ref: CTARef,
        CTALink: URLS.MortgageQue,
        analytics: ANALYTICS.SECOND_SECTION.CTA
    })

    return <GridSection className="additional-mort-second-section">
        <TextSection title={TITLE} text={PARAGRAPH} />
        <YoutubeVideo analytics={ANALYTICS.SECOND_SECTION.VIDEO} videoUrl={VIDEO_URL} />
        {CTAContainerAnimated}
    </GridSection>
}