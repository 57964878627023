import React from "react";
import {
  currentPopup,
  moveToOptions,
} from "../../../../redux/features/promotionalSlice";
import { useDispatch, useSelector } from "react-redux";
import AnimationContainer from "../../../components/product-pages/animation-container";
import ViewOptions from "./components/display-options";
import "./index.scss";
import CloseButton from "./components/close-button";
import { DESKTOP_SPREAD, FIRST_PART_ANIMATION, MOBILE_SPREAD } from "./consts";
import useIsMobile from "../../../hooks/useIsMobile";
import { FIRST_BREAKPOINT } from "../../consts";

function PopupSpread({ image }) {
  return <img className="popup-spread-image" src={image} />;
}

export default function PopUp({ className = "" }) {
  const dispatch = useDispatch();
  const userType = useSelector((state) => state.login.userType);
  const currentSelectedPopup = useSelector((state) =>
    currentPopup(state, userType)
  );
  const viewOptions = useSelector((state) => state.promotional.viewOptions);
  const user = useSelector((state) => state.user);
  const stringsReplacer = {
    firstName: user?.firstName || "",
    propertyCity: user?.propertyCity || "",
    propertyAddress: user?.propertyStreet
      ? `${user?.propertyStreet}, ${user?.propertyCity}`
      : user?.propertyCity || "",
    amountOfClicks: user?.numberOfClicks || "",
  };

  function replacePlaceholders(inputStr, replacers) {
    let resultStr = inputStr;

    for (const key in replacers) {
      const placeholder = `%${key}%`;
      resultStr = resultStr?.replace(
        new RegExp(placeholder, "g"),
        replacers[key]
      );
    }

    return resultStr;
  }

  const moveToOptionsSection = () => dispatch(moveToOptions());

  const isMobile = useIsMobile(FIRST_BREAKPOINT);

  const [isOpen, setIsOpen] = React.useState(false);
  const [boxDisplay, setBoxDisplay] = React.useState('none');

  React.useEffect(() => {
    if (currentSelectedPopup) {
      setBoxDisplay('block');
      setTimeout(() => {
        setIsOpen(true)
      }, 0);
    } else {
      setIsOpen(false);
      setTimeout(() => {
        setBoxDisplay('none');
      }, 500);
    }
  }, [currentSelectedPopup]);

  return (
    <>
      <div
        style={{ display: boxDisplay }}
        className={`pop-up-container ${
          isOpen ? "is-open" : ""
        } ${className} ${viewOptions ? "view-options" : ""}`}
      >
        {viewOptions ? (
          <ViewOptions />
        ) : (
          <>
            <div className="pop-up-content">
              <AnimationContainer
                style={{
                  width: "70px",
                  height: "70px",
                }}
                className="popup-animation"
                img={FIRST_PART_ANIMATION}
              />
              <p className="pop-up-text">
                {replacePlaceholders(
                  currentSelectedPopup?.content,
                  stringsReplacer
                )}
              </p>
            </div>
            <PopupSpread image={isMobile ? MOBILE_SPREAD : DESKTOP_SPREAD} />
            <button onClick={moveToOptionsSection} className="search-button">
              {currentSelectedPopup?.button_cta}
            </button>
          </>
        )}
        <CloseButton isPopupOpen={isOpen}/>
      </div>
      <div
        style={{ display: boxDisplay }}
        className={`pop-up-background ${isOpen ? "is-open" : ""}`}
      />
    </>
  );
}
