import React, { useRef } from "react";
import { ErrorMessage, Field } from "formik";

import Container from "../../../container";

import "./index.scss";
 

export default function Input({ type = "text", name, label, pattern, inputmode}) {
    const inputRef = useRef(null)

    return <Container className="input-container-mort-form">
        <Container onClick={() => inputRef.current && inputRef.current.focus()}  className="input-and-label">
            <label htmlFor={name}>{label}</label>
            <Field innerRef={inputRef} type={type} name={name} pattern={pattern} inputMode={inputmode}/>
        </Container>
        <ErrorMessage className="error-message" name={name} component="p" />
    </Container>
}