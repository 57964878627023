import Utils   from "../../../../../../../../shared/utils";
import {Slide} from "../../slide";

import "./index.scss";

const SuccessSlide = ({buttonPressed, withShadow}) => <div className = "SuccessSlide" >
	<Slide withShadow = {withShadow} >
		<img src = {Utils.buildTenantPageImgLink("bigPhone.svg")} alt = "" />
		<div className = "dataInput" >
			<h4 >זהו! הפרטים הוזנו בהצלחה</h4 >
			<br />
			<p >
				נשלח אליך שאלון בהודעת SMS בעזרתו נבנה לך את הפרופיל הדיגיטלי
			</p >
		</div >

		<div className = "buttonDiv" >
			<a onClick = {buttonPressed}
			   className = "btn--azure" >
				לשליחת טופס נוסף
			</a >
		</div >
	</Slide >
</div >;

export {SuccessSlide};