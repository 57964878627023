import React from "react";
import "./index.scss";

export default function ChatHeader({ onClick, isChatOpen }) {
  return (
    <button
      onClick={onClick}
      className={`chat-button ${isChatOpen ? "chat-open" : ""}`}
    >
      <div className="chat-button-text">
        WeCheck <span className="ai">AI</span>
      </div>
    </button>
  );
}
