import React from "react";

import {URLS} from "../../../../shared/urls";
import Utils  from "../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../components/logo";

const CalcSection = () => (
	<div className = "CalcSection" >
		<div className = "desktopOnly" >
			<h1 >מחשבון משכנתא משלימה</h1 >
		</div >

		<div className = "mobileOnly" >
			<h1 >מחשבון</h1 >
			<h1 >משכנתא משלימה</h1 >
		</div >
		<img
			className = "calcImg"
			src = {Utils.buildDashboardImgLink("mortgage/calculator.svg")}
			alt = ""
		/>
		<div className = "textSection" >
			<h4 >רוצים להבין עד כמה</h4 >
			<div className = "textWithLogo" >
				<h4 >משכנתא משלימה של </h4 >
				<Logo color="blue" />
			</div >
			<h4 >יכולה לעזור לכם להגיע לנכס שאתם רוצים?</h4 >
		</div >

		<div className = "buttonBox" >
			<a
				onClick = {() =>
					Utils.gaEvent(
						"עמוד ראשי משכנתאות",
						"בואו נעשה חשבון פשוט",
						""
					)
				}
				href = {URLS.MortCalc}
				className = "btn--oldred"
			>
				בואו נעשה חשבון פשוט
			</a >
		</div >
	</div >
);

export {CalcSection};
