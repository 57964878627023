import Utils from "../../../shared/utils";

export const ANIMATED_LIST_REALTOR = [
  {
    icon: Utils.buildLoginImgLink("icons/click-icon.svg"),
    text: `מקבלים הצעה לערבות בדקה\nמבצעים בדיקת רקע לדיירים ובתוך דקה\nמקבלים תשובה לגבי ערבות וויצ'ק - בכל\nשעה ביום`,
  },
  {
    icon: Utils.buildLoginImgLink("icons/plant-icon.svg"),
    text: `הבטחת התשואה לבעל הנכס\nבעזרת ערבות וויצ'ק תבטיחו את התשואה\nעל שכר הדירה ללקוחותיכם`,
  },
  {
    icon: Utils.buildLoginImgLink("icons/moneybags-icon.svg"),
    text: `קבלת תגמול על כל עסקה\nהבטחתם לבעל הנכס את התשואה על\nהדירה! קיבלתם מאיתנו תגמול על כל\nעסקה שהתבצעה`,
    disclaimer: `*בכפוף לתנאי ההסכם`,
  },
];

export const ANIMATED_LIST_REGULAR = [
  {
    icon: Utils.buildLoginImgLink("icons/document-icon.svg"),
    text: `הרשמה למערכת\nמזינים מספר טלפון ואתם בפנים!\nעכשיו ניתן לבצע בדיקה לדיירים`,
  },
  {
    icon: Utils.buildLoginImgLink("icons/rating-icon.svg"),
    text: `בדיקת רקע לדייר - בחינם!\nמזינים את מספר הטלפון של הדייר,\nהדייר עונה על מספר שאלות ואתם\nמקבלים את הדוח המוכן לנייד`,
  },
  {
    icon: Utils.buildLoginImgLink("icons/destination-icon.svg"),
    text: `רוכשים ערבות וויצ'ק!\nבדקתם את הדייר? עכשיו התקדמו\nלערבות שמגינה עליכם מכל הסיכונים\nבעסקת השכירות (ואפילו ללא צ'קים!)`,
  },
];

export const ANIMATED_LIST_NEWSLETTER = [
  {
    icon: Utils.buildLoginImgLink("icons/location-icon.svg"),
    text: `נתונים בפריסה ארצית\nמחירים של עשרות אלפי דירות ב-70\nערים שונות, לפי שכונות, רחובות\nואפילו חלוקה לחדרים!`,
  },
  {
    icon: Utils.buildLoginImgLink("icons/house-icon.svg"),
    text: `דירות בשכונה שלי\nרוצים לדעת מה קורה בשכונה שלכם?\nמחירי כל הדירות שפוסרמו בסביבתכם\nלפי תקופות וכמות חדרים`,
  },
  {
    icon: Utils.buildLoginImgLink("icons/graph-icon.svg"),
    text: `מגמות שינוי\nבשכר הדירה בהיצע הדירות בהשוואה\nלחודשים ושנים קודמות`,
  },
];

export const ANIMATED_LIST_TITLE = `רגע לפני שנכנסים למערכת\nאלו הפעולות שניתן לבצע בפנים`;

export const ANIMATED_LIST_TITLE_NEWSLETTER = `WeCheck RentAI\nמה תוכלו למצוא בפנים?`;

export const STEP_ANIMATION_RIGHT = Utils.buildLoginImgLink(
  "animations/step-transition-right.svg"
);
export const STEP_ANIMATION_LEFT = Utils.buildLoginImgLink(
  "animations/step-transition-left.svg"
);
