import React from "react";
import "./index.scss";
import { HEADER_IMAGE } from "../../../../mortgage/personal-area/components/personal-details-drawer/components/header/consts";
import { PERSONAL_DETAILS } from "./texts";
import { useDispatch } from "react-redux";
import { handleDrawer } from "../../../../../redux/features/userSlice";

export default function OpenDetails() {
  const dispatch = useDispatch();
  const openDetailsDrawer = () => dispatch(handleDrawer(true));
  return (
    <button onClick={openDetailsDrawer} className="open-details-button">
      <img src={HEADER_IMAGE} alt="" />
      {PERSONAL_DETAILS}
    </button>
  );
}
