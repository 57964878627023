import React from "react";
import useReplaceWithSpan from "../../../../../../../newsletter/hooks/useReplaceWithSpan";
import "./index.scss";
import { READ_MORE_CTA } from "../../consts";
import { Link } from "react-router-dom";
import { CHEVRON_LIGHTBLUE } from "../../../../../../../newsletter/components/location-cards/components/card/consts";
import Container from "../../../../../../../components/product-pages/container";
import ImageContainer from "../../../../../../../components/product-pages/image-container";
import useIsMobile from "../../../../../../../hooks/useIsMobile";

export default function Card({ card }) {
  const paragraph = useReplaceWithSpan(card.PARAGRAPH, card.PART_TO_REPLACE);
  const isMobile = useIsMobile(1000);

  return (
    <Container className="card-container">
      <div className="card-content">
        <ImageContainer className="card-icon-container" src={card.ICON} />
        <div className="card-title-container">
          <h3>{card.TITLE}</h3>
        </div>
        <div className="card-secondary-title-container">
          <h4>{card.SECONDARY_TITLE}</h4>
        </div>
        {!isMobile && (
          <div className="card-paragraph-container">
            <p>
              {paragraph}
              {card.DISCLAIMER && (
                <>
                  <br />
                  <br />
                  <span className="card-disclaimer">{card.DISCLAIMER}</span>
                </>
              )}
            </p>
          </div>
        )}
      </div>
      <Link to={card.LINK} className="card-button-cta">
        {READ_MORE_CTA}
        <div className="card-image-container">
          <img src={CHEVRON_LIGHTBLUE} alt="chevron" />
        </div>
      </Link>
    </Container>
  );
}
