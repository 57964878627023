import React from "react";

import "./index.scss";
import {Logo} from "../logo";

const LogoLine = ({before, after, isWhite}) => (
	<div className = "LogoLine" >
		<h3 >{before}</h3 >
		<Logo color={isWhite ? "white" : "blue"} />
		{after && <h3 >{after}</h3 >}
	</div >
);

export {LogoLine};
