import React, {useRef} from "react";
import useIsInViewport from "../../../../../hooks/useIsInViewport";
import Utils           from "../../../../../shared/utils";

import "./index.scss";
import {Logo} from "../../../../../components/logo";

const WhyWeCheck = () => {
	const titleRef   = useRef(null);
	const contentRef = useRef(null);

	return (
		<section className = "WhyWeCheck" >
			<div
				ref = {titleRef}
				className = {`title-container ${
					useIsInViewport(titleRef) ? "in-view" : ""
				}`}
			>
				<h2 >
					<span >למה </span >
	
					<Logo color="blue" />

					<span >?</span >
				</h2 >
			</div >
			<div
				ref = {contentRef}
				className = {`cards-grid-container ${
					useIsInViewport(contentRef) ? "in-view" : ""
				}`}
			>
				<div className = "card-container" >
					<img
						src = {Utils.buildImgLink("mort/WhyWeCheckIcon-1.svg")}
						alt = "Icon"
						className = "Icon"
					/>
					<div className = "card-content" >
						<h3 >2 סוגי משכנתאות</h3 >
						<p >
							<b >משכנתא משלימה</b > - משכנתא נוספת למשכנתא של הבנק
						</p >
						<p >
							<b >משכנתא פרימיום</b > - משכנתא שכל כולה נלקחת
							                        מ-WeCheck
						</p >
					</div >
				</div >
				<div className = "card-container" >
					<img
						src = {Utils.buildImgLink("mort/WhyWeCheckIcon-2.svg")}
						alt = "Icon"
						className = "Icon"
					/>
					<div className = "card-content" >
						<h3 >
							<span >עד 85% מימון</span >
							<br />
							<span >כבר אמרנו?</span >
						</h3 >
						<p >
							מימון מלא מWeCheck או משכנתא משלימה למשכנתא של הבנק
							במימון של עד 85% משווי הנכס
						</p >
					</div >
				</div >
				<div className = "card-container" >
					<img
						src = {Utils.buildImgLink("mort/WhyWeCheckIcon-3.svg")}
						alt = "Icon"
						className = "Icon"
					/>
					<div className = "card-content" >
						<h3 >
							<span >כי גם לנו נמאס מהניירת,</span >
							<br />
							<span >אנחנו בדיגיטל</span >
						</h3 >
						<p >תהליך בקשת וקבלת המשכנתא קל ונוח לשימוש</p >
					</div >
				</div >
				<div className = "card-container" >
					<img
						src = {Utils.buildImgLink("mort/WhyWeCheckIcon-4.svg")}
						alt = "Icon"
						className = "Icon"
					/>
					<div className = "card-content" >
						<h3 >תנאי הלוואה נוחים</h3 >
						<p >מגוון מסלולים עם תנאים נוחים</p >
					</div >
				</div >
			</div >
		</section >
	);
};

export {WhyWeCheck};
