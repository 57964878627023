import React from "react";
import Utils from "../../../../../shared/utils";
import { texts } from "../../consts";
import "./index.scss";

const replaceStringPartWithSpan = (string, part) => string.replace(part, `<span>${part}</span>`);

export default function TopSection () {
    return <section className="top-section-container">
        <div className="text-section">
            <h1>{texts.topSection.title}</h1>
            <h2 dangerouslySetInnerHTML={{
                __html: replaceStringPartWithSpan(texts.topSection.secondaryTitle, "85% מימון")
            }} />
            <p>{texts.topSection.paragraph}</p>
        </div>
        <div className="image-section">
            <img src={Utils.buildMortImgLink("landing-page/top-part-image.png")} />
        </div>
    </section>
}