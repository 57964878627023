import React, { useRef } from "react";

import CTAAnimationClick from "../../../../../components/product-pages/CTAAnimationClick";
import GridSection from "../../../../../components/product-pages/grid-section";
import TextSection from "../../../../../components/product-pages/text-section";
import CardList from "./components/card-list";
import Container from "../../../../../components/product-pages/container";
import ImageContainer from "../../../../../components/product-pages/image-container";

import { ANALYTICS } from "../../consts";
import { IMAGE_URL } from "./consts";
import { URLS } from "../../../../../shared/urls";
import { CTA_BUTTON_TEXT } from "../first-section/texts";
import { CTA_TITLE, PARAGRAPH, TITLE } from "./texts";
import { CARD_LIST } from "./components/card-list/consts";

import "./index.scss";

export default function ThirdSection() {
  const CTARef = useRef(null);
  return (
    <GridSection className="additional-mort-third-section">
      <TextSection title={TITLE} text={PARAGRAPH} />
      <CardList list={CARD_LIST} />
      <ImageContainer src={IMAGE_URL} />
      <Container className="animation-cta-container">
        <CTAAnimationClick
          analytics={ANALYTICS.THIRD_SECTION.CTA}
          reference={CTARef}
          text={CTA_BUTTON_TEXT}
          CTALink={URLS.MortgageQue}
          title={CTA_TITLE}
        />
      </Container>
    </GridSection>
  );
}
