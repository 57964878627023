import { createSlice } from "@reduxjs/toolkit";
import { FEATURE_FLAGS } from "../consts";

const initialState = {
  flags: FEATURE_FLAGS
};

export const flagsSlice = createSlice({
  name: "flags",
  initialState,
  reducers: {
    setFlags: (state, action) => {
        state.flags = action.payload;
    },
  },
});

export const { setFlags } = flagsSlice.actions;

export default flagsSlice.reducer
