import { useDispatch, useSelector } from "react-redux";
import { useGetAssetsQuery } from "../../../redux/services/newsletter-protected";
import {
  useGetAreasQuery,
  useGetCitiesQuery,
  useGetNeighborhoodsQuery,
} from "../../../redux/services/newsletter";
import {
  setAreas,
  setAssets,
  setCities,
  setNeighborhoods,
} from "../../../redux/features/newsletterSlice";
import { useEffect } from "react";

export default function useRentUI() {
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const closestCity = useSelector((state) => state.newsletter.closestCity);
  const filter = useSelector((state) => state.newsletter.filter);

  const {
    data: fetchedAssets,
    isFetching: isLoadingAssets,
    error: getAssetsError,
  } = useGetAssetsQuery(
    {
      cityCode: closestCity?.id,
      startMonth: filter.date.from,
      endMonth: filter.date.to ? filter.date.to : filter.date.from + 1,
      year: filter.date.availableYears[filter.date.currentYearIndex],
    },
    { skip: !closestCity || filter.isOpen || !isLoggedIn }
  );

  const {
    data: fetchedCities,
    isLoading: isLoadingCities,
    error: getCitiesError,
  } = useGetCitiesQuery();

  const {
    data: fetchedAreas,
    isLoading: isLoadingAreas,
    error: getAreasError,
  } = useGetAreasQuery();

  const {
    data: fetchedNeighborhoods,
    isLoading: isLoadingNeighborhoods,
    error: getNeighborhoodsError,
  } = useGetNeighborhoodsQuery();

  useEffect(() => {
    if (fetchedAssets) {
      dispatch(
        setAssets({
          assets: fetchedAssets.data,
          cityCode: closestCity.id,
        })
      );
    }
  }, [fetchedAssets]);

  useEffect(() => {
    if (fetchedCities) {
      dispatch(setCities(fetchedCities.data));
    }
  }, [fetchedCities]);

  useEffect(() => {
    if (fetchedAreas) {
      dispatch(setAreas(fetchedAreas.data));
    }
  }, [fetchedAreas]);

  useEffect(() => {
    if (fetchedNeighborhoods) {
      dispatch(setNeighborhoods(fetchedNeighborhoods.data));
    }
  }, [fetchedNeighborhoods]);

  return {
    isLoadingAreas,
    isLoadingCities,
    isLoadingAssets,
    isLoadingNeighborhoods,
  };
}
