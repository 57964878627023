import React, { useRef } from "react";
import useIsInViewport from "../../../../../hooks/useIsInViewport";
import useIsMobile from "../../../../../hooks/useIsMobile";
import { rows, additionalMortContent, premiumMortContent } from "./consts";

import "./index.scss";
import {Logo} from "../../../../../components/logo";

const Rows = ({ reference }) => {
    return (
        <div
            ref={reference}
            className={`rows-container ${
                useIsInViewport(reference) ? "in-view" : ""
            }`}
        >
            {rows.map((item, index) => {
                return (
                    <div key={index} className="single-row-item">
                        <div className="image-container">
                            <img src={item.image} alt="" />
                        </div>
                        <h4>{item.title}</h4>
                    </div>
                );
            })}
        </div>
    );
};

const SingleItem = ({ data }) => {
    return (
        <div className="single-item">
            <p>{data}</p>
        </div>
    );
};

const SingleColumn = ({ data, reference }) => {
    return (
        <div
            ref={reference}
            className={`single-column ${
                useIsInViewport(reference) ? "in-view" : ""
            }`}
        >
            <div className="image-container">
                <img src={data.image} alt="" />
            </div>
            <div className="title-container">
                <h3>{data.title}</h3>
                <h4>{data.subTitle}</h4>
            </div>
            <div className="content-container">
                {data.content.map((item, index) => {
                    return <SingleItem key={index} data={item} />;
                })}
            </div>
        </div>
    );
};

const TheWeCheckMortgage = () => {
    const isMobile = useIsMobile();
    const titleRef = useRef(null);
    const firstColumnRef = useRef(null);
    const secondColumnRef = useRef(null);
    const firstRowRef = useRef(null);
    const secondRowRef = useRef(null);

    return (
        <section className="TheWeCheckMortgage">
            <div
                ref={titleRef}
                className={`title-container ${
                    useIsInViewport(titleRef) ? "in-view" : ""
                }`}
            >
                <h2>
                    המשכנתא של{" "}
                    <Logo color="blue"/>
                </h2>
                <h3>ניתנת בתנאים הכי נוחים!</h3>
            </div>
            <div className="content-container">
                <div className="rows-container-owner">
                    <Rows reference={firstRowRef} />
                    {isMobile && <Rows reference={secondRowRef} />}
                </div>
                <div className="columns-container">
                    <SingleColumn
                        reference={firstColumnRef}
                        data={additionalMortContent}
                    />
                    <SingleColumn
                        reference={secondColumnRef}
                        data={premiumMortContent}
                    />
                </div>
            </div>
        </section>
    );
};

export { TheWeCheckMortgage };
