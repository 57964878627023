import { ImgAndTextStrip } from "./imgAndTextStrip";

import { VideoStrip } from "./videoStrip";

import "./index.scss";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getQueryParamsFromCookie } from "../../../../../hooks/useCampaignParams";

const HowTo = () => (
  <>
    <h2>איך עושים את זה?</h2>
    <ImgAndTextStrip />
  </>
);

const QueBuiltFrom = ({ isYad2 }) => (
  <>
    <h2>ממה מורכב השאלון?</h2>
    <VideoStrip isYad2={isYad2} />
  </>
);

const HowToSection = () => {
  const [isYad2, setIsYad2] = useState(false);

  const location = useLocation();

  const yad2Condition = (params) =>
    params?.utm_source === "yad2" && params?.utm_campaign === "propertybutton";

  useEffect(() => {
    const params = getQueryParamsFromCookie();
    if (params && yad2Condition(params)) {
      setIsYad2(true);
    }
  }, [location]);

  return (
    <div className="HowToSection">
      <div className="container">
        <HowTo />
        <QueBuiltFrom isYad2={isYad2} />
      </div>
    </div>
  );
};

export { HowToSection };
