import useIsMobile from "../../../../../../hooks/useIsMobile";
import Utils       from "../../../../../../shared/utils";

import "./index.scss";

const ImgAndTextSquareData = [
	{
		img     : Utils.buildTenantPageImgLink("note.svg"),
		boldText: "ממלאים את הפרטים",
		text    : "שלכם ושל בעל הדירה ממש כאן באתר"
	},
	{
		img     : Utils.buildTenantPageImgLink("bubble.svg"),
		boldText: "מילוי שאלון דיגיטלי",
		text    : "לאחר שתמלאו את הפרטים תקבלו שאלון קצר ב SMS"
	},
	{
		img     : Utils.buildTenantPageImgLink("fullnote.svg"),
		boldText: "המערכת מחשבת את הנתונים",
		text    : "הנתונים שמסרתם עוברים דרך האלגוריתם לניהול סיכונים שלנו"
	},
	{
		img     : Utils.buildTenantPageImgLink("man.svg"),
		boldText: "איזה כיף הדוח מוכן",
		text    : "דוח הדאבל צ'ק נשלח אליכם ולבעלי הדירות לנייד."
	},
	{
		img     : Utils.buildTenantPageImgLink("umbrella.svg"),
		boldText: "שכר הדירה בטוח!",
		text    : "במידה ורכשתם צ׳ק בטוח שכר הדירה שלכם מוגן",
		noArrow : true
	}
];

const ImgAndTextStrip = () => {
	const isMobile = useIsMobile();

	return <div className = "ImgAndTextStrip" >
		{ImgAndTextSquareData.map((data, index) => <>
				<div className = "imgAndTextBox" >
					<img src = {data.img} alt = "" />
					<p className = "caption" ><strong >{data.boldText}</strong ></p >
					<p >{data.text}</p >
				</div >
				{!data.noArrow &&
					<img className = "leftArrow" src = {Utils.buildImgLink(isMobile ? "downArrow.svg" : "leftArrow.svg")}
					     alt = "" />}
			</>
		)}
	</div >;
};

export {ImgAndTextStrip};