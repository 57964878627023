import { URLS } from "../../../../../shared/urls";
import Utils from "../../../../../shared/utils";

const PRODUCTS_SECTION_IMAGES = `products-section/`;

export const READ_MORE_CTA = `קראו עוד`;

export const TITLE = `המוצרים שלנו`;

export const CARDS = {
  DOUBLE_CHECK: {
    ICON: Utils.buildImgLink(`${PRODUCTS_SECTION_IMAGES}double-check-icon.svg`),
    TITLE: "דאבל צ'ק",
    SECONDARY_TITLE: "בדיקת רקע לדיירים - בחינם",
    PARAGRAPH: `שירות הדאבל צ׳ק המהפכני שלנו שלנו מהווה גשר בין בעל בית לדייר הפוטנציאלי. השירות מאפשר לדייר לספק לבעל הדירה את כל המידע הרלוונטי ללא צורך בטפסים ובירוקרטיות.\n\nלאחר שהדייר ממלא את השאלון אשר נשלח אליו לנייד, על בסיס טכנולוגיות מתקדמות של בינה מלאכותית, האלגוריתם שלנו מעבד את כלל הנתונים עד להפקת הדוח.`,
    PART_TO_REPLACE: `ללא צורך בטפסים ובירוקרטיות`,
    LINK: URLS.Doublecheck,
  },
  SAFE_CHEQUE: {
    ICON: Utils.buildImgLink(`${PRODUCTS_SECTION_IMAGES}safe-cheque-icon.svg`),
    TITLE: "צ׳ק בטוח",
    SECONDARY_TITLE: `התשלום חזר? תרגעו, שכר הדירה שלכם בטוח`,
    PARAGRAPH: `חדש! מהיום כבר לא צריך להתעסק יותר עם צ’קים. ערבות WeCheck היא הערבות היחידה שמגנה על כל שכר הדירה בכל דרכי התשלום, גם אם התשלומים חזרו. הכסף יכנס אליכם לחשבון, ואנחנו נטפל בכל מה שצריך.\n\nוויצ׳ק מבטיחה שתקבלו את הכסף שמגיע לכם ולעולם לא תבקש ממכם להסב אליה את הבטחונות שקיבלתם מהדייר.`,
    PART_TO_REPLACE: `גם אם התשלומים חזרו.`,
    LINK: URLS.Safecheck,
  },
  ALL_IN_ADVANCE: {
    ICON: Utils.buildImgLink(
      `${PRODUCTS_SECTION_IMAGES}all-in-advance-icon.svg`
    ),
    TITLE: "הכל מראש",
    SECONDARY_TITLE: `שכר דירה מראש לשנה שלמה`,
    PARAGRAPH: `עם שירות 'הכל מראש' תוכלו לקבל את כל הכסף כבר מההתחלה - בלי להמתין. הבטיחו לעצמכם שנה שלמה של שקט, ותנו לנו להתעסק בתשלומי שכר הדירה במקומכם.`,
    PART_TO_REPLACE: `כל הכסף כבר מההתחלה`,
    DISCLAIMER: `רכישת השירות כוללת גם את שירות הערבות צ'ק בטוח, השירות מיועד לבעלי דירות.`,
    LINK: URLS.AllInAdvance,
  },
};

export const RENT_AI_CARD = {
  ICON: Utils.buildImgLink(`${PRODUCTS_SECTION_IMAGES}rent-ai-icon.svg`),
  SPREAD: Utils.buildImgLink(`${PRODUCTS_SECTION_IMAGES}rent-ai-spread.svg`),
  SPREAD_MOBILE: Utils.buildImgLink(
    `${PRODUCTS_SECTION_IMAGES}rent-ai-spread-mobile.svg`
  ),
  TITLE: "RentAI",
  SECONDARY_TITLE: `מרכז המידע המדויק והעדכני ביותר בשוק השכירות בישראל - בחינם!`,
  PARAGRAPH: `בפנים תוכלו למצוא מחירים של עשרות אלפי דירות ב-70 ערים שונות, לפי שכונות, רחובות ואפילו חלוקה לחדרים! אז למה אתם מחכים? השימוש במוצר חינמי לחלוטין.`,
  PART_TO_REPLACE: `השימוש במוצר חינמי לחלוטין.`,
  BUTTON_CTA: `כניסה`,
  VIDEO_CTA: `הסרטון שיסביר הכל`,
  VIDEO_LINK: `_Pq-_J54iwk`,
  LINK: URLS.Newsletter,
};
