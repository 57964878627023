import "./index.scss";

const Slide = ({caption1, caption2, subCaption, children, withShadow}) => <div
	className = {`Slide ${withShadow ? "Slide--withShadow" : ""}`} >
	<h3 >{caption1}</h3 >
	{caption2 && <h3 >{caption2}</h3 >}
	{subCaption && <h4 >{subCaption}</h4 >}
	{children}
</div >;

export {Slide};