import React, { useEffect, useRef, useState } from "react";
import LoginTitle from "../title";
import {
  ADS_CONSENT,
  BUTTON_CONFIRM,
  BUTTON_SEND_CODE,
  CODE_SUB_TITLE,
  FOREIGN_RESIDENT,
  MAIN_TITLE,
  NO_CODE_RECEIVED,
  PHONE_SUB_TITLE,
  PHONE_WITH_ID_SUBTITLE,
  PRIVACY_POLICY,
  WRONG_PHONE,
} from "../../texts";
import { STEPS } from "../../consts";
import PhoneInput from "../phone-input";
import SubmitButton from "../submit-button";
import CodeInput from "../code-input";
import ResendCode from "../resend-code";
import { Validators } from "@wecheck/uisharedutils/validators";
import useFunctionalSection from "./hooks/useFunctionalSection";
import "./index.scss";
import Utils from "../../../shared/utils";
import Checkbox from "../checkbox";
import PolicyModal from "../policy-modal";
import IDNumInput from "../id-num-input";
import useModal from "../../../components/modal/hooks/useModal";
import { MODAL_TYPES } from "../../../components/modal/consts";

export default function FunctionalSection({
  step,
  onSubmit,
  sendPhone,
  phone,
  code,
  isLoading,
  codeError,
  phoneError,
  shouldApproveTerms,
  adsApproved,
  policyConfirmed,
  existingUserData,
  idNum,
  isMortLogin,
}) {
  const {
    setCode,
    setPhone,
    returnToPhoneStep,
    setAdsApproved,
    setPolicyConfirmed,
    setId,
    isValidIsraeliID,
  } = useFunctionalSection();

  const { open: openModal } = useModal();
  const [isResendCodeEnabled, setIsResendCodeEnabled] = useState(false);
  const [countdown, setCountdown] = useState(0); // New state for countdown timer

  useEffect(() => {
    if (step === STEPS.CODE && !isResendCodeEnabled) {
      const timerDuration = 30; // 30 seconds for countdown
      setCountdown(timerDuration);
      const interval = setInterval(() => {
        setCountdown((currentCountdown) => {
          if (currentCountdown <= 1) {
            setIsResendCodeEnabled(true);
            clearInterval(interval); // Clear interval when countdown is over
            return 0;
          }
          return currentCountdown - 1;
        });
      }, 1000);

      return () => clearInterval(interval); // Cleanup interval on component unmount or step change
    }
  }, [step, isResendCodeEnabled]);

  const isPhoneStepValid = isMortLogin
    ? isValidIsraeliID(idNum) && Validators.phone(phone)
    : Validators.phone(phone);
  const policyModalRef = useRef(null);
  const openPolicyModal = () => policyModalRef?.current?.showModal();

  const isCodeValid =
    code?.length === 6 &&
    (shouldApproveTerms || !existingUserData?.policyConfirmed
      ? policyConfirmed
      : true);

  const isValid = step === STEPS.PHONE ? isPhoneStepValid : isCodeValid;

  const subtitle =
    step === STEPS.PHONE
      ? isMortLogin
        ? PHONE_WITH_ID_SUBTITLE
        : PHONE_SUB_TITLE
      : CODE_SUB_TITLE;

  useEffect(() => {
    if (
      step === STEPS.CODE &&
      isCodeValid &&
      !isLoading &&
      existingUserData?.policyConfirmed
    ) {
      onSubmit();
    }
  }, [code]);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === "Enter" && step === STEPS.CODE && isValid) {
        e.preventDefault();
        onSubmit();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [step, isValid, onSubmit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <section className="login-functional-section-container">
      <LoginTitle title={MAIN_TITLE} subtitle={subtitle} />
      <form onSubmit={handleSubmit} className="functional-section-form">
        {step === STEPS.PHONE ? (
          isMortLogin ? (
            <>
              <IDNumInput
                value={idNum}
                placeholder="ת.ז"
                onChange={(e) => setId(e.target.value)}
              />
              <PhoneInput
                error={phoneError}
                shouldFocus={false}
                value={phone}
                placeholder="טלפון"
                onChange={(e) => setPhone(e.target.value, "phone")}
              />
            </>
          ) : (
            <PhoneInput
              error={phoneError}
              value={phone}
              onChange={(e) => setPhone(e.target.value, "phone")}
            />
          )
        ) : (
          step === STEPS.CODE && (
            <CodeInput error={codeError || phoneError} code={code} setCode={setCode} />
          )
        )}
        {step === STEPS.CODE && (
          <div className="code-page-extra-info-container">
            <div className="extra-buttons-container">
              <ResendCode
                text={NO_CODE_RECEIVED}
                countdown={countdown}
                disabled={isLoading || !isResendCodeEnabled}
                onClick={() => {
                  if (!isResendCodeEnabled) return;
                  sendPhone();
                  setCode("");
                  setIsResendCodeEnabled(false);
                  setTimeout(() => {
                    setIsResendCodeEnabled(true);
                    setCountdown(0);
                  }, 1000 * 30);
                }}
              />
              <ResendCode text={WRONG_PHONE} onClick={returnToPhoneStep} />
            </div>
            <div className="checkboxes-container">
              {(shouldApproveTerms || !existingUserData?.adsApproved) &&
                !isLoading && (
                  <Checkbox
                    onClick={() => setAdsApproved(adsApproved)}
                    value={adsApproved}
                    text={ADS_CONSENT}
                  />
                )}
              {(shouldApproveTerms || !existingUserData?.policyConfirmed) &&
                !isLoading && (
                  <Checkbox
                    onClick={() => setPolicyConfirmed(policyConfirmed)}
                    value={policyConfirmed}
                    text={PRIVACY_POLICY}
                    splitString={"לתקנון ולמדיניות הפרטיות"}
                    action={openPolicyModal}
                  />
                )}
            </div>
          </div>
        )}
        <SubmitButton
          onSubmit={handleSubmit}
          isLoading={isLoading}
          disabled={!isValid || isLoading}
          text={step === STEPS.PHONE ? BUTTON_SEND_CODE : BUTTON_CONFIRM}
        />
        {isMortLogin && step === STEPS.PHONE && (
          <button
            type="button"
            onClick={() => openModal({ type: MODAL_TYPES.FOREIGN_RESIDENT })}
            className="foreign-resident-button"
          >
            {FOREIGN_RESIDENT}
          </button>
        )}
      </form>
      <div className="section-image-container">
        <img
          src={Utils.buildLoginImgLink("functional-section-image.svg")}
          alt="section"
        />
      </div>
      <PolicyModal ref={policyModalRef} />
    </section>
  );
}
