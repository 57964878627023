import { MONTH_ENUM_ABBREVIATED } from "./components/filter/components/date-range-picker/consts";
import { MONTH_ENUM } from "./components/search/components/month-select/consts";
import { START_DAY } from "./consts";

export function getCorrectDate(isAdmin) {
  const today = new Date();

  if (isAdmin || today.getDate() >= START_DAY) {
    return today;
  }

  today.setMonth(today.getMonth() - 1);
  return today;
}

export function getDatesForLastYear(isAdmin) {
  const currentDate = getCorrectDate(isAdmin);
  currentDate.setMonth(currentDate.getMonth() - 1);
  const datesArray = [];
  for (let i = 0; i < 12; i++) {
    const date = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() - i
    );
    const month = MONTH_ENUM[date.getMonth()];
    const year = date.getFullYear();
    const abbreviatedYear = year.toString().slice(2);

    const dateString = `${month} ${year}`;
    const currentMonthDateString = `${
      MONTH_ENUM_ABBREVIATED[date.getMonth()]
    } ${abbreviatedYear}`;

    const lastMonthDateString =
      date.getMonth() === 0
        ? `דצמבר ${abbreviatedYear - 1}`
        : `${MONTH_ENUM_ABBREVIATED[date.getMonth() - 1]} ${abbreviatedYear}`;

    // Fix for twoMonthsAgoDateString
    const twoMonthsAgoDateString =
      date.getMonth() === 0
        ? `נוב' ${abbreviatedYear - 1}`
        : date.getMonth() === 1
        ? `דצמ' ${abbreviatedYear - 1}`
        : `${MONTH_ENUM_ABBREVIATED[date.getMonth() - 2]} ${abbreviatedYear}`;

    const lastYearDateString = `${MONTH_ENUM_ABBREVIATED[date.getMonth()]} ${
      year - 1
    }`;

    datesArray.push({
      month: date.getMonth(),
      year,
      dateString,
      currentMonthDateString,
      lastMonthDateString,
      twoMonthsAgoDateString,
      lastYearDateString,
    });
  }
  return datesArray;
}
