import { useDispatch } from "react-redux";
import {
  changeValue,
  init,
  setIdNum,
} from "../../../../../redux/features/loginSlice";

export default function useFunctionalSection() {
  const dispatch = useDispatch();

  const isValidIsraeliID = (inputID) => {
    const id = String(inputID).trim();

    if (id.length > 9 || id.length < 5 || isNaN(id)) {
      return false;
    }

    const paddedID = id.padStart(9, "0");

    const isValid =
      Array.from(paddedID, Number).reduce((sum, digit, index) => {
        const factor = (index % 2) + 1; // Factor is either 1 or 2
        const product = digit * factor;
        const singleDigitProduct = product > 9 ? product - 9 : product;
        return sum + singleDigitProduct;
      }, 0) %
        10 ===
      0;

    return isValid;
  };

  const setPhone = (phone, name) => {
    const numericPhone = phone.replace(/\D/g, "");
    if (numericPhone || numericPhone === "") {
      dispatch(changeValue({ name, value: numericPhone }));
    }
  };

  const setCode = (code) =>
    dispatch(changeValue({ name: "code", value: code }));

  const setAdsApproved = (adsApproved) =>
    dispatch(changeValue({ name: "adsApproved", value: !adsApproved }));

  const setPolicyConfirmed = (policyConfirmed) =>
    dispatch(changeValue({ name: "policyConfirmed", value: !policyConfirmed }));

  const setContactAgreement = (contactAgreement) =>
    dispatch(
      changeValue({ name: "contactAgreement", value: !contactAgreement })
    );

  const setId = (idNum) => dispatch(setIdNum(idNum));

  const returnToPhoneStep = () => dispatch(init());

  return {
    setPhone,
    setCode,
    setAdsApproved,
    setPolicyConfirmed,
    setContactAgreement,
    returnToPhoneStep,
    setId,
    isValidIsraeliID,
  };
}
