import React, { useRef } from "react";
import "./index.scss";
import {
  FILTER_TITLE,
  SEARCH_BUTTON_TEXT,
  BACK_TO_SEARCH_BUTTON,
} from "./texts";
import Container from "../../../components/product-pages/container";
import { useState } from "react";
import { FILTER_IMAGE } from "./consts";
import ImageContainer from "../../../components/product-pages/image-container";
import RoomMultiSelect from "./components/room-multi-select";
import Accordion from "./components/accordion";
import { useDispatch, useSelector } from "react-redux";
import {
  handleFilter,
  initialRooms,
  setDataViewActiveTab,
  setIsChatOpen,
} from "../../../../redux/features/newsletterSlice";
import CloseButton from "./components/close-button";
import InfoButton from "./components/info-button";
import SubmitButton from "./components/submit-button";
import DateRangePicker from "./components/date-range-picker";
import DisplayInfo from "./components/display-info";
import { useEffect } from "react";
import useHandleCLickOutside from "../../../hooks/useHandleClickOutside";
import { getCorrectDate } from "../../utils";
import useIsMobile from "../../../hooks/useIsMobile";
import { DATAVIEW_TABS } from "../../consts";

const Title = ({ isOpen, openFilter, isFiltered }) => {
  return (
    <Container
      onClick={openFilter}
      className={`filter-title-container ${isOpen ? "is-open" : ""} ${
        isFiltered ? "is-filtered" : ""
      }`}
    >
      <ImageContainer className="icon-container" src={FILTER_IMAGE} />
      {isOpen && <h3>{FILTER_TITLE}</h3>}
    </Container>
  );
};

const SearchButton = ({ isOpen, openSearch }) => {
  return (
    <Container
      dataTestId="data-view-open-button"
      onClick={openSearch}
      className={`filter-search-button-container ${isOpen ? "is-open" : ""}`}
    >
      {SEARCH_BUTTON_TEXT}
    </Container>
  );
};

export const BackToSearchByCostButton = () => {
  const dispatch = useDispatch();
  return (
    <div
      onClick={() => {
        dispatch(setDataViewActiveTab(DATAVIEW_TABS.DATA_BY_LOCATION));
      }}
      className="cta-button"
    >
      {BACK_TO_SEARCH_BUTTON}
    </div>
  );
};

export default function Filter() {
  const dispatch = useDispatch();
  const [isFiltered, setIsFiltered] = useState(false);
  const isMobile = useIsMobile(1000);
  const isOpen = useSelector((state) => state.newsletter.filter.isOpen);
  const isInfoOpen = useSelector(
    (state) => state.newsletter.filter.infoDisplay
  );

  const isAdmin = useSelector((state) => state.login.isAdmin);

  const isDataViewVisible = useSelector(
    (state) => state.newsletter.dataView.isVisible
  );
  const isChatOpen = useSelector((state) => state.newsletter.chat.isOpen);

  const dataViewActiveTab = useSelector(
    (state) => state.newsletter.dataView.activeTab
  );

  const filter = useSelector((state) => state.newsletter.filter);

  const filterRef = useRef(null);

  const isPopupOpen = useSelector(
    (state) => state.promotional.currentPopupIndex
  );

  useHandleCLickOutside({
    reference: filterRef,
    callback: () => dispatch(handleFilter(false)),
    disabled: isPopupOpen,
  });

  const openFilter = () => {
    if (isOpen) return;
    if (isChatOpen && isMobile) {
      dispatch(setIsChatOpen(false));
    }
    dispatch(handleFilter(true));
  };

  const openSearch = () => {
    dispatch(setDataViewActiveTab(DATAVIEW_TABS.RENT_COST));
  };

  useEffect(() => {
    if (!isOpen) {
      const roomsCondition =
        filter.rooms && filter.rooms.length === initialRooms.length;
      const datesCondition =
        filter.date.to === null &&
        filter.date.from === getCorrectDate(isAdmin).getMonth() &&
        filter.date.currentYearIndex === 0;
      if (roomsCondition && datesCondition) {
        setIsFiltered(false);
      } else {
        setIsFiltered(true);
      }
    }
  }, [isOpen]);

  return (
    <Container
      reference={filterRef}
      className={`filter-container ${isOpen ? "is-open" : ""}`}
    >
      {isOpen && (
        <div className={`overlay-buttons ${isOpen ? "is-open" : ""}`}>
          <CloseButton />
          <InfoButton />
        </div>
      )}
      {((dataViewActiveTab === DATAVIEW_TABS.DATA_BY_LOCATION &&
        !isDataViewVisible) ||
        isMobile) && (
        <>
          <Title
            isFiltered={isFiltered}
            openFilter={openFilter}
            isInfoOpen={isInfoOpen}
            isOpen={isOpen}
          />
          {!isMobile && !isOpen && (
            <SearchButton isOpen={isOpen} openSearch={openSearch} />
          )}
        </>
      )}
      {dataViewActiveTab === DATAVIEW_TABS.RENT_COST && !isMobile && (
        <Container className="search-button-wrapper">
          <BackToSearchByCostButton />
        </Container>
      )}
      {isInfoOpen && (
        <Accordion isOpen={isInfoOpen}>
          <DisplayInfo />
        </Accordion>
      )}
      {isOpen && !isInfoOpen && (
        <Accordion
          className="filter-accordion-content"
          isOpen={isOpen && !isInfoOpen}
        >
          <DateRangePicker />
          <RoomMultiSelect />
          <SubmitButton />
        </Accordion>
      )}
    </Container>
  );
}
