import PropTypes from "prop-types";
import React     from "react";

import {Link} from "react-router-dom";

import "./css/Button.scss";

class Button extends React.Component {
	static propTypes = {
		type    : PropTypes.string,
		icon    : PropTypes.any,
		arrows  : PropTypes.bool,
		onClick : PropTypes.func,
		disabled: PropTypes.bool,
		link    : PropTypes.string
	};

	static defaultProps = {
		type    : "primary",
		arrows  : false,
		disabled: false
	};

	render() {
		const {type, icon, arrows, link, onClick, disabled, children} =
			      this.props;

		const button = (
			<button
				disabled = {disabled}
				className = {`wecForm__Button--${type}`}
				onClick = {onClick && onClick}
			>
				{icon && (
					<div className = "wecForm__Button__icon" >
						<img src = {icon} alt = "כפתור סגירה" />
					</div >
				)}
				<h6 >{children}</h6 >
				{arrows && <span className = "wecForm__Button__arrows" />}
			</button >
		);

		if (link) {
			return <Link to = {link} >{button}</Link >;
		}
		else {
			return button;
		}
	}
}

export default Button;
