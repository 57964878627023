import Utils from "../../../shared/utils";

export const INTRO_FIRST_PART = {
  TITLE: "ברוכים הבאים ל - WeCheck RentAI",
  SUBTITLE: "מרכז המידע המדויק והעדכני ביותר בשוק השכירות בישראל.",
  TEXT: {
    FIRST_PART: `מה מחכה לכם בפנים?`,
  },
  INFO_LINES: [
    {
      TEXT: "קבלת מחיר שכר הדירה המדוייק ביותר",
      IMAGE: Utils.buildNewsletterLink("icons/destination.svg"),
    },
    {
      TEXT: "מידע עדכני על מגמות בשוק השכירות",
      IMAGE: Utils.buildNewsletterLink("icons/graph.svg"),
    },
    {
      TEXT: "צ׳אט מבוסס AI שיענה על כל השאלות שלכם הקשורות לעולם השכירות",
      IMAGE: Utils.buildNewsletterLink("icons/circle.svg"),
    },
  ],
};

export const INTRO_SECOND_PART = {
  TITLE: "חדש ב RentAI",
  SUBTITLE: "בדיקה מדוייקת של שווי שכר הדירה שלכם",
  TEXT: {
    FIRST_PART: "איך זה עובד?",
    SECOND_PART:
      "תוכלו לבחור בנתונים הספציפיים של הדירה שלכם כמו חניה ממ״ד\nומעלית ולקבל תמונה מדוייקת יותר של שווי שכר הדירה",
  },
};

export const INTRO_THIRD_PART = {
  TITLE: `מה תגלו בפנים?`,
  TEXT: `מחירים של עשרות אלפי דירות ב-70 ערים שונות, לפי שכונות, רחובות ואפילו חלוקה לחדרים! אז למה אתם מחכים? המוצר חינמי לחלוטין.`,
  PART_TO_REPLACE: `המוצר חינמי לחלוטין.`,
};

export const BUTTON_TEXT = `המשך`;
export const ALTERED_BUTTON_TEXT = `כניסה`;
