import React from "react";
import "./index.scss";
import ContactButton from "../../../../../../components/modal/components/functional-section/components/contact-button";
import { CONTACT_TYPES } from "../../../../../../components/modal/components/functional-section/components/contact-section/consts";

export default function ContactButtons({ className = "" }) {
  return (
    <div className={`contact-buttons-container ${className}`}>
      <ContactButton
        type={CONTACT_TYPES.WHATSAPP}
        alt
        className="personal-area-contact"
      />
      <ContactButton
        type={CONTACT_TYPES.PHONE}
        alt
        className="personal-area-contact"
      />
    </div>
  );
}
