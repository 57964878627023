import React from "react";

import GridSection from "../../../../../components/product-pages/grid-section";
import YoutubeVideo from "../../../../../components/product-pages/youtube-video";
import BottomSection from "./components/bottom-section";
import ItemList from "../../../../../components/product-pages/item-list";

import { ITEM_LIST, ITEM_LIST_TITLE, VIDEO_URL } from "./consts";

import "./index.scss";
import { ANALYTICS } from "../../consts";

export default function SecondSection() {
    return <GridSection className={"second-section-container"}>
        <ItemList list={ITEM_LIST} title={ITEM_LIST_TITLE}/>
        <YoutubeVideo analytics={ANALYTICS.SECOND_SECTION.VIDEO} videoUrl={VIDEO_URL}/>
        <BottomSection/>
    </GridSection>
}