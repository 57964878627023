export const OUTBRAIN_PIXEL = <script data-obct type="text/javascript">
    {
        `!function (_window, _document) {
            var OB_ADV_ID = '00c7783451763fcbefd0807dca747f736a';
            if (_window.obApi) {
                var toArray = function (object) {
                    return Object.prototype.toString.call(object) === '[object Array]' ? object : [object];
                };
                _window.obApi.marketerId = toArray(_window.obApi.marketerId).concat(toArray(OB_ADV_ID));
                return;
            }
            var api = _window.obApi = function () {
                api.dispatch ? api.dispatch.apply(api, arguments) : api.queue.push(arguments);
            };
            api.version = '1.1';
            api.loaded = true;
            api.marketerId = OB_ADV_ID;
            api.queue = [];
            var tag = _document.createElement('script');
            tag.async = true;
            tag.src = '//amplify.outbrain.com/cp/obtp.js';
            tag.type = 'text/javascript';
            var script = _document.getElementsByTagName('script')[0];
            script.parentNode.insertBefore(tag, script);
        }(window, document);
        
        obApi('track', 'PAGE_VIEW');`
    }
</script>

export const TABOOLA_PIXEL = <script data-obct type='text/javascript'>
   {`window._tfa = window._tfa || [];
    window._tfa.push({notify: 'event', name: 'page_view', id: 1525999});
    !function (t, f, a, x) {
       if (!document.getElementById(x)) {
        t.async = 1;t.src = a;t.id=x;f.parentNode.insertBefore(t, f);
       }
}(document.createElement('script'),
    document.getElementsByTagName('script')[0],
    '//cdn.taboola.com/libtrc/unip/1525999/tfa.js',
    'tb_tfa_script');`}
</script>
