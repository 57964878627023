import React, { useEffect, useRef } from "react";
import { getAuthErrorMessage } from "../../consts";
import "./index.scss";

export default function PhoneInput({ error, onChange, value, placeholder = "", shouldFocus = true }) {
  const inputRef = useRef(null);

  useEffect(() => {
    shouldFocus && inputRef.current.focus();
  }, []);

  return (
    <>
      <label>
        <span className="sr-only">Phone Number:</span>
        <input
          ref={inputRef}
          className="phone-input"
          placeholder={placeholder}
          type="tel"
          value={value || ''}
          onChange={onChange}
          aria-label="Phone Number"
          pattern="[0-9]*"
          inputMode="numeric"
          autoComplete="tel"
        />
      </label>
      {error && <div className="error-message">{getAuthErrorMessage(error?.data?.error?.statusCode)}</div>}
    </>
  );
}
