import React from "react";
import "./index.scss";
import useReplaceWithSpan from "../../../../hooks/useReplaceWithSpan";
import { INTRO_THIRD_PART } from "../../texts";
import AnimationContainer from "../../../../../components/product-pages/animation-container";
import { THIRD_PART_ANIMATION } from "../../consts";
import Paragraph from "../../../../../components/product-pages/paragraph";

export default function IntroThirdPart() {
    const alteredText = useReplaceWithSpan(INTRO_THIRD_PART.TEXT, INTRO_THIRD_PART.PART_TO_REPLACE);

  return <div className="intro-part third-part-container">
    <AnimationContainer className="third-part-animation" img={THIRD_PART_ANIMATION}/>
    <Paragraph text={alteredText} className="third-part-text"/>
  </div>;
}
