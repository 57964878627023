import Utils from "../../../../../shared/utils";

export const labelListProps = {
  fontSize: 14,
  fontWeight: 700,
  fill: "#1D3557",
  dataKey: "rent",
  position: "top",
  offset: 10,
};

export const lineProps = {
  type: "monotone",
  dataKey: "rent",
  stroke: "#1799FC",
  dot: {
    fill: "#1799FC",
    strokeWidth: 4,
  },
  fill: "#1799FC",
  strokeWidth: 2,
};

export const xAxisProps = {
  tick: {
    fontSize: "14px",
    fontWeight: "bold",
    fill: "#1D3557",
  },
  tickLine: false,
  dataKey: "name",
  strokeWidth: 2,
  stroke: "#1D3557",
  tickMargin: 12,
};

export const ERROR_DISPLAY = Utils.buildNewsletterLink('errors/graph.svg');