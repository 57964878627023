import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useJsApiLoader } from "@react-google-maps/api";
import { useDispatch } from "react-redux";
import {
  closeDataView,
  selectArea,
  selectCity,
  selectNeighborhood,
  setDataView,
  setDataViewActiveTab,
  setMapOptions,
} from "../../../../../redux/features/newsletterSlice";
import { setClosestCity } from "../../../../../redux/features/newsletterSlice";
import {
  AreaMarker,
  AssetMarker,
  CityMarker,
  NeighborhoodMarker,
} from "../components/marker";
import { ASSETS_FILTER_FACTOR, ZOOM_DISPLAY, options } from "../consts";
import debounce from "lodash/debounce";
import { haversineDistance } from "../utils";
import { CARD_CITIES } from "../../location-cards/consts";
import { DATAVIEW_TABS } from "../../../consts";
import useIsMobile from "../../../../hooks/useIsMobile";

export default function useMap({
  cities,
  areas,
  neighborhoods,
  assets,
  filter,
  selectedCity,
  selectedArea,
  selectedNeighborhood,
  mapOptions,
  closestCity,
  isLoggedIn,
  locationCardsData,
}) {
  const { isLoaded } = useJsApiLoader(options);
  const dispatch = useDispatch();

  const [map, setMap] = useState(null);
  const [currentFilter, setCurrentFilter] = useState(filter);
  const [currentSelectedCity, setCurrentSelectedCity] = useState(selectedCity);
  const [currentSelectedArea, setCurrentSelectedArea] = useState(selectedArea);
  const [currentSelectedNeighborhood, setCurrentSelectedNeighborhood] =
    useState(selectedNeighborhood);
  const [tooltipAsset, setTooltipAsset] = useState(null);
  const isMobile = useIsMobile();

  const onLoad = useCallback(
    (mapInstance) => {
      setMap(mapInstance);

      const zoomAndBoundsChangeHandler = debounce(() => {
        const zoom = mapInstance.getZoom();
        const bounds = mapInstance.getBounds();

        dispatch(
          setMapOptions({
            ...mapOptions,
            zoom,
            bounds,
          })
        );
      }, 200);

      mapInstance.addListener("zoom_changed", zoomAndBoundsChangeHandler);
      mapInstance.addListener("bounds_changed", zoomAndBoundsChangeHandler);

      return () => {
        window.google.maps.event.removeListener(zoomAndBoundsChangeHandler);
      };
    },
    [dispatch, mapOptions]
  );

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const setCenterAndZoom = useCallback(
    (location, zoomLevel) => {
      if (map && location) {
        const center = new window.google.maps.LatLng({
          lat: parseFloat(location.lat),
          lng: parseFloat(location.lng),
        });
        map.setZoom(zoomLevel);
        map.setCenter(center);
      }
    },
    [map]
  );

  useEffect(() => {
    if (!isLoggedIn) return;
    if (selectedArea !== currentSelectedArea) {
      setCurrentSelectedArea(selectedArea);
      setCenterAndZoom(selectedArea, ZOOM_DISPLAY.AREAS.FOCUS);
    } else if (selectedNeighborhood !== currentSelectedNeighborhood) {
      setCurrentSelectedNeighborhood(selectedNeighborhood);
      setCenterAndZoom(selectedNeighborhood, ZOOM_DISPLAY.NEIGHBORHOODS.FOCUS);
    } else if (selectedCity !== currentSelectedCity) {
      if (!selectedCity?.id) return;
      const relatedAreas = areas.find(
        (area) => area.city_id === selectedCity.id
      );
      setCurrentSelectedCity(selectedCity);
      setCenterAndZoom(
        selectedCity,
        relatedAreas
          ? ZOOM_DISPLAY.CITIES.FOCUS
          : ZOOM_DISPLAY.CITIES.FOCUS_NO_AREAS
      );
    }
  }, [selectedCity, selectedArea, selectedNeighborhood, isLoggedIn]);

  useEffect(() => {
    setCurrentFilter(filter);
  }, [filter]);

  useEffect(() => {
    if (map) {
      calculateClosestCity();
    }
  }, [mapOptions.bounds]);

  useEffect(() => {
    setTooltipAsset(null);
  }, [currentFilter.rooms, currentFilter.date]);

  useEffect(() => {
    if (tooltipAsset && mapOptions.zoom < ZOOM_DISPLAY.ASSETS.VISIBLE) {
      setTooltipAsset(null);
    }
  }, [mapOptions.zoom, tooltipAsset]);

  const calculateClosestCity = useCallback(
    debounce(() => {
      if (mapOptions.zoom >= ZOOM_DISPLAY.AREAS.VISIBLE) {
        const center = map.getCenter();
        let currentClosestNeighborhood = null;
        let closestDistance = null;
        let currentClosestCity = null;

        for (let i = 0; i < neighborhoods.length; i++) {
          const distance = haversineDistance(
            center.lat(),
            center.lng(),
            parseFloat(neighborhoods[i].lat),
            parseFloat(neighborhoods[i].lng)
          );

          if (closestDistance === null || distance < closestDistance) {
            currentClosestNeighborhood = neighborhoods[i];
            closestDistance = distance;
          }
        }

        if (currentClosestNeighborhood) {
          const currentCityId = currentClosestNeighborhood.city_id;
          currentClosestCity = cities.find((city) => city.id === currentCityId);
        }

        if (
          currentClosestCity &&
          (!closestCity || currentClosestCity?.id !== closestCity.id)
        ) {
          dispatch(setClosestCity(currentClosestCity));
        }
      }
    }, 300),
    [mapOptions.bounds, neighborhoods, map]
  );

  const handleMarkerClick = useCallback(
    (location, zoom, asset = null) => {
      if (!isLoggedIn) {
        if (location.id === CARD_CITIES.TEL_AVIV.CODE) {
          dispatch(
            setDataView({
              ...location,
              locationData: locationCardsData?.telAviv,
            })
          );
        } else return dispatch(setDataView({ ...location, sendToLogin: true }));
      }

      if (asset) {
        !isMobile && dispatch(closeDataView());
        if (tooltipAsset === asset) {
          setTooltipAsset(null);
        } else {
          setTooltipAsset(asset);
        }
      } else {
        setTooltipAsset(null);
        dispatch(setDataViewActiveTab(DATAVIEW_TABS.RENT_COST));
      }
      setMap((currentMap) => {
        if (currentMap) {
          const center = new window.google.maps.LatLng({
            lat: parseFloat(location.lat),
            lng: parseFloat(location.lng),
          });
          if (location.lat && location.lng) {
            if (mapOptions.zoom < ZOOM_DISPLAY.ASSETS.FOCUS) {
              currentMap.setZoom(zoom);
              currentMap.setCenter(center);
            } else if (
              !asset &&
              mapOptions.zoom >= ZOOM_DISPLAY.ASSETS.VISIBLE
            ) {
              currentMap.setCenter(center);
            }
          }
          if (!asset) {
            if (location.city_code) {
              dispatch(selectCity(location.id));
            } else if (location.display_name) {
              dispatch(selectNeighborhood(location.id));
            } else {
              dispatch(selectArea(location.id));
            }
          }
        }
        return currentMap;
      });
    },
    [tooltipAsset, isLoggedIn, mapOptions.zoom, locationCardsData]
  );

  const renderCityMarkers = useMemo(() => {
    return cities.map((city) => {
      const connectedAreas = areas.find((area) => area.city_id === city.id);
      return (
        <CityMarker
          key={city.id}
          city={city}
          areas={connectedAreas}
          handleMarkerClick={handleMarkerClick}
          currentZoom={mapOptions.zoom}
        />
      );
    });
  }, [cities, areas, handleMarkerClick, mapOptions.zoom]);

  const renderAreaMarkers = useMemo(() => {
    return areas.flatMap((area) => {
      const city = cities.find((city) => city.id === area.city_id);

      const isVisible =
        map &&
        mapOptions.zoom >= ZOOM_DISPLAY.AREAS.VISIBLE &&
        mapOptions.bounds?.contains(
          new window.google.maps.LatLng({
            lat: parseFloat(area.lat),
            lng: parseFloat(area.lng),
          })
        );

      return (
        <AreaMarker
          key={area.id}
          area={area}
          city={city}
          handleMarkerClick={handleMarkerClick}
          visible={isVisible}
        />
      );
    });
  }, [areas, cities, handleMarkerClick, map, mapOptions]);

  const renderNeighborhoodMarkers = useMemo(() => {
    return neighborhoods.flatMap((neighborhood) => {
      const city = cities.find((city) => city.id === neighborhood.city_id);

      const isVisible =
        map &&
        mapOptions.zoom >= ZOOM_DISPLAY.NEIGHBORHOODS.VISIBLE &&
        mapOptions.bounds?.contains(
          new window.google.maps.LatLng({
            lat: parseFloat(neighborhood.lat),
            lng: parseFloat(neighborhood.lng),
          })
        );

      return (
        <NeighborhoodMarker
          key={neighborhood.id}
          neighborhood={neighborhood}
          city={city}
          handleMarkerClick={handleMarkerClick}
          visible={isVisible}
        />
      );
    });
  }, [neighborhoods, cities, handleMarkerClick, map, mapOptions]);

  const assetsFlat = useMemo(() => {
    const shouldFilter = mapOptions.zoom < ZOOM_DISPLAY.NEIGHBORHOODS.VISIBLE;

    return Object.values(assets)
      .map((assetArray) => {
        return shouldFilter
          ? assetArray.filter(
              (asset, index) => index < assetArray.length * ASSETS_FILTER_FACTOR
            )
          : assetArray;
      })
      .flat();
  }, [assets, mapOptions.zoom]);

  const filteredAssets = useMemo(
    () =>
      assetsFlat.filter((asset) =>
        currentFilter.rooms.includes(asset?.room_numbers)
      ),
    [assetsFlat, currentFilter.rooms]
  );

  const renderAssetMarkers = useMemo(() => {
    return filteredAssets.map((asset, index) => {
      const isInBounds =
        map &&
        mapOptions.zoom >= ZOOM_DISPLAY.ASSETS.VISIBLE &&
        mapOptions.bounds?.contains(
          new window.google.maps.LatLng({
            lat: parseFloat(asset.lat),
            lng: parseFloat(asset.lng),
          })
        );
      return (
        <AssetMarker
          visible={isInBounds}
          key={asset.id}
          asset={asset}
          currentZoom={mapOptions.zoom}
          handleMarkerClick={handleMarkerClick}
          tooltipAsset={tooltipAsset}
          setTooltipAsset={setTooltipAsset}
        />
      );
    });
  }, [filteredAssets, handleMarkerClick, tooltipAsset, map, mapOptions]);

  return {
    isLoaded,
    mapOptions,
    renderCityMarkers,
    renderAreaMarkers,
    renderNeighborhoodMarkers,
    renderAssetMarkers,
    onLoad,
    onUnmount,
  };
}
