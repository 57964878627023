import React from "react";
import { useDispatch, useSelector } from "react-redux";

import YearMonthDisplay from "./year-month-display";

import { CHEVRON_BOX } from "./consts";

import "./index.scss";
import { setCurrentDateIndex } from "../../../../../../redux/features/newsletterSlice";

export default function MonthSelect() {
  const dispatch = useDispatch();

  const datesArray = useSelector((state) => state.newsletter.datesArray);
  const currentDateIndex = useSelector(
    (state) => state.newsletter.currentDateIndex
  );
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);

  const handleClick = (direction) => {
    if (direction === "left") {
      dispatch(setCurrentDateIndex(currentDateIndex + 1));
    } else {
      dispatch(setCurrentDateIndex(currentDateIndex - 1));
    }
  };

  return (
    <div className={`month-select-container ${isLoggedIn ? "active" : ""}`}>
      <button
        disabled={currentDateIndex === 0 || !isLoggedIn}
        onClick={() => handleClick("right")}
        className="arrow-right"
      >
        <img src={CHEVRON_BOX} alt="Right Arrow" />
      </button>
      <YearMonthDisplay />
      <button
        disabled={currentDateIndex === datesArray.length - 1 || !isLoggedIn}
        onClick={() => handleClick("left")}
        className="arrow-left"
      >
        <img src={CHEVRON_BOX} alt="Left Arrow" />
      </button>
    </div>
  );
}
