import React, { useRef } from "react";
import PersonalDetailsHeader from "./components/header";
import Details from "./components/details";
import { useDispatch, useSelector } from "react-redux";
import "./index.scss";
import DrawerFooter from "./components/footer";
import useHandleCLickOutside from "../../../../hooks/useHandleClickOutside";
import { handleDrawer } from "../../../../../redux/features/userSlice";

export default function PersonalDetailsDrawer() {
  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state.user.isPersonalAreaDrawerOpen);
  const drawerContentRef = useRef(null);
  useHandleCLickOutside({
    reference: drawerContentRef,
    callback: () => dispatch(handleDrawer(false)),
  });
  return (
    <div
      className={`personal-details-drawer-container ${isOpen ? "is-open" : ""}`}
    >
      <div ref={drawerContentRef} className="personal-details-drawer-content">
        <PersonalDetailsHeader />
        <Details />
        <DrawerFooter />
      </div>
    </div>
  );
}
