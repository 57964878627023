export const TITLE = `למי ההצעה הזו רלוונטית?`;
export const SUBTITLE = `הצעה זו רלוונטית לבעלי נכסים אשר זקוקים למימון נוסף עד לגובה של - 85% מימון משווי הנכס. את המסלול ניתן לקבל לצורך מגוון מטרות כגון חילוץ הון עצמי מנכס קיים, השלמת הון לרכישת נכס, שיפוץ וריהוט הנכס, איחוד הלוואות קיימות בהלוואת משכנתא אחת ולצורך מגוון מטרות נוספות. חשוב לציין, הסכום המינימלי לקבלת ההצעה הינו 250,000 ש”ח.`;

export const PARAGRAPHS = [
  {
    title: `דוגמא לקבלת משכנתא מסלול מופחת למטרת איחוד הלוואות`,
    text: `לקוחות רבים אשר משלמים את תשלומי המשכנתא השוטפים ובנוסף משלמים בכל חודש סכומים לא מבוטלים עבור הלוואות למגוון מטרות שנלקחו לטווחים קצרים, מגיעים להחזר חודשי גבוה.\n\nאנו מאפשרים לאחד את כל ההלוואת הקיימות(למעט המשכנתא) להלוואה אחת ולפרוס את תקופת ההלוואה ל-30 שנה ובנוסף במסלול חדש, להפחית את ההחזר בצורה מהותית.\nכך שאיתנו תוכלו להפחית את ההחזר החודשי פעמיים; גם באמצעות פריסת הלוואות ל- 30 שנה וגם באמצעות המסלול למשכנתא בתשלום מופחת.\nבסופו של דבר, ההחזר החודשי נמוך יותר, התזרים משתפר, השקט הנפשי עולה והכי חשוב הופך את החיים להרבה יותר קלים.`,
  },
  {
    title: `דוגמא מספרית עם מסלול התשלום המופחת של WeCheck`,
    text: `במשכנתא במסלול תשלום חודשי מופחת מסכום של 250,000 ש”ח תוכלו להנות מכ- 1,000 ש”ח הפחתה בהחזר החודשי. שימו לב, בסכומי משכנתא גבוהים יותר ניתן ליהנות מהפחתה גבוהה יותר ועד ל 3,000 ש”ח. בנוסף, המסלול נותן הטבה משמעותית של כ 35% בהחזר החודשי למשך השנתיים הראשונות. לאחר שנתיים, הלקוח יחזור לשלם בתשלום שפיצר רגיל ליתרת תקופת ההלוואה. כמובן שבמידה וריבית הפריים תרד ההחזר החודשי ירד בהתאמה.`,
  },
];

export const CTA_TITLE = `למה אתם מחכים?`;
export const CTA_BUTTON_TEXT = `לבדיקת זכאות וקבלת הצעה`