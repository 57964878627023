import axios from "axios";
import { useEffect, useRef } from "react";
import config from "../../config/config";
import Cookies from "js-cookie";

const RELEVANT_UTMS = [
  "utm_source",
  "utm_medium",
  "utm_adgroup",
  "utm_creative",
  "utm_content",
  "utm_keyword",
  "utm_campaign",
];

function saveQueryParamsToCookie() {
  const queryParams = new URLSearchParams(window.location.search);
  const referrer = document.referrer;

  let paramsToSend = {};

  RELEVANT_UTMS.forEach((param) => {
    const value = queryParams.get(param);
    if (value !== null) {
      const isEmptyUTMSource = !value && param === "utm_source";
      if (value) paramsToSend[param] = value;
      else if (isEmptyUTMSource) paramsToSend[param] = "EmptySource";
    }
  });

  const existingParams = Cookies.get("PARAMS_TO_SEND")
    ? JSON.parse(Cookies.get("PARAMS_TO_SEND"))
    : null;

  if (existingParams) return;

  if (!paramsToSend["utm_source"] && referrer.includes("www.google.")) {
    paramsToSend["utm_source"] = "GoogleOrganic";
  }

  if (Object.keys(paramsToSend).length) {
    if (
      !existingParams ||
      JSON.stringify(paramsToSend) !== JSON.stringify(existingParams)
    ) {
      Cookies.set("PARAMS_TO_SEND", JSON.stringify(paramsToSend), {
        expires: 7, // Expires in 7 Days
      });
    }
  }
}

export function getQueryParamsFromCookie() {
  const cookieData = Cookies.get("PARAMS_TO_SEND");
  return cookieData ? JSON.parse(cookieData) : null;
}

export async function sendQueryParamsToServer(reportInfoId) {
  const utms = getQueryParamsFromCookie() || {};
  if (!utms["utm_source"]) utms["utm_source"] = "Direct";

  axios.post(
    config.serverlessAddr
      ? `${config.serverlessAddr}/auth/log`
      : `${config.AUTH_LOCAL}/auth/log`,
    {
      data: {
        utms,
        href: window.location.href,
        reportID: reportInfoId,
      },
    }
  );

  const response = await axios.post(
    `${config.serverAddr}/SyncCampaignUTMList/${reportInfoId}`,
    utms
  );
  return response;
}

export default function useCampaignParams() {
  const prevSearch = useRef(window.location.search);

  useEffect(() => {
    saveQueryParamsToCookie();

    const checkForURLChange = (mutationsList, observer) => {
      for (let mutation of mutationsList) {
        if (mutation.type === "childList") {
          const newSearch = window.location.search;
          if (prevSearch.current !== newSearch) {
            saveQueryParamsToCookie();
            prevSearch.current = newSearch;
          }
        }
      }
    };

    const observer = new MutationObserver(checkForURLChange);

    observer.observe(document, { childList: true, subtree: true });

    return () => {
      observer.disconnect();
    };
  }, []);
}
