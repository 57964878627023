import {Validators}          from "@wecheck/uisharedutils/validators";
import {useState, useEffect} from "react";
import Utils                 from "../../../../../../../../shared/utils";
import {GenderBox}           from "../../GenderSelect";
import {NameAndPhone}        from "../../nameAndPhone";
import {Slide}               from "../../slide";

import "./index.scss";

const TenantDetailsSlide = ({currName, currPhone, currGender, done, existingPhone, withShadow}) => {

	const israeliNumberRegex = /^[0][5][0|2|3|4|5|7|9]{1}[-]{0,1}[0-9]{7}$/g;

	const [firstName, setFirstName] = useState(currName);
	const [phone, setPhone]         = useState(currPhone);
	const [gender, setGender]       = useState(currGender);
	const [isValid, setIsValid]     = useState(false);

	const dataChanged = (name, phone) => {
		setFirstName(name);
		setPhone(phone);
	};

	const genderChanged = value => {
		setGender(value);
	};

	const fireAnalytics = fieldName => {

		let eventName = "";
		switch (fieldName) {
			case "name":
				eventName = "פרטי דייר - שם";
				break;

			case "phone":
				eventName = "פרטי דייר - טלפון";
				break;

			case "gender":
				eventName = "פרטי דייר - מגדר";
				break;

			default:
		}

		if (eventName) {
			Utils.gaEvent("עמוד אני דייר", "טופס מילוי דיירים", eventName);
		}
	};

	const validateInput = () => Validators.hebrewOnly(firstName) &&
		israeliNumberRegex.test(phone)
		&& (gender !== undefined)
		&& (phone !== existingPhone);

	useEffect(() => {
		setIsValid(validateInput());
	}, []);

	useEffect(() => {
		setIsValid(validateInput());
	}, [firstName, phone, gender]);

	return <div className = "TenantDetailsSlide" >
		<Slide withShadow = {withShadow} caption1 = "ובמרחק לחיצת כפתור אחת"
		       subCaption = "הזינו את הפרטים שלכם בשביל שנוכל לשלוח את השאלון" >
			<div className = "dataInput" >
				<NameAndPhone namePlaceHolder = "שם פרטי" phonePlaceHolder = "טלפון"
				              currName = {firstName} currPhone = {phone} existingPhone = {existingPhone}
				              fireAnalytics = {fireAnalytics}
				              dataChanged = {dataChanged} />
			</div >
			<GenderBox gender = {gender} onChange = {genderChanged} fireAnalytics = {fireAnalytics} />
			<div className = "buttons" >
				<div className = "buttonDiv" onClick = {() => done(firstName, phone, gender, false)} >
					<a
						className = "btn--transparent" >
						<img src = {Utils.buildTenantPageImgLink("rightArrow.svg")} alt = "" />
					</a >
				</div >
				<div className = "buttonDiv" >
					<a onClick = {() => {
						Utils.gaEvent("עמוד אני דייר", "טופס מילוי דיירים", "שלח הזמנה");
						done(firstName, phone, gender, true);
					}}
					   className = {isValid ? "btn--azure" : "btn--gray"} >
						שלח
					</a >
				</div >
			</div >
		</Slide >
	</div >;
};

export {TenantDetailsSlide};