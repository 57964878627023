import {useEffect, useMemo, useState} from "react";

export default function useIsInViewport(ref, threshold, keepObserving) {
	const [isIntersecting, setIsIntersecting] = useState(false);

	const observer = useMemo(
		() =>
			new IntersectionObserver(
				([entry]) => setIsIntersecting(entry.isIntersecting),
				{threshold: threshold ? threshold : 0}
			),
		[threshold]
	);

	useEffect(() => {
		observer.observe(ref.current);
		return () => {
			observer.disconnect();
		};
	}, [ref, observer]);

	useEffect(() => {
		if (isIntersecting && !keepObserving) {
			observer.unobserve(ref.current);
		}
	}, [isIntersecting]);

	return isIntersecting;
}
