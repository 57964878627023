import Utils from "../../../../../shared/utils";

export const SECOND_SECTION_IMAGE = Utils.buildProductPageImageLink(
  "mort/reduced-mort/second-section-animation.svg"
);

export const ITEM_LIST = [
  {
    icon: Utils.buildProductPageImageLink("icons/rising-graph-icon.svg"),
    text: `כאשר הריבית במשק תרד, הלווה יהנה מירידת הריבית וכתוצאה מכך מירידת ההחזר החודשי`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/loan-icon.svg"),
    text: `החזר חודשי מופחת למשך שנתיים`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/phone-icon.svg"),
    text: `אפשרויות מגוונות לפירעון מוקדם של הלוואת המשכנתא`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/change-location-icon.svg"),
    text: `המסלול אינו צמוד למדד המחירים לצרכן אשר נמצא כיום בעלייה`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/normal-graph-icon.svg"),
    text: `היתרה לסילוק הולכת וקטנה בכל חודש`,
  },
];
