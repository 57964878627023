import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  loans: [],
};

export const loansSlice = createSlice({
  name: "loans",
  initialState,
  reducers: {
    setLoans: (state, action) => {
      state.loans = action.payload;
    },
  },
});

export const { setLoans } = loansSlice.actions;

export default loansSlice.reducer;
