const CTA_CLICK_TRIGGER = `לחיצה על כפתור 'לבדיקת רקע חינם'`

const CTA_CLICK_ACTION = `לבדיקת רקע חינם -`

const EVENT_CATEGORY = `עמוד מוצר - דאבל צ'ק`

export const ANALYTICS = {
    FIRST_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `${CTA_CLICK_ACTION} סקשן פותח`
        }
    },
    SECOND_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `${CTA_CLICK_ACTION} איך זה עובד`
        },
        VIDEO: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: `הפעלת סרטון`,
            ACTION: `הפעלת סרטון`
        }
    },
    THIRD_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `${CTA_CLICK_ACTION} 150 אלף בעלי דירות`
        }
    },
    FOURTH_SECTION: {
        CTA: {
            CATEGORY: EVENT_CATEGORY,
            TRIGGER: CTA_CLICK_TRIGGER,
            ACTION: `${CTA_CLICK_ACTION} יש לכם דיירים פוטנציאלים`

        }
    },
}