import React from "react";

import "./index.scss";

export default function SearchList({
  list,
  handleSelect,
  isListVisible,
  setIsListVisible,
}) {
  return (
    <div className={`search-list-container ${isListVisible ? "is-open" : ""}`}>
      {list.map((item, index) => {
        return (
          <div
            onClick={() => {
              handleSelect(item);
              setIsListVisible(false);
            }}
            key={index}
            className="single-list-item"
          >
            {item.name
              ? item.city_code
                ? item.name
                : `${item.name} - אזור`
              : item.display_name}
          </div>
        );
      })}
    </div>
  );
}
