import React from "react";
import MortgageContact from "../../../components/product-pages/mortgage-contact";
import PageContainer from "../../../components/product-pages/page-container";
import FirstSection from "./components/first-section";
import "./index.scss";
import SecondSection from "./components/second-section";
import ThirdSection from "./components/third-section";
import FourthSection from "./components/fourth-section";
import Disclaimer from "../../../components/product-pages/disclaimer";
import { DISCLAIMER } from "./texts";

export default function ReducedMort() {
  return (
    <PageContainer>
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <Disclaimer text={DISCLAIMER}/>
      <MortgageContact />
    </PageContainer>
  );
}
