import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useIsMobile from "../hooks/useIsMobile";

import Map from "./components/map";
import Search from "./components/search";
import Container from "../components/product-pages/container";

import "./index.scss";
import {
  closeDataView,
  currentDate,
  setDatesArray,
  setIsIntroVisible,
  setDataViewActiveTab,
  setIsChatOpen,
} from "../../redux/features/newsletterSlice";
import DataView from "./components/data-view";
import {
  DATAVIEW_TABS,
  FIRST_BREAKPOINT,
  CTA_TEXT_AFTER_USED_ONE_TIME,
  CTA_TEXT,
} from "./consts";
import Filter from "./components/filter";
import DoubleCheckCTA from "./components/double-check-cta";
import ChatUI from "../chat";

import Spinner from "../new-login/components/spinner";
import useMap from "./components/map/hooks/useMap";
import { getDatesForLastYear } from "./utils";
import useRentUI from "./hooks/useRentUI";
import Intro from "./components/intro";
import LegalDisclaimer from "./components/intro/components/legal-disclaimer";
import PopUp from "./components/pop-up";
import usePromotionalContent from "./hooks/usePromotionalContent";
import useRentUser from "./hooks/useRentUser";
import { pushToGTM } from "../../helpers/gtm";

export default function Newsletter() {
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const dispatch = useDispatch();

  const isMobile = useIsMobile(FIRST_BREAKPOINT);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const cities = useSelector((state) => state.newsletter.cities);
  const areas = useSelector((state) => state.newsletter.areas);
  const neighborhoods = useSelector((state) => state.newsletter.neighborhoods);
  const assets = useSelector((state) => state.newsletter.assets);
  const closestCity = useSelector((state) => state.newsletter.closestCity);

  const mapOptions = useSelector((state) => state.newsletter.mapOptions);
  const filter = useSelector((state) => state.newsletter.filter);
  const selectedCity = useSelector((state) => state.newsletter.selectedCity);
  const selectedArea = useSelector((state) => state.newsletter.selectedArea);
  const selectedNeighborhood = useSelector(
    (state) => state.newsletter.selectedNeighborhood
  );
  const isDataViewVisible = useSelector(
    (state) => state.newsletter.dataView.isVisible
  );
  const dataViewActiveTab = useSelector(
    (state) => state.newsletter.dataView.activeTab
  );

  const intro = useSelector((state) => state.newsletter.intro);
  const searchedByRentCostOnce = useSelector(
    (state) => state.newsletter.dataView.searchedByRentCostOnce
  );
  const locationCardsData = useSelector(
    (state) => state.newsletter.locationCardsData
  );

  const date = useSelector(currentDate);

  const { isLoading, isAdmin } = useRentUser();

  const { isLoadingCities, isLoadingUserDetails } = useRentUI();

  const [isChatInputFocused, setIsChatInputFocused] = useState(false);

  const {
    isLoaded,
    renderCityMarkers,
    renderAreaMarkers,
    renderNeighborhoodMarkers,
    renderAssetMarkers,
    onLoad,
    onUnmount,
  } = useMap({
    isLoggedIn,
    cities,
    areas,
    neighborhoods,
    assets,
    date,
    filter,
    selectedCity,
    selectedArea,
    selectedNeighborhood,
    mapOptions,
    closestCity,
    locationCardsData,
  });

  const mapData = {
    isLoaded,
    renderCityMarkers,
    renderAreaMarkers,
    renderNeighborhoodMarkers,
    renderAssetMarkers,
    onLoad,
    onUnmount,
  };

  // usePromotionalContent();

  const handleChatInputFocus = (isFocused) => {
    setIsChatInputFocused(isFocused);
  };

  useEffect(() => {
    const datesArray = getDatesForLastYear(isAdmin);
    dispatch(setDatesArray(datesArray));
    dispatch(closeDataView());
  }, [isAdmin]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(setIsIntroVisible(false));
    } else {
      dispatch(setIsIntroVisible(true));
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (isMobile && isDataViewVisible) {
      setIsSearchOpen(false);
    }
  }, [isDataViewVisible]);

  useEffect(() => {
    const shouldBackToDataViewAfterLogin = sessionStorage.getItem(
      "backToDataViewAfterLogin"
    );
    if (shouldBackToDataViewAfterLogin && isMobile) {
      dispatch(setDataViewActiveTab(DATAVIEW_TABS.DATA_BY_LOCATION));
      dispatch(setIsChatOpen(false));
      setIsSearchOpen(true);
      sessionStorage.removeItem("backToDataViewAfterLogin");
    }
  }, [dispatch, isMobile]);

  const handleDataByRentCostClick = () => {
    pushToGTM(
      searchedByRentCostOnce
        ? "מנגנון חיפוש - לחיצה על לחישוב נכס נוסף"
        : "מנגנון חיפוש - לחיצה על לחישוב שכר הדירה שלי"
    );

    dispatch(setDataViewActiveTab(DATAVIEW_TABS.DATA_BY_LOCATION));
    dispatch(setIsChatOpen(false));
    setIsSearchOpen(true);
  };

  if (isLoadingUserDetails || isLoadingCities || isLoading) return <Spinner />;

  return (
    <Container
      className={`newsletter-container ${
        isDataViewVisible ? "data-view-visible" : ""
      } ${intro?.isVisible ? "intro-visible" : ""}`}
    >
      {intro?.isVisible && (
        <div className="intro-and-disclaimer-container">
          <Intro />
          <LegalDisclaimer />
        </div>
      )}
      <Map {...mapData} />

      <DoubleCheckCTA setIsSearchOpen={setIsSearchOpen} />
      <div
        className={`search-interface ${
          filter.isOpen && dataViewActiveTab !== DATAVIEW_TABS.RENT_COST
            ? "reverse"
            : ""
        }`}
      >
        <Search
          setIsOpen={setIsSearchOpen}
          isOpen={isSearchOpen}
          setIsSearchOpen={setIsSearchOpen}
        />
        {isLoggedIn && <Filter />}
      </div>
      <DataView />
      <div className="cards-and-chat-container">
        {!isDataViewVisible && !isSearchOpen && (isLoggedIn || !isMobile) && (
          <ChatUI
            isLoggedIn={isLoggedIn}
            handleChatInputFocus={handleChatInputFocus}
          />
        )}
        {isMobile && !isChatInputFocused && (
          <div onClick={handleDataByRentCostClick} className="cta-button" data-testid="calculator-cta-button">
            {searchedByRentCostOnce ? CTA_TEXT_AFTER_USED_ONE_TIME : CTA_TEXT}
          </div>
        )}
      </div>
      <PopUp />
    </Container>
  );
}
