import Utils from "../../../../../../../shared/utils";

export const ITEM_LIST_FIRST_PART = [
  {
    icon: Utils.buildProductPageImageLink("icons/shield-icon.svg"),
    text: `לא צריך להתעסק יותר עם צ'קים, ערבות WeCheck מגנה על כל שכר הדירה בכל דרכי התשלום.`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/moneybag-icon.svg"),
    text: `הכסף ייכנס לחשבונכם תוך 14 ימים ללא מאמץ וטרחה`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/box-icon.svg"),
    text: `פינוי דייר סרבן גם לאחר שחוזה השכירות הסתיים`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/judgement-icon.svg"),
    text: `במידת הצורך, טיפול משפטי מול הדייר כולל פינוי`,
  },
];

export const ITEM_LIST_SECOND_PART = [
  {
    icon: Utils.buildProductPageImageLink("icons/swap-icon.svg"),
    text: `סיוע במציאת דייר חלופי במקרה של עזיבה פתאומית של הדייר בניגוד להסכם השכירות ותשלום שכר הדירה בזמן תהליך מציאת הדייר החדש`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/handshake-icon.svg"),
    text: `ניתן להפסיק את השירות בכל רגע ומכל סיבה שהיא`,
  },
  {
    icon: Utils.buildProductPageImageLink("icons/defense-icon.svg"),
    text: `יתרון לדייר- לא צריך לשעבד סכום כסף בבנק לטובת ערבות בנקאית או צ'ק בנקאי`,
  },
];
