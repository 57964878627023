import React, {useRef}  from "react";
import useIsInViewport  from "../../../../../hooks/useIsInViewport";
import {URLS}           from "../../../../../shared/urls";
import Utils            from "../../../../../shared/utils";
import {WeCheckSection} from "../../../../../UIWck/Section";

import "./index.scss";

const SoWhatIsTheProcess = () => {
	const titleRef         = useRef(null);
	const titleRefMobile   = useRef(null);
	const contentRef       = useRef(null);
	const contentRefMobile = useRef(null);
	const ctaRef           = useRef(null);

	return (
		<WeCheckSection className = "SoWhatIsTheProcess" >
			<img
				ref = {titleRefMobile}
				src = {Utils.buildImgLink("mort/SoWhatIsTheProcess-Mobile-1.svg")}
				alt = "אז מה התהליך?"
				className = {`mobileOnly  ${
					useIsInViewport(titleRefMobile) ? "in-view" : ""
				}`}
			/>
			<a
				onClick = {() =>
					Utils.gaEvent(
						"אתר שיווקי -￼ משכנתאות",
						"לבדיקת זכאות וקבלת הצעה - סקשן תהליך",
						""
					)
				}
				href = {URLS.MortgageQue}
			>
				<img
					ref = {contentRefMobile}
					src = {Utils.buildImgLink(
						"mort/SoWhatIsTheProcess-Mobile-2.svg"
					)}
					alt = "אז מה התהליך?"
					className = {`mobileOnly content-container  ${
						useIsInViewport(contentRefMobile) ? "in-view" : ""
					}`}
				/>
			</a >

			<img
				ref = {titleRef}
				src = {Utils.buildImgLink(
					"mort/SoWhatIsTheProcess-Desktop-1.svg"
				)}
				alt = "אז מה התהליך?"
				className = {`desktopOnly ${
					useIsInViewport(titleRef) ? "in-view" : ""
				}`}
			/>
			<div
				ref = {contentRef}
				className = {`desktopOnly content-container ${
					useIsInViewport(contentRef) ? "in-view" : ""
				}`}
			>
				<div className = "GridBox" >
					<div className = "div1" >
						<img
							src = {Utils.buildImgLink(
								"mort/SoWhatIsTheProcess-Desktop-div1.svg"
							)}
							alt = "אז מה התהליך?"
						/>
					</div >
					<div className = "div2" >
						<div className = "WhiteBox" >
							<div className = "absoluteNumber" >1</div >
							<img
								src = {Utils.buildImgLink(
									"mort/SoWhatIsTheProcess-Desktop-Icon1.svg"
								)}
								alt = "משכנתא משלימה"
								className = "ProcessIcon"
							/>
							<h3 >
								<span >ממלאים את השאלון</span >
								<br />
								<span >ב-5 דקות</span >
							</h3 >
							<p >
								בכדי להכיר אתכם יותר טוב הכנסו שאלון קצר באורך
								של 5 דק שתצטרכו למלא
							</p >
						</div >
					</div >
					<div className = "div3" >
						<div className = "WhiteBox" >
							<div className = "absoluteNumber" >2</div >
							<img
								src = {Utils.buildImgLink(
									"mort/SoWhatIsTheProcess-Desktop-Icon2.svg"
								)}
								alt = "משכנתא משלימה"
								className = "ProcessIcon"
							/>
							<h3 >
								<span >ממתינים לקבלת</span >
								<br />
								<span >התשובה ב-SMS</span >
							</h3 >
							<p >
								לאחר שליחת הבקשה האלגוריתם שלנו בודק את הנתונים
								כדי לשלוח לכם את ההצעה הטובה ביותר בשבילכם
							</p >
						</div >
					</div >
					<div className = "div4" >
						<div className = "WhiteBox" >
							<div className = "absoluteNumber" >3</div >
							<img
								src = {Utils.buildImgLink(
									"mort/SoWhatIsTheProcess-Desktop-Icon3.svg"
								)}
								alt = "משכנתא משלימה"
								className = "ProcessIcon"
							/>
							<h3 >
								<span >אתם בדרך לנכס</span >
								<br />
								<span >שחלמתם עליו</span >
							</h3 >
							<p ></p >
						</div >
					</div >
					<div className = "div5" >
						<img
							src = {Utils.buildImgLink(
								"mort/SoWhatIsTheProcess-Desktop-div5.svg"
							)}
							alt = "אז מה התהליך?"
						/>
					</div >
					<div className = "div6" >
						<img
							src = {Utils.buildImgLink(
								"mort/SoWhatIsTheProcess-Desktop-div6.svg"
							)}
							alt = "אז מה התהליך?"
						/>
					</div >
					<div className = "div7" >
						<img
							src = {Utils.buildImgLink(
								"mort/SoWhatIsTheProcess-Desktop-div7.svg"
							)}
							alt = "אז מה התהליך?"
						/>
					</div >
				</div >
			</div >
			<a
				ref = {ctaRef}
				onClick = {() =>
					Utils.gaEvent(
						"אתר שיווקי -￼ משכנתאות",
						"לבדיקת זכאות וקבלת הצעה - סקשן תהליך",
						""
					)
				}
				href = {URLS.MortgageQue}
				className = {`desktopOnly cta-container ${
					useIsInViewport(ctaRef) ? "in-view" : ""
				}`}
			>
				<img
					src = {Utils.buildImgLink(
						"mort/SoWhatIsTheProcess-Desktop-3.svg"
					)}
					alt = "אז מה התהליך?"
				/>
			</a >
		</WeCheckSection >
	);
};

export {SoWhatIsTheProcess};
