import React from "react";
import "./index.scss";
import { CONTACT_CTA } from "./texts";
import ContactButtons from "../../../loans/components/contact";

export default function ContactSection() {
  return (
    <div className="personal-area-drawer-contact-section-container">
      <h4>{CONTACT_CTA}</h4>
      <ContactButtons className="drawer-contact-section"/>
    </div>
  );
}
