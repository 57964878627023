import React, { useEffect, useState } from "react";

import MapView from "../map-view";
import RentMonthly from "../rent-monthly";

import { RENT_MONTHLY_TITLE, RENT_YEARLY_TITLE } from "./texts";

import "./index.scss";
import RentYearly from "../rent-yearly";

export default function GraphsSection({ currentDate, dataViewData, isMobile }) {
  return (
    <div className="graphs-section-container">
      {isMobile ? (
        <>
          <div className="single-section-container">
            <h3>{RENT_MONTHLY_TITLE}</h3>
            <RentMonthly
              currentMonth={dataViewData?.currentMonth?.averageRent}
              lastMonth={dataViewData?.lastMonth?.averageRent}
              twoMonthsAgo={dataViewData?.twoMonthsAgo?.averageRent}
              currentDate={currentDate}
              error={
                dataViewData?.currentMonth?.error ||
                dataViewData?.lastMonth?.error ||
                dataViewData?.twoMonthsAgo?.error
              }
            />
          </div>
          <div className="single-section-container">
            <h3>{RENT_YEARLY_TITLE}</h3>
            <RentYearly
              currentYear={dataViewData?.currentMonth?.averageRent}
              currentYearDate={currentDate?.dateString}
              lastYear={dataViewData?.lastYear?.averageRent}
              currentDate={currentDate}
              error={
                dataViewData?.currentMonth?.error ||
                dataViewData?.lastYear?.error
              }
            />
          </div>
          <div className="single-section-container">
            <MapView />
          </div>
        </>
      ) : (
        <>
          <div className="single-section-container">
            <h3>{RENT_YEARLY_TITLE}</h3>
            <RentYearly
              currentYear={dataViewData?.currentMonth?.averageRent}
              currentYearDate={currentDate?.dateString}
              lastYear={dataViewData?.lastYear?.averageRent}
              currentDate={currentDate}
              error={
                dataViewData?.currentMonth?.error ||
                dataViewData?.lastYear?.error
              }
            />
          </div>
          <div className="single-section-container">
            <MapView />
          </div>
          <div className="single-section-container">
            <h3>{RENT_MONTHLY_TITLE}</h3>
            <RentMonthly
              currentMonth={dataViewData?.currentMonth?.averageRent}
              lastMonth={dataViewData?.lastMonth?.averageRent}
              twoMonthsAgo={dataViewData?.twoMonthsAgo?.averageRent}
              currentDate={currentDate}
              error={
                dataViewData?.currentMonth?.error ||
                dataViewData?.lastMonth?.error ||
                dataViewData?.twoMonthsAgo?.error
              }
            />
          </div>
        </>
      )}
    </div>
  );
}
