import React from "react";
import "./index.scss";

export default function ModalImage({ src }) {
  return (
    <div className="modal-image-container">
      <img rel={'prefetch'} src={src} alt={""} />
    </div>
  );
}
