import React from "react";
import { useNavigate } from "react-router-dom";
import Utils from "../../shared/utils";
import { Header } from "../header";
import useHeader from "../header/hooks/useHeader";
import { texts } from "./consts";
import "./index.scss";
import config from "../../../config/config";

const CTAContainer = () => {
    const navigate = useNavigate();
	const {isMortSite} = useHeader();

    return <div className="cta-container">
        <h1>{texts.text}</h1>
        <button onClick={() => isMortSite ? navigate("/mortinfo" ): window.location.href = config.HOMEPAGE_URL}>{texts.button}</button>
    </div>
}

const Background = () => {
    return <div className="background-container">
        <img className="background" src={Utils.buildImgLink('404/background.png')} />
        <img className="squares" src={Utils.buildImgLink('404/squares.svg')} />
    </div>
}

export default function FourOhFourPage() {    
    return <div className="FourOhFourPageContainer">
        <Header/>
        <div className="center-image-container">
            <Background />
            <img className="center-image" src={Utils.buildImgLink('404/center-image.svg')} />
            <img className="center-image-shadow" src={Utils.buildImgLink('404/center-image-shadow.svg')} />
        </div>
        <CTAContainer />
    </div>
}