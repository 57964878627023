import axios from "axios";
import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import config from "./config/config";
import "./index.scss";
import "./web/index.scss";
import useCampaignParams from "./web/hooks/useCampaignParams";
import { MortgageRouter } from "./web/mortgage";
import { NewMortgageRouter } from "./web/mortgage-new";
import { RentRouter } from "./web/rent";

import { URLS } from "./web/shared/urls";

import { store } from "./redux/store";
import { Provider, useSelector } from "react-redux";
import Modal from "./web/components/modal";
import useGetAllFlags from "./web/hooks/usePremiumFlag";
import Spinner from "./web/new-login/components/spinner";

function App() {
  const isLoading = useGetAllFlags();
  useCampaignParams();

  const isNewMortSiteActive = useSelector(
    (state) => state.flags.flags.isNewMortSiteActive
  );
  const isMortPersonalAreaActive = useSelector(
    (state) => state.flags.flags.isMortPersonalAreaActive
  );

  const Redirects = [
    {
      path: URLS.SURL,
      loader: async ({ params }) => {
        axios.defaults.baseURL = config.serverAddr;
        const url = await axios
          .get(`${config.serverAddr}/translateaddress/${params.token}`)
          .then((res) => {
            if (res) {
              return res.data.fullURL;
            }
            return null;
          });
        if (url) {
          return (window.location = `https://${url}`);
        }
        return null;
      },
    },
    {
      path: URLS.FURL,
      loader: async ({ params }) => {
        return (window.location = `${config.dashboardAddr}/furl/${params.token}`);
      },
    },
    {
      path: URLS.YAD2Agent,
      loader: async ({ params }) => {
        return (window.location = `${config.dashboardAddr}${URLS.YAD2Agent}`);
      },
    },
    {
      path: URLS.YAD2Owner,
      loader: async ({ params }) => {
        return (window.location = `${config.dashboardAddr}${URLS.YAD2Owner}`);
      },
    },
  ];

  const AlteredRentRouter = {
    ...RentRouter,
    children: RentRouter.children.filter((route) =>
      !isNewMortSiteActive ? !route.isPremium : route
    ),
  };

  const router = createBrowserRouter([
    ...Redirects,
    isNewMortSiteActive
      ? NewMortgageRouter({ isMortPersonalAreaActive })
      : MortgageRouter({ isMortPersonalAreaActive }),
    AlteredRentRouter,
  ]);

  if (isLoading) return <Spinner />;

  return <RouterProvider router={router} />;
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <Modal />
    <App />
  </Provider>
);
