import {Validators}          from "@wecheck/uisharedutils/validators";
import {useEffect, useState} from "react";
import Utils                 from "../../../../../../../../shared/utils";
import {NameAndPhone}        from "../../nameAndPhone";
import {Slide}               from "../../slide";

import "./index.scss";

const OwnerDetailsSlide = ({currName, currPhone, done, withShadow}) => {

	const israeliNumberRegex = /^[0][5][0|2|3|4|5|7|9]{1}[-]{0,1}[0-9]{7}$/g;

	const [firstName, setFirstName] = useState(currName);
	const [phone, setPhone]         = useState(currPhone);
	const [isValid, setIsValid]     = useState(false);

	const dataChanged = (name, phone) => {
		setFirstName(name);
		setPhone(phone);
	};

	const fireAnalytics = fieldName => {
		Utils.gaEvent("עמוד אני דייר", "טופס מילוי דיירים",
			fieldName === "name" ? "פרטי בעל דירה - שם בעל דירה" : "פרטי בעל דירה - טלפון בעל דירה");
	};

	const validateInput = () => Validators.hebrewOnly(firstName) && israeliNumberRegex.test(phone);

	useEffect(() => {
		setIsValid(validateInput());
	}, []);

	useEffect(() => {
		setIsValid(validateInput());
	}, [firstName, phone]);

	return <div className = "OwnerDetailsSlide" >
		<Slide withShadow = {withShadow}
		       caption1 = "יש לנו את זה!" caption2 = "אפשר לשאול מיהו בעל/ת הדירה המאושר/ת?" >
			<div className = "dataInput" >
				<NameAndPhone namePlaceHolder = "שם פרטי בעל/ת הדירה" phonePlaceHolder = "טלפון בעל/ת הדירה"
				              currName = {firstName} currPhone = {phone}
				              fireAnalytics = {fireAnalytics}
				              dataChanged = {dataChanged} />
			</div >

			<div className = "buttons" >
				<div className = "buttonDiv" onClick = {() => done(firstName, phone, false)} >
					<a
						className = "btn--transparent" >
						<img src = {Utils.buildTenantPageImgLink("rightArrow.svg")} alt = "" />
					</a >
				</div >
				<div className = "buttonDiv" >
					<a onClick = {() => {
						Utils.gaEvent("עמוד אני דייר", "טופס מילוי דיירים", "פרטי בעל דירה - מעבר לשלב הבא");
						done(firstName, phone, true);
					}}
					   className = {isValid ? "btn--azure" : "btn--gray"} >
						הבא
					</a >
				</div >
			</div >
		</Slide >
	</div >;
};

export {OwnerDetailsSlide};