import React from "react";
import "./index.scss";

export default function DetailsItem({ label, data, icon }) {
  return (
    <div className="details-item-container">
      <div className="details-item-icon">
        <img src={icon} alt="icon" />
      </div>
      <div className="details-item-data-section">
        <h5 className="details-item-label">{label}</h5>
        <p className="details-item-data">{data}</p>
      </div>
    </div>
  );
}
