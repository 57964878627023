import { URLS } from "../../../../../../shared/urls";
import { MODAL_TYPES } from "../../../../consts";

export const APPROVE_SECTION_TYPES = {
  [MODAL_TYPES.MORT_NO_QUE_FILLED]: {
    cancel: "מעדיף להגיש מאוחר יותר",
    confirm: "לבדיקת זכאות וקבלת הצעה",
    isExternalLink: true,
    href: URLS.MortgageQue,
  },
};
