import React from "react";
import "./index.scss";
import { useDispatch } from "react-redux";
import { handleFilter } from "../../../../../../redux/features/newsletterSlice";
import { CLOSE_ICON } from "../../../data-view/components/close-button/consts";

export default function CloseButton() {
    const dispatch = useDispatch();

    const close = () => {
        dispatch(handleFilter(false));
    };

    return (
        <button onClick={close} className="close-button filter">
            <img src={CLOSE_ICON} />
        </button>
    );
}
