import React          from "react";
import SectionWrapper from "../../../../../components/section-wrapper";
import Utils          from "../../../../../shared/utils";

import "./index.scss";

const headerProps = {
	title: "בדירת השותפים שלנו"
};

const Picture = ({children}) => (
	<div className = "Partners__window__container__pictures__picture" >
		<div className = "Partners__window__container__pictures__picture__pin" />
		<div className = "Partners__window__container__pictures__picture__content" >
			{children}
		</div >
	</div >
);

export default ({reference}) => (
	<div ref = {reference} className = "PartnersSection" >
		<SectionWrapper
			{...headerProps}
			withoutBrikim
			className = "Partners"
			id = "Partners"
		>
			<div className = "Partners__window" >
				<div className = "Partners__window__container" >
					<h4 className = "Partners__window__container__title desktopOnly" >
						אנחנו כאן כדי לקיים ולא רק להבטיח
					</h4 >
					<h4 className = "desktopOnly" >
						זו הסיבה שיצרנו שותפות אמיתית עם 2 חברות
						<br />
						המובילות בתחומן
					</h4 >
					<div className = "Partners__window__container__pictures" >
						<Picture >
							<img
								src = {Utils.buildImgLink("isracartlogo.jpg")}
								alt = ""
							/>
						</Picture >
						<Picture >
							<img
								src = {Utils.buildImgLink(
									"yad2NewLogo.svg",
									true
								)}
								alt = ""
							/>
						</Picture >
					</div >
				</div >
			</div >
		</SectionWrapper >
	</div >
);
