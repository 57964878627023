import Utils from "../../../../../shared/utils";

export const xAxisProps = {
  tick: {
    fontSize: "14px",
    fontWeight: "bold",
    fill: "#1D3557",
  },
  strokeWidth: 2,
  stroke: "#1D3557",
  tickMargin: 12,
  tickLine: false,
  dataKey: "name",
};

export const CATEGORY_GAP = "5%";

export const RADIUS = [10, 10, 0, 0];

export const BAR_CHART_COLORS = {
  first: "#3D5A80",
  second: "#87B2D7",
};

export const MARGINS = {
    DESK: 60,
    MOBILE: 30,
}

export const ERROR_DISPLAY = Utils.buildNewsletterLink('errors/bar.svg');
