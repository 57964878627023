import React from "react";
import "./index.scss";
import { NOOP } from "../../../../../../../utils";
import AnchorTagWithQueryParams from "../../../../../anchor-tag-with-params";
import useModal from "../../../../hooks/useModal";

export default function ModalSubmitButton({
  confirm: text,
  onClick = NOOP,
  isDisabled = false,
  isExternalLink = false,
  href,
}) {
  const { close: closeModal } = useModal();

  const handleSubmit = () => {
    onClick();
    closeModal();
  };
  
  return isExternalLink ? (
    <AnchorTagWithQueryParams
      className="modal-submit-button"
      href={href}
      text={text}
    />
  ) : (
    <button
      className={`modal-submit-button ${isDisabled ? "disabled" : ""}`}
      onClick={handleSubmit}
      disabled={isDisabled}
      type="default"
    >
      {text}
    </button>
  );
}
